const base = "https://api.mentordh.com"; // Productivo
//const base = "http://localhost:3001"; // Local

export default class Api {
  constructor() {
    this.http = new Http(base);
  }

  get base() {
    return this.http.base;
  }

  set ambito(ambito) {
    this.http.ambito = ambito;
  }
}

class Http {
  constructor(base) {
    this._base = base;
    this._ambito = "";
  }
  get base() {
    return this._base + (this._ambito ? `:${this._ambito}` : "");
  }
  set ambito(ambito) {
    this._ambito = ambito;
  }

  get(url, token = "", options = {}) {
    return fetch(`${this.base}/${url}`, {
      ...options,
      method: "GET", // or 'PUT'

      headers: {
        "Content-Type": "application/json",
        plataform: "mentor",
        authorization: token ? `Bearer ${token}` : "",
      },
    })
      .then((res) => {
        if (res.status === 401) {
          return { res, data: res.json(), logout: true };
        }
        if (res.headers.get("authorization")) {
          const _token_ = res.headers
            .get("authorization")
            .replace("Bearer ", "");

          if (token !== _token_) {
            return { res, data: res.json(), token: _token_ };
          }
        }
        return { res, data: res.json() };
      })
      .catch((err) => console.error("Http:err", err));
  }

  delete(url, token = "", options = {}) {
    return fetch(`${this.base}/${url}`, {
      ...options,
      method: "DELETE", // or 'PUT'

      headers: {
        "Content-Type": "application/json",
        plataform: "mentor",
        authorization: token ? `Bearer ${token}` : "",
      },
    })
      .then((res) => {
        if (res.status === 401) {
          return { res, data: res.json(), logout: true };
        }
        if (res.headers.get("authorization")) {
          const _token_ = res.headers
            .get("authorization")
            .replace("Bearer ", "");

          if (token !== _token_) {
            return { res, data: res.json(), token: _token_ };
          }
        }
        return { res, data: res.json() };
      })
      .catch((err) => console.error("Http:err", err));
  }

  patch(url, body, token = "", options = {}) {
    const headers = {
      "Content-Type": "application/json",
      plataform: "mentor",
    };
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    return fetch(`${this.base}/${url}`, {
      ...options,
      method: "PATCH", // or 'PUT'
      body: JSON.stringify(body), // data can be `string` or {object}!
      headers,
    })
      .then((res) => {
        if (res.status === 401) {
          return { res, data: res.json(), logout: true };
        }
        if (res.headers.get("authorization")) {
          const _token_ = res.headers
            .get("authorization")
            .replace("Bearer ", "");

          if (token !== _token_) {
            return { res, data: res.json(), token: _token_ };
          }
        }
        return { res, data: res.json() };
      })
      .catch((err) => {
        console.error("Http:err", err);
      });
  }

  put(url, body, token = "", options = {}) {
    const headers = {
      "Content-Type": "application/json",
      plataform: "mentor",
    };
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    return fetch(`${this.base}/${url}`, {
      ...options,
      method: "PUT", // or 'PUT'
      body: JSON.stringify(body), // data can be `string` or {object}!
      headers,
    })
      .then((res) => {
        if (res.status === 401) {
          return { res, data: res.json(), logout: true };
        }
        if (res.headers.get("authorization")) {
          const _token_ = res.headers
            .get("authorization")
            .replace("Bearer ", "");

          if (token !== _token_) {
            return { res, data: res.json(), token: _token_ };
          }
        }
        return { res, data: res.json() };
      })
      .catch((err) => {
        console.error("Http:err", err);
      });
  }

  post(url, body, token = "", options = {}) {
    const headers = {
      "Content-Type": "application/json",
      plataform: "mentor",
    };
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    return fetch(`${this.base}/${url}`, {
      ...options,
      method: "POST", // or 'PUT'
      body: JSON.stringify(body), // data can be `string` or {object}!
      headers,
    })
      .then((res) => {
        if (res.status === 401) {
          return { res, data: res.json(), logout: true };
        }

        if (res.headers.get("authorization")) {
          const _token_ = res.headers
            .get("authorization")
            .replace("Bearer ", "");

          if (token !== _token_) {
            return { res, data: res.json(), token: _token_ };
          }
        }
        return { res, data: res.json() };
      })
      .catch((err) => {
        console.error("Http:err", err);
      });
  }

  postBlob(url, body, token = "", options = {}) {
    const headers = {
      "Content-Type": "application/json",
      plataform: "mentor",
    };
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    return fetch(`${this.base}/${url}`, {
      ...options,
      method: "POST", // or 'PUT'
      body: JSON.stringify(body), // data can be `string` or {object}!
      headers,
    })
      .then((res) => {
        if (res.status === 401) {
          return { res, data: res.blob(), logout: true };
        }
        if (res.headers.get("authorization")) {
          const _token_ = res.headers
            .get("authorization")
            .replace("Bearer ", "");

          if (token !== _token_) {
            return { res, data: res.blob(), token: _token_ };
          }
        }
        return { res, data: res.blob() };
      })
      .catch((err) => console.error("Http:err", err));
  }

  postMultipart(url, body, token = "", options = {}) {
    const headers = {
      plataform: "mentor",
    };
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    return fetch(`${this.base}/${url}`, {
      ...options,
      method: "POST", // or 'PUT'
      body: body, // data can be `string` or {object}!
      headers,
    })
      .then((res) => {
        if (res.status === 401) {
          return { res, data: res.json(), logout: true };
        }
        if (res.headers.get("authorization")) {
          const _token_ = res.headers
            .get("authorization")
            .replace("Bearer ", "");

          if (token !== _token_) {
            return { res, data: res.json(), token: _token_ };
          }
        }
        return { res, data: res.json() };
      })
      .catch((err) => console.error("Http:err", err));
  }
}
