import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";



import "./Card.scss";
import cursoImg from "../../assets/mocks/activo-5.png";
import { transformHours } from "../../utilities/transformHours";

const Curso = ({
  image,
  name,
  hasProgress,
  progress,
  n_duration,
  n_modules,
  sku,
  ...props
}) => {
  return (
    <div
      className="CardCurso"
      style={{ backgroundImage: `url('${image?.location || cursoImg}')` }}
    >
      <Link to={`/curso/${sku}`}>
        <div className="vel"></div>
        <strong>{name}</strong>
        <section className="info">
          <article className="item">
            <i className="fas fa-bars"></i> Módulo
            {n_modules > 1 ? "s" : ""} : {n_modules}{" "}
          </article>
          <article className="item">
            <i className="fas fa-clock"></i> Horas:
            {transformHours(n_duration)}
          </article>
        </section>

        {hasProgress ? (
          <div className="curso-progress">
            <div
              className="bar"
              style={{
                width: `${progress > 100 ? 100 : progress < 0 ? 0 : progress}%`,
              }}
            ></div>
          </div>
        ) : null}
      </Link>
    </div>
  );
};

Curso.propTypes = {
  title: PropTypes.string,
  hasProgres: PropTypes.bool,
  progres: PropTypes.number,
};

Curso.defaultProps = {
  title: "Curso",
  hasProgress: false,
  progress: 10,
};

export default Curso;
