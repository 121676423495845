import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useController } from "react-hook-form";
import "../Forms.scss";

const SelectC = ({
  label,
  isMulti,
  name,
  control,
  rules,
  style,
  placeholder,
  options,
  defaultValue,
  onInput,
  optional,
  className,
  disabled,
  isClearable,
  readOnly,
  ...props
}) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    control,
    defaultValue,
    rules,
    name,
  });
  const selectProps = {
    ...props,
    isMulti,
    ref,
    placeholder,
    onChange: (v) => {
      if(isMulti){
        onChange(v.map(m=>m.value)||[]);
        onInput(v.map(m=>m.value)||[]);
      }
      else{
        onChange(v?.value||'');
        onInput(v?.value||'');
      }
    },
    onBlur,
    value:(isMulti?options.filter(ff=>value?.includes(ff.value)):options.find(f=>f.value===value))||null,
    isDisabled: disabled||readOnly,
    isClearable,
  };
  return (
    <div
      className={`FormC SelectC${isTouched ? " formc__touched" : ""}${
        isDirty ? " formc__dirty" : ""
      }`}
      style={style}
    >
      {label ? <label htmlFor={`_${name}_`}>{rules.required?<strong>* </strong>:null}{label}: {optional?<span className='optional'>({typeof optional === 'string'?optional:'opcional'})</span>:null}</label> : null}
      <Select id={`_${name}_`} className={`formc-input ${className||''}`} {...selectProps} options={options} />
      <span className="label__error">{invalid ? error.message : ""}</span>
    </div>
  );
};

SelectC.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  defaultValue: PropTypes.any,
  options: PropTypes.array,
};
SelectC.defaultProps = {
  name: "select",
  placeholder: "- Selecciona -",
  rules: {},
  onInput: (v) => {},
  isMulti: false,
  isClearable: true,
  options: [],
};

export default SelectC;
