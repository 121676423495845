import React, { useContext } from "react";
import { Helmet } from "react-helmet";

//Data
import GlobalContext from "../../context/GlobalContext";

//Components
import Cursos from "./Cursos";
import EditPerfil from "./EditPerfil";

/**
 * @component
 * Component responsible for displaying the selected profile of backoffice
 */

export default function PerfilBackoffice() {
  const {
    state: { user },
  } = useContext(GlobalContext);
  return (
    <div className="PerfilBackoffice ">
      <Helmet>
        <title>Perfil | Mentor</title>
      </Helmet>
      <EditPerfil isBackOffice={true} />
      {/* <h2>Cursos:</h2>
      <div className="perfil-container cursos flex-wrap">
        <Cursos
          style={{ display: "flex" }}
          url={`cursos/user/${user._id}/all`}
        />
      </div> */}
    </div>
  );
}
