const useFormatter = () => {
  const NumberF = (num, coin = false, replace = "", decimals = 2) => {
    return (
      (coin ? "$" : "") +
      Number(num)
        .toFixed(decimals)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        .replace(replace, "")
    );
  };
  const TextOrNumber = (str, ...rest) => {
    return isNaN(str) ? str : NumberF(str, ...rest);
  };
  const Unidades = (num) => {
    return (
      [
        "",
        "UN",
        "DOS",
        "TRES",
        "CUATRO",
        "CINCO",
        "SEIS",
        "SIETE",
        "OCHO",
        "NUEVE",
      ][num] || ""
    );
  }; // Unidades()
  const Decenas = (num) => {
    const decena = Math.floor(num / 10);
    const unidad = num - decena * 10;

    return [
      () => Unidades(unidad),
      () =>
        unidad < 6
          ? ["DIEZ", "ONCE", "DOCE", "TRECE", "CATORCE", "QUINCE"][unidad]
          : "DIECI" + Unidades(unidad),
      () => (!unidad ? "VEINTE" : "VEINTI" + Unidades(unidad)),
      () => DecenasY("TREINTA", unidad),
      () => DecenasY("CUARENTA", unidad),
      () => DecenasY("CINCUENTA", unidad),
      () => DecenasY("SESENTA", unidad),
      () => DecenasY("SETENTA", unidad),
      () => DecenasY("OCHENTA", unidad),
      () => DecenasY("NOVENTA", unidad),
    ][decena]();
  }; // Decenas()
  const DecenasY = (strSin, numUnidades) => {
    return strSin + (numUnidades > 0 ? ` Y ${Unidades(numUnidades)}` : "");
  }; // DecenasY()

  const Centenas = (num) => {
    const centenas = Math.floor(num / 100);
    const decenas = num - centenas * 100;
    return [
      () => Decenas(decenas),
      () => (decenas > 0 ? `CIENTO ${Decenas(decenas)}` : "CIEN"),
      () => `DOSCIENTOS ${Decenas(decenas)}`,
      () => `TRESCIENTOS ${Decenas(decenas)}`,
      () => `CUATROCIENTOS ${Decenas(decenas)}`,
      () => `QUINIENTOS ${Decenas(decenas)}`,
      () => `SEISCIENTOS ${Decenas(decenas)}`,
      () => `SETECIENTOS ${Decenas(decenas)}`,
      () => `OCHOCIENTOS ${Decenas(decenas)}`,
      () => `NOVECIENTOS ${Decenas(decenas)}`,
    ][centenas]();
  }; // Centenas()

  const Seccion = (num, divisor, strSingular, strPlural) => {
    const cientos = Math.floor(num / divisor);
    const resto = num - cientos * divisor;

    let letras = "";

    if (cientos > 0) {
      if (cientos > 1) letras = Centenas(cientos) + " " + strPlural;
      else letras = strSingular;
    }

    if (resto > 0) letras += "";

    return letras;
  }; // Seccion()

  const Miles = (num) => {
    const divisor = 1000;
    const cientos = Math.floor(num / divisor);
    const resto = num - cientos * divisor;

    const strMiles = Seccion(num, divisor, "UN MIL", "MIL");
    const strCentenas = Centenas(resto);

    return (strMiles !== "" ? `${strMiles} ` : "") + strCentenas;
  }; // Miles()

  const Millones = (num) => {
    const divisor = 1000000;
    const cientos = Math.floor(num / divisor);
    const resto = num - cientos * divisor;

    const strMillones = Seccion(num, divisor, "UN MILLON DE", "MILLONES DE");
    const strMiles = Miles(resto);

    return (strMillones !== "" ? `${strMillones} ` : "") + strMiles;
  }; // Millones()

  const NumeroALetras = (num) => {
    var data = {
      numero: num,
      enteros: Math.floor(num),
      centavos: Math.round(num * 100) - Math.floor(num) * 100,
      letrasCentavos: "",
      letrasMonedaPlural: "PESOS", // "PESOS", 'Dólares', 'Bolívares', 'etcs'
      letrasMonedaSingular: "PESO", // "PESOS", 'Dólar', 'Bolivar', 'etc'

      letrasMonedaCentavoPlural: "CENTAVOS",
      letrasMonedaCentavoSingular: "CENTAVO",
    };

    if (data.centavos > 0) {
      data.letrasCentavos =
        "CON " +
        (function () {
          if (data.centavos === 1) {
            return (
              Millones(data.centavos) + " " + data.letrasMonedaCentavoSingular
            );
          } else {
            return (
              Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural
            );
          }
        })();
    }

    if (data.enteros === 0) {
      return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    }
    if (data.enteros === 1) {
      return (
        Millones(data.enteros) +
        " " +
        data.letrasMonedaSingular +
        " " +
        data.letrasCentavos
      );
    } else {
      return (
        Millones(data.enteros) +
        " " +
        data.letrasMonedaPlural +
        " " +
        data.letrasCentavos
      );
    }
  };

  const countRepSubString = (str, find) => {
    try {
      let cuenta = 0;
      let posicion = `${str}`.indexOf(find);
      while (posicion !== -1) {
        cuenta++;
        posicion = `${str}`.indexOf(find, posicion + 1);
      }
      return cuenta;
    } catch (err) {
      debugger;
    }
  };

  const OnlyNumber = (str) => {
    if (countRepSubString(str, ".") > 1) {
      const [first, ...rest] = str.split(".");
      str = [first || 0, rest.join("")].join(".");
    }
    return str.replace(/([^0-9.])/g, "");
  };

  const OnlyAlphanumeric = (str) => {
    return str.replace(/([^a-zA-z0-9-])/g, "");
  };
  const OnlyTime = (str) => {
    switch (countRepSubString(str, ":")) {
      case 1: {
        const [first, ...rest] = str.split(":");
        str = [first || 0, rest.join("")].join(":");
        break;
      }
      case 0:
        break;
      case 2:
      default: {
        const [first, secund, ...rest] = str.split(":");
        str = [first || 0, secund || 0, rest.join("")].join(":");
        break;
      }
    }
    return str.replace(/([^0-9:])/g, "");
  };
  const OnlyNumberF = (str, coin = false) => {
    const format = OnlyNumber(str);
    const hasDot = format.includes(".");
    const [total, decimals] = format.split(".");
    return (
      (coin ? "$" : "") +
      (total
        .split("")
        .reverse()
        .join("")
        .match(/.{1,3}/g)
        ?.reverse()
        .map((m) => m.split("").reverse().join(""))
        .join(",") || "") +
      `${hasDot ? "." + (decimals || "") : ""}`
    );
  };
  const isValidURL = (str) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  function BlobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  function BlobtoUrl(blob) {
    return URL.createObjectURL(blob);
  }

  return {
    OnlyNumber,
    OnlyTime,
    OnlyNumberF,
    NumberF,
    TextOrNumber,
    NumeroALetras,
    BlobToBase64,
    BlobtoUrl,
    isValidURL,
    OnlyAlphanumeric,
  };
};

export default useFormatter;
