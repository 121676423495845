import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from "react-slick";



const SlidersBanner = ({banners}) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        cssEase: "linear",
        pauseOnHover: true,
        
      };
    return(
        banners.length&&banners.length>1?
        <Slider {...settings}>
            {banners.map(banner=><Banner {...banner} key={banner.key} />)}
        </Slider>
        :<Banner {...banners[0]} />
    )
}
const Banner = ({location})=>{
  const strongRef = useRef(null)
  const [strongHeight, setStrongHeight] = useState( (strongRef.current?.offsetHeight||0)/2)
  useEffect(() => {
    setStrongHeight((strongRef.current?.offsetHeight||0)/2)
  }, [strongRef])
    return(
        <div className="Slide-Banner">
            <div className="banner-body" style={{backgroundImage: `url('${location}')`}}>
            </div>
        </div>
    )
}


SlidersBanner.propTypes = {
  banners: PropTypes.array
}
SlidersBanner.defaultProps = {
  banners: []
}

export default SlidersBanner