import React, { useCallback, useContext, useEffect, useState } from "react";

//Data
import GlobalContext from "../../context/GlobalContext";

//Components
import CursoItem from "./CursoItem";

/**
 * @component
 * Component in charge of displaying courses
*/

export default function Cursos({ url }) {
  const { setAppLoading, NestGet } = useContext(GlobalContext);
  const [data, setData] = useState([]);

  const fetchData = useCallback(
    async (page = 1) => {
      setAppLoading(true);
      NestGet({
        schema: url,
      })
        .then((res) => {
          setData(res.data);
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading, url]
  );

  useEffect(() => {
    fetchData();
  }, []);
  console.log(data)
  return (
    <>
      {data && data.length !== 0 ? (
        data.map((cursoAsignado) => (
          <CursoItem
            item={cursoAsignado.curso ? cursoAsignado.curso : cursoAsignado}
            percentage={cursoAsignado.percentage}
            key={(cursoAsignado.curso ? cursoAsignado.curso : cursoAsignado)._id}
          />
        ))
      ) : (
        <h1 className="curso_empty">No hay cursos.</h1>
      )}
    </>
  );
}
