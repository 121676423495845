import React, { useRef } from "react";
import PropTypes from "prop-types";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import "./Gallery.scss";

const Gallery = ({ images }) => {
  const carouselRef = useRef(null);

  const images_ = images.map((img) => ({
    src: img,
  }));

  const _images_= images.slice(0, 2)

  const handleToggleIsMaximized = (index) => {
    carouselRef.current.goToIndex(index);
    carouselRef.current.maximize();
  };

  return (
    <div className="Gallery">
      {_images_.map((src, i) => (
        <Image src={src} alt={`imagen numero ${i + 1} de la galeria`} key={i} onClick={()=>handleToggleIsMaximized(i)} />
      ))}
        <div className="gallery-veil" onClick={()=>handleToggleIsMaximized(_images_.length-1)}>
            Ver más
        </div>

      <Carousel
        ref={carouselRef}
        toggleIsMaximized={handleToggleIsMaximized}
        images={images_}
        style={{ height: "0px", width: "0px" }}
      />
    </div>
  );
};

const Image = ({ src, alt, onClick }) => {
  return (
    <div className="gallery-item" onClick={onClick}>
      <img src={src} alt={alt} />
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Gallery;
