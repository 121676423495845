import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import "../Forms.scss";

const CheckGroupC = ({
  name,
  control,
  rules,
  style,
  options,
  defaultValue,
  onInput,
  className,
  readOnly,
  disabled,
  ...props
}) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isTouched, isDirty, error },
  } = useController({
    control,
    defaultValue,
    rules,
    name,
  });

  const isTouched_ = isTouched ? " formc__touched" : "";
  const isDirty_ = isDirty ? " formc__dirty" : "";
  const className_ = className ? ` ${className}` : "";
  const error_ = error ? ` label__error` : "";

  const selectProps = {
    ref,
    onChange: (v) => {
      let newValue = [];
      if (v.target.checked) {
        newValue.push(...value, v.target.value);
      } else {
        newValue = value.filter((ff) => v.target.value !== ff);
      }
      onChange(newValue);
      onInput(newValue);
    },
    onBlur,
    ...props,
  };

  const _disable = useMemo(() => {
    const arrDisabled = Array.isArray(disabled)
      ? disabled
      : options.map((m) => disabled && m);
    return options.map((m) => arrDisabled.includes(m) || readOnly);
  }, [disabled, options, readOnly]);

  return (
    <div
      className={`CheckGroupC${isTouched_}${isDirty_}${className_}${error_}`}
    >
      {options.map((option, i) => (
        <label
          key={`${name}_${i}`}
          className={`CheckC${_disable[i] ? " disabled" : ""}`}
          htmlFor={`_${name}_${option}`}
        >
          <input
            {...selectProps}
            id={`_${name}_${option}`}
            type="checkbox"
            value={option}
            checked={value?.includes(option)}
            disabled={_disable[i]}
          />
          <span className="checkbox"></span>
          {option}
        </label>
      ))}
    </div>
  );
};

const HashMap = ({
  name,
  control,
  rules,
  style,
  options,
  defaultValue,
  onInput,
  className,
  disabled,
  readOnly,
  ...props
}) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isTouched, isDirty, error, invalid },
  } = useController({
    control,
    defaultValue,
    rules,
    name,
  });

  const isTouched_ = isTouched ? " formc__touched" : "";
  const isDirty_ = isDirty ? " formc__dirty" : "";
  const className_ = className ? ` ${className}` : "";
  const error_ = error ? ` label__error` : "";

  const selectProps = {
    ref,
    onChange: (v) => {
      let newValue = [];
      if (v.target.checked) {
        newValue.push(...(value || []), v.target.value);
      } else {
        newValue = value.filter((ff) => v.target.value !== ff);
      }
      onChange(newValue);
      // debugger
      onInput(newValue);
    },
    onBlur,
    ...props,
  };

  const _disable = useMemo(() => {
    const arrDisabled = Array.isArray(disabled)
      ? disabled
      : options.map((m) => disabled && m);
    return options.map((m) => arrDisabled.includes(m.value) || readOnly);
  }, [disabled, options, readOnly]);

  return (
    <div
      className={`CheckGroupC${isTouched_}${isDirty_}${className_}${error_}`}
    >
      {invalid ? <span className="label__error"> {error.message}</span> : null}
      {options.map((option, i) => (
        <label
          key={`${name}_${i}`}
          className={`CheckC${_disable[i] ? " disabled" : ""}`}
          htmlFor={`_${name}_${option.value}`}
        >
          <input
            {...selectProps}
            id={`_${name}_${option.value}`}
            type="checkbox"
            value={option.value}
            checked={value?.includes(option.value)}
            disabled={_disable[i]}
          />
          <span className="checkbox"></span>
          {option.label}
        </label>
      ))}
    </div>
  );
};

CheckGroupC.HashMap = HashMap;

HashMap.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,

  defaultValue: PropTypes.array,
};
HashMap.defaultProps = {
  name: "select",
  rules: {},
  onInput: (v) => {},
  defaultValue: [],
  value: true,
};
CheckGroupC.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,

  defaultValue: PropTypes.array,
};
CheckGroupC.defaultProps = {
  name: "select",
  rules: {},
  onInput: (v) => {},
  defaultValue: [],
  value: true,
};

export default CheckGroupC;
