import React, { useContext, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UserForm } from "../../components/Forms";
import GlobalContext from "../../context/GlobalContext";
import { TabSimple, Title } from "../../core";
import useS3File from "../../hooks/useS3File";
import Cursos from "../Backoffice/Cursos";
import Swal from "sweetalert2";
/**
 * @component
 * Component responsible for displaying the info of a user(not admin)
 */

const EditUser = () => {
  const { id: user_id } = useParams();
  const { NestGet, NestPatch, setAppLoading } = useContext(GlobalContext);
  const { control, handleSubmit, setValue, unregister } = useForm();
  const { handleUpload, handleDelete } = useS3File();

  const fetchData = useCallback(() => {
    NestGet({ schema: "user", id: user_id }).then((res) => {
      delete res.data.id;
      const {
        customer_data,
        bill_data,
        phone,
        names,
        last_name,
        mothers_last_name,
        email,
        image,
        tipo_estudiante,
        puesto,
      } = res.data;
      if (res.status === "success") {
        Object.keys({
          customer_data,
          bill_data,
          phone,
          names,
          last_name,
          mothers_last_name,
          email,
          image,
          tipo_estudiante,
          puesto,
        }).forEach((key) => {
          setValue(key, res.data[key]);
        });
      }
    });
  }, [user_id, NestGet, setValue]);

  const onSubmit = useCallback(
    async (data) => {
      setAppLoading(true);
      if ( (data.bill_data !== undefined && data.bill_data.persona !== undefined) && data.bill_data.persona === '' ) {
        data.bill_data.cfdi_use = '';
        data.bill_data.cp = '';
        data.bill_data.email = '';
        data.bill_data.full_name = '';
        data.bill_data.regimen = '';
        data.bill_data.rfc = '';
        data.bill_data.tax_residence = '';
        
      }
      data.image = await handleUpload(data.image, `mentor/cursos/${user_id}`);
      NestPatch({ schema: "user", body: data, id: user_id })
        .then((res) => {
          if (res.status === "success") {           
            Swal.fire({
              title: "Cambios guardados correctamente",
              icon: "success",
            });
            fetchData();
            setAppLoading(false);
          }     
        })
        .catch((err) => {
          Swal.fire({
            title: "Hubo un error intenta mas tarde",
            icon: "error",
          });
        });
    },    
    [NestPatch, user_id, fetchData]
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="User">
      <Helmet>
        <title>Usuario | Mentor</title>
      </Helmet>
      <div className="not-container">
        <UserForm
          isBackOfficeEditing={true}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
        />
      </div>
      {/* <div className="work_area">
        <div className="cursos-impartidos">
          <TabSimple className="tabla-informacion">
            <TabSimple.Tab color="black" name="CURSOS">
              <Cursos url={`cursos/user/${user_id}/all`} />
            </TabSimple.Tab>
          </TabSimple>
        </div>
      </div> */}
    </div>
  );
};

export default EditUser;
