import React from 'react'
import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'
import '../Forms.scss'

const ToggleSwitchC = ({control, defaultValue, rules, name, disabled, onChange, onInput, checked, label, optional, style, color, ...props}) => {

  const {
    field: { ref, onChange:onChangeC, onBlur, value },
    fieldState: { isTouched, isDirty, error, invalid },
  } = useController({
    control,
    defaultValue: defaultValue||false,
    name,
  });
      const selectProps = {
        onChange: (v) => {
          onChangeC(v.target.checked);
          onChange(v.target.checked);
          onInput(v.target.checked);
        },
        disabled,
        checked: value,
        ...props
      };
    return (
      <div className={`FormC ${isTouched ? " formc__touched" : ""}${isDirty ? " formc__dirty" : ""}`} style={{...style,...color?{'--color-switch':color}:{}}} onClick={onBlur}>
      {label ? <label htmlFor={`_${name}_`}>{rules.required?<strong>* </strong>:null}{label}: {optional?<span className='optional'>({typeof optional === 'string'?optional:'opcional'})</span>:null}</label> : null}
        <div className="ToggleSwitchC">
            <label className="switch" htmlFor={`_${name}_`}>
                <input type="checkbox" {...selectProps} id={`_${name}_`} />
                <span className="slider round"></span>
            </label>
        </div>
        <span className="label__error">{invalid ? error.message : ""}</span>
      </div>
    )
}

ToggleSwitchC.propTypes = {

name: PropTypes.string,

rules: PropTypes.object,
onInput: PropTypes.func,
onChange: PropTypes.func,

defaultValue: PropTypes.bool,

};

ToggleSwitchC.defaultProps = {
name: "select",
rules: {},
onInput: (v) => {},
onChange: (v) => {},
defaultValue: false,
value: true
};

export default ToggleSwitchC
