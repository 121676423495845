import React, { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

//Data
import GlobalContext from "../../context/GlobalContext";

//Components
import PedidoGrid from "./PedidoGrid";

/**
 * @component
 * Component responsible for displaying the list of orders
 */

const Pedido = () => {
  const { setAppLoading, NestGet } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState();

  const columns = [
    {
      title: "N.Pedido",
      key: "n_pedido",
    },
    {
      title: "Usuario",
      key: "user",
    },

    {
      title: "Cursos",
      key: "courses",
    },
    {
      title: "Precio comprado",
      key: "amount",
    },
    {
      title: "Estatus",
      key: "stripe.paymentIntent.status",
    },
    {
      title: "Solicitud de factura",
      key: "request_invoice",
    },

    {
      title: "",
      key: "action",
    },
  ];

  const fetchData = useCallback(
    async (page = 1) => {
      setAppLoading(true);
      NestGet({
        schema: "orders/detail",
      })
        .then((res) => {
          const columnData = [];
          console.log("resdata", res.data);
          let index = 0;
          for (const ord of res.data) {
            console.log(`pedido ${ord.n_pedido}`, ord);
            columnData.push({
              n_pedido: ord.n_pedido,
              user: ord.user,
              courses:
                ord.num_course > 1
                  ? ord.num_course
                  : res.data[index].courses[0].name,
              amount: ord.amount,
              stripe: ord.stripe,
              notes: ord.notes,
              _id: ord._id,
              coursesInfo: ord.courses,
              buy_date: ord.buy_date,
              discount: ord.discount,
              request_invoice: ord.request_invoice,
            });
            index++;
          }

          console.log(columnData);
          setData(columnData);
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading]
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Pedidos | Mentor</title>
      </Helmet>
      <div className="work_area">
        <PedidoGrid
          columns={columns}
          data={data}
          fetchData={fetchData}
          pagination={pagination}
        />
      </div>
    </>
  );
};

export default Pedido;
