import Swal from "sweetalert2";
import React, { useCallback, useContext, useEffect, useState } from "react";

//Hooks
import { useForm, useWatch } from "react-hook-form";
import useS3NestFile from "../../hooks/useS3NestFile";
import { rulesRequired } from "../../Constants";

//Components
import {
  Button,
  CheckC,
  Dialog,
  FileInputC,
  Grid,
  InputC,
  PillButton,
  TextAreaC,
  ToggleSwitch,
  LabelWrapper,
  ImageFullScreen,
} from "../../core";

//Data
import GlobalContext from "../../context/GlobalContext";
import { Helmet } from "react-helmet";

/**
 * @component
 * Component in charge of banners
 */

const BannersGrid = () => {
  const { handleUpload, handleDelete: handleDelete_ } = useS3NestFile();
  const { setAppLoading, NestGet, NestPost, NestDelete, NestPatch, NestPut } =
    useContext(GlobalContext);
  const { control, handleSubmit, reset, setValue } = useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState();
  const has_button = useWatch({
    control,
    name: "has_button",
    defaultValue: false,
  });
  const active = useWatch({ control, name: "active", defaultValue: true });
  const [spinLoad, setSpinLoad] = useState(false);
  const columns = [
    {
      title: "Imagen",
      key: "image.location",
      width: 300,
    },
    {
      title: "Título",
      key: "title",
    },
    {
      title: "Mensaje",
      key: "message",
      style: {
        maxWidth: 400,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },

    {
      title: "Estatus",
      key: "active",
    },

    {
      title: "Acciones",
      width: 100,
      key: "action",
    },
  ];
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Eliminar",
      text: "¿Quieres eliminar este elemento?",
      icon: "question",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setAppLoading(true);
        NestDelete({
          schema: "banners",
          id,
        })
          .then((id) => {
            const found = data.find((f) => f.id === id);
            if (found) {
              handleDelete_(found.image.key);
            }
            fetchData();
          })
          .finally((_) => {
            setAppLoading(false);
          });
      }
    });
  };

  const changeStatus = async (id, active) => {
    NestPatch({
      schema: "banners",
      body: { active },
      id,
    }).then((res) => {
      if (res.status === "success") {
        const newData = JSON.parse(JSON.stringify(data));
        const index = newData.map((m) => m.id).indexOf(id);
        if (index > -1) {
          newData[index] = res.data;
          setData(newData);
        }
      }
    });
  };

  const closeModal = useCallback(() => {
    reset({ file: null, name: "", description: "" });
    setVisible(false);
  }, [reset]);

  const handleEdit = async (id) => {
    const found = data.find((f) => f.id === id);
    if (found) {
      Object.keys(found).forEach((key) => {
        setValue(key, found[key]);
      });
      setVisible(true);
    }
  };

  const onSubmit = async (doc) => {
    setSpinLoad(true);
    doc.system = "Mentor";
    console.log(doc);
    const s3Data = await handleUpload(doc.image, `mentor/banner`);
    if (!s3Data) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: " Algo salió mal, ¡intenta después!",
      });
    }
    doc.image = { location: s3Data.location, key: s3Data.key };
    let promise;
    if (doc.id) {
      promise = NestPut({
        schema: "banners",
        body: doc,
        id: doc.id,
      });
    } else {
      promise = NestPost({
        schema: "banners",
        body: doc,
      });
    }
    promise.then((res) => {
      if (res.status === "success") {
        const found = data.find((f) => f.id === doc.id);
        if (found && found.image.key !== res.data.image.key) {
          handleDelete_(found.image.key);
        }
        reset({
          active: true,
          title: "",
          message: "",
          image: null,
          has_button: false,
        });
        setVisible(false);
        fetchData();
        setSpinLoad(false);
      }
    });
  };
  const fetchData = useCallback(
    async (page = 1) => {
      setAppLoading(true);
      NestGet({
        schema: `banners`,
        query: {
          limit: 20,
          page,
          query: JSON.stringify({ system: "Mentor" }),
        },
      })
        .then((res) => {
          setData(res.data);
          setPagination(res.pagination);
          console.log("fetchData", res);
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading]
  );

  useEffect(() => {
    fetchData();
    // handleDelete_('mentor/banner/0/PQnYq1zP6GkefetGQorq3.png')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Banners | Mentor</title>
      </Helmet>
      <div className="work_area">
        <div className="d-flex justify-content-end">
          <Button color="#66bb6a" onClick={(_) => setVisible(true)}>
            Agregar <i className="fa fa-plus"></i>
          </Button>
        </div>
        <Grid
          rowKey="id"
          columns={columns}
          dataSource={data}
          className="table-striped"
          pagination={pagination}
          onChangePage={fetchData}
        >
          <Grid.Column
            name="image.location"
            context={({ column, row }) => (
              <div className="Film">
                <div className="preview">
                  <ImageFullScreen
                    className="w-100"
                    style={{ objectFit: "contain" }}
                    src={column}
                    alt={row.name}
                  />
                </div>
              </div>
            )}
          />
          <Grid.Column
            name="active"
            context={({ column, row }) => (
              <>
                <ToggleSwitch
                  checked={column}
                  name={`${row.id}`}
                  onChange={(v) => changeStatus(row.id, v)}
                />
              </>
            )}
          />
          <Grid.Column
            name="action"
            context={({ column, row }) => (
              <div className="d-flex">
                <PillButton
                  color={"#00b8d4"}
                  onClick={(_) => handleEdit(row.id)}
                >
                  <i className="fas fa-edit"></i>
                </PillButton>
                <PillButton
                  color={"#c62828"}
                  onClick={(_) => handleDelete(row.id)}
                >
                  <i className="fas fa-trash"></i>
                </PillButton>
              </div>
            )}
          />
        </Grid>
        <Dialog visible={visible} onClose={closeModal}>
          <Dialog.Header>
            <strong>Agregar un banner para la pantalla de inicio</strong>
          </Dialog.Header>
          <Dialog.Body>
            <LabelWrapper label="Estatus" className="mb-3">
              <ToggleSwitch
                name="active"
                checked={active}
                onChange={(v) => setValue("active", v)}
              />
            </LabelWrapper>
            <InputC
              control={control}
              label="Título"
              name="title"
              rules={rulesRequired}
            />
            <TextAreaC control={control} label="Mensaje" name="message" />
            <FileInputC
              control={control}
              label="Banner"
              name="image"
              accept="image/*"
              rules={rulesRequired}
            />
            <LabelWrapper label="Tiene botón" className="mb-3">
              <ToggleSwitch
                name="has_button"
                checked={has_button}
                onChange={(v) => setValue("has_button", v)}
              />
            </LabelWrapper>
            {has_button ? (
              <>
                <InputC
                  control={control}
                  label="Texto del botón"
                  name="button_text"
                  rules={rulesRequired}
                />
                <InputC
                  control={control}
                  label="URL de redirección"
                  name="button_action"
                  rules={rulesRequired}
                />
                <CheckC control={control} name="is_internal">
                  El enlace es interno
                </CheckC>
              </>
            ) : null}
          </Dialog.Body>
          <Dialog.Footer className="d-flex justify-content-end">
            {spinLoad ? (
              <i className="fa-solid fa-spinner fa-spin-pulse"></i>
            ) : (
              <Button onClick={handleSubmit(onSubmit)}>Cargar</Button>
            )}
          </Dialog.Footer>
        </Dialog>
      </div>
    </>
  );
};

export default BannersGrid;
