import { useCallback, useEffect, useRef } from 'react';
import socketio from "socket.io-client";
import API from '../utilities/api';

const api = new API();


const useSocket = () => {
  const socketRef = useRef()
  const setSocket = useCallback(
    (token) => {
      socketRef.current = socketio.connect(api.base,{
        query: { token }
      })
    },
    [],
  )
  useEffect(() => {
    socketRef.current = socketio.connect(api.base)
    return () => {
        
    };
  }, [])
  return [socketRef.current, setSocket]
};

export default useSocket;
