import React, { useContext, useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Grid, InputC, CheckC, CheckGroupC } from "../../core";
import { useWatch } from "react-hook-form";

const columns = [
  {
    title: "",
    width: 100,
    key: "action",
  },
  {
    title: "Nombre completo",

    key: "names",
  },
  {
    title: "Correo",
    key: "email",
  },
  {
    title: "RFC",
    key: "customer_data.rfc",
  },
  {
    title: "Dirección",
    key: "customer_data.direction",
  },
];

const UserPriceGrid = ({
  control,
  labelInputCopy,
  copy,
  userType,
  OnlyNumberF,
  setValue,
}) => {
  const { NestGet, setAppLoading } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState();
  const [interval, setInterval] = useState(null);
  const query = useWatch({ control, name: `search_${userType}` });
  const selectAll = useWatch({
    control,
    name: `cost_all_${userType}`,
  });

  console.log({ selectAll });

  const fetchData = useCallback(
    (page) => {
      setAppLoading(true);
      NestGet({
        schema: `user/search?limit=10&page=${page}&usrType=${userType}${
          query ? `&query=${query}` : ""
        }`,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
            setPagination(res.pagination);
          }
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading, query]
  );

  useEffect(() => {
    setValue(`cost_all_${userType}`, selectAll);
  }, []);

  useEffect(() => {
    clearTimeout(interval);
    setInterval(
      setTimeout(() => {
        fetchData(1);
      }, 500)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (selectAll) setUsersSelected();
  }, []);

  function handleSelectAll() {
    //The form will have the latest state, eg if its checked it will be false, if its not then it will be true
    const currentState = !selectAll;
    if (!data) return setValue(`users_${userType}`, []);
    if (currentState) {
      setUsersSelected();
    }
    return setValue(`users_${userType}`, []);
  }

  function setUsersSelected() {
    NestGet({
      schema: `user/search?usrType=${userType}`,
    })
      .then((res) => {
        if (res.status === "success") {
          if (!res.data) return;
          const usersId = res.data.map((user) => user._id);
          setValue(`cost_all_${userType}`, true);
          return setValue(`users_${userType}`, usersId);
        }
      })
      .finally((_) => {
        setAppLoading(false);
      });
  }

  return (
    <>
      <div className="w-100 " style={{ marginBottom: "70px" }}>
        <div className="w-100">
          <InputC
            control={control}
            name={`cost_${userType}`}
            label={labelInputCopy}
            setFormate={OnlyNumberF}
          />
        </div>
        <div className="w-100">
          <div className="row">
            <div className="col-md-9">
              <p>{copy}</p>
            </div>
            <div className="col-md-3">
              <InputC
                control={control}
                name={`search_${userType}`}
                placeholder="Buscar"
                className="w-100"
              />
            </div>
          </div>
        </div>
        <CheckC
          onClick={handleSelectAll}
          control={control}
          name={`cost_all_${userType}`}
        >
          Seleccionar todos
        </CheckC>
        <Grid
          rowKey="id"
          columns={columns}
          dataSource={data}
          className="table-striped"
          pagination={pagination}
          onChangePage={fetchData}
        >
          <Grid.Column
            name="names"
            context={({ column, row }) => (
              <>
                {row.names} {row.last_name} {row.mothers_last_name}
              </>
            )}
          />
          <Grid.Column
            name="action"
            context={({ column, row }) => (
              <div className="d-flex">
                <CheckGroupC.HashMap
                  options={[
                    {
                      value: row._id,
                      label: "",
                    },
                  ]}
                  name={`users_${userType}`}
                  control={control}
                />
              </div>
            )}
          />
        </Grid>
      </div>
    </>
  );
};

export default UserPriceGrid;
