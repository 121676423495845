import React, { useCallback, useContext, useEffect } from "react";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";

import GlobalContext from "./context/GlobalContext";
import { toast } from "react-toastify";

import {
  AvisoPrivacidad,
  Buscardor,
  Confirm,
  Contacto,
  Forgot,
  ForgotForm,
  Inicio,
  Login,
  Registro,
  TerminosCondiciones,
  CentroDeAyuda,
  QuienesSomos,
  NuestroObjetivo,
} from "./pages/Inicio";
import { Capsula, Curso, Cursos, Quiz, Examen } from "./pages/Cursos";
import Home from "./layout/Home";
import Dash from "./layout/Dash";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import {
  BannersGrid,
  BuzonContactoGrid,
  CategoriasGrid,
  CursosForm,
  CursosGrid,
  FilmotecaGrid,
  Perfil,
  ReportesCursos
} from "./pages/Backoffice";
import {
  User,
  UserGrid,
  Kardex,
  Diplomado,
  ListaPedidos,
  DetallesPedido,
  Buscador,
} from "./pages/Users";
import CarritoCompras from "./pages/Users/CarritoCompras";
import ListaDeseos from "./pages/Users/ListaDeseos";
import Pedido from "./pages/Backoffice/Pedido";
import PedidoDetalle from "./pages/Backoffice/PedidoDetalle";
import AsignarCurso from "./pages/Backoffice/AsignarCurso";
import EditPerfil from "./pages/Backoffice/EditPerfil";
import PerfilBackoffice from "./pages/Backoffice/PerfilBackoffice";
import AuthChooser from "./pages/Inicio/AuthChooser";

const SwitchRouter = () => {
  const history = useHistory();
  const { socket, GetMyNotifications, isAdmin, isLogin } =
    useContext(GlobalContext);
  const notifyCallback = useCallback(
    (payload) => {
      toast(payload.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          history.push(`/${payload.endpoint}`);
        },
      });
      GetMyNotifications();
    },
    [GetMyNotifications, history]
  );
  useEffect(() => {
    socket?.on("notify", notifyCallback);
    return () => {
      socket?.off("notify", notifyCallback);
    };
  }, [notifyCallback, socket]);
  return (
    <Switch>
      {isLogin && isAdmin ? (
        <Route path="/Backoffice">
          <BackOfficeRouter />
        </Route>
      ) : (
        <Redirect path="/Backoffice" to="/" strict />
      )}
      <Route exact path="">
        <StudentRouter />
      </Route>
    </Switch>
  );
};

const BackOfficeRouter = () => {
  const match = useRouteMatch();
  const { isLogin, isAdmin } = useContext(GlobalContext);

  // debugger
  return (
    <Dash>
      <Switch>
        <Route exact path={`${match.path}/Perfil`}>
          <PerfilBackoffice />
        </Route>
        <Route exact path={`${match.path}/Filmoteca`}>
          <FilmotecaGrid />
        </Route>
        <Route path={`${match.path}/Usuarios`}>
          <UserRoutes />
        </Route>
        <Route path={`${match.path}/Reportes`}>
          <ReportesCursos />
        </Route>
        <Route exact path={`${match.path}/Banners`}>
          <BannersGrid />
        </Route>
        <Route exact path={`${match.path}/Categorias`}>
          <CategoriasGrid />
        </Route>
        <Route path={`${match.path}/Cursos`}>
          <CursosRouter />
        </Route>
        <Route exact path={`${match.path}/Buzon_contacto`}>
          <BuzonContactoGrid />
        </Route>
        <Route exact path={`${match.path}/Pedido`}>
          <Pedido />
        </Route>
        <Route exact path={`${match.path}/Pedido/:id`}>
          <PedidoDetalle />
        </Route>
        <Route exact path={`${match.path}/Asignar`}>
          <AsignarCurso />
        </Route>
        <Redirect to={`${match.path}/Filmoteca`} />
      </Switch>
    </Dash>
  );
};

function CursosRouter() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/new`} name="NewCourse">
        <CursosForm isNew />
      </Route>
      <Route exact path={`${match.path}/:id`} name="EditCourse">
        <CursosForm />
      </Route>
      <Route exact path={`${match.path}`}>
        <CursosGrid />
      </Route>
    </Switch>
  );
}

function UserRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/new`} name="NewUser">
        <User isNew='true'/>
      </Route>
      <Route exact path={`${match.path}/:id`} name="EditUser">
        <User isNew='false'/>
      </Route>
      <Route exact path={`${match.path}`}>
        <UserGrid />
      </Route>
      <Route exact path={`${match.path}`}>
        <UserGrid />
      </Route>
    </Switch>
  );
}

const StudentRouter = () => {
  return (
    <Home>
      <Switch>
        <Route exact path="/Contacto">
          <Contacto />
        </Route>
        <Route exact path="/quienes_somos">
          <QuienesSomos />
        </Route>
        <Route exact path="/nuestro_objetivo">
          <NuestroObjetivo />
        </Route>
        <Route exact path="/aviso_de_privacidad">
          <AvisoPrivacidad />
        </Route>
        <Route exact path="/centro_de_ayuda">
          <CentroDeAyuda />
        </Route>
        <Route exact path="/terminos_condiciones">
          <TerminosCondiciones />
        </Route>

        <Route exact path="/cursos">
          <Cursos />
        </Route>
        <Route exact path="/cursos/:sku">
          <Cursos />
        </Route>
        <Route exact path="/curso/:sku">
          <Curso />
        </Route>
        <Route exact path="/buscar/:busqueda">
          <Buscador />
        </Route>
        <Route exact path="/Perfil">
          <Perfil />
        </Route>
        <Route exact path="/Perfil/Edit">
          <EditPerfil />
          {/* <EditPerfil /> */}
        </Route>
        <Route exact path="/Pedidos">
          <ListaPedidos />
        </Route>
        <Route exact path="/Detalles/Pedido/:id">
          <DetallesPedido />
        </Route>
        {/* <Route exact path="/Kardex">
          <Kardex />
        </Route> */}

        <Route exact path="/registro">
          <AuthChooser />
        </Route>
        <Route exact path="/login">
          <AuthChooser />
        </Route>
        <Route exact path="/process-forgot">
          <Forgot />
        </Route>
        <Route exact path="/forgot-form/:token">
          <ForgotForm />
        </Route>
        <Route exact path="/confirm/:token">
          <Confirm />
        </Route>
        {/*
        <Route exact path="/buscar/:query">
          <Buscardor />
        </Route>*/}
        {/* :sku/:module */}
        <Route exact path="/clase/:sku/:module/quiz">
          {/* ruta para la pantalla de examnes */}
          <Quiz />
        </Route>
        <Route exact path="/clase/:sku/:module/:capsule">
          <Capsula />
        </Route>
        <Route exact path="/exam/:sku">
          {/* ruta para la pantalla de examnes */}
          <Examen />
        </Route>
        <Route exact path="/diplomado/:sku">
          {/* ruta para la pantalla de diplomados */}
          <Diplomado />
        </Route>
        <Route exact path="/ver_carrito/">
          <CarritoCompras />
        </Route>
        <Route exact path="/wishlist">
          <ListaDeseos />
        </Route>

        <Route exact path="">
          <Inicio />
        </Route>
        <Redirect to="/login" />
      </Switch>
    </Home>
  );
};

function ScrollToTop() {
  const { pathname } = useLocation();
  const { NestGet, setState, isLogin } = useContext(GlobalContext);

  useEffect(() => {
    if (isLogin) {
      NestGet({
        schema: `user/rank`,
      }).then((res) => {
        setState({ rank: res.data });
      });
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Router = () => {
  const { isLoadedState } = useContext(GlobalContext);

  if (!isLoadedState) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SwitchRouter />
    </BrowserRouter>
  );
};

export default Router;
