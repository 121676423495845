import React from 'react'
import PropTypes from 'prop-types'
import {useController} from 'react-hook-form';
import '../Forms.scss'

const InputPasswordC = ({
    label,
    name,
    control,
    rules,
    style,
    defaultValue,
    onInput,
    setFormate,
    Wrapper,
    optional,
    className,
    ...props
  }) => {
    const {
      field: {ref, onChange, onBlur, value},
      fieldState: {invalid, isTouched, isDirty, error},
    } = useController({
      control,
      defaultValue: setFormate(defaultValue),
      rules,
      name,
    });
    const inputProps = {
      ...props,
      ref,
      onChange: v=>{
        onChange(setFormate(v?.target?.value))
        onInput(setFormate(v?.target?.value))
      },
      onBlur,
      value,
    };
    return (
      <div className={`FormC InputC${isTouched?' formc__touched':''}${isDirty?' formc__dirty':''}`} style={style}>
        {label ? <label htmlFor={`_${name}_`}>{rules.required?<strong>* </strong>:null}{label}: {optional?<span className='optional'>({typeof optional === 'string'?optional:'opcional'})</span>:null}</label> : null}
        <Wrapper>
          <div>
            <input id={`_${name}_`} className={`formc-input ${className||''}`} {...inputProps} />
          </div>
        </Wrapper>
        <span className='label__error'>{invalid ? error.message : ''}</span>
      </div>
    )
}

InputPasswordC.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  setFormate: PropTypes.func,
  defaultValue: PropTypes.any,
  Wrapper: PropTypes.func,
};

InputPasswordC.defaultProps = {
  name: 'input',
  rules: {},
  defaultValue: '',
  onInput: v => {},
  setFormate: v => v,
  Wrapper: p=><>{p.children}</>,
};
  
export default InputPasswordC
