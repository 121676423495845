import React, { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

//Data
import GlobalContext from "../../context/GlobalContext";

//Hooks
import { useForm, useWatch } from "react-hook-form";
import { rulesRequired } from "../../Constants";
import useS3File from "../../hooks/useS3File";
import useS3NestFile from "../../hooks/useS3NestFile";
import {
  Button,
  Dialog,
  FileInputC,
  Grid,
  InputC,
  PillButton,
  ToggleSwitch,
  LabelWrapper,
  Gallery,
} from "../../core";
import { Helmet } from "react-helmet";

/**
 * @component
 * Component in charge of modifying and adding categories
 */

const CategoriasGrid = () => {
  const { handleDelete: handleDeleteS3 } = useS3File();
  const { handleUpload, handleDelete: handleDelete_ } = useS3NestFile();
  const { setAppLoading, NestGet, NestPost, NestDelete, NestPatch, NestPut } =
    useContext(GlobalContext);
  const { control, handleSubmit, reset, setValue } = useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState();
  const active = useWatch({ control, name: "active", defaultValue: true });
  const [spinLoad, setSpinLoad] = useState(false);
  const columns = [
    {
      title: "Imagen",
      key: "images",
      width: 193,
    },
    {
      title: "Nombre",
      key: "name",
    },
    {
      title: "SKU",
      key: "sku",
    },

    {
      title: "Estatus",
      key: "active",
    },

    {
      title: "Acciones",
      width: 100,
      key: "action",
    },
  ];
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Eliminar",
      text: "¿Quieres eliminar este elemento?",
      icon: "question",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setAppLoading(true);
        NestDelete({
          schema: "categorias",
          id,
        })
          .then((id) => {
            const found = data.find((f) => f.id === id);
            if (found) {
              found?.images?.forEach((image) => {
                if(image?.location?.includes('mentorstore')){
                  handleDeleteS3(image.key)
                } else {
                  handleDelete_(image.key)
                }
              });
            }
            fetchData();
          })
          .finally((_) => {
            setAppLoading(false);
          });
      }
    });
  };

  const changeStatus = async (id, active) => {
    NestPatch({
      schema: "categorias",
      body: { active },
      id,
    }).then((res) => {
      if (res.status === "success") {
        const newData = JSON.parse(JSON.stringify(data));
        const index = newData.map((m) => m.id).indexOf(id);
        if (index > -1) {
          newData[index] = res.data;
          setData(newData);
        }
      }
    });
  };

  const closeModal = useCallback(() => {
    reset({ file: null, name: "", description: "" });
    setVisible(false);
  }, [reset]);

  const handleEdit = async (id) => {
    const found = data.find((f) => f.id === id);
    if (found) {
      Object.keys(found).forEach((key) => {
        setValue(key, found[key]);
      });
      setVisible(true);
    }
  };

  const [skuCalculate, setSkuCalculate] = useState(true);

  const notChangeSKU = useCallback((v) => {
    setSkuCalculate(false);
  }, []);

  const generateSku = useCallback(
    (v) => {
      if (skuCalculate) {
        const traslate = {
          á: "a",
          é: "e",
          í: "i",
          ó: "o",
          ú: "u",
          Á: "A",
          É: "E",
          Í: "I",
          Ó: "O",
          Ú: "U",
          " ": "-",
        };
        let newSku = v
          .replace(/[áéíóúÁÉÍÓÚ ]/g, (c) => {
            return traslate[c];
          })
          .toLowerCase();
        setValue("sku", newSku);
      }
    },
    [skuCalculate, setValue]
  );

  const onSubmit = async (doc) => {
    const s3Ds = await Promise.all(
      doc.images.map((image) => handleUpload(image, `mentor/categorias`))
    );
    setSpinLoad(true);
    doc.images = s3Ds.map((s3Data) => ({
      location: s3Data.location,
      key: s3Data.key,
    }));
    let promise;
    if (doc.id) {
      promise = NestPut({
        schema: "categorias",
        body: doc,
        id: doc.id,
      });
    } else {
      promise = NestPost({
        schema: "categorias",
        body: doc,
      });
    }
    promise.then((res) => {
      if (res.status === "success") {
        const found = data.find((f) => f.id === doc.id);
        if (found) {
          const keys = res.data.images.map((m) => m.key);
          found?.images?.forEach((image) => {
            if(!keys.includes(image.key)){
              if(image?.location?.includes('mentorstore')){
                handleDeleteS3(image.key)
              } else {
                handleDelete_(image.key)
              }
            }
          });
        }
        reset({
          active: true,
          name: "",
          sku: "",
          images: [],
        });
        setVisible(false);
        setSpinLoad(false);
        fetchData();
      }
    });
  };
  const fetchData = useCallback(
    async (page = 1) => {
      setAppLoading(true);
      NestGet({
        schema: `categorias`,
        query: {
          limit: 20,
          page,
        },
      })
        .then((res) => {
          setData(res.data);
          setPagination(res.pagination);
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading]
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Categorías | Mentor</title>
      </Helmet>
      <div className="work_area">
        <div className="d-flex justify-content-end">
          <Button color="#66bb6a" onClick={(_) => setVisible(true)}>
            Agregar <i className="fa fa-plus"></i>
          </Button>
        </div>
        <Grid
          rowKey="id"
          columns={columns}
          dataSource={data}
          className="table-striped"
          pagination={pagination}
          onChangePage={fetchData}
        >
          <Grid.Column
            name="images"
            context={({ column, row }) => (
              <div style={column.length > 1 ? { width: 386 } : { width: 193 }}>
                <Gallery images={column.map((m) => m.location)} />
              </div>
            )}
          />
          <Grid.Column
            name="active"
            context={({ column, row }) => (
              <>
                <ToggleSwitch
                  checked={row.active}
                  name={`${row.id}`}
                  onChange={(v) => changeStatus(row.id, v)}
                />
              </>
            )}
          />
          <Grid.Column
            name="action"
            context={({ column, row }) => (
              <div className="d-flex">
                <PillButton
                  color={"#00b8d4"}
                  onClick={(_) => handleEdit(row.id)}
                >
                  <i className="fas fa-edit"></i>
                </PillButton>
                <PillButton
                  color={"#c62828"}
                  onClick={(_) => handleDelete(row.id)}
                >
                  <i className="fas fa-trash"></i>
                </PillButton>
              </div>
            )}
          />
        </Grid>
        <Dialog visible={visible} onClose={closeModal}>
          <Dialog.Header>
            <strong>Agregar una categoría para los cursos</strong>
          </Dialog.Header>
          <Dialog.Body>
            <LabelWrapper label="Estatus" className="mb-3">
              <ToggleSwitch
                name="active"
                checked={active}
                onChange={(v) => setValue("active", v)}
              />
            </LabelWrapper>
            <InputC
              control={control}
              label="Nombre"
              name="name"
              rules={rulesRequired}
              onInput={generateSku}
            />
            <InputC
              control={control}
              label="SKU"
              name="sku"
              rules={rulesRequired}
              onInput={notChangeSKU}
            />
            <FileInputC
              control={control}
              label="Banners"
              name="images"
              accept="image/*"
              rules={rulesRequired}
              multiple
            />
          </Dialog.Body>
          <Dialog.Footer className="d-flex justify-content-end">
            {spinLoad ? (
              <i className="fa-solid fa-spinner fa-spin-pulse"></i>
            ) : (
              <Button onClick={handleSubmit(onSubmit)}>Cargar</Button>
            )}
          </Dialog.Footer>
        </Dialog>
      </div>
    </>
  );
};

export default CategoriasGrid;
