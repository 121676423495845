import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const TabSimple = ({children,current}) => {
  const [tabCurrent, setTabCurrent] = useState(current)
  const tabs = useMemo(()=>Array.isArray(children)?children.filter(ff=>ff.type === Tab):children?.type===Tab?[children]:[],[children])
  useEffect(() => {
    setTabCurrent(current)
  }, [current])
  return (
    <div className="TabSimple">
      <div className="tabs-header">
        {tabs.map((m,i)=><TabButton onClick={_=>setTabCurrent(i)} active={i===tabCurrent} key={`${m.props.name}_${i}`}>{m.props.name}</TabButton>)}
      </div>
      <div className="tabs-body">
        {tabs.map((m,i)=><Tab active={i===tabCurrent} key={`${m.props.name}_${i}`} {...m.props} />)}
      </div>
    </div>
  )
}

const TabButton = ({children, active, onClick}) => {
  return(
    <div className={`tab-button${active?' tab-button__active':''}`} onClick={onClick}>
      <span>{children}</span>
    </div>
  )
}

const Tab = ({name,children, active})=>{
    return (
        <div className={`tab${active?' tab__active':''}`}>{children}</div>
    )
}

TabSimple.Tab = Tab

TabSimple.propTypes = {
  current: PropTypes.number
}

TabSimple.defaultProps = {
  current: 0
}

export default TabSimple