import { uploadFile, deleteFile } from 'react-s3';
import {nanoid} from 'nanoid'
import { ACCESS_KEY, REGION, S3_BUCKET, SECRET_ACCESS_KEY } from '../config';
import { useEffect } from 'react';


const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

const useS3File = () => {

    const handleDelete = async (fileName,dirName='') => {
        return deleteFile(fileName, {...config,dirName})
          .catch(err => console.error(err))
    }
    const handleUpload = async (file,dirName='',conservar_name=false) => {
        if(!(file instanceof File)){
            return Promise.resolve(file?.location?file:{location:file})
        }
        const id = await nanoid()
        const [ext, name_] = file.name.split('.').reverse()
        const name = `${name_}<@>`
        const blob = file.slice(0, file.size, file.type); 
        
        const newFile = new File([blob], `${conservar_name?name:''}${id}.${ext}`, {type: file.type});
        return uploadFile(newFile, {...config,dirName})
          .catch(err => console.error(err))
    }
    useEffect(() => {
        window.Buffer = window.Buffer || require("buffer").Buffer;
    }, [])

    return {
        handleDelete,
        handleUpload
    }
}

export default useS3File;