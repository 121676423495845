import React, { useContext, useEffect, useCallback, useState } from "react";
import liston from "../../assets/images/liston.png";

import "./Users.scss";
import foto_perfil from "../../assets/images/contacto_img.png";
import { DetallePerfil } from "../Backoffice";
import GlobalContext from "../../context/GlobalContext";
import { useParams, useHistory } from "react-router-dom";
import { Button, Dialog } from "../../core";
import { Rating } from "react-simple-star-rating";
import { RatingInputC, TextAreaC } from "../../core/Forms/Inputs";
import { useForm } from "react-hook-form";
import { rulesRequired } from "../../Constants";
import { Helmet } from "react-helmet";

const Diplomado = () => {
  const history = useHistory();
  const { sku } = useParams();
  const { control, handleSubmit } = useForm();
  const { NestPost, NestGet } = useContext(GlobalContext);
  const [certificate, setCertificate] = useState(null);
  const [showQualified, setShowQualified] = useState(false);
  const onSubmit = (data) => {
    NestPost({
      schema: `cursos/qualified/${sku}`,
      body: data,
    }).then((res) => {
      setShowQualified(false);
    });
  };
  const fetchData = useCallback(() => {
    NestGet({ schema: `cursos/certificate/${sku}` }).then((res) => {
      if (res.status === "error") {
        return history.replace("/cursos");
      }
      if (res?.data?.reload) {
        return history.replace(res.data.reload);
      }
      if (!res?.data?.isQualified) {
        setShowQualified(true);
      }

      setCertificate(res.data.certificate);
      //window.pdfjsLib.getDocument(res.data.certificate)
      //debugger
    });
  }, [sku]);
  useEffect(() => {
    if (sku) {
      fetchData();
    } else {
      history(`/cursos`);
    }
  }, [history, fetchData, sku]);
  return (
    <>
      <div className="Diplomado">
        <Helmet>
          <title>Diplomado</title>
        </Helmet>
        <div className="perfil-container">
          <div className="informacion-section">
            <DetallePerfil />
          </div>
          <div className="diploma-detalles row bg-white justify-content-center ">
            <div className="d-flex flex-wrap w-100 py-5 my-5 container-diplomas align-items-center">
              <div className="col-md-12">
                <h2 className="my-5 text-center">¡Muchas Felicidades!</h2>
              </div>
              <div className="col">
                <div className="diploma-section">
                  {certificate ? <div className="d-flex flex-column align-items-center">
                    <a className="h5 text-dark text-underline" download={`${sku}.pdf`} href={certificate} target="_blank" rel="noopener noreferrer">La resolución de tu pantalla es muy grande y no aparece el diploma haz click aqui</a>
                    <embed src={certificate} />
                  </div> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog visible={showQualified}>
        <Dialog.Header>
          <h2 className="text-center flex-1">Califica tu curso</h2>
        </Dialog.Header>
        <Dialog.Body>
          <RatingInputC
            control={control}
            size={40}
            name="cal_teacher"
            label="¿Como calificas al/los profesores?"
            rules={rulesRequired}
          />
          <RatingInputC
            control={control}
            size={40}
            name="cal_curse"
            label="¿Como calificas el curso?"
            rules={rulesRequired}
          />
          <TextAreaC
            control={control}
            name="comment"
            label="Dejanos un comentario"
          />
        </Dialog.Body>
        <Dialog.Footer>
          <Button onClick={handleSubmit(onSubmit)}>Enviar</Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

export default Diplomado;
