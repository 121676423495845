import React, { useMemo, useState, useRef, useEffect, useContext } from 'react'
import { useTable, useSortBy, usePagination } from "react-table";
import PropTypes from 'prop-types'
import './Grid.scss'
import TableFilter from "react-table-filter";
import "./MentorGridFilter.scss"
import TablePagination from "@mui/material/TablePagination";
import {
    Paper,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TableContainer,
} from "@material-ui/core";

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Swal from "sweetalert2";
import ChecklistIcon from '@mui/icons-material/Checklist';
import DehazeIcon from '@mui/icons-material/Dehaze';
import IconButton from '@mui/material/IconButton';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import GlobalContext from "../../context/GlobalContext";


const MentorGridWithFilters = props => {

    const [filteredData, setFilteredData] = useState([props.data]);
    const filterRef = useRef(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selected, setSelected] = useState([]);
    const [selectedStatus, setselectedStatus] = useState("");
    const SelectStatusError = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2800,
    });
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const [selectable, setSelectable] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        table.gotoPage(newPage);
    };

    const changeGridType = async () => {
        setSelectable(!selectable);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        table.setPageSize(parseInt(event.target.value));
        table.gotoPage(0);

    };

    const table = useTable(
        {
            columns: props.columns,
            data: filteredData,
            initialState: {
                pageSize: rowsPerPage,
                pageIndex: page
            }
        },
        usePagination
    );

    useEffect(() => {
        filterRef.current.reset(props.data, true);
        setFilteredData(props.data);
    }, [props.data]);

    const updateFilterHandler = newData => {
        setFilteredData([])
        var updatedNewData = newData.map(usr => {
            var userInParentData = props.data.find(x => x.id === usr.id)
            if (userInParentData !== undefined) {
                return userInParentData;
            }
        });
        setFilteredData(updatedNewData);
    };

    const handleSelectAllClick = (event) => {

        if (event.target.checked) {
            var status = ""
            var errors = false;
            let selectedItems = [];
            filteredData.forEach((n) => {

                if (status === "") {
                    status = n.active;
                    setselectedStatus(status)
                } else if (status !== n.active) {
                    errors = true;
                    return;
                }
                selectedItems.push(n.id);
            });
            if (errors) {
                SelectStatusError.fire({
                    title: "No se pueden seleccionar usuarios con diferente status",
                    icon: "error",
                });
                setselectedStatus("")
                setSelected([]);
                return;
            }
            setSelected(selectedItems);
            return;
        }
        setSelected([]);
    };

    const selectRow = (event, id, status) => {

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedStatus === "") {
            setselectedStatus(status)
        } else if (status !== selectedStatus) {
            SelectStatusError.fire({
                title: "No se pueden seleccionar usuarios con diferente status",
                icon: "error",
            });
            return;
        }

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            setselectedStatus("");
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const _columns_ = useMemo(() => {
        if (props.columns) {
            return props.columns
        } else {
            return props.data.reduce((v, c) => {
                Object.keys(c).forEach(key => {
                    try {
                        if (!v.find(f => f.key === key)) {
                            v.push({
                                key: key,
                                title: key.replace(/_/g, ' ').toCapitalize(),
                                dataIndex: key
                            })
                        }
                    } catch (error) {
                    }
                });

                return v
            }, [])
        }
    }, [props.columns, props.data])

    return (
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
            <EnhancedTableToolbar
                numSelected={selected.length}
                changeGridType={changeGridType}
                selectable={selectable}
                selectedStatus={selectedStatus}
                selected={selected}
                setSelected={setSelected}
                setSelectedStatus={setselectedStatus}
                data={filteredData}
            />
            <TableContainer style={{ maxHeight: "60vh", minHeight: "50vh" }}>
                <Table size="small" stickyHeader {...table.getTableProps()} className={`Grid table ${props.className} mentor_table`}>
                    <TableHead>
                        {table.headerGroups.map(headerGroup => (
                            <TableFilter
                                {...headerGroup.getHeaderGroupProps()}
                                rows={props.data}
                                onFilterUpdate={updateFilterHandler}
                                ref={filterRef}
                            >

                                {selectable
                                    ?
                                    <TableCell padding="checkbox">
                                        <SelectableHeader
                                            selected={selected}
                                            handleSelectAllClick={handleSelectAllClick}
                                            numOfRows={table.page.length}
                                        />
                                    </TableCell>
                                    :
                                    <></>
                                }
                                {props.columns.map((column) =>
                                    <TableCell
                                        id={column.id}
                                        key={column.key}
                                        filterkey={column.filterkey}
                                        className={`${column.className || ''}`}
                                        style={{
                                            'textIndent': '18px',
                                        }}
                                    >
                                        {column.title}
                                    </TableCell>
                                )}
                            </TableFilter>
                        ))}
                    </TableHead >
                    {props.data.length > 0 && (
                        <TableBody>
                            {((props.enablePagination === true && table.page !== undefined) ? table.page : table.rows).map(
                                (row, i) => {
                                    const isItemSelected = isSelected(row.original.id);
                                    const labelId = `enhanced-table-checkbox-${i}`;
                                    if (selectable) {
                                        return (
                                            <SelectableRow
                                                key={row.original.id}
                                                id={row.original.id}
                                                columns={_columns_}
                                                row={row.original}
                                                children={props.children} index={i}
                                                isItemSelected={isItemSelected}
                                                role="checkbox"
                                                onClick={(event) => selectRow(event, row.original.id, row.original.active)}
                                                labelId={labelId}
                                            />
                                        );

                                    } else {
                                        return (
                                            <Row
                                                key={row.original.id}
                                                id={row.original.id}
                                                columns={_columns_}
                                                row={row.original}
                                                children={props.children} index={i}
                                            />
                                        );
                                    }

                                })}
                        </TableBody>
                    )}
                    {props.loading === false && props.data.length === 0 && (
                        <TableRow style={{height:"100px"}}>
                            <TableCell colSpan={6}>no records found</TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableContainer>
            <TablePagination className='mentor_table'
                size="small"
                rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                component="div"
                count={table.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                    inputProps: {
                        'aria-label': 'rows per page',
                    },
                    native: true,
                }}
            />
        </Paper>
    );
}

function Column({ column, row, name, children, index }) {
    const childCol = Array.isArray(children) ?
        children.find(f => f?.type === Column && f?.props?.name === name) : (
            children?.type === Column && children?.props?.name === name && children
        )
    if (childCol) {
        try {
            return childCol?.props?.context({ column: JSON.parse(column), row, index })
        } catch (error) {
            return childCol?.props?.context({ column, row, index })
        }
    }


    try {
        return <>{JSON.parse(column)}</>
    } catch (error) {
        return <>{column}</>
    }

}

const Row = ({ row, columns, children, index }) => {
    const columnsHashMap = useMemo(
        () => {
            const hashMap = columns.reduce((v, c) => {
                const path = (c.dataIndex || c.key).split('.')
                const column = path.reduce((vk, ck) => vk?.[ck], row)
                return {
                    ...v,
                    [c.key]: typeof column === 'string' ? column : JSON.stringify(column)
                }
            }, {})
            return hashMap
        },
        [row, columns],
    )
    return (
        <TableRow>
            {columns.map((column) =>
                <TableCell key={column.key} className={`${column.className || ''}`} style={{ width: column.width || 'inherit', ...column.style || {} }}>
                    <Column row={row} column={columnsHashMap[column.key] || ''} name={column.key} children={children} index={index} />
                </TableCell>
            )}
        </TableRow>
    )
}

const SelectableRow = ({ row, columns, children, index, isItemSelected, role, onClick, labelId }) => {
    const columnsHashMap = useMemo(
        () => {
            const hashMap = columns.reduce((v, c) => {
                const path = (c.dataIndex || c.key).split('.')
                const column = path.reduce((vk, ck) => vk?.[ck], row)
                return {
                    ...v,
                    [c.key]: typeof column === 'string' ? column : JSON.stringify(column)
                }
            }, {})
            return hashMap
        },
        [row, columns],
    )

    return (
        <TableRow
            hover
            onClick={onClick}
            role={role}
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            style={{ cursor: 'pointer' }}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    size='small'
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                />
            </TableCell>
            {columns.map((column) =>
                <TableCell key={column.key} className={`${column.className || ''}`} style={{ width: column.width || 'inherit', ...column.style || {} }}>
                    <Column row={row} column={columnsHashMap[column.key] || ''} name={column.key} children={children} index={index} />
                </TableCell>
            )}
        </TableRow>
    )
}

const changeStatusList = async (ids, active,data, setAppLoading,NestPatch,setSelected,setSelectedStatus) => {

    
    setAppLoading(true)
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2800,
    });

    NestPatch({
      schema: "admin/student/status",
      body: { user_ids: ids, active: active },
    }).then((res) => {
      if (res.status === "success") {
        ids.forEach(element => {
          const index = JSON.parse(JSON.stringify(data)).map((m) => m.id).indexOf(element);
          if (index > -1) {
            data[index].active = active;
          }
        });
        setSelected([]);
        setSelectedStatus("");
        Toast.fire({
          title: "Cambio de estatus exitoso",
          icon: "success",
        });
      }
    })
      .finally((_) => {
        setAppLoading(false);
      });
  };

function EnhancedTableToolbar(props) {
    const { numSelected, changeGridType, selectable, selectedStatus,selected, data,setSelected, setSelectedStatus} = props;

    const { setAppLoading, NestPatch } = useContext(GlobalContext);
    return (

        <Toolbar
            variant="dense"
            sx={{                
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle2"
                    component="div"
                >
                    {numSelected} users selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="subtitle2"
                    id="tableTitle"
                    component="div"
                >
                    {!selectable
                        ? "Filtrar Usuarios"
                        : "Seleccionar y Filtrar Usuarios"
                    }

                </Typography>
            )}

            {numSelected > 0 ? (
                <Button 
                    style={{ color: "hsl(205, 29%, 29%)" }} 
                    size="small" 
                    variant="outlined" 
                    startIcon={<ChangeCircleIcon />}  
                    onClick={(event) => changeStatusList(selected,!selectedStatus,data,setAppLoading,NestPatch,setSelected,setSelectedStatus)}                  
                >
                    {selectedStatus === false
                        ? "Activar"
                        : "Desactivar"
                    }
                </Button>
            ) : (
                <>
                    {!selectable
                        ?
                        <Tooltip title="Permitir seleccionar Usuarios">
                            <IconButton onClick={changeGridType}>
                                <ChecklistIcon style={{ fontSize: "25px", color: "hsl(205, 29%, 29%)" }} />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Deshabilitar seleccion de usuario">
                            <IconButton onClick={changeGridType}>
                                <DehazeIcon style={{ fontSize: "25px", color: "hsl(205, 29%, 29%)" }} />
                            </IconButton>
                        </Tooltip>
                    }
                </>
            )}
        </Toolbar>
    )
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function SelectableHeader(props) {
    const { selected, handleSelectAllClick, numOfRows } =
        props;

    return (
        <Checkbox
            size='small'
            style={{ color: "hsl(0, 0%, 100%)" }}
            indeterminate={selected.length > 0 && selected.length < numOfRows}
            checked={numOfRows > 0 && selected.length === numOfRows}
            onChange={handleSelectAllClick}
            inputProps={{
                'aria-label': 'Select all Users',
            }}
        />
    );
}
SelectableHeader.propTypes = {
    selected: PropTypes.number.isRequired,
    numOfRows: PropTypes.number.isRequired,
    handleSelectAllClick: PropTypes.func.isRequired

};

MentorGridWithFilters.Column = Column

MentorGridWithFilters.propTypes = {
    rowKey: PropTypes.string,
    columns: PropTypes.array,
    dataSource: PropTypes.array,
    pagination: PropTypes.object,
    className: PropTypes.string,
    onChangePage: PropTypes.func,
    children: PropTypes.array
}

MentorGridWithFilters.defaultProps = {
    rowKey: 'id',
    dataSource: [],
    className: '',
    onChangePage: () => { },
    onCloseCb: cb => cb(),
    children: []
}

export default MentorGridWithFilters
