import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { colors } from "../../Color";
import "./Avatar.scss";

const Avatar = ({ src, name, color, size, onClick }) => {
  const [hasImg, setHasImg] = useState(false);
  useEffect(() => {
    const img = new Image();

    img.onload = function () {
      // image  has been loaded
      setHasImg(true);
    };

    img.src = src;
    return () => {};
  }, [src]);
  return (
    <div
      className="Avatar"
      style={{
        backgroundColor: color,
        height: size,
        width: size,
        borderRadius: size / 2,
      }}
      onClick={onClick}
    >
      {name && !hasImg ? (
        <strong style={{ fontSize: size / 2 }}>{name.slice(0, 2)}</strong>
      ) : null}
      {hasImg?<div
        className="avatar-img"
        style={{
          backgroundImage: `url('${src}')`,
        }}
      ></div>:null}
    </div>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};
Avatar.defaultProps = {
  name: "Avatar",
  color: colors[Math.floor(Math.random() * colors.length)],
  size: 30,
};

export default Avatar;
