import React from 'react'
import PropTypes from 'prop-types'
import './Dropdown.scss'
import { Link } from 'react-router-dom'

const DropdownItem = ({children, onClick, className}) => {
    return (
        <li className={`DropdownItem ${className}`} onClick={onClick}>
            {children}
        </li>
    )
}
export const DropdownItemLink = ({children, to, className}) => {
    return (
        <Link to={to}>
            <li className={`DropdownItem ${className}`}>
                {children}
            </li>
        </Link>
    )
}

DropdownItem.propTypes = {
    onClick: PropTypes.func
}

export default DropdownItem
