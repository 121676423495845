import React, { useCallback, useEffect, useContext } from "react";
import { useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useController, useForm } from "react-hook-form";

import GlobalContext from "../../context/GlobalContext";
import cursoImg from "../../assets/mocks/activo-5.png";

export default function Buscador() {
  const { busqueda } = useParams();
  const {
    NestGet,
    NestPost,
    isLogin,
    state: { user },
  } = useContext(GlobalContext);
  const [Resultados, SetResultados] = useState([]);

  const fetchData = useCallback(async () => {
    NestGet({
      schema: `cursos/search/${busqueda}`,
      query: {
        user_id: JSON.stringify({ user_id: user._id }),
      },
    }).then((res) => {
      console.log(res.data);
      SetResultados(res.data);
    });
  }, [NestGet, busqueda]);

  useEffect(() => {
    fetchData();
  }, [busqueda]);

  return (
    <div className="Page-Buscador">
      <div className="buscador-container">
        <InputSearch />
        <h1 className="title-search">
          {" "}
          Resultado de la búsqueda "{busqueda}"{" "}
        </h1>
        <div className="search-items">
          {Resultados.map((item) => (
            <Link to={`/curso/${item.sku}`} key={item._id}>
              <div className="d-flex my-5 flex-wrap align-items-center">
                <div className="col-md-4">
                  <img
                    style={{ borderRadius: 16 }}
                    src={item?.image?.location || cursoImg}
                    alt={`imagen del ${item.name}`}
                  />
                </div>
                <div className="col-md-5">
                  <h4 className="search-name">{item.name}</h4>

                  <p className="search-description">{item.description}</p>
                  <small className="search-teacher">{item.teacher}</small>
                </div>
                <div className="col-md-2">
                  <h4 className="search-cost text-right">
                    {item.cost > 0 ? `$ ${item.cost}` : "Gratis"}
                  </h4>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

const InputSearch = () => {
  const { handleSubmit, control } = useForm();
  const history = useHistory();

  const Buscar = (data) => {
    history.replace(`/buscar/${data.busqueda}`);
  };

  return (
    <div className="InputSearch">
      <Input
        onEnter={handleSubmit(Buscar)}
        label="Busqueda"
        name="busqueda"
        control={control}
      />
      <button onClick={handleSubmit(Buscar)}>Buscar</button>
    </div>
  );
};

const Input = ({ name, label, control, onEnter, ...props }) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    rules: { required: "es requerido" },
    defaultValue: "",
  });

  const inputProps = {
    ...props,
    ref,
    onChange: (v) => {
      onChange(v?.target?.value);
    },
    onBlur,
    value,
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnter();
    }
  };

  return (
    <>
      <input
        onKeyDown={(ev) => {
          handleKeyDown(ev);
        }}
        type="text"
        {...inputProps}
        placeholder={label}
        name={name}
      />
    </>
  );
};
