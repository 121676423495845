import React, { useContext, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import "./styles.scss";

import BarsSVG from "../../assets/svgs/BarsSVG";
import BellSVG from "../../assets/svgs/BellSVG";
import WASVG from "../../assets/svgs/WASVG";

import logo from "../../assets/images/mentor_primary_B.png";
import GlobalContext from "../../context/GlobalContext";
import {
  Dropdown,
  DropdownItem,
  Bandera,
  AvatarWhitLabel,
  DropdownItemLink,
} from "../../core";
import { useCallback } from "react";

const Header = () => {
  const {
    setState,
    state: { language, user, notifications },
    Logout,
    NestPatch,
    GetMyNotifications,
    isAdmin,
  } = useContext(GlobalContext);

  const [showNotifications, setShowNotifications] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const handledLogoutout = () => {
    Logout().then((_) => {
      history.push("/");
    });
  };
  const fullName = useMemo(
    () => `${user?.names || ""} ${user?.last_name || ""}`.trim(),
    [user]
  );
  const active = useMemo(() => {
    return notifications.some((s) => !s.opened);
  }, [notifications]);
  const handleNotificationClick = useCallback(
    (notification) => {
      NestPatch({
        schema: "notifications",
        id: notification.id,
      }).then((res) => {
        GetMyNotifications();
      });
      history.push(`/${notification.endpoint}`);
      setShowNotifications(false);
    },
    [GetMyNotifications, NestPatch, history]
  );
  return (
    <div className="Header">
      <div className="header-menu__movil">
        <BarsSVG onClick={() => setState({ menuOpen: true })} />
      </div>
      <Link to="/">
        <img
          className="header-logo"
          height="50"
          width="180"
          src={logo}
          alt=""
        />
      </Link>
      <nav
        className={`header-nav ${
          show ? "header-nav__show" : "header-nav__close"
        }`}
      >
        <ul className="header-routes"></ul>

        {/* <ul className="header-keypad">
          <li>
            <BellSVG
              onClick={(_) => setShowNotifications(!showNotifications)}
              active={active}
            />
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=5213321012020&amp;text=Hola quisiera información."
              target="_blank"
              rel="noopener noreferrer"
            >
              <WASVG />
            </a>
          </li>
          <li>
            <Bandera
              onClick={() =>
                setState({ language: language === "es-MX" ? "en-US" : "es-MX" })
              }
            />
          </li>

          <ul
            className={`header-notifications ${
              showNotifications ? "notification__show" : ""
            }`}
          >
            {notifications.map((n) => (
              <li
                className="notify-item pointer"
                key={n.id}
                onClick={(_) => handleNotificationClick(n)}
              >
                <strong>{n.title}</strong>
                <p>{n.message}</p>
                {n.opened ? null : <div className="green-point" />}
              </li>
            ))}
          </ul>
        </ul> */}
        <Dropdown
          className="dash"
          onToggle={(v) => {
            setShow(v);
          }}
          toggle={
            <>
              <AvatarWhitLabel
                src={user.image?.location}
                name={fullName}
                size={45}
              />
            </>
          }
        >
          <DropdownItemLink className={"closable"} to={`/Backoffice/Perfil`}>Perfil</DropdownItemLink>
          <DropdownItem  className={"closable"} onClick={handledLogoutout}>Cerrar sesión</DropdownItem>
        </Dropdown>
      </nav>
    </div>
  );
};

export default Header;
