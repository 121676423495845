import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import "../Forms.scss";
import useFormatter from "../../../hooks/useFormatter";

const FileInputC = ({
  outLine,
  children,
  label,
  name,
  control,
  rules,
  style,
  defaultValue,
  onInput,
  color,
  optional,
  multiple,
  readOnly,
  ...props
}) => {
  const fileRef = useRef();
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    control,
    defaultValue: multiple ? defaultValue || [] : defaultValue,
    rules,
    name,
  });
  const style_ = color ? { "--color": color } : {};
  const files = useMemo(
    () => (multiple ? (value ? value : []) : value ? [value] : []),
    [value, multiple]
  );

  const handleClick = useCallback(() => {
    fileRef.current.click();
  }, [fileRef]);
  const onChange_ = useCallback(
    (v) => {
      const files = multiple
        ? [...value, ...v.target.files]
        : v.target.files[0];
      if (!files) {
        return;
      }
      onChange(files);
      onInput(files);
      const dt = new DataTransfer();
      fileRef.current.files = dt.files;
    },
    [multiple, value, onChange, onInput]
  );

  const inputProps = {
    ...props,
    ref: fileRef,
    onChange: onChange_,
    onBlur,
    type: "file",
    multiple,
  };

  const del = useCallback(
    (index) => {
      const dt = [];
      if (multiple) {
        dt.push(...value.filter((ff, i) => i !== index));
      }
      const files = multiple ? [...dt] : dt[0];
      onChange(files);
      onInput(files);
    },
    [multiple, onChange, onInput, value]
  );

  return (
    <div
      className={`FormC FileInputC${isTouched ? " formc__touched" : ""}${
        isDirty ? " formc__dirty" : ""
      }${!label ? " formc__unlabel" : ""}`}
      style={style}
    >
      {label ? (
        <label htmlFor={`_${name}_`}>
          {rules.required ? <strong>* </strong> : null}
          {label}:{" "}
          {optional ? (
            <span className="optional">
              ({typeof optional === "string" ? optional : "opcional"})
            </span>
          ) : null}
        </label>
      ) : null}
      {!readOnly ? (
        <>
          <input {...inputProps} />
          <button
            style={style_}
            onClick={handleClick}
            className={`Button${outLine ? " button__outline" : ""}`}
          >
            <span>{children || "Seleccionar archivo"}</span>
          </button>
        </>
      ) : null}
      {files.map((m, i) => (
        <File readOnly={readOnly} key={i} file={m} onDelete={() => del(i)} />
      ))}
      <span className="label__error">{invalid ? error.message : ""}</span>
    </div>
  );
};

const File = ({ onDelete, file, readOnly, ...props }) => {
  const { BlobtoUrl } = useFormatter();
  const file_ = useMemo((_) => file?.location || file, [file]);
  const fileName = useMemo(() => {
    return typeof file_ === "string"
      ? file_.split("/").reverse()[0]
      : file_.name;
  }, [file_]);
  const openFileHref = useCallback(() => {
    const a = document.createElement("a");
    a.href = typeof file_ === "string" ? file_ : BlobtoUrl(file_);
    a.target = "_new";
    a.click();
  }, [file_, BlobtoUrl]);
  return (
    <div {...props}>
      {readOnly ? null : (
        <i className="fa fa-trash text-danger pointer" onClick={onDelete} />
      )}{" "}
      <span className="pointer" onClick={openFileHref}>
        {readOnly ? <i className="fa fa-file" /> : null} {fileName}
      </span>
    </div>
  );
};

FileInputC.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  defaultValue: PropTypes.any,
  outLine: PropTypes.bool,
};

FileInputC.defaultProps = {
  name: "input",
  rules: {},
  onInput: (v) => {},
  outLine: false,
};

export default FileInputC;
