import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import Vimeo from "@u-wave/react-vimeo";

//Data
import GlobalContext from "../../context/GlobalContext";

//Hooks
import { useForm, useWatch} from "react-hook-form";
import {
  Button,
  Dialog,
  FileInputC,
  Grid,
  InputC,
  PillButton,
  TextAreaC,
} from "../../core";
import { rulesRequired } from "../../Constants";
import useVimeo from "../../hooks/useVimeo";
import { Helmet } from "react-helmet";

/**
 * @component
 * Component in charge of listing the videos
 */

const FilmotecaGrid = () => {
  const {
    state: { accessToken },
    setState,
    setAppLoading,
    NestGet,
  } = useContext(GlobalContext);
  const { availableToken, setAccessToken, deleteVideo, uploadVideo, getVideo, editVideo } =
    useVimeo();
  const { control, handleSubmit, reset, setValue } = useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState();
  const [porcentual, setPorcentual] = useState(-1);
  // const [count, setCount] = useState(0);
  const [spinLoad, setSpinLoad] = useState(false);
  const [edit, setEdit] = useState('')
  const query = useWatch({ control, name: "query" });
  const [interval, setInterval] = useState(null);

  const columns = [
    {
      title: "Video",
      width: 300,
      key: "id",
    },
    {
      title: "Título",
      key: "name",
    },

    {
      title: "Duración",
      key: "duration",
    },

    {
      title: "Acciones",
      width: 100,
      key: "action",
    },
  ];
  const handleDelete = async (video_id) => {
    Swal.fire({
      title: "Eliminar",
      text: "¿Quieres eliminar este elemento?",
      icon: "question",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setAppLoading(true);
        deleteVideo(video_id).then((res) => {
          fetchData();
        });
      }
    });
  };
  const handleEdit = useCallback((id) => {
    const item = data.find(f => f.id === id)
    setValue('name', item.name || '')
    setValue('description', item.description || '')
    debugger
    setEdit(id)
  }, [data, setValue])
  const closeModal = useCallback(() => {
    if (porcentual === -1) {
      setAppLoading(false);
      reset({ file: null, name: "", description: "" });
      setVisible(false);
      setEdit('')
    }
  }, [porcentual, reset]);

  const onEdit = async ({ name, description }) => {
    setSpinLoad(true);
    editVideo({
      name,
      description,
      id: edit,
      onError: (err) => {
        Swal.fire({
          title: "Error!",
          text: err.message,
          icon: "error",
        });
        setSpinLoad(false);
      },
      
      onSuccess: function () {
        fetchData();
        setPorcentual(-1);
        closeModal();
        setSpinLoad(false);
      },
    });
  };
  const onSubmit = async ({ file, name, description }) => {
    setSpinLoad(true);
    uploadVideo({
      file,
      name,
      description,
      onStart: () => setPorcentual(0),
      onError: (err) => {
        Swal.fire({
          title: "Error!",
          text: err.message,
          icon: "error",
        });
        setSpinLoad(false);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setPorcentual(percentage);
      },
      onSuccess: function () {
        fetchData();
        setPorcentual(-1);
        closeModal();
        setSpinLoad(false);
      },
    });
  };
  const fetchData = useCallback(
    async (page = 1, load = true) => {
      if (load) setAppLoading(true);


      let queryValue = query ? query : "";
      const response = await getVideo({ page, direction: "asc" , query: queryValue });
      const ever = ""
      setData(
        response.data.map((m) => ({ ...m, id: m.uri.replace("/videos/", "") }))
      );
      setPagination({
        page: response.page,
        last_page: Math.ceil(response.total / response.per_page),
      });

      console.log("fetchData", response);
      if (load) setAppLoading(false);
    },
    [getVideo, setAppLoading, query]
  );

  // useEffect(() => {
  //   const iter =
  //     count > 0
  //       ? setTimeout(() => {
  //           fetchData(pagination?.page || 1, false);
  //           setCount((c) => c + 1);
  //         }, 10000)
  //       : null;
  //   return () => {
  //     clearTimeout(iter);
  //   };
  // }, [fetchData, count, pagination]);

  useEffect(() => {
    if (availableToken) {
      fetchData();
      // setCount(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableToken]);

  useEffect(() => {
    if (!accessToken) {
      NestGet({
        schema: "admin/getAccessTokenVimeo",
      }).then(async (res) => {
        setState({ accessToken: res.data });
        setAccessToken(res.data);
      });
    } else {
      setAccessToken(accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearTimeout(interval);
    setInterval(
      setTimeout(() => {
        fetchData(1,true);
      }, 500)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, query]);

  return (
    <>
       <Helmet>
          <title>Filmoteca | Mentor</title>
        </Helmet>
      <div className="filter_work_area">
        <div style={{
          width: "99%",
          overflow: "hidden",
        }}>
     
        <div 
        className="d-flex justify-content-end" 
        style={{
          float: "right",
          "paddingTop": "15px"
        }}
        >
          <Button color="#66bb6a" onClick={(_) => setVisible(true)}>
            Agregar <i className="fa fa-plus"></i>
          </Button>
        </div>
          <div style={{
            width: "95%",
            "padding-left": "6px",
            "padding-top": "7px",
          }}>
            <FilterSection control={control} />
          </div>
        </div>
      </div>
      <div className="work_area">

        <Grid
          rowKey="id"
          columns={columns}
          dataSource={data}
          className="table-striped"
          pagination={pagination}
          onChangePage={fetchData}
        >
          <Grid.Column
            name="id"
            context={({ column, row }) => (
              <>
                <div className="Film">
                  <div className="preview">
                    <img
                      className="w-100"
                      style={{ objectFit: "contain" }}
                      src={row.pictures.base_link}
                      alt={row.name}
                    />
                  </div>
                  <Vimeo
                    className="capsula-video"
                    video={column}
                    responsive
                    controls={false}
                  />
                </div>
              </>
            )}
          />
          <Grid.Column
            name="duration"
            context={({ column, row }) => (
              <strong>
                {moment.utc(Number(column) * 1000).format("HH:mm:ss")}
              </strong>
            )}
          />
          <Grid.Column
            name="action"
            context={({ column, row }) => (
              <div className="d-flex">
                <PillButton
                  onClick={(_) => handleEdit(row.id)}
                >
                  <i className="fas fa-pen"></i>
                </PillButton>
                <PillButton
                  color={"#c62828"}
                  onClick={(_) => handleDelete(row.id)}
                >
                  <i className="fas fa-trash"></i>
                </PillButton>
              </div>
            )}
          />
        </Grid>
        <Dialog visible={visible} onClose={closeModal}>
          <Dialog.Header>
            <strong>Agregar un video a tu Filmoteca</strong>
          </Dialog.Header>
          <Dialog.Body>
            <FileInputC
              control={control}
              label="Video"
              name="file"
              accept="video/*"
              rules={rulesRequired}
              readOnly={porcentual > -1}
            />
            <InputC
              control={control}
              label="Título"
              name="name"
              rules={rulesRequired}
              readOnly={porcentual > -1}
            />
            <TextAreaC
              control={control}
              label="Descripción"
              name="description"
              readOnly={porcentual > -1}
            />
          </Dialog.Body>
          <Dialog.Footer className="d-flex justify-content-end">
            {porcentual > -1 ? (
              <>
                <div className="progress w-100">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow={porcentual}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${porcentual}%` }}
                  ></div>
                </div>
              </>
            ) : spinLoad ? (
              <i className="fa-solid fa-spinner fa-spin-pulse"></i>
            ) : (
              <Button onClick={handleSubmit(onSubmit)}>Cargar</Button>
            )}
          </Dialog.Footer>
        </Dialog>
        <Dialog visible={!!edit} onClose={closeModal}>
          <Dialog.Header>
            <strong>Editar el vide de la Filmoteca</strong>
          </Dialog.Header>
          <Dialog.Body>
            <InputC
              control={control}
              label="Título"
              name="name"
              rules={rulesRequired}
              readOnly={porcentual > -1}
            />
            <TextAreaC
              control={control}
              label="Descripción"
              name="description"
              readOnly={porcentual > -1}
            />
          </Dialog.Body>
          <Dialog.Footer className="d-flex justify-content-end">
            {porcentual > -1 ? (
              <>
                <div className="progress w-100">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow={porcentual}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${porcentual}%` }}
                  ></div>
                </div>
              </>
            ) : spinLoad ? (
              <i className="fa-solid fa-spinner fa-spin-pulse"></i>
            ) : (
              <Button onClick={handleSubmit(onEdit)}>Editar</Button>
            )}
          </Dialog.Footer>
        </Dialog>
      </div>
    </>
  );
};

const FilterSection = ({ control }) => {
  return (
    <>
      <div className="d-flex align-items-center flex-wrap justify-content-center">
        <div className="flex-fill flex-grow-1">
          <InputC
            control={control}
            name="query"
            placeholder="Buscar"
            className="w-100"
          />
        </div>
      </div>
    </>
  );
};

export default FilmotecaGrid;
