import React from "react";
import PropTypes from "prop-types";

const CursosSVG = ({ height, width, fill, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 263.49 360.07"
      fill={fill}
    >
      <g id="Capa_1-2">
        <g>
          <path d="M188.97,300c.63,1.48,1.08,2.61,1.59,3.7,4.79,10.29,9.57,20.59,14.39,30.86,1.36,2.9,2.04,5.74-.44,8.33-2.34,2.44-5.07,2.02-7.95,.92-5.34-2.04-10.74-3.92-16.56-6.03-2.13,5.77-4.06,11.38-6.3,16.86-.71,1.73-1.95,3.59-3.47,4.56-3.17,2.03-6.56,.34-8.54-3.91-6.27-13.45-12.9-26.76-18.57-40.47-2.77-6.71-5.92-10.18-13.71-9.12-3.57,.49-5.3,1.26-6.77,4.53-6.69,14.91-13.71,29.67-20.53,44.51-1.34,2.91-2.95,5.34-6.52,5.18-3.56-.16-5.01-2.7-6.06-5.73-1.88-5.4-3.91-10.74-5.99-16.42-5.76,2.08-11.26,4.01-16.71,6.07-2.9,1.1-5.63,1.42-7.92-1.07-2.29-2.48-1.76-5.21-.44-8,4.83-10.27,9.6-20.57,14.38-30.87,.52-1.11,.99-2.24,1.73-3.93h-4.33c-20.71,0-41.41,0-62.12,0-6.15,0-8.09-1.92-8.09-8.01C0,197.38,0,102.77,0,8.17,0,1.84,1.88,0,8.31,0,90.64,0,172.96,0,255.28,0c6.34,0,8.2,1.88,8.2,8.28,0,94.48,0,188.96,0,283.44,0,6.39-1.87,8.27-8.22,8.27-20.58,0-41.16,0-61.74,0h-4.55Zm-107.32-12.27c-17.69-16.05-27.03-34.99-27.65-57.73-.63-22.77,7.43-42.33,23.72-58.25,28.16-27.52,73.36-29.27,103.43-4.15,15.18,12.68,24.68,28.81,27.47,48.37,4.01,28.14-5.31,51.78-26.5,71.67h69.19V12.23H12.24V287.73H81.65Zm50.16-125.71c-36.68,.11-65.97,29.6-65.92,66.39,.04,36.23,29.87,65.71,66.36,65.59,35.88-.12,65.42-29.88,65.37-65.84-.05-36.56-29.58-66.24-65.8-66.14Zm-35.1,175.81c5.43-11.69,10.58-22.8,15.75-33.93q-5.47-2.35-7.68,2.39c-2.94,6.32-5.56,12.81-8.93,18.89-2.44,4.4-1.85,8.1,.86,12.65Zm54.27-34c5.28,11.35,10.44,22.44,15.74,33.82,3.54-6.21,3.55-6.45,.99-11.96-2.98-6.43-5.97-12.86-8.95-19.3q-2.19-4.73-7.78-2.56Zm-60.34-9.64c-5.21,11.19-10.31,22.16-15.41,33.12l.68,.69c2.38-1.15,5.94-1.68,6.94-3.57,4.68-8.85,8.66-18.08,12.94-27.26-1.86-1.08-3.34-1.94-5.14-2.98Zm97.78,33.43c-5.31-11.38-10.43-22.34-15.62-33.46-1.97,1.18-3.44,2.07-5.08,3.05,3.54,7.64,7.29,14.95,10.31,22.55,1.88,4.73,4.88,7.17,10.38,7.85Z" />
          <path d="M227.38,66.26v11.55H36.23v-11.55H227.38Z" />
          <path d="M227.37,113.86H36.18v-11.65H227.37v11.65Z" />
          <path d="M102.12,30.07h59.27v11.68h-59.27v-11.68Z" />
          <path d="M160.3,273.75c-7.35-3.12-12.16,1.52-17.34,7.46-6.8,7.79-17.21,7.13-23.61-1.18-4.2-5.46-8.54-7.3-15.57-6.63-12.15,1.16-18.59-5.78-17.34-18.03,.66-6.43-.91-10.54-5.99-14.38-9.62-7.28-9.46-18.97,.27-26.22,4.87-3.63,6.43-7.61,5.73-13.75-1.46-12.72,5.64-19.87,18.43-18.38,5.94,.69,9.92-.73,13.47-5.51,7.51-10.1,19.17-10.19,26.64-.16,3.64,4.88,7.66,6.35,13.76,5.67,12.64-1.4,19.62,5.53,18.26,18.19-.67,6.28,.87,10.33,5.86,14.07,9.66,7.23,9.67,18.97,0,26.22-4.98,3.74-6.51,7.78-5.88,14.06,1.22,12-4.39,17.91-16.73,18.58Zm-56.58-79.22c-4.74-.17-6.02,.76-5.32,5.03,1.74,10.5-1.78,18.46-10.17,24.8-4.17,3.15-3.91,3.8,.03,7.43,.37,.34,.71,.71,1.12,1,7.76,5.64,10.44,13.24,9.08,22.64-.78,5.4,.43,6.72,5.75,5.93,9.56-1.43,17,1.49,22.95,9.2,4.22,5.46,4.93,5.47,9.1,.08,5.95-7.67,13.41-10.64,22.94-9.28,5.31,.76,6.37-.52,5.89-5.86-.06-.62-.16-1.24-.23-1.86-.89-8.07,1.67-14.63,8.04-19.85,6.9-5.65,6.89-5.9-.08-11.64-6.36-5.24-8.84-11.83-7.95-19.88,.1-.87,.25-1.74,.29-2.61,.2-4.43-.64-5.3-4.95-5.14-.62,.02-1.24,.1-1.86,.18-8.59,1.18-15.59-1.39-21.15-8.29-5.29-6.57-5.65-6.58-10.86-.05-5.54,6.94-12.53,9.51-21.13,8.35-.62-.08-1.24-.15-1.5-.18Z" />
        </g>
      </g>
    </svg>
  );
};

CursosSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

CursosSVG.defaultProps = {
  height: 26,
  width: 26,
  fill: "#222",
};

export default CursosSVG;
