import React from "react";
import PropTypes from "prop-types";

const SoporteSVG = ({ height, width, onClick, active }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.32 21.95"
      fill="#fff"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M9.4,22c-.31-.1-.61-.23-.89-.39-.63-.45-.91-1.25-.71-2,.2-.8,.87-1.41,1.69-1.51,.79-.07,1.54,.35,1.89,1.07,.05,.15,.2,.24,.35,.22h3c1.07,.04,1.96-.79,2-1.86,0-.05,0-.1,0-.14v-1.9h-1.27v1.26h-1.29V7.75h1.27v1.25h1.29c.2-2.45-.88-4.83-2.85-6.3-2.27-1.74-5.38-1.9-7.82-.42-2.34,1.4-3.68,4-3.46,6.72h1.26v-1.23h1.29v9h-1.28v-1.29h-1.87c-.43,0-.84-.13-1.18-.39-.5-.33-.8-.88-.82-1.48v-2.7c.02-.81,.56-1.53,1.33-1.78v-1.43c.14-2.11,1.1-4.08,2.67-5.5C5.6,.66,7.78-.13,10,.02c3.28,.11,6.18,2.19,7.34,5.26,.49,1.15,.72,2.4,.66,3.65-.02,.14,.06,.27,.19,.32,.7,.3,1.14,.99,1.11,1.75v2.59c.02,.76-.43,1.45-1.13,1.75-.1,.04-.17,.15-.15,.26v1.86c.02,1.78-1.41,3.23-3.19,3.25-.01,0-.03,0-.04,0h-3.11c-.14,0-.21,0-.27,.16-.25,.55-.75,.95-1.34,1.08l-.07,.05h-.6Zm6.06-7.76h2c.32-.03,.57-.3,.56-.62v-2.59c0-.33-.24-.6-.57-.63h-2v3.84ZM3.86,10.33H1.93c-.35,0-.63,.28-.63,.63,0,.01,0,.03,0,.04v2.5c-.02,.35,.24,.66,.6,.68,.03,0,.06,0,.09,0h1.87v-3.85Zm6.44,9.67c0-.35-.3-.64-.65-.63-.35,0-.64,.3-.63,.65,0,.35,.3,.64,.65,.63,.35-.02,.63-.3,.63-.65Z" />
          {active?
            <circle id="Elipse_23" fill="#ffb400" cx="3.78" cy="3.77" r="3.77" />
          :null}
        </g>
      </g>
    </svg>
  );
};

SoporteSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

SoporteSVG.defaultProps = {
  height: 22,
  width: 22,
  active: false,
};

export default SoporteSVG;
