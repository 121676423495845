export { default as states } from "./json/states.json";
export { default as countries } from "./json/countries.json";

export const regimens = [
  {
    value: 'Régimen Simplificado de Confianza',
    label: 'Régimen Simplificado de Confianza'
  },
  {
    value: 'Sueldos y salarios e ingresos asimilados a salarios',
    label: 'Sueldos y salarios e ingresos asimilados a salarios'
  },
  {
    value: 'Régimen de Actividades Empresariales y Profesionales',
    label: 'Régimen de Actividades Empresariales y Profesionales'
  },
  {
    value: 'Régimen de Incorporación Fiscal',
    label: 'Régimen de Incorporación Fiscal'
  },
  { value: 'Enajenación de bienes', label: 'Enajenación de bienes' },
  {
    value: 'Régimen de Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    label: 'Régimen de Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'
  },
  {
    value: 'Régimen de Arrendamiento',
    label: 'Régimen de Arrendamiento'
  },
  { value: 'Intereses', label: 'Intereses' },
  { value: 'Obtención de premios', label: 'Obtención de premios' },
  { value: 'Dividendos', label: 'Dividendos' },
  { value: 'Demás ingresos', label: 'Demás ingresos' },
  {
    value: 'Régimen Simplificado de Confianza',
    label: 'Régimen Simplificado de Confianza'
  },
  {
    value: 'Régimen de Actividades Empresariales con ingresos a través de Plataformas Tecnológicas.',
    label: 'Régimen de Actividades Empresariales con ingresos a través de Plataformas Tecnológicas.'
  },
  {
    value: 'Régimen de Arrendamiento',
    label: 'Régimen de Arrendamiento'
  },
  {
    value: 'Régimen de Actividades Empresariales y Profesionales',
    label: 'Régimen de Actividades Empresariales y Profesionales'
  },
  { value: 'Persona moral', label: 'Persona moral' }
]

export const tipodeEstudiantes = [
    {
      value: 'Externo',
      label: 'Externo'
    },
    {
      value: 'Interno',
      label: 'Interno'
    }
]

export const usoCDFI = [
  {
    use: "G01",
    label: "G01 Adquisición de mercancías",
    persona: ["Física", "Moral"],
  },
  {
    use: "G02",
    label: "G02 Devoluciones, descuentos o bonificaciones",
    persona: ["Física", "Moral"],
  },
  { use: "G03", label: "G03 Gastos en general", persona: ["Física", "Moral"] },
  { use: "I01", label: "I01 Construcciones", persona: ["Física", "Moral"] },
  {
    use: "I02",
    label: "I02 Mobiliario y equipo de oficina por inversiones",
    persona: ["Física", "Moral"],
  },
  {
    use: "I03",
    label: "I03 Equipo de transporte",
    persona: ["Física", "Moral"],
  },
  {
    use: "I04",
    label: "I04 Equipo de cómputo y accesorios",
    persona: ["Física", "Moral"],
  },
  {
    use: "I05",
    label: "I05 Dados, troqueles, moldes, matrices y herramental",
    persona: ["Física", "Moral"],
  },
  {
    use: "I06",
    label: "I06 Comunicaciones telefónicas",
    persona: ["Física", "Moral"],
  },
  {
    use: "I07",
    label: "I07 Comunicaciones satelitales",
    persona: ["Física", "Moral"],
  },
  {
    use: "I08",
    label: "I08 Otra maquinaria y equipo",
    persona: ["Física", "Moral"],
  },
  {
    use: "D01",
    label: "D01 Honorarios médicos, dentales y gastos hospitalarios.",
    persona: ["Física"],
  },
  {
    use: "D02",
    label: "D02 Gastos médicos por incapacidad o discapacidad",
    persona: ["Física"],
  },
  { use: "D03", label: "D03 Gastos funerales.", persona: ["Física"] },
  { use: "D04", label: "D04 Donativos", persona: ["Física"] },
  {
    use: "D05",
    label:
      "D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
    persona: ["Física"],
  },
  {
    use: "D06",
    label: "D06 Aportaciones voluntarias al SAR.",
    persona: ["Física"],
  },
  {
    use: "D07",
    label: "D07 Primas por seguros de gastos médicos.",
    persona: ["Física"],
  },
  {
    use: "D08",
    label: "D08 Gastos de transportación escolar obligatoria.",
    persona: ["Física"],
  },
  {
    use: "D09",
    label:
      "D09 Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
    persona: ["Física"],
  },
  {
    use: "D10",
    label: "D10 Pagos por servicios educativos (colegiaturas)",
    persona: ["Física"],
  },
  { use: "P01", label: "P01 Por definir", persona: ["Física", "Moral"] },
];

export const tiposUsuarios = [
  {
    user_type_id: 1,
    unique_id: "interno",
    name: "Interno",
  },
  {
    user_type_id: 2,
    unique_id: "externo",
    name: "Externo",
  },
];

export const tiposCursos = [
  {
    course_type_id: 1,
    unique_id: "interno",
    name: "Interno",
  },
  {
    course_type_id: 2,
    unique_id: "externo",
    name: "Externos",
  },
];

export const directions = [
  { label: "Administración y finanzas", value: "Administración y finanzas" },
  { label: "Comercial", value: "Comercial" },
  { label: "Desarrollo Humano", value: "Desarrollo Humano" },
  { label: "Direccion General", value: "Direccion General" },
  { label: "General", value: "General" },
  { label: "Marcela Gándara Cantú", value: "Marcela Gándara Cantú" },
  { label: "Mercadotecnica", value: "Mercadotecnica" },
  { label: "Mercadotecnia", value: "Mercadotecnia" },
  { label: "Titulacion", value: "Titulacion" },
  { label: "Nuevos Negocios", value: "Nuevos Negocios" },
  { label: "Operativo", value: "Operativo" },
  { label: "Operativo y Comercial", value: "Operativo y Comercial" },
  { label: "Servicios", value: "Servicios" },
  { label: "Tecnología", value: "Tecnología" },
  { label: "Uso Comercial", value: "Uso Comercial" },
  { label: "Usos Comerciales", value: "Usos Comerciales" },
];

export const departments = [
  { label: "Administración RH", value: "Administración RH" },
  { label: "Administrativo", value: "Administrativo" },
  { label: "Capacitación", value: "Capacitación" },
  { label: "Comercial", value: "Comercial" },
  { label: "Contact Center", value: "Contact Center" },
  { label: "Corretaje General", value: "Corretaje General" },
  {
    label: "Corretaje General / Zona Cancún",
    value: "Corretaje General / Zona Cancún",
  },
  {
    label: "Adamar, El Campestre",
    value: "Adamar, El Campestre",
  },
  {
    label: "Adamar, El Campestre, Punto Sur 724 Apartments",
    value: "Adamar, El Campestre, Punto Sur 724 Apartments",
  },
  {
    label: "Adamar El Campestre",
    value: "Adamar El Campestre",
  },
  {
    label: "Adamar El Campestre Punto Sur 724 Apartments",
    value: "Adamar El Campestre Punto Sur 724 Apartments",
  },
  { label: "Administración", value: "Administración" },
  { label: "Administracion", value: "Administracion" },
  { label: "Alva", value: "Alva" },
  { label: "Corretaje ZMG", value: "Corretaje ZMG" },
  { label: "Berlio", value: "Berlio" },
  { label: "Boskia, Viviento", value: "Boskia, Viviento" },
  { label: "Boskia Viviento", value: "Boskia Viviento" },
  { label: "Cancún", value: "Cancún" },
  { label: "Capital Norte", value: "Capital Norte" },
  { label: "Capital Sur", value: "Capital Sur" },
  { label: "CDMX", value: "CDMX" },
  { label: "Centralia", value: "Centralia" },
  { label: "CEO", value: "CEO" },
  { label: "Compras", value: "Compras" },
  { label: "Contabilidad", value: "Contabilidad" },
  { label: "Coranta", value: "Coranta" },
  { label: "Corretaje CDMX", value: "Corretaje CDMX" },
  { label: "Corretaje Cdmx", value: "Corretaje Cdmx" },
  { label: "Corretaje ZMG", value: "Corretaje ZMG" },
  { label: "Corretaje Zmg", value: "Corretaje Zmg" },
  { label: "Desarrollo Humano", value: "Desarrollo Humano" },
  { label: "Dirección General", value: "Dirección General" },
  { label: "Direccion General", value: "Direccion General" },
  { label: "Dirección Industrial", value: "Dirección Industrial" },
  { label: "Dirección Retail", value: "Dirección Retail" },
  { label: "Distrito Natura", value: "Distrito Natura" },
  { label: "El Eden", value: "El Eden" },
  { label: "Expansion", value: "Expansion" },
  { label: "Expansión", value: "Expansión" },
  { label: "Ferrara", value: "Ferrara" },
  { label: "Finanzas", value: "Finanzas" },
  { label: "Foro 4", value: "Foro 4" },
  { label: "Fuerza De Venta", value: "Fuerza De venta" },
  { label: "Habitacional CDMX", value: "Habitacional CDMX" },
  { label: "Habitacional Cdmx", value: "Habitacional Cdmx" },
  { label: "Herana", value: "Herana" },
  { label: "Heredit", value: "Heredit" },
  { label: "Heredit, Yatta", value: "Heredit, Yatta" },
  { label: "Heredit Yatta", value: "Heredit Yatta" },
  { label: "Hubspot y pivot", value: "Hubspot y pivot" },
  { label: "General", value: "General" },
  { label: "Kulkana", value: "Kulkana" },
  { label: "Las Lomas", value: "Las Lomas" },
  { label: "Legal y Comercial ", value: "Legal y Comercial " },
  { label: "Legal", value: "Legal" },
  { label: "León", value: "León" },
  { label: "Leon West Towers Leon Foro 4", value: "Leon West Towers Leon Foro 4" },
  { label: "Leon, West Towers Leon, Foro 4", value: "Leon, West Towers Leon, Foro 4" },
  { label: "Listing", value: "Listing" },
  { label: "Los Cabos", value: "Los Cabos" },
  { label: "Loma Sur", value: "Loma Sur" },
  { label: "Lomas Sur", value: "Lomas Sur" },
  { label: "Luna Moranta", value: "Luna Moranta" },
  { label: "Maestros, Parker", value: "Maestros, Parker" },
  { label: "Maestros Parker", value: "Maestros Parker" },
  { label: "Mercadotecnia", value: "Mercadotecnia" },
  { label: "Miralto", value: "Miralto" },
  { label: "Nuevos Negocios", value: "Nuevos Negocios" },
  { label: "No hace Guardias", value: "No hace Guardias" },
  { label: "Onturia Cabos By Autèntico Vertical", value: "Onturia Cabos By Autèntico Vertical" },
  { label: "Operativo", value: "Operativo" },
  { label: "Operaciones", value: "Operaciones" },
  { label: "Paraíso Punto Sur", value: "Paraíso Punto Sur" },
  { label: "Punto Sur 724 Apartments", value: "Punto Sur 724 Apartments" },
  { label: "Punto Sur 724 Apartments", value: "Punto Sur 724 Apartments" },
  { label: "Reclutamiento y Selección", value: "Reclutamiento y Selección" },
  { label: "Retail", value: "Retail" },
  { label: "Second Homes", value: "Second Homes" },
  { label: "Second Homes y Pto Vallarta", value: "Second Homes y Pto Vallarta" },
  { label: "Sin Area", value: "Sin Area" },
  { label: "Soare We Solares", value: "Soare We Solares" },
  { label: "Staff", value: "Staff" },
  { label: "Sybil", value: "Sybil" },
  { label: "Tecnologia", value: "Tecnologia" },
  { label: "Tecnología", value: "Tecnología" },
  { label: "Terrasoles", value: "Terrasoles" },
  { label: "Titulación", value: "Titulación" },
  { label: "Titulacion", value: "Titulacion" },
  { label: "Torre Mm", value: "Torre Mm" },
  { label: "Torre MM", value: "Torre MM" },
  { label: "Usos Comerciales", value: "Usos Comerciales" },
  { label: "Usos Industriales", value: "Usos Industriales" },
  { label: "Usos Industriales, Talmer", value: "Usos Industriales, Talmer" },
  { label: "Usos Industriales Talmer", value: "Usos Industriales Talmer" },
  { label: "Ventura", value: "Ventura" },
  { label: "Ventura / No Hace Guardias", value: "Ventura / No Hace Guardias" },
  { label: "Ventura No Hace Guardias", value: "Ventura No Hace Guardias" },
  { label: "West Towers Leon", value: "West Towers Leon" },
  { label: "Will", value: "Will" },
  { label: "Yatta", value: "Yatta" },
  { label: "Yconia", value: "Yconia" },
  { label: "Zona Cancún", value: "Zona Cancún" },
  { label: "Zona Centro", value: "Zona Centro" },
  { label: "Zona Norte", value: "Zona Norte" },
  { label: "Zona Norte y Qro", value: "Zona Norte y Qro" },
  { label: "Zona Norte y QRO", value: "Zona Norte y QRO" },
  { label: "Zona Puerto Vallarta", value: "Zona Puerto Vallarta" },
  { label: "Zona QRO", value: "Zona QRO" },
  { label: "Zona Real", value: "Zona Real" },
  { label: "Zona Real / Zona Cancún", value: "Zona Real / Zona Cancún" },
  { label: "Zona Real y Zona Cancún", value: "Zona Real y Zona Cancún" },
  { label: "Zona Sur, Adamar, El Campestre, Paraíso, El Eden, Punto Sur y Corretaje Sur", value: "Zona Sur, Adamar, El Campestre, Paraíso, El Eden, Punto Sur y Corretaje Sur" },
  { label: "Zona Sur Adamar El Campestre Paraíso El Eden Punto Sur y Corretaje Sur", value: "Zona Sur Adamar El Campestre Paraíso El Eden Punto Sur y Corretaje Sur" },
  { label: "Zona Sur", value: "Zona Sur" },
  { label: "Zona Sur y Zona Cancún", value: "Zona Sur y Zona Cancún" },
];

export const zones = [
  { label: "Administración y finanzas", value: "Administración y finanzas" },
  { label: "Administración finanzas y contabilidad", value: "Administración finanzas y contabilidad" },
  { label: "Administración, finanzas y contabilidad", value: "Administración, finanzas y contabilidad" },
  { label: "Cancún", value: "Cancún" },
  { label: "Cancun", value: "Cancun" },
  { label: "CDMX", value: "CDMX" },
  { label: "Centro", value: "Centro" },
  { label: "Corretaje Zmg", value: "Corretaje Zmg" },
  { label: "Comercial", value: "Comercial" },
  { label: "Direccion General", value: "Direccion General" },
  { label: "Expansión", value: "Expansión" },
  { label: "Expansion", value: "Expansion" },
  { label: "GDL", value: "GDL" },
  { label: "Gdl", value: "Expansion" },
  { label: "Las Lomas", value: "Las Lomas" },
  { label: "Legal", value: "Legal" },
  { label: "Leon", value: "Leon" },
  { label: "Mercadotecnia", value: "Mercadotecnia" },
  { label: "Norte", value: "Norte" },
  { label: "Operaciones", value: "Operaciones"},
  { label: "Puerto Vallarta", value: "Puerto Vallarta"},
  { label: "Queretaro", value: "Queretaro"},
  { label: "Real", value: "Real"},
  { label: "Servicios", value: "Servicios"},
  { label: "Staff", value: "Staff"},
  { label: "Sur", value: "Sur"},
  { label: "Tecnologia", value: "Tecnologia"},
  { label: "Yconia", value: "Yconia"},
  { label: "Ventas de Capacitación", value: "Ventas de Capacitación" },
  { label: "Comercial", value: "Comercial" },
  { label: "Sin Área / Kulkana", value: "Sin Área / Kulkana" },
  { label: "Los Cabos", value: "Los Cabos" },
  { label: "León", value: "León" },
  { label: "General", value: "General" },
  { label: "Lomas", value: "Lomas" },
  { label: "Sin Área y Second Homes", value: "Sin Área y Second Homes" },
  { label: "Cuentas", value: "Cuentas" },
  { label: "Digital", value: "Digital" },
  { label: "Administración", value: "Administración" },
  { label: "Creatividad", value: "Creatividad" },
  { label: "Operativo", value: "Operativo" },
  { label: "Second Homes", value: "Second Homes" },
  { label: "Kulkana", value: "Kulkana" },
  { label: "Héredit", value: "Héredit" },
  { label: "Sybil", value: "Sybil" },
  { label: "Will", value: "Will" },
  { label: "Yatta", value: "Yatta" },
  { label: "Parker", value: "Parker" },
  { label: "Sonata, Paseo Central", value: "Sonata, Paseo Central" },
  { label: "Vitana Terrenos, Miralto", value: "Vitana Terrenos, Miralto" },
  { label: "Altures", value: "Altures" },
  { label: "Capital Norte", value: "Capital Norte" },
  { label: "Alva", value: "Alva" },
  { label: "Loma Bosque", value: "Loma Bosque" },
  { label: "Distrito Natura", value: "Distrito Natura" },
  { label: "Capital Sur", value: "Capital Sur" },
  { label: "Alalma, Capital Sur", value: "Alalma, Capital Sur" },
  { label: "Petrel, Manahal", value: "Petrel, Manahal" },
  {
    label: "Corretaje Zona Qro, Manahal, Petrel",
    value: "Corretaje Zona Qro, Manahal, Petrel",
  },
  { label: "Ventura", value: "Ventura" },
  { label: "Soaré, We Solares", value: "Soaré, We Solares" },
  { label: "Sin Área y Kulkana", value: "Sin Área y Kulkana" },
  {
    label: "Soaré, We Solares / Kulkana",
    value: "Soaré, We Solares / Kulkana",
  },
  { label: "Classiqa", value: "Classiqa" },
  { label: "Boskia, Vivento", value: "Boskia, Vivento" },
  { label: "El Origen, El Edén", value: "El Origen, El Edén" },
  { label: "Boskia Reserva", value: "Boskia Reserva" },
  { label: "Loma Sur", value: "Loma Sur" },
  { label: "Loma Sur / Kulkana", value: "Loma Sur / Kulkana" },
  {
    label: "Adamar, El Campestre /Kulkana",
    value: "Adamar, El Campestre /Kulkana",
  },
  { label: "Adamar, El Campestre", value: "Adamar, El Campestre" },
  { label: "Paraíso y Distrito Natura", value: "Paraíso y Distrito Natura" },
  { label: "Punto Sur", value: "Punto Sur" },
  { label: "Los Espinos", value: "Los Espinos" },
  { label: "Distrito Natura", value: "Distrito Natura" },
  { label: "Punto Sur 724", value: "Punto Sur 724" },
  { label: "Usos Industriales", value: "Usos Industriales" },
  { label: "El Edén", value: "El Edén" },
  { label: "Nuvola, Parker y Mtros", value: "Nuvola, Parker y Mtros" },
  { label: "Desarrollo Humano", value: "Desarrollo Humano" },
  { label: "Corretaje ZMG", value: "Corretaje ZMG" },
  { label: "West Torres León", value: "West Torres León" },
  { label: "Foro 4 León", value: "Foro 4 León" },
  { label: "Usos Comerciales", value: "Usos Comerciales" },
  { label: "Tecnología", value: "Tecnología" },
  { label: "Boskia", value: "Boskia" },
  { label: "Corretaje ZMG", value: "Corretaje ZMG" },
  { label: "Luna Moranta", value: "Luna Moranta" },
];

export const occupation = [
  { label: "Agricultura", value: "Agricultura" },
  {
    label: "Bienes De Consumo (Alimentación, Bebidas Y Tabaco)",
    value: "Bienes De Consumo (Alimentación, Bebidas Y Tabaco)",
  },
  { label: "Bienes Inmuebles", value: "Bienes Inmuebles" },
  { label: "Comercio", value: "Comercio" },
  { label: "Comercio Electrónico", value: "Comercio Electrónico" },
  { label: "Construcción", value: "Construcción" },
  { label: "Deporte Y Ocio", value: "Deporte Y Ocio" },
  { label: "Educación", value: "Educación" },
  { label: "Energía Y Medio Ambiente", value: "Energía Y Medio Ambiente" },
  { label: "Establecimientos", value: "Establecimientos" },
  { label: "Finanzas", value: "Finanzas" },
  { label: "Función Pública", value: "Función Pública" },
  { label: "Ganadería", value: "Ganadería" },
  {
    label: "Hotelería, Restauración, Turismo",
    value: "Hotelería, Restauración, Turismo",
  },
  { label: "Industria Farmacéutica", value: "Industria Farmacéutica" },
  { label: "Industria Marítima", value: "Industria Marítima" },
  { label: "Industrias Químicas", value: "Industrias Químicas" },
  { label: "Ingeniería Mecánica", value: "Ingeniería Mecánica" },
  { label: "Logística Y Transporte", value: "Logística Y Transporte" },
  {
    label: "Medios De Comunicación Y Marketing",
    value: "Medios De Comunicación Y Marketing",
  },
  { label: "Metalurgia Y Electrónica", value: "Metalurgia Y Electrónica" },
  { label: "Minería", value: "Minería" },
  { label: "Salud", value: "Salud" },
  { label: "Seguros", value: "Seguros" },
  { label: "Servicios", value: "Servicios" },
  { label: "Sociedad", value: "Sociedad" },
  {
    label: "Tecnología Y Telecomunicaciones",
    value: "Tecnología Y Telecomunicaciones",
  },
  { label: "Textiles Y Vestido", value: "Textiles Y Vestido" },
  { label: "Labores Domésticas", value: "Labores Domésticas" },
];

export const birthplaces = [
  { label: "Extranjero", value: "Extranjero" },
  { label: "Aguascalientes", value: "Aguascalientes" },
  { label: "Baja California", value: "Baja California" },
  { label: "Baja California Sur", value: "Baja California Sur" },
  { label: "Campeche", value: "Campeche" },
  { label: "Coahuila", value: "Coahuila" },
  { label: "Colima", value: "Colima" },
  { label: "Chiapas", value: "Chiapas" },
  { label: "Chihuahua", value: "Chihuahua" },
  { label: "Durango", value: "Durango" },
  { label: "CDMX", value: "CDMX" },
  { label: "Guanajuato", value: "Guanajuato" },
  { label: "Guerrero", value: "Guerrero" },
  { label: "Hidalgo", value: "Hidalgo" },
  { label: "Jalisco", value: "Jalisco" },
  { label: "México", value: "México" },
  { label: "Michoacán", value: "Michoacán" },
  { label: "Morelos", value: "Morelos" },
  { label: "Nayarit", value: "Nayarit" },
  { label: "Nuevo León", value: "Nuevo León" },
  { label: "Oaxaca", value: "Oaxaca" },
  { label: "Puebla", value: "Puebla" },
  { label: "Querétaro", value: "Querétaro" },
  { label: "Quintana Roo", value: "Quintana Roo" },
  { label: "San Luis Potosí", value: "San Luis Potosí" },
  { label: "Sinaloa", value: "Sinaloa" },
  { label: "Sonora", value: "Sonora" },
  { label: "Tabasco", value: "Tabasco" },
  { label: "Tamaulipas", value: "Tamaulipas" },
  { label: "Tlaxcala", value: "Tlaxcala" },
  { label: "Veracruz", value: "Veracruz" },
  { label: "Yucatán", value: "Yucatán" },
  { label: "Zacatecas", value: "Zacatecas" },
];

export const marital_statuses = [
  { label: "Soltero", value: "Soltero" },
  { label: "Casado", value: "Casado" },
  { label: "Divorciado", value: "Divorciado" },
  { label: "Unión Libre", value: "Unión Libre" },
];

export const matrimonial_regimes = [
  { label: "N/A", value: "N/A" },
  {
    label: "Sociedad Conyugal o Voluntaria",
    value: "Sociedad Conyugal o Voluntaria",
  },
  { label: "Sociedad Legal", value: "Sociedad Legal" },
  { label: "Separación de Bienes", value: "Separación de Bienes" },
];

export const types_of_road = [
  { label: "Calle", value: "Calle" },
  { label: "Avenida", value: "Avenida" },
  { label: "Carretera", value: "Carretera" },
  { label: "Andador", value: "Andador" },
];

export const months_to_number = [
  { label: "Enero", value: 1 },
  { label: "Febrero", value: 2 },
  { label: "Marzo", value: 3 },
  { label: "Abril", value: 4 },
  { label: "Mayo", value: 5 },
  { label: "Junio", value: 6 },
  { label: "Julio", value: 7 },
  { label: "Agosto", value: 8 },
  { label: "Septiembre", value: 9 },
  { label: "Octubre", value: 10 },
  { label: "Noviembre", value: 11 },
  { label: "Diciembre", value: 12 },
];

export const wiggote_amenities = [
  {
    amenity_id: 1,
    unique_id: "acceso-discapacitados",
    name: "Acceso discapacitados",
  },
  {
    amenity_id: 2,
    unique_id: "aire-acondicionado",
    name: "Aire Acondicionado",
  },
  {
    amenity_id: 4,
    unique_id: "alarma",
    name: "Alarma",
  },
  {
    amenity_id: 5,
    unique_id: "alberca",
    name: "Alberca",
  },
  {
    amenity_id: 6,
    unique_id: "amueblado",
    name: "Amueblado",
  },
  {
    amenity_id: 7,
    unique_id: "andenes-para-trailer",
    name: "Andenes para Tráiler",
  },
  {
    amenity_id: 8,
    unique_id: "asador",
    name: "Asador",
  },
  {
    amenity_id: 9,
    unique_id: "balcones",
    name: "Balcones",
  },
  {
    amenity_id: 10,
    unique_id: "bodega",
    name: "Bodega",
  },
  {
    amenity_id: 11,
    unique_id: "bodega-de-servicio",
    name: "Bodega de servicio",
  },
  {
    amenity_id: 12,
    unique_id: "calentador-de-agua",
    name: "Calentador de agua",
  },
  {
    amenity_id: 13,
    unique_id: "cancha-de-tenis",
    name: "Cancha de tenis",
  },
  {
    amenity_id: 14,
    unique_id: "caseta-de-guardia",
    name: "Caseta de guardia",
  },
  {
    amenity_id: 15,
    unique_id: "centros-comerciales-cercanos",
    name: "Centros comerciales cercanos",
  },
  {
    amenity_id: 16,
    unique_id: "chimenea",
    name: "Chimenea",
  },
  {
    amenity_id: 17,
    unique_id: "circuito-cerrado",
    name: "Circuito cerrado",
  },
  {
    amenity_id: 18,
    unique_id: "cisterna",
    name: "Cisterna",
  },
  {
    amenity_id: 19,
    unique_id: "cocina-equipada",
    name: "Cocina equipada",
  },
  {
    amenity_id: 20,
    unique_id: "cocina-integral",
    name: "Cocina integral",
  },
  {
    amenity_id: 22,
    unique_id: "cuarto-de-tv",
    name: "Cuarto de TV",
  },
  {
    amenity_id: 23,
    unique_id: "elevador",
    name: "Elevador",
  },
  {
    amenity_id: 24,
    unique_id: "escuelas-cercanas",
    name: "Escuelas Cercanas",
  },
  {
    amenity_id: 25,
    unique_id: "estacionamiento-de-visitas",
    name: "Estacionamiento de visitas",
  },
  {
    amenity_id: 26,
    unique_id: "estacionamiento-techado",
    name: "Estacionamiento techado",
  },
  {
    amenity_id: 27,
    unique_id: "estudio",
    name: "Estudio",
  },
  {
    amenity_id: 28,
    unique_id: "facilidades-para-adultos-mayores",
    name: "Facilidades para adultos mayores",
  },
  {
    amenity_id: 29,
    unique_id: "facilidades-para-discapacitados",
    name: "Facilidades para discapacitados",
  },
  {
    amenity_id: 30,
    unique_id: "frente-a-parque",
    name: "Frente a parque",
  },
  {
    amenity_id: 31,
    unique_id: "fumadores",
    name: "Fumadores",
  },
  {
    amenity_id: 34,
    unique_id: "gimnasio",
    name: "Gimnasio",
  },
  {
    amenity_id: 35,
    unique_id: "jacuzzi",
    name: "Jacuzzi",
  },
  {
    amenity_id: 36,
    unique_id: "jardin",
    name: "Jardín",
  },
  {
    amenity_id: 38,
    unique_id: "linea-telefonica",
    name: "Línea telefónica",
  },
  {
    amenity_id: 39,
    unique_id: "mascotas",
    name: "Mascotas",
  },
  {
    amenity_id: 41,
    unique_id: "patio-de-lavado",
    name: "Patio de lavado",
  },
  {
    amenity_id: 42,
    unique_id: "planta-electrica",
    name: "Planta eléctrica",
  },
  {
    amenity_id: 43,
    unique_id: "roof-garden",
    name: "Roof garden",
  },
  {
    amenity_id: 44,
    unique_id: "salon-de-fiestas",
    name: "Salón de fiestas",
  },
  {
    amenity_id: 45,
    unique_id: "salon-de-usos-multiples",
    name: "Salón de usos múltiples",
  },
  {
    amenity_id: 46,
    unique_id: "se-aceptan-mascotas",
    name: "Se aceptan mascotas",
  },
  {
    amenity_id: 47,
    unique_id: "se-permite-fumar",
    name: "Se permite fumar",
  },
  {
    amenity_id: 48,
    unique_id: "seguridad-contra-incendios",
    name: "Seguridad contra incendios",
  },
  {
    amenity_id: 49,
    unique_id: "seguridad-privada",
    name: "Seguridad privada",
  },
  {
    amenity_id: 50,
    unique_id: "servicios-basicos",
    name: "Servicios básicos",
  },
  {
    amenity_id: 51,
    unique_id: "sistema-de-ventilacion",
    name: "Sistema de ventilación",
  },
  {
    amenity_id: 52,
    unique_id: "tanque-de-agua",
    name: "Tanque de agua",
  },
  {
    amenity_id: 54,
    unique_id: "terraza",
    name: "Terraza",
  },
  {
    amenity_id: 55,
    unique_id: "tinaco",
    name: "Tinaco",
  },
  {
    amenity_id: 56,
    unique_id: "zona-industrial",
    name: "Zona Industrial",
  },
  {
    amenity_id: 57,
    unique_id: "area-de-juegos-infantiles",
    name: "Área de Juegos Infantiles",
  },
];

export const wiggote_developments_amenities = [
  {
    amenity_id: 1,
    unique_id: "circuito-cerrado",
    name: "Circuito cerrado",
  },
  {
    amenity_id: 2,
    unique_id: "gimnasio",
    name: "Gimnasio",
  },
  {
    amenity_id: 3,
    unique_id: "zona-wifi",
    name: "Zona Wifi",
  },
  {
    amenity_id: 5,
    unique_id: "planta-de-emergencia",
    name: "Planta de emergencia",
  },
  {
    amenity_id: 6,
    unique_id: "area-de-juegos-infantiles",
    name: "Área de juegos infantiles",
  },
  {
    amenity_id: 7,
    unique_id: "seguridad-contra-incendios",
    name: "Seguridad contra incendios",
  },
  {
    amenity_id: 8,
    unique_id: "spa",
    name: "Spa",
  },
  {
    amenity_id: 9,
    unique_id: "valet-parking",
    name: "Valet Parking",
  },
  {
    amenity_id: 10,
    unique_id: "conmutador",
    name: "Conmutador",
  },
  {
    amenity_id: 11,
    unique_id: "acabados-de-lujo",
    name: "Acabados de lujo",
  },
  {
    amenity_id: 13,
    unique_id: "elevador",
    name: "Elevador",
  },
  {
    amenity_id: 14,
    unique_id: "jacuzzi",
    name: "Jacuzzi",
  },
  {
    amenity_id: 15,
    unique_id: "jardin-principal",
    name: "Jardín principal",
  },
  {
    amenity_id: 17,
    unique_id: "centros-comerciales-cercanos",
    name: "Centros comerciales cercanos",
  },
  {
    amenity_id: 18,
    unique_id: "escuelas-cercanas",
    name: "Escuelas Cercanas",
  },
  {
    amenity_id: 19,
    unique_id: "frente-a-parque",
    name: "Frente a parque",
  },
  {
    amenity_id: 20,
    unique_id: "lobby",
    name: "Lobby",
  },
  {
    amenity_id: 21,
    unique_id: "areas-verdes",
    name: "Áreas verdes",
  },
  {
    amenity_id: 22,
    unique_id: "campo-de-golf",
    name: "Campo de Golf",
  },
  {
    amenity_id: 23,
    unique_id: "cancha-de-paddle",
    name: "Cancha de Paddle",
  },
  {
    amenity_id: 24,
    unique_id: "cancha-de-squash",
    name: "Cancha de squash",
  },
  {
    amenity_id: 25,
    unique_id: "cancha-de-tenis",
    name: "Cancha de tenis",
  },
  {
    amenity_id: 26,
    unique_id: "carril-de-nado",
    name: "Carril de nado",
  },
  {
    amenity_id: 27,
    unique_id: "estacionamiento-de-visitas",
    name: "Estacionamiento de visitas",
  },
  {
    amenity_id: 28,
    unique_id: "pista-de-jogging",
    name: "Pista de jogging",
  },
  {
    amenity_id: 29,
    unique_id: "roof-garden",
    name: "Roof Garden",
  },
  {
    amenity_id: 32,
    unique_id: "business-center",
    name: "Business center",
  },
  {
    amenity_id: 33,
    unique_id: "cine",
    name: "Cine",
  },
  {
    amenity_id: 36,
    unique_id: "sauna",
    name: "Sauna",
  },
  {
    amenity_id: 37,
    unique_id: "vapor",
    name: "Vapor",
  },
  {
    amenity_id: 77,
    unique_id: "accesibilidad-para-adultos-mayores",
    name: "Accesibilidad para adultos mayores",
  },
  {
    amenity_id: 78,
    unique_id: "accesibilidad-para-discapacitados",
    name: "Accesibilidad para discapacitados",
  },
  {
    amenity_id: 79,
    unique_id: "acceso-controlado",
    name: "Acceso controlado",
  },
  {
    amenity_id: 80,
    unique_id: "aire-acondicionado",
    name: "Aire acondicionado",
  },
  {
    amenity_id: 81,
    unique_id: "alarma",
    name: "Alarma",
  },
  {
    amenity_id: 82,
    unique_id: "ama-de-llaves",
    name: "Ama de llaves",
  },
  {
    amenity_id: 83,
    unique_id: "andenes-de-carga-y-descarga",
    name: "Andenes de carga y descarga",
  },
  {
    amenity_id: 84,
    unique_id: "area-de-picnic",
    name: "Área de picnic",
  },
  {
    amenity_id: 85,
    unique_id: "area-de-yoga",
    name: "Área de yoga",
  },
  {
    amenity_id: 86,
    unique_id: "asadores",
    name: "Asadores",
  },
  {
    amenity_id: 87,
    unique_id: "asoleaderos",
    name: "Asoleaderos",
  },
  {
    amenity_id: 88,
    unique_id: "banos-en-areas-comunes",
    name: "Baños en áreas comunes",
  },
  {
    amenity_id: 89,
    unique_id: "bar",
    name: "Bar",
  },
  {
    amenity_id: 90,
    unique_id: "biblioteca",
    name: "Biblioteca",
  },
  {
    amenity_id: 91,
    unique_id: "bodegas-de-servicio",
    name: "Bodegas de servicio",
  },
  {
    amenity_id: 92,
    unique_id: "boliche",
    name: "Boliche",
  },
  {
    amenity_id: 93,
    unique_id: "caballerizas",
    name: "Caballerizas",
  },
  {
    amenity_id: 94,
    unique_id: "cafeteria",
    name: "Cafetería",
  },
  {
    amenity_id: 95,
    unique_id: "calefaccion",
    name: "Calefacción",
  },
  {
    amenity_id: 96,
    unique_id: "calentador-de-agua",
    name: "Calentador de agua",
  },
  {
    amenity_id: 97,
    unique_id: "cancha-de-basketball",
    name: "Cancha de basketball",
  },
  {
    amenity_id: 98,
    unique_id: "cancha-de-futbol",
    name: "Cancha de fútbol",
  },
  {
    amenity_id: 99,
    unique_id: "casa-club",
    name: "Casa club",
  },
  {
    amenity_id: 100,
    unique_id: "caseta-de-vigilancia",
    name: "Caseta de vigilancia",
  },
  {
    amenity_id: 101,
    unique_id: "cerca-electrificada",
    name: "Cerca electrificada",
  },
  {
    amenity_id: 102,
    unique_id: "chapoteadero",
    name: "Chapoteadero",
  },
  {
    amenity_id: 103,
    unique_id: "ciclopuerto",
    name: "Ciclopuerto",
  },
  {
    amenity_id: 104,
    unique_id: "ciclovias",
    name: "Ciclovías",
  },
  {
    amenity_id: 105,
    unique_id: "cinema-al-aire-libre",
    name: "Cinema al aire libre",
  },
  {
    amenity_id: 106,
    unique_id: "cisterna",
    name: "Cisterna",
  },
  {
    amenity_id: 107,
    unique_id: "club-de-golf",
    name: "Club de golf",
  },
  {
    amenity_id: 108,
    unique_id: "club-de-playa",
    name: "Club de playa",
  },
  {
    amenity_id: 109,
    unique_id: "club-de-yates",
    name: "Club de yates",
  },
  {
    amenity_id: 110,
    unique_id: "club-deportivo",
    name: "Club deportivo",
  },
  {
    amenity_id: 111,
    unique_id: "comedor",
    name: "Comedor",
  },
  {
    amenity_id: 112,
    unique_id: "consierge",
    name: "Consierge",
  },
  {
    amenity_id: 113,
    unique_id: "cuarto-de-objetos",
    name: "Cuarto de objetos",
  },
  {
    amenity_id: 114,
    unique_id: "cuartos-de-basura",
    name: "Cuartos de basura",
  },
  {
    amenity_id: 115,
    unique_id: "drenaje",
    name: "Drenaje",
  },
  {
    amenity_id: 116,
    unique_id: "fogatero",
    name: "Fogatero",
  },
  {
    amenity_id: 117,
    unique_id: "food-court",
    name: "Food court",
  },
  {
    amenity_id: 118,
    unique_id: "gas-natural",
    name: "Gas natural",
  },
  {
    amenity_id: 119,
    unique_id: "gimnasio-al-aire-libre",
    name: "Gimnasio al aire libre",
  },
  {
    amenity_id: 120,
    unique_id: "huerto-urbano",
    name: "Huerto urbano",
  },
  {
    amenity_id: 121,
    unique_id: "iluminacion-led",
    name: "Iluminación LED",
  },
  {
    amenity_id: 122,
    unique_id: "ingreso-controlado",
    name: "Ingreso controlado",
  },
  {
    amenity_id: 123,
    unique_id: "instalaciones-ocultas",
    name: "Instalaciones ocultas",
  },
  {
    amenity_id: 124,
    unique_id: "lago",
    name: "Lago",
  },
  {
    amenity_id: 125,
    unique_id: "lavanderia",
    name: "Lavandería",
  },
  {
    amenity_id: 126,
    unique_id: "limites-de-velocidad-establecidos",
    name: "Límites de velocidad establecidos",
  },
  {
    amenity_id: 127,
    unique_id: "lockers",
    name: "Lockers",
  },
  {
    amenity_id: 128,
    unique_id: "ludoteca",
    name: "Ludoteca",
  },
  {
    amenity_id: 129,
    unique_id: "mascotas-permitidas",
    name: "Mascotas permitidas",
  },
  {
    amenity_id: 130,
    unique_id: "mesa-de-futbolito",
    name: "Mesa de futbolito",
  },
  {
    amenity_id: 131,
    unique_id: "mesa-de-ping-pong",
    name: "Mesa de ping pong",
  },
  {
    amenity_id: 132,
    unique_id: "motor-lobby",
    name: "Motor lobby",
  },
  {
    amenity_id: 133,
    unique_id: "muros-tilt-up",
    name: "Muros tilt up",
  },
  {
    amenity_id: 134,
    unique_id: "paneles-solares",
    name: "Paneles solares",
  },
  {
    amenity_id: 135,
    unique_id: "parque-para-perros",
    name: "Parque para perros",
  },
  {
    amenity_id: 136,
    unique_id: "patio-de-maniobras",
    name: "Patio de maniobras",
  },
  {
    amenity_id: 137,
    unique_id: "planta-de-tratamiento-de-agua",
    name: "Planta de tratamiento de agua",
  },
  {
    amenity_id: 138,
    unique_id: "porton-electrico",
    name: "Portón eléctrico",
  },
  {
    amenity_id: 139,
    unique_id: "regaderas",
    name: "Regaderas",
  },
  {
    amenity_id: 140,
    unique_id: "restaurantes",
    name: "Restaurantes",
  },
  {
    amenity_id: 141,
    unique_id: "sala-de-tareas",
    name: "Sala de tareas",
  },
  {
    amenity_id: 142,
    unique_id: "sala-lounge",
    name: "Sala lounge",
  },
  {
    amenity_id: 143,
    unique_id: "salon-de-eventos",
    name: "Salón de eventos",
  },
  {
    amenity_id: 144,
    unique_id: "salon-de-usos-multiples",
    name: "Salón de usos múltiples",
  },
  {
    amenity_id: 145,
    unique_id: "se-permite-fumar",
    name: "Se permite fumar",
  },
  {
    amenity_id: 146,
    unique_id: "seguridad-privada",
    name: "Seguridad privada",
  },
  {
    amenity_id: 147,
    unique_id: "sendero-de-trekking",
    name: "Sendero de trekking",
  },
  {
    amenity_id: 148,
    unique_id: "servicios-basicos-agua-y-luz",
    name: "Servicios básicos (agua y luz)",
  },
  {
    amenity_id: 149,
    unique_id: "servicios-medicos-cercanos",
    name: "Servicios médicos cercanos",
  },
  {
    amenity_id: 150,
    unique_id: "sistema-de-captacion-de-agua-pluvial",
    name: "Sistema de captación de agua pluvial",
  },
  {
    amenity_id: 151,
    unique_id: "sistema-de-ventilacion",
    name: "Sistema de ventilación",
  },
  {
    amenity_id: 152,
    unique_id: "sky-bar",
    name: "Sky bar",
  },
  {
    amenity_id: 153,
    unique_id: "telefonia",
    name: "Telefonía",
  },
  {
    amenity_id: 154,
    unique_id: "templo",
    name: "Templo",
  },
  {
    amenity_id: 155,
    unique_id: "zona-industrial",
    name: "Zona industrial",
  },
  {
    amenity_id: 156,
    unique_id: "alberca",
    name: "Alberca",
  },
];

export const rulesRequired = { required: "El campo es requerido" };
export const rulesEmail = {
  pattern: {
    value: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
    message: "El campo no es email",
  },
};
export const rulesCURP = {
  pattern: {
    value:
      /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/,
    message: "El campo no es CURP",
  },
};
export const rulesRFC = {
  pattern: {
    value:
      /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[0-9A-Z]{3}$/,
    message: "El campo no es RFC",
  },
};
export const rulesPhone = {
  maxLength: {
    value: 10,
    message: "El número de teléfono no es válido max 10 digitos.",
  },
};
