import React, { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

//Hooks
import { useForm } from "react-hook-form";
import {
  Button,
  CheckGroupC,
  ChooseC,
  LabelWrapper,
  SwitchC,
} from "../../core";

//Data
import GlobalContext from "../../context/GlobalContext";

//Components
import { tiposUsuarios } from "../../Constants";
import { Helmet } from "react-helmet";

/**
 * @component
 * Component in charge of assigning courses to users
 */

export default function AsignarCurso() {
  const { control, handleSubmit, reset } = useForm();
  const [type, setType] = useState(tiposUsuarios[0].name);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [availableCourses, setAvailableCourses] = useState([]);
  const { setAppLoading, NestPost } = useContext(GlobalContext);
  const [spinLoad, setSpinLoad] = useState(false);
  const onSubmit = (data) => {
    setSpinLoad(true);
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 1300,
    });
    if (data.courses.length < 1) {
      setSpinLoad(false);
      return Toast.fire({
        icon: "error",
        title: "Asegúrate de seleccionar cursos.",
      });
    }
    if (data.usersId.length < 1) {
      setSpinLoad(false);
      return Toast.fire({
        icon: "error",
        title: "Asegúrate de seleccionar usuarios.",
      });
    }
    try {
      NestPost({
        schema: "admin/assigned",
        body: data,
      }).then((res) => {
        setSpinLoad(false);
        Toast.fire({
          icon: "success",
          title: "Se asignaron los cursos correctamente.",
        });
      });
      reset();
    } catch (error) {
      setSpinLoad(false);
      Toast.fire({
        icon: "error",
        title: "Hubo un problema al asignar los cursos.",
      });
    }
  };
  const fetchUserData = useCallback(
    async (page = 1) => {
      setAppLoading(true);
      NestPost({
        schema: `user/search`,
        body: {
          query: { tipo_estudiante: type },
        },
      })
        .then((res) => {
          if (!res.data) return;
          const users = res.data
            .map((usr) => {
              return {
                user_type_id: usr.id,
                unique_id: usr.id,
                name: `${usr.names.toCapitalize()} ${usr.last_name.toCapitalize()} ${(usr.mothers_last_name
                  ? usr.mothers_last_name
                  : ""
                ).toCapitalize()}`,
              };
            })
            .sort((a, b) => a.name.localeCompare(b.name));
          setAvailableUsers(users);
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestPost, setAppLoading, type]
  );
  useEffect(() => {
    async function fetch() {
      await fetchUserData();
      await fetchCoursesData();
    }
    fetch();
  }, [type]);

  const onUserInput = (val) => {
    reset({ usersId: [], courses: [], tipo_usuario: val });
    setType(val);
  };

  const fetchCoursesData = useCallback(async () => {
    setAppLoading(true);
    NestPost({
      schema: `cursos/search`,
      body: {
        courseType: type,
      },
    })
      .then((res) => {
        if (!res.data) return;
        const courses = res.data.map((course) => {
          return {
            course_type_id: course.id,
            unique_id: course.id,
            name: course.name,
          };
        });
        setAvailableCourses(courses);
      })
      .finally((_) => {
        setAppLoading(false);
      });
  }, [NestPost, type, setAppLoading]);

  return (
    <div className="work_area">
      <Helmet>
        <title>Asignar cursos | Mentor</title>
      </Helmet>
      <div className="modal-body row">
        <div className="col-md-6">
          <SwitchC
            defaultValue={tiposUsuarios[0].name}
            onInput={onUserInput}
            label="Seleccionar tipo de usuarios y cursos"
            name="tipo_usuario"
            control={control}
            options={tiposUsuarios.map((tipo) => ({
              label: tipo.name,
              value: tipo.name,
            }))}
          />
        </div>
        <div className="col-md-6">
          {spinLoad ? (
            <i className="fa-solid fa-spinner fa-spin-pulse"></i>
          ) : (
            <Button onClick={handleSubmit(onSubmit)}>
              Asignar cursos seleccionados
            </Button>
          )}
        </div>
        <div className="col-md-6">
          {availableUsers?.length > 0 && (
            <LabelWrapper label="Seleccionar usuarios" className="w-100">
              <CheckGroupC.HashMap
                className="grid-col-3 d-flex flex-column"
                control={control}
                name="usersId"
                options={availableUsers.map((m) => ({
                  value: m.unique_id,
                  label: m.name,
                }))}
              />
            </LabelWrapper>
          )}
        </div>
        <div className="col-md-6">
          {availableCourses?.length > 0 && (
            <LabelWrapper label="Seleccionar cursos" className="w-100">
              <CheckGroupC.HashMap
                className="grid-col-3 d-flex flex-column"
                control={control}
                name="courses"
                options={availableCourses.map((m) => ({
                  value: m.unique_id,
                  label: m.name,
                }))}
              />
            </LabelWrapper>
          )}
        </div>
      </div>
    </div>
  );
}
