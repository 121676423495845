import React from "react";


/**
 * @component
 * Component in charge of displaying courses detail
*/

export default function CursosDetalle(props) {
  return (
    <div>
      <h2>Cursos del pedido</h2>
      <table className="table">
        <thead className="thead-light ">
          <tr>
            <th scope="col">Curso</th>
            <th scope="col">Maestro</th>
            <th scope="col">Precio</th>
          </tr>
        </thead>
        <tbody>
          {props.courseInfo &&
            props.courseInfo.map((course) => {
              return (
                <tr>
                  <td>{course.name}</td>
                  <td>{course.teacher}</td>
                  <td>{"$" + course.price}</td>
                </tr>
              );
            })}
          <tr></tr>
        </tbody>
      </table>
    </div>
  );
}
