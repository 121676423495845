import React from 'react'
import { Link } from 'react-router-dom'
import "./styles.scss";

const Footer = () => {
    return (
        <footer className='dash'>
            <span>Grupo Guía @2021</span><span>Todos los derechos reservados</span>
            <Link to="/aviso_de_privacidad" target="_blank" rel="noopener noreferrer">Aviso de privacidad</Link>
        </footer>
    )
}

export default Footer
