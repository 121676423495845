import React from 'react'
import PropTypes from 'prop-types'
import './Button.scss'

const Button = ({outLine, children, onClick, disabled, color, className}) => {
    const style = color?{"--color": color}:{}
    const outLine_ = outLine?' button__outline':''
    const className_ = className?` ${className}`:''
    return (
        <button style={style} disabled={disabled} onClick={onClick} className={`Button${outLine_}${className_}`}>
            <span>{children}</span>
        </button>
    )
}

Button.propTypes = {
    disabled: PropTypes.bool,
    outLine: PropTypes.bool,
    onClick: PropTypes.func
}

Button.defaultProps = {
    disabled: false,
    outLine: false,
    onClick: ()=>{}
}

export default Button
