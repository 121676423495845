import React from "react";
import PropTypes from "prop-types";

const DesarrolloSVG = ({ height, width, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 383.3 378.5"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M383.3,328.49v44.92c-2.39,3.88-5.89,5.1-10.38,5.09q-72-.13-144.1-.06c-7.21,0-11-2.73-10.91-7.81s3.83-7.55,10.84-7.55H368.08V338.71H15.21v24.37H154.64c6.93,0,10.7,2.69,10.75,7.64s-3.73,7.72-10.63,7.72H66.81c-19.22,0-38.43,0-57.65,0-6.38,0-9.14-2.82-9.15-9.1q0-18.52,0-37.05c0-6.32,2.73-9,9.19-9q20.4,0,40.8,0h4.2V304.37c-3.19,0-6.16.06-9.13,0-5.64-.15-8.71-2.94-8.81-8.46q-.24-13.27,0-26.57c.11-5.38,3.21-8.12,8.61-8.18,3.07,0,6.14,0,9.25,0V218.4c-3.16,0-6,0-8.89,0-5.89,0-8.9-2.75-9-8.56-.15-8.6-.13-17.22,0-25.83.08-5.82,3-8.65,8.85-8.78,3-.07,5.93,0,9,0V132.47c-3.42,0-6.52.08-9.62,0-5.1-.17-8.07-2.75-8.19-7.77Q36,111,36.29,97.36c.12-5.19,3.15-7.73,8.49-7.81,3.08,0,6.16,0,9.65,0v-79C54.44,2.35,56.74,0,64.81,0H318.58c7.88,0,10.28,2.39,10.29,10.27q0,37.25,0,74.5v4.77c3.44,0,6.4,0,9.36,0,5.91.06,8.75,2.72,8.83,8.65q.19,12.72,0,25.45c-.08,6.1-2.88,8.72-9.1,8.83-2.93.05-5.86,0-8.84,0v42.72c3.35,0,6.45-.09,9.55,0,5.08.19,8.18,2.82,8.3,7.75q.36,13.85,0,27.7c-.13,5-3.27,7.62-8.28,7.71-3.19.05-6.38,0-9.63,0v42.74c3.15,0,6.11,0,9.07,0,5.83.05,8.82,2.82,8.91,8.66q.19,12.92,0,25.83c-.09,5.76-3.12,8.59-8.94,8.73-2.95.07-5.9,0-9,0v18.91h4.29c13.35,0,26.7.13,40-.07C377.93,323.15,381.18,324.56,383.3,328.49ZM313.42,323v-18.6H257.73c-7.68,0-10.41-2.69-10.41-10.29q0-126.13,0-252.28c0-7.7,2.68-10.39,10.3-10.4q26,0,52,0h3.83V15.65H69.81V31.39h55.55c8,0,10.63,2.61,10.63,10.46v251.9c0,8-2.62,10.61-10.47,10.61H69.79v18.75C98,323.12,285.49,323,313.42,323ZM69.73,89.29h50.66V47H69.73ZM313.58,47H262.92V89.3h50.66ZM120.4,175V132.74H69.87V175Zm142.48-42.35V175h50.55v-42.3Zm-142.37,86H69.85v42.23h50.66Zm193,42.3V218.7H262.85V261ZM120.38,117.26V104.83H51.81v12.43Zm142.42-.11h68.69V104.83H262.8Zm-211,85.9h68.67V190.86H51.76Zm279.76.1V190.89H262.9v12.26ZM120.44,276.52H51.75v12.29h68.69Zm211.11,0H262.86v12.29h68.69Z" />
          <path d="M191.41,363.17a7.62,7.62,0,1,1,0,15.23,7.63,7.63,0,0,1-7.38-7.41A7.55,7.55,0,0,1,191.41,363.17Z" />
          <path d="M191.62,104.59c-9.36,0-18.71,0-28.06,0-5.94,0-8.86-2.68-8.89-8.59q-.15-27.88,0-55.75,0-8.79,8.78-8.81,28.25-.12,56.49,0c5.65,0,8.67,2.91,8.7,8.5q.15,28.07,0,56.13c0,5.88-3,8.51-8.95,8.52C210.32,104.61,201,104.59,191.62,104.59Zm21.65-57.53h-43.1V89.25h43.1Z" />
          <path d="M191.74,132.79c9.35,0,18.71,0,28.06,0,5.95,0,8.87,2.66,8.9,8.58q.15,27.87,0,55.75c0,6-3,8.81-9.14,8.83q-27.87.08-55.75,0c-6.09,0-9.07-2.87-9.09-8.87q-.14-27.69,0-55.38c0-6.3,2.89-8.9,9.33-8.91Zm-21.58,15.29v42.29h43V148.08Z" />
          <path d="M191.74,231c9.35,0,18.71,0,28.06,0,5.95,0,8.87,2.65,8.9,8.57q.15,27.87,0,55.75c0,6-3,8.82-9.14,8.83q-27.87.07-55.75,0c-6.09,0-9.07-2.86-9.09-8.87q-.14-27.69,0-55.38c0-6.3,2.89-8.9,9.33-8.91Zm-21.58,15.29v42.29h43V246.32Z" />
        </g>
      </g>
    </svg>
  );
};

DesarrolloSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};
DesarrolloSVG.defaultProps = {
  height: 22,
  width: 22,
};

export default DesarrolloSVG;
