import React, { useContext, useMemo } from "react";

//Data
import GlobalContext from "../../context/GlobalContext";

//Hooks
import { TabSimple, Avatar } from "../../core";
import { Link } from "react-router-dom";

//Components
import Cursos from "./Cursos";
import { DetallePerfil } from ".";

//Assets
import "./Perfil.scss";
import BandaPlatinoSVG from "../../assets/svgs/BandaPlatinoSVG";
import BandaPlataSVG from "../../assets/svgs/BandaPlataSVG";
import BandaInicioSVG from "../../assets/svgs/BandaInicioSVG";
import BandaOroSVG from "../../assets/svgs/BandaOroSVG";
import useScreenChange from "../../hooks/useScreenChange";

/**
 * @component
 * Component responsible for displaying the selected profile
 */

const Perfil = () => {
  const {
    state: { user },
  } = useContext(GlobalContext);

  return (
    <div className="Perfil">
      <div className="perfil-container">
        <div className="informacion-section">
          <DetallePerfil />
        </div>
        <div className="cursos-impartidos">
          <TabSimple className="tabla-informacion">
            <TabSimple.Tab color="black" name="CURSOS">
              <Cursos url={`cursos/user/${user._id}/all`} />
            </TabSimple.Tab>
            <TabSimple.Tab name="CURSO EN PROCESO">
              <Cursos url={`cursos/user/${user._id}/progress`} />
            </TabSimple.Tab>
            <TabSimple.Tab name="CURSOS ASIGNADO">
              <Cursos url={`cursos/user/${user._id}/assigned`} />
            </TabSimple.Tab>
          </TabSimple>
        </div>
      </div>
    </div>
  );
};
const Liston = ({ points = 0 }) => {
  const { width } = useScreenChange();
  const sizeListon = useMemo(() => {
    if (width > 1100) {
      return { width: 89, height: 131 };
    }
    return { width: 66.75, height: 98.25 };
  }, [width]);

  if (!points) return <BandaInicioSVG {...sizeListon} />;
  if (points > 0 && points <= 100) {
    //liston inicial
    return <BandaInicioSVG {...sizeListon} />;
  }
  if (points > 100 && points <= 500) {
    //liston plata
    return <BandaPlataSVG {...sizeListon} />;
  }
  if (points > 500 && points <= 1000) {
    //TODO liston oro
    return <BandaOroSVG {...sizeListon} />;
  }
  //a partir de 1001
  //liston platino
  return <BandaPlatinoSVG {...sizeListon} />;
};

export const Detalles = () => {
  const { width } = useScreenChange();
  const {
    state: { user, rank },
  } = useContext(GlobalContext);
  const fullName = useMemo(
    () =>
      [user.names, user.last_name, user.mothers_last_name]
        .filter((f) => f)
        .map((m) => m.trim())
        .join(" "),
    [user]
  );
  const sizeAvatar = useMemo(() => {
    if (width > 1100) {
      return 200;
    }
    return 150;
  }, [width]);

  const tipoEstudiante = user.tipo_estudiante;
  const isExterno = tipoEstudiante === "Externo";

  return (
    <>
      <div className="container-detail">
        <div className="foto-perfil">
          <Avatar
            name={fullName}
            src={user?.image?.location}
            size={sizeAvatar}
          />
          <p className="texto-foto">
            {user?.customer_data?.zone && (
              <div>
                {isExterno ? (
                  <i className="fa-solid fa-location-pin">
                    <p
                      style={{ lineHeight: 1.2 }}
                    >{`${user?.customer_data?.country}, ${user?.customer_data.state}, ${user?.customer_data?.municipality}`}</p>
                  </i>
                ) : (
                  <p
                    style={{ lineHeight: 1.2 }}
                  >{`${user?.customer_data?.zone}, ${user?.customer_data.direction}, ${user?.customer_data?.department}`}</p>
                )}
              </div>
            )}
          </p>
        </div>
        <div className="datos-perfil">
          <h1 className="nombre-perfil">{fullName}</h1>
          {/* <h3 className='texto-menor'>@Luisperez88</h3> */}
        </div>
        <div className="calificaciones">
          <div className="calificacion-perfil">
            <h1 className="puntos">{rank.points}</h1>
            <small className="text-puntos">Puntos</small>
          </div>
          <div>
            <Liston points={rank?.points} />
          </div>
          <div className="calificacion-perfil">
            <h1 className="puntos">
              {rank.place}/{rank.of}
            </h1>
            <small className="text-puntos">Ranking</small>
          </div>
        </div>
      </div>
      <div className="perfil-settings">
        <Link to={`/Perfil/Edit`}>
          <i className="fa-solid fa-gears"></i>
        </Link>
      </div>
    </>
  );
};

export default Perfil;
export { Liston };
