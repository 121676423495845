import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

//Hooks
import Vimeo from "@u-wave/react-vimeo";
import { useHistory } from "react-router-dom";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  LinkButton,
  HtmlFotmat,
  Subtitle,
  TabSimple,
  Title,
  Markdown,
} from "../../core";
import useVimeo from "../../hooks/useVimeo";

//Data
import GlobalContext from "../../context/GlobalContext";
import FormContext from "../../context/FormContext";

//Assets
import sample from "../../assets/sample.jpg";
import { Helmet } from "react-helmet";

// Logo
import logo from "../../assets/images/mentor_white_B.png";

// MUI
import { Button } from "../../core";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepConnector from "@mui/material/StepConnector";
import CircularProgress, { circularProgressClasses, } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import StepButton from '@mui/material/StepButton';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import List from '@mui/material/List';

// Alerts
import Swal from "sweetalert2";

import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import { LegendToggle, QuizSharp } from "@mui/icons-material";

const Capsula = (props) => {
  const {
    NestGet,
    NestPost,
    NestPatch,
    isLogin,
    state: { accessToken, user },
    setState,
  } = useContext(GlobalContext);
  const history = useHistory();
  const { sku, module, capsule } = useParams();
  const [showCapsule, setShowCapsule] = useState(false);
  const [lastCompletedCap, setLastCompletedCap] = useState(false);
  const [capsule_, setCapsule] = useState({});
  const { availableToken, setAccessToken, getImage } = useVimeo();
  const time = useRef(0);
  const [capId, setCapId] = useState(capsule_);

  const SelectStatusError = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2800,
  });

  const getLastCapCompleted = useCallback(() => {
    NestGet({
      schema: "cursos",
      query: {
        query: JSON.stringify({ sku: sku }),
        user_id: JSON.stringify({ user_id: user._id }),
      },
    })
      .then((res) => {
        if (res.status === "success") {
          const curs = res?.data?.[0];
          if (curs && curs.lastCap) {
            setLastCompletedCap(typeof curs.lastCap.split("/").at(4) === 'undefined' ? "" : curs.lastCap.split("/").at(4));
          }
        }
      });
  }, [NestGet, history, user._id, sku]);

  const fetchData = useCallback(() => {
    NestPost({
      schema: `cursos/capsula/${sku}`,
      body: {
        modul_id: module,
        cap_id: capsule,
      },
    }).then((res) => {
      setShowCapsule(true);
      if (res.status === "success") {
        //debugger;
        console.log("fetching", res.data);
        setCapsule(res.data);
        
      } else {
        history.replace(`/cursos/${sku}`);
      }
    });
  }, [NestPost, sku, module, capsule, history]);

  const completeCap = useCallback((cap_id) => {
    NestPost({
      schema: `cursos/capsula/completed/${sku}/${cap_id}`,
      body: {
        modul_id: module,
      },
    }).then((res) => {
      setShowCapsule(true);
      if (res.status === "success") {
        //debugger;
        console.log("update capsule update (completed)", res.data);
        SelectStatusError.fire({
          title: "Completaste una cápsula más",
          icon: "success",
        });
      } else {
        SelectStatusError.fire({
          title: "Ups algo salio mal , inténtalo más tarde",
          icon: "error",
        });
      }
    });
  },[NestPost, sku, module]);

  useEffect(() => {
    setShowCapsule(false);
    fetchData();
    getLastCapCompleted();
    return () => {
      trackVideoProgress(capId);
    };
  }, [sku, module, capsule]);

  const this_cap = useMemo(() => {
    return (
      capsule_.modules
        ?.find((f) => f._id === module)
        ?.capsules?.find((f) => f._id === capsule) || {}
    );
  }, [capsule, module, capsule_]);

  const next_cap = useMemo(() => {
    if (Object.keys(capsule_).length === 0) {
      return "";
    }
    const currModule = capsule_.modules?.find((f) => f._id === module);
    const { quiz = [], capsules = [] } = currModule;
    const index = capsules.map((m) => m._id)?.indexOf(capsule);
    const nexCap =
      index !== -1 && capsules.length > index + 1
        ? capsules[index + 1]._id
        : "quiz";
    if (nexCap === "quiz" && quiz.length == 0) {
      //course has no quiz so we need to set next module
      const modul_index = capsule_.modules?.map((m) => m._id).indexOf(module);
      const module_ = capsule_.modules?.[modul_index + 1];
      const noQuiz = module_?.capsules?.[0]?._id;
      if (modul_index + 1 >= capsule_.modules?.length) {
        return `/exam/${sku}`;
      }
      return `/clase/${sku}/${module_._id}/${noQuiz}`;
    } else {
      //course quiz or next capsule
      return `/clase/${sku}/${module}/${nexCap}`;
    }
  }, [capsule, capsule_, module, sku]);


  function isExamenAvailable(){
    
    const isAvailable = temario?.map(mod => mod?.capsules?.find(cap => cap.completed === false)).find(item => item)?._id;
    if (isAvailable !== undefined) {
      SelectStatusError.fire({
        title: "Aún no puedes tomar el examen, asegúrate de terminar todas las cápsulas y módulos",
        icon: "info",
      });
      history.push("#"); 
    } else {
      history.push(`/exam/${sku}`);
    }     
  }

  const next_module = useMemo(() => {
    if (Object.keys(capsule_).length === 0) {
      return "";
    }
    const currModule = capsule_.modules?.find((f) => f._id === module);
    const { quiz = [], capsules = [] } = currModule;
    const index = capsules.map((m) => m._id)?.indexOf(capsule);
    const nexCap =
      index !== -1 && capsules.length > index + 1
        ? capsules[index + 1]._id
        : "quiz";
    if (nexCap === "quiz" && quiz.length == 0) {
      //course has no quiz so we need to set next module
      const modul_index = capsule_.modules?.map((m) => m._id).indexOf(module);
      const module_ = capsule_.modules?.[modul_index + 1];      
      if (modul_index + 1 >= capsule_.modules?.length) {
        return false;
      }
      return module_._id;
    } else {
      //course quiz or next capsule
      return module;
    }
  }, [capsule, capsule_, module, sku]);

  function onFinishedCapsule(cap_id) {
    completeCap(cap_id)
    history.push(next_cap);
    setExpandedTrigger(next_module)
  }

  function isCapCompleted(cap_id, module_id) {
    const isCompleted =  capsule_.tracker?.modules?.find(mod => mod.module_id === module_id)?.capsules.find(c => c.capsule_id === cap_id)?.completed 
    return isCompleted ? isCompleted : false;
  }

  function isQuizCompleted(module_id){
    const isCompleted =  capsule_.tracker?.modules?.find(mod => mod.module_id === module_id)?.completedQuiz
    return isCompleted ? isCompleted : false;
  }

  function isModuleCompleted(module_id){
    const notCompleted =  capsule_.tracker?.modules?.find(mod => mod.module_id === module_id)?.capsules?.find(c => c.completed === false)
    if (notCompleted === undefined){
      return true
    }
    return false;
  }

  function openQuiz(module_id){
    if (isModuleCompleted(module_id)){
      history.push(`/clase/${sku}/${module_id}/quiz`);
    }
    else {
      SelectStatusError.fire({
        title: "No has completado todas las cápsulas del módulo",
        icon: "info",
      });
    }
    
  }

  const [expandedTrigger, setExpandedTrigger] = useState(module);

  const temario = useMemo(() => {
    let visited = true;
    const result =  (capsule_?.modules || []).map((m) => {
      let mod_id = m._id;
      if (m._id === module) {
        visited = false;
        let cap_visited = true;        
        return {
          ...m,
          visited: true,
          active: true,
          capsules: m.capsules.map((cap) => {
            if (cap._id === capsule) {
              cap_visited = false;
              return { ...cap, visited: true, active: true, completed:isCapCompleted(cap._id,mod_id) };
            } else {
              return { ...cap, visited: cap_visited , completed:isCapCompleted(cap._id,mod_id) };
            }
          }),
        };
      } else {
        return {
          ...m,
          visited,
          capsules: m.capsules.map((cap) => ({ ...cap, visited: visited , completed:isCapCompleted(cap._id,mod_id) })),
        };
      }
    }
    );
    return result; 
  }, [capsule_, module]);

  const trackVideoProgress = async (capId) => {
    if (!capsule_?._doc?._id || time.current <= 0 || !capId) return;
    NestPost({
      schema: `cursos/${capsule_._doc._id}/trackCapsule`,
      body: {
        modul_id: module,
        cap_id: capId,
        timelapse: time.current,
      },
    }).then(() => {
      time.current = 0;
    });
  };

 
  const handleModuleAccordionChange = (module) => (event, isExpanded) => {

    if (!expandedTrigger && !isExpanded){
      // corner case it is the same panel pre-expanded
      setExpandedTrigger(null);
    } else {
      setExpandedTrigger(isExpanded ? module : false);
    }


};

  const stepsList = {};
  const modulesProgress = {};


  function fillPreRequiredData() {

    temario.forEach((module_, module_index) => {
      var completedCapsules = 0;
      module_.capsules.forEach((capsule, step_index) => {
        stepsList[module_._id + "_" + capsule._id] = step_index;
        if (capsule.completed) {
          completedCapsules += 1;
        }
      });
      if (module_.quiz.length > 0){
        stepsList[module_.quiz[0]._id] = Object.keys(stepsList).length;
      }
      var progress = Math.round((completedCapsules / module_.capsules.length) * 100);
      modulesProgress[module_._id] = progress
    });
  }

  function getActiveStep(module) {

    var currentCap = module.capsules.find(item => item._id === this_cap._id)
    if (currentCap) {
      return stepsList[module._id + "_" + this_cap._id];
    } else {
      return -1;
    }

  }

  const handleNextCapsule = (capsule_id, module_id, visited) => {

    const isNewCapsuleAlreadyCompleted = temario?.find(mod => mod._id === module_id)?.capsules.find(cap => cap._id ===capsule_id )?.completed

    if (isNewCapsuleAlreadyCompleted || !capsule_.unskippable){
      history.replace(`/clase/${sku}/${module_id}/${capsule_id}`);
    }
     else {
      SelectStatusError.fire({
        title: "No has completado todas las cápsulas previas",
        icon: "info",
      });
      history.replace("#");
    }
  }

  function isQuizCurrentActive(){
    const currentModule = temario?.find((f) => f._id === module)
    
    if(!currentModule.quiz.length > 0){
        return false;
    }
    const quizId = currentModule.quiz[0]._id
    return getActiveStep(currentModule) === stepsList[quizId] ? true : false

  }

  function showQuiz(){
    const currentModule = temario?.find((f) => f._id === module)
    
    if(!currentModule.quiz.length > 0){
        return false;
    }
    const quizId = currentModule.quiz[0]._id
    return getActiveStep(currentModule) === stepsList[quizId] ? true : false

  }


  const CustomConnector = withStyles({
    line: {
      marginLeft: "-5px",
    },

  })(StepConnector);

  return (
    <FormContext.Provider value={{ availableToken, getImage }}>
      <Helmet>
        <title>Capsula</title>
      </Helmet>
      {fillPreRequiredData()}
      <div className="ClaseFormContainer" style={{overflow: "auto"}}>
        <div className="not-container-item column clase-contenido">
          <div className="clase-titulo">
            <div className="column" style={{ width: "80%" }}>
              <span className="title-clase-form">{capsule_.name} - </span>
              <span className="h1-clase-form">{this_cap.name}</span>  
            </div>
            <div className="column" style={{ width: "20%", textAlign: "right", paddingRight: "15px" }}>
              <Button className="back-button" onClick={() => history.replace(`/cursos/${sku}`)} >Volver a Cursos</Button>
            </div>
          </div>
          <div className="clase-video">           
            {showCapsule && this_cap?.has_video ? (
 
                <Vimeo
                  className="capsula-video"
                  video={this_cap.video}
                  autoplay={false}
                  responsive 
                  paused={false}
                  showTitle={false}
                  portrait={false}
                  muted={false}
                  start={Number(
                    Math.max(this_cap.start || 0, this_cap.moment || 0)
                  )}
                  onEnd={() => onFinishedCapsule(this_cap._id)}
                  onTimeUpdate={(ev) => {
                    time.current = Math.floor(ev.seconds);
                    if (Math.floor(ev.seconds) === Number(this_cap.end)) {
                      onFinishedCapsule(this_cap._id);
                    }
                  }}
                  onPause={() => {
                    trackVideoProgress(capId);
                  }}
                />
            ) : null
            }
          </div>
        </div>
        <div className="column clase-details">
          <div className="clase-details-content class-controls" style={{ textAlign: "justify" }}>
            <img
              height="30"
              width="120"
              src={logo}
              alt=""
            />
          </div>
          <div className="clase-details-content" style={{ paddingTop: "40px" }}>
            <span className="h1-clase-form">Módulos</span>
          </div>
          <div className="scroll clase-accordion-content">
            {temario.map((module_, module_index) => (             
              <div>
                <Accordion key={module_._id} style={{ paddingTop: "5px", backgroundColor: "#233A46" }} expanded={expandedTrigger === module_._id} onChange={handleModuleAccordionChange(module_._id)}>
                  <AccordionSummary
                    sx={{
                      backgroundColor: "#357081"
                    }}
                    expandIcon={<ExpandMoreIcon style={{ color: '#30E0E0' }} />}
                    aria-controls="panel1bh-content"
                    id={module_._id}
                  >
                    <div style={{ height: "20px" }}>
                      <div style={{ float: "left", marginTop: "-4px" }}>
                        <Box sx={{ position: 'relative' }}>
                          <Tooltip title={"Completado " + modulesProgress[module_._id] + "%"}>
                            <CircularProgress
                              variant="determinate"
                              sx={{
                                color: "#FFFFFF",
                              }}
                              size={18}
                              thickness={6}
                              value={100}
                            />
                            <CircularProgress
                              variant="determinate"
                              sx={{
                                color: "#30E0E0",
                                // color: "#34495e",
                                position: 'absolute',
                                left: 0,
                                [`& .${circularProgressClasses.circle}`]: {
                                  strokeLinecap: 'round',
                                },
                              }}
                              size={18}
                              thickness={6}
                              {...props}
                              value={modulesProgress[module_._id]}
                            />
                          </Tooltip>
                        </Box>
                      </div>
                      <div style={{ float: "left", paddingLeft: "10px" }}>
                        <div style={{ float: "left", marginTop: "-8px" }}><span className="h2-clase-form"> Módulo {module_index + 1}</span></div>
                        <div style={{ float: "left", clear: "left", marginTop: "-8px" }}><span className="h3-clase-form">{module_.capsules.length} clases</span></div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="scroll" sx={{ backgroundColor: "#357081", maxHeight: "50vh" }}>
                    <div>
                      <Stepper
                        nonLinear
                        activeStep={getActiveStep(module_)}
                        connector={<CustomConnector />}
                        orientation="vertical"
                        sx={{
                          backgroundColor: "#357081"
                        }}
                      >
                        {module_.capsules.map((capsule, step_index) => (                          
                          <Step key={capsule._id}
                            active={capsule._id === this_cap._id ? true : false}
                            completed={capsule.completed}
                            sx={{
                              '& .MuiStepLabel-root .Mui-completed': {
                                color: '#30E0E0', // circle color (COMPLETED)                              
                              },
                              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                              {
                                color: 'grey.500', // Just text label (COMPLETED)
                              },
                              '& .MuiStepLabel-root .Mui-active': {
                                color: '#30E0E0', // circle color (ACTIVE)        

                              },
                              '& .MuiSvgIcon-root': {
                                // color: '#30E0E0', // circle color (ACTIVE) 
                                fontSize: "15px",
                                marginTop: "-12px"
                              },
                              '& .MuiStepConnector-lineVertical':
                              {
                                color: 'common.white', // Just text label (ACTIVE)
                              },
                              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                fill: '#30E0E0',   //circle's text color (ACTIVE)                          
                              },
                              "& .MuiStepIcon-text": {
                                display: 'none',
                              },
                            }
                            }
                          >
                            <StepButton  className="StepButton" autoFocus={capsule._id === this_cap._id ? true : false} onClick={() => handleNextCapsule(capsule._id, module_._id, capsule.visited)}>
                              <div>
                                <span className="h4-clase-form"> {capsule.name}</span>
                              </div>
                              <div>
                                <span className="h3-clase-form">{capsule.duration}</span>
                              </div>
                            </StepButton>
                          </Step>
                        ))}

                        {
                          module_.quiz.length > 0 
                          ? 
                          <Step key={module_.quiz[0]._id}                         
                          completed={isQuizCompleted(module_._id)}
                          sx={{
                            '& .MuiStepLabel-root .Mui-completed': {
                              color: '#30E0E0', // circle color (COMPLETED)                              
                            },
                            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                            {
                              color: 'grey.500', // Just text label (COMPLETED)
                            },
                            '& .MuiStepLabel-root .Mui-active': {
                              color: '#30E0E0', // circle color (ACTIVE)        

                            },
                            '& .MuiSvgIcon-root': {
                              // color: '#30E0E0', // circle color (ACTIVE) 
                              fontSize: "15px",
                              marginTop: "-12px"
                            },
                            '& .MuiStepConnector-lineVertical':
                            {
                              color: 'common.white', // Just text label (ACTIVE)
                            },
                            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                              fill: '#30E0E0',   //circle's text color (ACTIVE)                          
                            },
                            "& .MuiStepIcon-text": {
                              display: 'none',
                            },
                          }
                          }
                        >
                          <StepButton  className="StepButton" onClick={() => openQuiz(module_._id)}>
                            <div style={{marginTop:"-15px"}}>
                              <span className="h4-clase-form" >Quiz</span>
                            </div>
                          </StepButton>
                        </Step>
                          : null
                        }
                      </Stepper>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}          
          </div>
          <div className="scroll clase-accordion-content">
            {
              capsule_.has_exam
              ?
              <List
                sx={{
                  width: '100%',                  
                  bgcolor: 'background.paper',
                  height: 50,
                  backgroundColor: "#357081"
                }}
                >
                <ListItem style={{marginTop:"-10px", marginLeft:"-20px"}}>        
                  <ListItemButton  onClick={() => isExamenAvailable()}> 
                  <FactCheckIcon sx={{ color: "#FFFFFF" }} />
                    <div>
                      <span 
                        style={{paddingLeft:"10px"}} className="h2-clase-form">
                           Evaluación final
                      </span>
                    </div>         
                  </ListItemButton>
                </ListItem>
              </List>
              : 
              null
            }
          </div>
        </div>
      </div>
    </FormContext.Provider>
  )
};

export default Capsula;
