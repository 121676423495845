import React from 'react'
import PropTypes from 'prop-types'
import {useController} from 'react-hook-form';
import '../Forms.scss'
import { Rating } from 'react-simple-star-rating';

const RatingInputC = ({
    label,
    name,
    control,
    rules,
    style,
    defaultValue,
    onInput,
    setFormate,
    Wrapper,
    optional,
    className,
    ...props
  }) => {
    const {
      field: {ref, onChange, onBlur, value},
      fieldState: {invalid, isTouched, isDirty, error},
    } = useController({
      control,
      defaultValue: setFormate(defaultValue),
      rules,
      name,
    });
    const inputProps = {
      ...props,
      ref,
      onClick: v=>{
        onChange(setFormate(5*(v/100)))
        onInput(setFormate(5*(v/100)))
      },
      onBlur,
      ratingValue: value*20,
    };
    return (
      <div className={`FormC RatingInputC${isTouched?' formc__touched':''}${isDirty?' formc__dirty':''}`} style={style}>
        {label ? <label htmlFor={`_${name}_`}>{rules.required?<strong>* </strong>:null}{label}: {optional?<span className='optional'>({typeof optional === 'string'?optional:'opcional'})</span>:null}</label> : null}
        <Wrapper>
            <Rating size={28} fillColor="#34495e" emptyColor="#9b9b9b" {...inputProps} />
        </Wrapper>
        <span className='label__error'>{invalid ? error.message : ''}</span>
      </div>
    )
}

RatingInputC.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  setFormate: PropTypes.func,
  defaultValue: PropTypes.any,
  Wrapper: PropTypes.func,
};

RatingInputC.defaultProps = {
  name: 'input',
  rules: {},
  defaultValue: '',
  onInput: v => {},
  setFormate: v => v,
  Wrapper: p=><>{p.children}</>,
};
  
export default RatingInputC
