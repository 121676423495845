import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-date-picker';
import {useController} from 'react-hook-form';
import '../Forms.scss'
import moment from 'moment';



const DateInputMentor = ({
    label,
    name,
    control,
    rules,
    style,
    defaultValue,
    optional,
    onInput,
    format,
    maxDate,
    minDate,
    ...props
  }) => {
    const {
      field: {ref, onChange, onBlur, value},
      fieldState: {invalid, isTouched, isDirty, error},
    } = useController({
      control,
      defaultValue,
      rules,
      name,
    });
    const getDate = (v)=>{
      return (v==='now')?moment():moment(v,format)
    }
    const inputProps = {
      ...props,
      ref,
      onChange: v=>{
        onChange(v?moment(v).format(format):null)
        onInput(v?moment(v).format(format):null)
      },
      onBlur,
      value: value?moment(value,format).toDate():null,
      maxDate:maxDate?getDate(maxDate).toDate():null,
      minDate:minDate?getDate(minDate).toDate():null,
    };
    
    return (
        <div className={`FormC DateInputMentor${isTouched?' formc__touched':''}${isDirty?' formc__dirty':''}`} style={style}>         
            <DatePicker id={`_${name}_`} className='formc-input-mentor' {...inputProps} />            
            <span className='label__error'>{invalid ? error.message : ''}</span>
            {label ? <label className="mentor-inuput" htmlFor={`_${name}_`}>{label}{rules.required?<span> *</span>:null}{optional?<span className='optional'>({typeof optional === 'string'?optional:'opcional'})</span>:null}</label> : null}   
        </div>
    )
}


DateInputMentor.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  defaultValue: PropTypes.any,
  format: PropTypes.string,
};

DateInputMentor.defaultProps = {
  name: 'input',
  rules: {},
  onInput: v => {},
  format: 'YYYY-MM-DD',
};

export default DateInputMentor
