import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FileInputC,
  InputC,
  LabelWrapper,
  SelectC,
  TextAreaC,
} from "../../core";
import { rulesRequired } from "../../Constants";
import { useController, useForm } from "react-hook-form";
import useVimeo from "../../hooks/useVimeo";
import GlobalContext from "../../context/GlobalContext";

const FilmotecaInput = ({
  control: controlF,
  name,
  label,
  filmoteca,
  rules,
  onChangeFil,
  onLoadNewFil,
  single,
}) => {
  const {
    field: { onChange, value },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    control: controlF,
    defaultValue: "",
    rules,
    name,
  });
  const {
    state: { accessToken },
  } = useContext(GlobalContext);
  const { control, handleSubmit, reset } = useForm();
  const { uploadVideo, setAccessToken } = useVimeo();
  const [porcentual, setPorcentual] = useState(-1);
  const changeFilOption = (v) => {
    const fil = filmoteca.find((f) => f.value === v)?.filmData;
    onChangeFil({ ...fil, id: fil.uri.replace("/videos/", "") });
  };
  const onSubmit = async ({ file, name, description }) => {
    uploadVideo({
      file,
      name,
      description,
      onStart: () => setPorcentual(0),
      onProgress: function (bytesUploaded, bytesTotal) {
        let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setPorcentual(percentage);
      },
      onSuccess: function (upload) {
        setPorcentual(-1);
        onChange(upload.uri.replace("/videos/", ""));
        onChangeFil({ ...upload, id: upload.uri.replace("/videos/", "") });
        onLoadNewFil();
      },
    });
  };
  useEffect(() => {
    if (value) {
      reset({ file: null, name: "", description: "" });
    }
  }, [reset, value]);

  useEffect(() => {
    if (accessToken) {
      setAccessToken(accessToken);
    }
  }, [accessToken, setAccessToken]);
  return (
    <LabelWrapper label={label}>
      <div className="FilmInput">
        <div className="flex-1 w-100">
          <SelectC
            control={controlF}
            className="w-100"
            name={name}
            label="Film"
            rules={rules}
            options={filmoteca}
            readOnly={porcentual > -1}
            onInput={changeFilOption}
          />
        </div>
       {!single?<>
        <div className="separator">
          <strong style={{ fontSize: 20 }}>ó</strong>
        </div>
        <div className="flex-1 w-100">
          {value ? (
            <div className="d-flex justify-content-center">
              <Button onClick={(_) => onChange(null)}>Subir uno nuevo</Button>
            </div>
          ) : (
            <>
              <div className="d-flex flex-wrap">
                <FileInputC
                  control={control}
                  label="Video"
                  name="file"
                  accept="video/*"
                  rules={rulesRequired}
                  readOnly={porcentual > -1 || value}
                />
                <div className="flex-grow-1">
                  <InputC
                    className="w-100"
                    control={control}
                    label="Título"
                    name="name"
                    rules={rulesRequired}
                    readOnly={porcentual > -1 || value}
                  />
                </div>
              </div>
              <TextAreaC
                control={control}
                label="Descripción"
                name="description"
                readOnly={porcentual > -1 || value}
              />
              {!value ? (
                porcentual > -1 ? (
                  <>
                    <div className="progress w-100">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuenow={porcentual}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${porcentual}%` }}
                      ></div>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-end">
                    <Button onClick={handleSubmit(onSubmit)}>Cargar</Button>
                  </div>
                )
              ) : null}
            </>
          )}
        </div>
        </>:null
       }
      </div>
    </LabelWrapper>
  );
};

FilmotecaInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  filmoteca: PropTypes.array,
  onChangeFil: PropTypes.func,
  onLoadNewFil: PropTypes.func,
  single: PropTypes.bool,
};
FilmotecaInput.defaultProps = {
  name: "film",
  label: "Filmoteca",
  onChangeFil: () => {},
  onLoadNewFil: () => {},
  single: false
};

export default FilmotecaInput;
