import React, { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm, useWatch } from "react-hook-form";
import { useHistory, useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";

//Data
import GlobalContext from "../../context/GlobalContext";

//Hooks
import {
  Grid,
  LinkButton,
  LinkPillButton,
  PillButton,
  ToggleSwitch,
} from "../../core";
import useS3File from "../../hooks/useS3File";
import useS3NestFile from "../../hooks/useS3NestFile";

/**
 * @component
 * Component responsible for displaying the list of courses
 */

const CursosGrid = () => {
  const match = useRouteMatch();
  const { handleDelete: handleDeleteS3 } = useS3File();
  const { handleDelete: handleDelete_ } = useS3NestFile();

  const { setAppLoading, NestGet, NestPost, NestDelete, NestPatch, NestPut } =
    useContext(GlobalContext);
  const { control, handleSubmit, reset, setValue } = useForm();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState();
  const active = useWatch({ control, name: "active", defaultValue: true });
  const history = useHistory();
  const columns = [
    {
      title: "Nombre",
      key: "name",
    },
    {
      title: "Módulos",
      key: "n_modules",
    },
    {
      title: "Duración",
      key: "n_duration",
    },
    {
      title: "Acceso",
      key: "access",
    },

    {
      title: "Estatus",
      key: "active",
    },

    {
      title: "Acciones",
      width: 100,
      key: "action",
    },
  ];
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Eliminar",
      text: "¿Quieres eliminar este elemento?",
      icon: "question",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setAppLoading(true);
        NestDelete({
          schema: "cursos",
          id,
        })
          .then((id) => {
            const found = data.find((f) => f.id === id);
            if (found) {
              found.images.forEach((image) => {
                if (image?.location?.includes("mentorstore")) {
                  handleDeleteS3(image.key);
                } else {
                  handleDelete_(image.key);
                }
              });
            }
            fetchData();
          })
          .finally((_) => {
            setAppLoading(false);
          });
      }
    });
  };

  const changeStatus = async (row, active) => {
    NestPatch({
      schema: "cursos/status",
      body: { active: active },
      id: row.id,
    }).then((res) => {
      if (res.status === "success") {
        const newData = JSON.parse(JSON.stringify(data));
        const index = newData.map((m) => m.id).indexOf(row.id);
        if (index > -1) {
          newData[index] = res.data;
          setData(newData);
        }
      }
    });
  };

  const closeModal = useCallback(() => {
    reset({ file: null, name: "", description: "" });
    setVisible(false);
  }, [reset]);

  const handleEdit = async (id) => {
    const found = data.find((f) => f.id === id);
    if (found) {
      Object.keys(found).forEach((key) => {
        setValue(key, found[key]);
      });
      setVisible(true);
    }
  };

  const fetchData = useCallback(
    async (page = 1) => {
      setAppLoading(true);
      NestGet({
        schema: "cursos",
        query: {
          limit: 20,
          page,
        },
      })
        .then((res) => {
          setData(res.data);
          setPagination(res.pagination);
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading]
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="work_area">
        <Helmet>
          <title>Cursos | Mentor</title>
        </Helmet>
        <div className="d-flex justify-content-end">
          <LinkButton
            color="#66bb6a"
            className="d-flex align-items-center"
            to={`${match.path}/new`}
          >
            Agregar <i className="fa fa-plus ml-1"></i>
          </LinkButton>
        </div>
        <Grid
          rowKey="id"
          columns={columns}
          dataSource={data}
          className="table-striped"
          pagination={pagination}
          onChangePage={fetchData}
        >
          <Grid.Column
            name="n_modules"
            context={({ column, row }) => (
              <>
                <strong>{column}</strong>
              </>
            )}
          />
          <Grid.Column
            name="active"
            context={({ column, row }) => (
              <>
                <ToggleSwitch
                  checked={row.active}
                  name={`${row.id}`}
                  onChange={(v) => changeStatus(row, v)}
                />
              </>
            )}
          />
          <Grid.Column
            name="action"
            context={({ column, row }) => (
              <div className="d-flex">
                <LinkPillButton
                  color={"#00b8d4"}
                  to={`${match.path}/${row.id}`}
                >
                  <i className="fas fa-edit"></i>
                </LinkPillButton>
                <PillButton
                  color={"#c62828"}
                  onClick={(_) => handleDelete(row.id)}
                >
                  <i className="fas fa-trash"></i>
                </PillButton>
              </div>
            )}
          />
        </Grid>
      </div>
    </>
  );
};

export default CursosGrid;
