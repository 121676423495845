import React from "react";
import PropTypes from "prop-types";

const HomeSVG = ({ height, width,onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.92 21.96"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M4.42,22a5.08,5.08,0,0,1-.85-.31,2.31,2.31,0,0,1-1.21-2.12V13c-.25,0-.49,0-.71-.07A1.91,1.91,0,0,1,.12,11.64a2,2,0,0,1,.39-2,2.09,2.09,0,0,1,.2-.22L9.43.72a2,2,0,0,1,3.1,0c2.93,2.92,5.84,5.86,8.78,8.77a2.12,2.12,0,0,1,0,3A2,2,0,0,1,20,13h-.37v6.38a2.37,2.37,0,0,1-2,2.56l0,0H13.6a.75.75,0,0,1-.51-.81c0-1.64,0-3.27,0-4.91a1.08,1.08,0,0,0-1.18-1.17H10.09a1.09,1.09,0,0,0-1.22,1.21c0,1.62,0,3.24,0,4.86a.76.76,0,0,1-.51.82Zm10-1.28h2.71a1.1,1.1,0,0,0,1.23-1.23v-7a.67.67,0,0,1,.74-.74H20a.74.74,0,0,0,.69-.55.84.84,0,0,0-.33-.85L11.63,1.64a.78.78,0,0,0-1.3,0L2.58,9.39,1.52,10.45a.74.74,0,0,0,.53,1.27h.79c.57,0,.81.23.81.81,0,2.33,0,4.67,0,7a1.07,1.07,0,0,0,1.14,1.14H7.38l.21,0V20.4c0-1.45,0-2.91,0-4.36a2.27,2.27,0,0,1,2.07-2.22c.87-.06,1.75,0,2.63,0l.21,0a2.35,2.35,0,0,1,1.87,2.34v4.5Z" />
        </g>
      </g>
    </svg>
  );
};

HomeSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func
};

HomeSVG.defaultProps = {
  height: 22,
  width: 22,
};

export default HomeSVG;
