import React, { useContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useForm, useWatch } from "react-hook-form";
import { UserForm } from "../../components/Forms";
import GlobalContext from "../../context/GlobalContext";
import useS3File from "../../hooks/useS3File";
import Swal from "sweetalert2";

/**
 * @component
 * Component responsible for displaying the info of a user(not admin)
 */

const NewUser = () => {

  const { control, handleSubmit } = useForm();
  const { NestPost, setAppLoading } = useContext(GlobalContext);
  const [visible, setVisible] = useState(false);
  const { handleUpload, handleDelete } = useS3File();
  const history = useHistory();

  const onSubmit = useCallback(
    
    async (data) => {
      setAppLoading(true);

      if (data.new_password != data.new_password_confirmation) {
        Swal.fire({
          title: "Las contraseñas no coinciden.",
          icon: "error",
        });
        setAppLoading(false);
        return;
      }


      data.status = true;
      data.type = "student";
      data.password = data.new_password
      data.tipo_estudiante = 'Externo'
      NestPost({
        schema: "admin/student",
        body: data
      })
        .then((res) => {
          if (res.status === "success") {
            Swal.fire({
              title: 'Usuario creado correctamente, Deseas crear otro Usuario ?',
              showDenyButton: true,        
              confirmButtonText: 'Yes',
              denyButtonText: 'No',
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload(false);
              } else if (result.isDenied) {
                history.push('/Backoffice/Usuarios/')
              }
            });
          } else if (res.status === "error" && res.message === "User already exists") {
            Swal.fire({
              title: "Un usuario con ese correo ya existe en el sistema",
              icon: "error",
            });
          }
           else {
            Swal.fire({
              title: "Ocurrio un problema con la carga del usuario, Intente nuevamente",
              icon: "error",
            });
          }
          setVisible(false);
          setAppLoading(false);
        })
        .catch((err) => {
          setAppLoading(false);
        });
    },
    [setAppLoading, NestPost]  
  );

  return (
    <div className="User">
      <Helmet>
        <title>Usuario | Mentor</title>
      </Helmet>
      <div className="not-container">
        <UserForm
          isBackOfficeEditing={false}
          control={control}
          initialLoad={true}
          onSubmit={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};

export default NewUser;
