import React from "react";
import PropTypes from "prop-types";

const AyudaSVG = ({ height, width, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.97 21.96"
      fill="#fff"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M22,10.29v1.38a.47.47,0,0,0,0,.16,10.68,10.68,0,0,1-2.66,6.35,10.74,10.74,0,0,1-6.39,3.61c-.4.08-.81.12-1.21.17H10.3l-.16,0A11,11,0,1,1,21.8,9.09C21.87,9.49,21.91,9.89,22,10.29ZM1.72,11A9.27,9.27,0,1,0,11,1.71,9.27,9.27,0,0,0,1.72,11Z" />
          <path d="M11.84,12.27v1.3a.86.86,0,1,1-1.71,0V11.67a.88.88,0,0,1,.95-1,1.69,1.69,0,0,0,1.53-1.17,1.63,1.63,0,0,0-.46-1.79,1.6,1.6,0,0,0-1.81-.33A1.65,1.65,0,0,0,9.27,8.88a.87.87,0,0,1-.82.92.86.86,0,0,1-.89-.87,3.43,3.43,0,1,1,4.53,3.26Z" />
          <path d="M11,17.3a1.07,1.07,0,1,1,1.08-1.06A1.07,1.07,0,0,1,11,17.3Z" />
        </g>
      </g>
    </svg>
  );
};

AyudaSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

AyudaSVG.defaultProps = {
  height: 22,
  width: 22,
};

export default AyudaSVG;
