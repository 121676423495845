import React from 'react'
import PropTypes from 'prop-types'
import './Gauge.scss'
import useFormatter from '../../hooks/useFormatter'

const RadioGauge = ({percentage, color}) => {
    const {NumberF} = useFormatter()
    return (
        <div className="RadioGauge" style={{'--color': color,'--percetage':`${percentage||0}%`}}>
            <div className="gauge-inner">
                <strong>{NumberF(percentage||0,false,'.00')}%</strong>
            </div>
        </div>
    )
}

RadioGauge.propTypes = {
    percentage: PropTypes.number,
    color:  PropTypes.string,
}
RadioGauge.defaultProps = {
    percentage: 1,
    color: '#5CE55C'
}

export default RadioGauge
