import React from "react";
import PropTypes from 'prop-types'

function BandaPlataSVG({
  height,
  width,
  fill,
  onClick,
}) {
  return (
    <svg
      id="Capa_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 208.12 319.24"
      onClick={onClick}
      height={height}
      width={width}
      fill={fill}
    >
      <g id="Insignias">
        <g>
          <path
            
            d="M208.12,103.58c.18,57.02-46.66,103.87-103.91,103.95C46.96,207.61,.2,161.1,0,103.87-.2,46.83,46.51,.13,103.91,0c57.31-.13,104.02,46.3,104.2,103.58Zm-155.98-.14c-.13,28.75,22.83,51.97,51.64,52.21,28.64,.24,52.05-22.92,52.21-51.65,.16-28.73-22.94-52.07-51.64-52.16-28.82-.1-52.08,22.89-52.21,51.6Z"
          />
          <path
            
            d="M39.16,319.24v-102.79c43.17,22.7,86.28,22.79,129.66,.07v102.54c-1.31-.59-2.52-1.09-3.68-1.68-19.32-9.64-38.65-19.25-57.92-28.97-2.22-1.12-3.95-1.16-6.2-.02-19.17,9.68-38.4,19.23-57.61,28.81-1.22,.61-2.46,1.18-4.25,2.04Z"
          />
          <path
            
            d="M104.25,77.86c14.37,.09,26.01,11.82,25.85,26.05-.16,14.3-11.92,25.84-26.23,25.76-14.25-.08-26.01-11.96-25.85-26.13,.16-14.34,11.82-25.76,26.22-25.67Z"
          />
        </g>
      </g>
    </svg>
  );
}



BandaPlataSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func
};

BandaPlataSVG.defaultProps = {
  height: 18,
  width: 18,
  fill: '#aec1d2',
  onClick: ()=>{},
};


export default BandaPlataSVG