import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";

//Hooks
import { useForm } from "react-hook-form";

//Components
import { Button, Grid, LinkPillButton } from "../../core";

//Data
import GlobalContext from "../../context/GlobalContext";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

/**
 * @component
 * Component in charge of receiving the contact mailbox
 */
const BuzonContactoGrid = () => {
  const { setAppLoading, NestGet, NestPost, 
    setHasNotifications } =
    useContext(GlobalContext);
  const { control, handleSubmit, reset, setValue } = useForm();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState();

  const columns = [
    {
      title: "Nombre",
      key: "names",
    },
    {
      title: "Correo",
      key: "email",
    },
    {
      title: "Mensaje",
      key: "message",
    },
    {
      title: "Fecha",
      key: "created_at",
    },

    {
      title: "Acciones",
      width: 100,
      key: "action",
    },
  ];

  const fetchData = useCallback(
    async (page = 1) => {
      setAppLoading(true);
      NestGet({
        schema: "contacto/notifications",
      })
        .then((res) => {
          setHasNotifications(res.data);
        })
        .finally((_) => {});
      NestGet({
        schema: "contacto",
        query: {
          limit: 20,
          page,
        },
      })
        .then((res) => {
          const sortedResponse = res.data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setData(sortedResponse);
          setPagination(res.pagination);
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading]
  );

  useEffect(() => {
    fetchData();
    // handleDelete_('mentor/banner/0/PQnYq1zP6GkefetGQorq3.png')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markAllAsRead = () => {
    setAppLoading(true);
    NestPost({
      schema: "contacto/readed",
    })
      .then((res) => {
        fetchData();
      })
      .finally((_) => {
        setAppLoading(false);
      });
  };

  const markOneAsRead = (id) => {
    setAppLoading(true);
    NestPost({
      schema: `contacto/${id}/readed`,
    })
      .then((res) => {
        fetchData();
      })
      .finally((_) => {
        setAppLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Contacto | Mentor</title>
      </Helmet>
      <div className="work_area">
        <div className="d-flex justify-content-center">
          <Button onClick={markAllAsRead}> Marcar todos como leidos</Button>
        </div>
        <Grid
          rowKey="id"
          columns={columns}
          dataSource={data}
          className="table-striped"
          pagination={pagination}
          onChangePage={fetchData}
        >
          <Grid.Column
            name="message"
            context={({ column, row }) => (
              <div className="d-flex flex-column">
                {row.phone ? (
                  <>
                    Teléfono: {row.phone}
                    <br />
                  </>
                ) : (
                  ""
                )}
                {row.business ? (
                  <>
                    Empresa: {row.business}
                    <br />
                  </>
                ) : (
                  ""
                )}
                {column.split('\n').map(m=>(<>{m}<br /></>))}
                {row.from ? (
                  <>
                    <Link to={row.from === "/" ? "/inicio" : row.from}>Desde: {row.from === "/" ? "/inicio" : row.from}</Link>
                    <br />
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          />
          <Grid.Column
            name="created_at"
            context={({ column, row }) => (
              <div className="d-flex">
                {moment(column, "YYYY-MM-DD[T]HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </div>
            )}
          />
          {
            <Grid.Column
              name="action"
              context={({ column, row }) => (
                <div className="d-flex">
                  {row.readed ? (
                    <></>
                  ) : (
                    <div
                      onClick={() => {
                        markOneAsRead(row._id);
                      }}
                    >
                      <LinkPillButton color={"#00b8d4"}>
                        <i className="fas fa-bell"></i>
                      </LinkPillButton>
                    </div>
                  )}
                </div>
              )}
            />
          }
        </Grid>
      </div>
    </>
  );
};

export default BuzonContactoGrid;
