import React from 'react'
import foco from '../../assets/images/foco_b.png'
import './Loading.scss'

const Loading = () => {
    return (
        <div className="Loading">
            <div className="loading-box">
                <img className="loading-foco" height="80" src={foco} alt="Foco Mentor" />
            </div>
        </div>
    )
}

export default Loading
