import React,{ useEffect, useRef } from "react";
import PropTypes from 'prop-types'
import {
  Scrollbar,
  Controller,
  Autoplay,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/effect-flip";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SwiperBanner = ({banners}) => {
  const mainSlider = useRef(null);
  const navSlider = useRef(null);
  const mainSliderOptions = {
    updateOnImagesReady: true,
    loop: true,
    speed: 2000,
    loopAdditionalSlides: 10,
    grabCursor: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    onInit: function () {
      
      //this.autoplay.stop();
    },
    onImagesReady: function () {
      //debugger
      //this.autoplay.start();
    },
    onClick: function () {
      this.autoplay.start();
    },
    onTransitionStart: function(){
      let swiper = this,
        captions = swiper.el.querySelectorAll(".caption"),
        btns = swiper.el.querySelectorAll(".btn");


      for (let i = 0; i < captions.length; ++i) {
        captions[i].classList.remove("show");
      }
      for (let i = 0; i < btns.length; ++i) {
        btns[i].classList.remove("show");
      }
    },
    onTouchStart: function () {
      let swiper = this;
      for (let i = 0; i < swiper.slides.length; i++) {
        swiper.slides[i].style.transition = "";
      }
    },
    onTransitionEnd: function(){
      let swiper = this
      swiper.slides[swiper.activeIndex]
        .querySelector(".caption")
        .classList.add("show");

      swiper.slides[swiper.activeIndex]
        .querySelector(".btn")
        ?.classList?.add("show");
    },
    onSetTransition: function (speed) {
      let swiper = this;
      for (let i = 0; i < swiper.slides.length; i++) {
        swiper.slides[i].style.transition = speed + "ms";
        swiper.slides[i].querySelector(".slide-bgimg").style.transition =
          speed + "ms";
      }
    },
  };
  const navSliderOptions = {
    loop: true,
    loopAdditionalSlides: 10,
    speed: 1000,
    spaceBetween: 5,
    slidesPerView: 5,
    centeredSlides: true,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    direction: "vertical",

    onImagesReady: function () {
      
    },
    onClick: function () {
      mainSlider.current.autoplay.stop();
    },
  };

  useEffect(() => {
    if(banners.length>1){
      mainSlider.current.controller.control = navSlider.current;
      mainSlider.current.autoplay.delay = 4000
      mainSlider?.current?.autoplay?.start();
      navSlider.current.controller.control = mainSlider.current;
    }
    return () => {};
  }, [mainSlider,navSlider,banners.length]);
  if(banners.length===0){
    return <></>
  }
  return (
    <div className="SwiperBanner">
      <Swiper
        // install Swiper modules
        className="swiper-container main-slider"
        modules={[Autoplay, Scrollbar, Controller]}
        onSwiper={(swiper) => (mainSlider.current = swiper)}
        
        {...mainSliderOptions}
      >
          {banners.map(banner=><SwiperSlide  key={`main-${banner.id}`}><Banner {...banner} /></SwiperSlide>)}
      </Swiper>
      {banners.length>1?<Swiper
        // install Swiper modules
        className="swiper-container nav-slider"
        modules={[Autoplay, Scrollbar, Controller]}
        onSwiper={(swiper) => (navSlider.current = swiper)}
        
        {...navSliderOptions}
      >
          {banners.map(banner=><SwiperSlide key={`nav-${banner.id}`}><Banner preview {...banner} /></SwiperSlide>)}
      </Swiper>:null}
    </div>
  )
};
const Banner = ({
  title,
  message,
  image,
  has_button,
  button_text,
  button_action,
  is_internal,
  preview
}) => {
    return(
        <>
            <figure
            className="slide-bgimg"
            style={{
                backgroundImage: `url(${image.location})`,
            }}
            >
                <img src={image.location} className="entity-img" alt="" />
            </figure>
            <div className="content">
                <p className="title">{title}</p>
                {preview?null:<span className="caption show">{message}</span>}
                {preview?null:(has_button?<Anchor is_internal={is_internal} button_text={button_text} button_action={button_action} />:null)}
            </div>
        </>
    );
};

const Anchor = ({button_text,button_action,is_internal,}) =>{
    return is_internal?<Link className="btn show" to={button_action}>{button_text}</Link>:<a className="btn show" href={button_action} target="_blank" rel="noopener noreferrer">{button_text}</a>
  }


SwiperBanner.propTypes = {
    banners: PropTypes.array
}
SwiperBanner.defaultProps = {
    banners: [
        {
            _id: 1,
            title: `Shaun Matthews`,
            message: `Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.`,
            image:{
                location: 'https://images.unsplash.com/photo-1483985988355-763728e1935b?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTY0MDE1NTg2Ng&ixlib=rb-1.2.1&q=85'
            }
        },
        {
            _id: 2,
            title: `Alexis Berry`,
            message: `Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.`,
            image:{
                location: 'https://images.unsplash.com/photo-1500643752441-4dc90cda350a?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTY0MDE1NTg2Ng&ixlib=rb-1.2.1&q=85'
            }
        },
        {
            _id: 3,
            title: `Billie Pierce`,
            message: `Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.`,
            image:{
                location: 'https://images.unsplash.com/photo-1465408953385-7c4627c29435?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTY0MDE1NTg2Ng&ixlib=rb-1.2.1&q=85'
            }
        },
        {
            _id: 4,
            title: `Trevor Copeland`,
            message: `Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.`,
            image:{
                location: 'https://images.unsplash.com/photo-1538329972958-465d6d2144ed?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTY0MDE1NTkzNg&ixlib=rb-1.2.1&q=85'
            }
        },
        {
            _id: 5,
            title: `Bernadette Newman`,
            message: `Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.`,
            image:{
                location: 'https://images.unsplash.com/photo-1583292650898-7d22cd27ca6f?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTY0MDE1NTk1OQ&ixlib=rb-1.2.1&q=85'
            }
        },
    ]
}

export default SwiperBanner