import React from "react";
import { useState } from "react";
import { InputC, SelectC } from "../../core";

export const jobTitleOptions = [
  { label: "Director Comercial", value: "Director_Comercial" },
  { label: "City Manager", value: "City_Manager" },
  {
    label: "Director de Equipo Comercial",
    value: "Director_de_Equipo_Comercial",
  },
  { label: "Gerente UDN", value: "Gerente_UDN" },
  { label: "Sub Gerente UDN", value: "Sub_Gerente_UDN" },
  { label: "Coordinador UDN", value: "Coordinador_UDN" },
  { label: "Asesor", value: "Asesor" },
  { label: "Staff comercial", value: "Staff_comercial" },
  { label: "Director Área/Departamento", value: "Director_Área/Departamento" },
  {
    label: "Gerente de Área/Departamento",
    value: "Gerente_de_Área/Departamento",
  },
  {
    label: "Coordinador Área/Departamento",
    value: "Coordinador_Área/Departamento",
  },
  { label: "Staff", value: "Staff" },
];
const UserPriceByTitle = ({ control, OnlyNumberF }) => {
  const [jobTitle, setJobTitle] = useState(jobTitleOptions[0].value);
  return (
    <>
      <div className="w-100">
        <div className="w-100">
          <div className="row">
            <SelectC
              control={control}
              label="Puesto"
              name="job_title"
              className="w-100"
              onInput={(e) => setJobTitle(e)}
              options={jobTitleOptions}
              defaultValue={jobTitleOptions[0].value}
            />
            {jobTitleOptions.map((jobT) => (
              <InputC
                control={control}
                style={jobT.value == jobTitle ? {} : { display: "none" }}
                label="Costo por puesto"
                name={`cost_job_title.${jobT.value}`}
                setFormate={OnlyNumberF}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPriceByTitle;
