import React from "react";
import EditUser from "./EditUser";
import NewUser from "./NewUser";
/**
 * @component
 * Component responsible for displaying the info of a user(not admin)
 */

const User = (actionType) => {

  if (actionType.isNew === 'true') {
    return (
      <NewUser></NewUser>
    );
  } else {
    return (
      <EditUser></EditUser>
    );
  }
};

export default User;
