/* eslint-disable import/no-anonymous-default-export */
export const colors = [
  "#FF6F00",
  "#4CAF50",
  "#512DA8",
  "#388E3C",
  "#7B1FA2",
  "#2E7D32",
  "#AFB42B",
  "#1565C0",
  "#006064",
  "#FBC02D",
  "#9C27B0",
  "#F57C00",
  "#0D47A1",
  "#1976D2",
  "#455A64",
  "#5E35B1",
  "#C0CA33",
  "#FFB300",
  "#FFA000",
  "#673AB7",
  "#0097A7",
  "#00695C",
  "#303F9F",
  "#01579B",
  "#EF6C00",
  "#00897B",
  "#827717",
  "#8E24AA",
  "#616161",
  "#FFC107",
  "#7CB342",
  "#FF8F00",
  "#CDDC39",
  "#FFEB3B",
  "#283593",
  "#33691E",
  "#F9A825",
  "#00BCD4",
  "#03A9F4",
  "#FDD835",
  "#558B2F",
  "#1E88E5",
  "#3F51B5",
  "#311B92",
  "#263238",
  "#757575",
  "#6A1B9A",
  "#4527A0",
  "#FB8C00",
  "#009688",
  "#4A148C",
  "#004D40",
  "#795548",
  "#00ACC1",
  "#6D4C41",
  "#43A047",
  "#37474F",
  "#0277BD",
  "#212121",
  "#424242",
  "#546E7A",
  "#039BE5",
  "#E65100",
  "#3E2723",
  "#00796B",
  "#3949AB",
  "#0288D1",
  "#607D8B",
  "#FF9800",
  "#5D4037",
  "#2196F3",
  "#8BC34A",
  "#9E9E9E",
  "#689F38",
  "#1B5E20",
  "#00838F",
  "#F57F17",
  "#9E9D24",
  "#1A237E",
  "#4E342E",
];
