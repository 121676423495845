import React from 'react'
import PropTypes from 'prop-types'
import './Schemas.scss'

const Alert = ({children, type}) => {
    const className = {
        warm: ' alert__warm',
        error: ' alert__error',
        info: '',
    }[type]||''
    return (
        <div className={`Alert${className}`} >
            {children}
            <i className="fa fa-info-circle"></i>
        </div>
    )
}

Alert.propTypes = {
    type: PropTypes.oneOf(['warm',
    'error','info'])
}

Alert.defaultProps={
    type: 'info'
}
export default Alert
