import React from "react";

//Hooks
import { Link } from "react-router-dom";

/**
 * @component
 * Component in charge of visualizing a demo of the course
 */

export default function CursoItem({ item, percentage }) {
  const url =
    percentage == 100 ? `/diplomado/${item?.sku}` : `/Curso/${item?.sku}`;
  return (
    <>
      {item && (
        <Link to={url}>
          <div
            className="cursos-section"
            style={{
              backgroundImage: `url(${item?.image_detalle?.location})`,
            }}
          >
            <div className="texto">
              <div className="row-texto">
                <p>{`${item.name}(${
                  percentage ? percentage.toFixed(2) : 0
                }%)`}</p>
                <div className="d-flex iconos-texto">
                  <div className="icons-centers">
                    <i className="fa-solid fa-bars"></i>
                    <span>Módulos: {item.n_modules}</span>
                  </div>
                  <div className="icons-centers">
                    <i className="fa-solid fa-clock"></i>
                    <span>Horas: {item.n_duration}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </>
  );
}
