import React from 'react'
import PropTypes from 'prop-types'
import { useController } from "react-hook-form";
import "../Forms.scss";

const CheckC = ({
    children,
    name,
    control,
    rules,
    style,
    defaultValue,
    onInput,
    value,
    className,
    disabled,
    readOnly,
    ...props
  }) => {
    const {
      field: { ref, onChange, onBlur, value:checked },
      fieldState: { isTouched, isDirty, error },
    } = useController({
      control,
      defaultValue,
      rules,
      name,
    });

  const isTouched_ = isTouched ? " formc__touched" : ""
  const isDirty_ = isDirty ? " formc__dirty" : ""
  const className_ = className ? ` ${className}` : ""
  const error_ = error ? ` label__error` : ""

    const selectProps = {
      
      ref,
      onChange: (v) => {
        onChange(v.target.checked?value:false);
        onInput(v.target.checked?value:false);
      },
      onBlur,
      checked: checked,
      disabled:disabled||readOnly,
      ...props
    };
    
    return (
        
            <label className={`CheckC${isTouched_}${isDirty_}${className_}${error_}${disabled?' disabled':''}`} htmlFor={`_${name}_`}>
                <input {...selectProps} id={`_${name}_`} type="checkbox" />
                <span className="checkbox"></span>
                {rules.required?<strong>* </strong>:null}{children}
            </label>
        
    )
}

CheckC.propTypes = {
  
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  
  defaultValue: PropTypes.bool,
  
};
CheckC.defaultProps = {
  name: "select",
  rules: {},
  onInput: (v) => {},
  defaultValue: false,
  value: true
};

export default CheckC
