import React, { useEffect } from 'react';
import GlobalContext from './context/GlobalContext.js';
import useApiContext from './hooks/useApiContext.js';
import Router from './Router';
import { LoadScript } from '@react-google-maps/api';
import {googleMapsApiKey} from './config'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line no-extend-native
String.prototype.toCapitalize = function () {
  return `${this.charAt(0)}`.toUpperCase() + this.slice(1).toLowerCase();
};
const App = () => {
  const useApi = useApiContext()
  return (
    <GlobalContext.Provider value={useApi}>
      {/* <LoadScript libraries={['places','geometry']} googleMapsApiKey={googleMapsApiKey}> */}
          <Router />
          <ToastContainer />
      {/* </LoadScript> */}
    </GlobalContext.Provider>
  );
};

export default App;

