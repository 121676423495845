import React from "react";
import PropTypes from "prop-types";

const LupaSVG = ({ height, width, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.95 21.96"
      fill="#fff"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path
            d="M22,21.27l-.06.16a.85.85,0,0,1-1.36.31c-.15-.14-.29-.29-.44-.44l-4.76-4.76a1.21,1.21,0,0,1-.14-.2c-.41.29-.77.55-1.14.78A9.22,9.22,0,0,1,2.27,15.3,9.11,9.11,0,0,1,0,8.81,9.27,9.27,0,0,1,6.24.5,9,9,0,0,1,8.48,0l.14,0h1.2l.91.12a9.24,9.24,0,0,1,5.91,14.65l-.31.4c.07.06.14.1.2.16,1.67,1.66,3.34,3.32,5,5a4.29,4.29,0,0,1,.42.6ZM1.71,9.24A7.53,7.53,0,1,0,9.25,1.71,7.53,7.53,0,0,0,1.71,9.24Z"
          />
        </g>
      </g>
    </svg>
  );
};

LupaSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

LupaSVG.defaultProps = {
  height: 22,
  width: 22,
};

export default LupaSVG;
