import React, { useContext, useEffect, useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import { MentorGridWithFilters, MentorGrid, ToggleSwitch, InputC } from "../../core";
import Swal from "sweetalert2";
import { useForm, useWatch } from "react-hook-form";
import { Helmet } from "react-helmet";
import Tooltip from '@mui/material/Tooltip';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Link } from "react-router-dom";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';

const UserGrid = () => {
  const { control } = useForm();
  const { NestGet, NestPost, setAppLoading, NestPatch } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState();
  const [interval, setInterval] = useState(null);
  const [gridType, setGridType] = useState(false);

  const query = useWatch({ control, name: "query" });

  const columns = [
    {
      title: "Nombre completo",
      id: "names",
      key: "names",
      filterkey: "names",
      width: 100,
    },
    {
      title: "Correo",
      key: "email",
      id: "email",
      filterkey: "email",
      width: 80,
    },
    {
      title: "Tipo",
      key: "tipo_estudiante",
      id: "tipo_estudiante",
      filterkey: "tipo_estudiante",
      width: 50,
    },
    {
      title: "Dirección",
      key: "customer_data.direction",
      id: "customer_data.direction",
      filterkey: "customer_data.direction",
      width: 80,
    },
    {
      title: "Departamento",
      key: "customer_data.department",
      id: "customer_data.department",
      filterkey: "customer_data.department",
      width: 80,
    },
    {
      title: "Area",
      key: "customer_data.zone",
      id: "customer_data.zone",
      filterkey: "customer_data.zone",
      width: 80,
    },
    {
      title: "Puesto",
      key: "customer_data.job_title",
      id: "customer_data.job_title",
      filterkey: "customer_data.job_title",
      width: 80,

    },
    {
      title: "Fact",
      width: 50,
      key: "hasBillData",
      id: "hasBillData",
      filterkey: "hasBillData",
    },
    {
      title: "Activo",
      width: 80,
      key: "activeStringValue",
      id: "activeStringValue",
      filterkey: "activeStringValue",
    },
    {
      title: "Acciones",
      width: 80,
      key: "action",
      id: "action",
      filterkey: "",
    },
  ];

  var allData = gridType;

  const fetchData = useCallback(
    (page) => {
      setAppLoading(true);

      if (!allData) {
        var data_schema = `admin/students?limit=10&page=${page}${query ? `&query=${query}` : ""}`
      } else {
        var data_schema = `admin/students`
      }
      // schema: `admin/students${query ? `&query=${query}` : ""
      NestGet({
        schema: data_schema,
      })
        .then((res) => {
          if (res.status === "success") {

            res.data.map(function (item, i) {

              let full_name = item.names.concat(" " + item.last_name, " ", item.mothers_last_name);
              item.names = full_name;
              item.active === true
                ? item.activeStringValue = "Activo"
                : item.activeStringValue = "No Activo"

              item.bill_data === undefined || item.bill_data.persona === '' || item.bill_data.persona === 'Sin datos'
                ? item.hasBillData = "Sin datos"
                : item.hasBillData = "Con datos"
            })
            setData(res.data);
            setPagination(res.pagination);
          }
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading, query]
  );

  const changeStatus = async (id, event, row) => {

    setAppLoading(true)
    var active = event.target.checked;
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2800,
    });

    NestPatch({
      schema: "admin/student/status",
      body: { active: active },
      id,
    }).then((res) => {
      if (res.status === "success") {
        const index = JSON.parse(JSON.stringify(data)).map((m) => m.id).indexOf(id);
        if (index > -1) {
          data[index].active = active;
          row.active = active;
        }
        Toast.fire({
          title: "Cambio de estatus exitoso",
          icon: "success",
        });
      }
    })
      .finally((_) => {
        setAppLoading(false);
      });

  };

  const changeFilterUI = async () => {
    setData([]);
    if (allData) {
      allData = false;
      fetchData()
      setGridType(false);
    } else {
      allData = true;
      setGridType(true)
      fetchData()
    }
  };

  useEffect(() => {
    clearTimeout(interval);
    setInterval(
      setTimeout(() => {
        fetchData(1);
      }, 500)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <>
      <Helmet>
        <title>Usuarios | Mentor</title>
      </Helmet>
      <div className="filter_work_area">
        <div style={{
          overflow: "hidden",
          marginBottom: "-5px"
        }}>
          <div style={{
            float: "right",
            paddingTop: "13px",
            paddingRight: "5px"

          }}>
            <Tooltip title="Add User">
              <IconButton color="secondary" aria-label="filter columns">
                <Link to={`/Backoffice/Usuarios/new`}>
                  <PersonAddIcon style={{ fontSize: "25px", color: "hsl(205, 29%, 29%)" }} />
                </Link>
              </IconButton>
            </Tooltip>
          </div>
          <div style={{
            float: "right",
            "paddingTop": "13px"
          }}>
            <Tooltip title={!allData ? "Filtros tipo Excel (todos los datos)" : "Remover filtros"}  >
              {/* FilterListIcon */}
              <IconButton
                color="secondary"
                aria-label="filter columns"             
                onClick={changeFilterUI}
              >
                {allData
                  ? <FilterListOffIcon style={{ fontSize: "25px", color: "hsl(205, 29%, 29%)" }} />
                  : <FilterListIcon style={{ fontSize: "25px", color: "hsl(205, 29%, 29%)" }} />
                }
              </IconButton>
            </Tooltip>
          </div>
          <div style={{
            width: "95%",
            // "padding-left": "6px",
            // "padding-top": "1px",
          }}>
            <FilterSection control={control} disabled={allData} />
          </div>
        </div>
      </div>
      <div className="work_area">
        {allData
          ?
          <MentorGridWithFilters
            rowKey="id"
            columns={columns}
            data={data}
            className="table-hover"
            enablePagination={true}
          >
            <MentorGridWithFilters.Column
              name="email"
              context={({ column, row }) => (
                <a
                  style={{
                    color: "blue",
                    overflow: "hidden",
                    'textOverflow': "ellipsis",
                    'maxWidth': 80,
                  }}
                  href={`mailto:${row.email}`}>{row.email}
                </a>

              )}
            />
             <MentorGridWithFilters.Column
              name="customer_data.direction"
              context={({ column, row }) => (
                <>
                  {(row !== undefined && row.customer_data !== undefined && row.customer_data.direction !== undefined)
                    ? <div
                      style={{
                        overflow: "hidden",
                        'textOverflow': "ellipsis",
                        'maxWidth': 80,
                      }}
                    >
                      <Tooltip title={`${row.customer_data.department}`}>
                        <span> {row.customer_data.department}</span>
                      </Tooltip>
                    </div>
                    : <></>
                  }
                </>
              )}
            />
            <MentorGridWithFilters.Column
              name="customer_data.department"
              context={({ column, row }) => (
                <>
                  {(row !== undefined && row.customer_data !== undefined && row.customer_data.zone !== undefined)
                    ? <div
                      style={{
                        overflow: "hidden",
                        'textOverflow': "ellipsis",
                        'maxWidth': 80,
                      }}
                    >
                      <Tooltip title={`${row.customer_data.department}`}>
                        <span> {row.customer_data.department}</span>
                      </Tooltip>
                    </div>
                    : <></>
                  }
                </>
              )}
            />
            <MentorGridWithFilters.Column
              name="customer_data.zone"
              context={({ column, row }) => (
                <>
                  {(row !== undefined && row.customer_data !== undefined && row.customer_data.zone !== undefined)
                    ? <div
                      style={{
                        overflow: "hidden",
                        'textOverflow': "ellipsis",
                        'maxWidth': 80,
                      }}
                    >
                      <Tooltip title={`${row.customer_data.zone}`}>
                        <span> {row.customer_data.zone}</span>
                      </Tooltip>
                    </div>
                    : <></>
                  }
                </>
              )}
            />
            <MentorGridWithFilters.Column
              name="customer_data.position"
              context={({ column, row }) => (
                <>
                  {(row !== undefined && row.customer_data !== undefined && row.customer_data.position !== undefined)
                    ? <div
                      style={{
                        overflow: "hidden",
                        'textOverflow': "ellipsis",
                        'maxWidth': 80,
                      }}
                    >
                      <Tooltip title={`${row.customer_data.position}`} >
                        <span>{row.customer_data.position}</span>
                      </Tooltip>
                    </div>
                    : <></>
                  }
                </>
              )}
            />
            <MentorGridWithFilters.Column
              name="hasBillData"
              context={({ column, row }) => (
                <div className="d-flex"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',

                  }}
                >
                  {(row !== undefined && row.hasBillData !== undefined &&  row.hasBillData !== 'Sin datos' )
                    ? <DoneIcon style={{ fontSize: "15px" }} />
                    : <CloseIcon style={{ fontSize: "15px" }} />
                  }
                </div>
              )}

            />
            <MentorGridWithFilters.Column
              name="activeStringValue"
              context={({ column, row }) => (
                <div className="d-flex"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {/* <ToggleSwitch
                    checked={row.active}
                    name={`${row.id}`}
                    onChange={(v) => changeStatus(row.id, v, row)}
                  /> */}
                  <Switch
                    color="secondary"
                    size="small"
                    checked={row.active}
                    name={`${row.id}`}
                    onChange={(v) => changeStatus(row.id, v, row)}                    
                   />
                </div>
              )}
            />
            <MentorGridWithFilters.Column
              name="action"
              context={({ column, row }) => (
                <div className="d-flex"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title="Detalle de  usuario">
                    <IconButton color="secondary" aria-label="filter columns" size="small" >
                      <Link to={`/Backoffice/Usuarios/${row.id}`}>
                        <ManageAccountsIcon style={{ fontSize: "25px", color: "hsl(205, 29%, 29%)" }} />
                      </Link>
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            />
          </MentorGridWithFilters>
          :
          <MentorGrid
            rowKey="id"
            columns={columns}
            data={data}
            className="table-hover"
            pagination={pagination}
            onChangePage={fetchData}
          >
            <MentorGrid.Column
              name="email"
              context={({ column, row }) => (
                <a
                  style={{
                    color: "blue",
                  }}
                  href={`mailto:${row.email}`}>{row.email}
                </a>

              )}
            />
            <MentorGrid.Column
              name="customer_data.direction"
              context={({ column, row }) => (
                <>
                  {(row !== undefined && row.customer_data !== undefined && row.customer_data.direction !== undefined)
                    ? <div
                      style={{
                        overflow: "hidden",
                        'textOverflow': "ellipsis",
                        'maxWidth': 80,
                      }}
                    >
                      <Tooltip title={`${row.customer_data.department}`}>
                        <span> {row.customer_data.department}</span>
                      </Tooltip>
                    </div>
                    : <></>
                  }
                </>
              )}
            />
            <MentorGrid.Column
              name="customer_data.department"
              context={({ column, row }) => (
                <>
                  {(row !== undefined && row.customer_data !== undefined && row.customer_data.zone !== undefined)
                    ? <div
                      style={{
                        overflow: "hidden",
                        'textOverflow': "ellipsis",
                        'maxWidth': 80,
                      }}
                    >
                      <Tooltip title={`${row.customer_data.department}`}>
                        <span> {row.customer_data.department}</span>
                      </Tooltip>
                    </div>
                    : <></>
                  }
                </>
              )}
            />
            <MentorGrid.Column
              name="customer_data.zone"
              context={({ column, row }) => (
                <>
                  {(row !== undefined && row.customer_data !== undefined && row.customer_data.zone !== undefined)
                    ? <div
                      style={{
                        overflow: "hidden",
                        'textOverflow': "ellipsis",
                        'maxWidth': 80,
                      }}
                    >
                      <Tooltip title={`${row.customer_data.zone}`}>
                        <span> {row.customer_data.zone}</span>
                      </Tooltip>
                    </div>
                    : <></>
                  }
                </>
              )}
            />
            <MentorGrid.Column
              name="customer_data.position"
              context={({ column, row }) => (
                <>
                  {(row !== undefined && row.customer_data !== undefined && row.customer_data.position !== undefined)
                    ? <div
                      style={{
                        overflow: "hidden",
                        'textOverflow': "ellipsis",
                        'maxWidth': 80,
                      }}
                    >
                      <Tooltip title={`${row.customer_data.position}`} >
                        <span>{row.customer_data.position}</span>
                      </Tooltip>

                    </div>
                    : <></>
                  }
                </>
              )}
            />
            <MentorGrid.Column
              name="hasBillData"
              context={({ column, row }) => (
                <div className="d-flex"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',

                  }}
                >
                  {(row !== undefined && row.hasBillData !== undefined &&  row.hasBillData !== 'Sin datos' )
                    ? <DoneIcon style={{ fontSize: "15px" }} />
                    : <CloseIcon style={{ fontSize: "15px" }} />
                  }
                </div>
              )}

            />
            <MentorGrid.Column
              name="activeStringValue"
              context={({ column, row }) => (
                <div className="d-flex"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {/* <ToggleSwitch
                    checked={row.active}
                    name={`${row.id}`}
                    onChange={(v) => changeStatus(row.id, v, row)}
                  /> */}
                   <Switch
                   color="secondary"
                    size="small"
                    checked={row.active}
                    name={`${row.id}`}
                    onChange={(v) => changeStatus(row.id, v, row)}                    
                   />
                </div>
              )}
            />
            <MentorGrid.Column
              name="action"
              context={({ column, row }) => (
                <div className="d-flex"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title="Detalle de usuario">
                    <IconButton color="secondary" aria-label="filter columns" size="small" >
                      <Link to={`/Backoffice/Usuarios/${row.id}`}>
                        <ManageAccountsIcon style={{ fontSize: "25px", color: "hsl(205, 29%, 29%)" }} />
                      </Link>
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            />
          </MentorGrid>
        }
      </div>
    </>
  );
};

const FilterSection = ({ control, disabled }) => {
  return (
    <>
      <div className="d-flex align-items-center flex-wrap justify-content-center">
        <div className="flex-fill flex-grow-1">
          <InputC
            control={control}
            name="query"
            placeholder="Buscar"
            className="w-100"
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};

export default UserGrid;