import React from "react";
import PropTypes from "prop-types";

const WASVG = ({ height, width, fill, active, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.09 26.2"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M0,26.2c.28-1,.55-2,.82-3l1-3.54a.61.61,0,0,0,0-.38A12.67,12.67,0,0,1,.15,12,12.74,12.74,0,0,1,5,2.87,12.46,12.46,0,0,1,10.5.26,13,13,0,0,1,16,25.66a12.88,12.88,0,0,1-8.89-1.15.64.64,0,0,0-.41,0L.33,26.12Zm3.14-3.1L3.45,23l3.44-.91a.72.72,0,0,1,.61.09,10.67,10.67,0,0,0,8,1.28,10.78,10.78,0,1,0-13.21-11A10.63,10.63,0,0,0,3.7,18.28a3,3,0,0,1,.48.92,3,3,0,0,1-.26,1C3.66,21.17,3.4,22.12,3.14,23.1Z" />
          <path d="M6.47,10.07A3.76,3.76,0,0,1,7.68,7.34,1.19,1.19,0,0,1,8.54,7a4.87,4.87,0,0,0,.54,0,.67.67,0,0,1,.74.47c.36.87.73,1.72,1.06,2.59a.69.69,0,0,1-.06.56,10.73,10.73,0,0,1-.81,1.1.5.5,0,0,0,0,.64,8.93,8.93,0,0,0,4.47,3.84.51.51,0,0,0,.65-.17c.32-.4.66-.8,1-1.21a.5.5,0,0,1,.66-.21c.93.44,1.85.88,2.77,1.34a.42.42,0,0,1,.19.32,2.44,2.44,0,0,1-1.1,2.34,3.91,3.91,0,0,1-3.39.42A13.15,13.15,0,0,1,8,13.73,7.8,7.8,0,0,1,6.61,11C6.54,10.7,6.52,10.38,6.47,10.07Z" />
        </g>
      </g>
    </svg>
  );
};

WASVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

WASVG.defaultProps = {
  height: 28,
  width: 28,
  fill: "#000",
  active: false,
};

export default WASVG;
