import React, { useCallback, useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import "./ComponetsForms.scss";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Swal from "sweetalert2";
import moment from "moment";

/**
 * @component
 * Component responsible for showing the modal of stripe pay
 */

const CheckoutForm = ({ onSuccess, amount, customer_id, cursos }) => {
  const { StripeApi } = useContext(GlobalContext);
  const stripe = useStripe();
  const elements = useElements();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    StripeApi({
      url: "create-payment",
      body: { amount: amount * 100, customer_id, courses: cursos },
    }).then((data) => {
      if (data.status !== "success") {
        setError(data.message);
      }
      if (data?.result?.clientSecret) {
        setClientSecret(data.result.clientSecret);
      }
    });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    // debugger;
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    // debugger;

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      onSuccess(payload);
    }
  };

  return (
    <>
      {cursos &&
        cursos.map((item) => (
          <div className="datos-compra" key={item._id}>
            <span className="title-cur">{item.name}</span>
            <span className="amount-cur">${item.cost?.toFixed(2)}</span>
          </div>
        ))}
      <div className="Stripe">
        <form id="payment-form" onSubmit={handleSubmit}>
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
          <button disabled={processing || disabled || succeeded} id="submit">
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pagar ahora"
              )}
            </span>
          </button>

          {error && (
            <div className="card-error" role="alert">
              {error}
            </div>
          )}
        </form>
      </div>
    </>
  );
};

const stripePromise = loadStripe(
  "pk_live_51KyHycATHQMqTio5DbGZTFNZQyfKO5f1VU7FnRwwNaHyPVQYw2CgL0okvcgWPFMUHHDSfybliTKhJEpALqkoBed100YQdM4YlE"
);

const StripeFormModal = ({
  activate,
  onSuccess,
  amount,
  customer_id,
  cursos,
  name_curse,
  onCancel,
  notClosable,
}) => {
  const [visible, setVisible] = useState(false);
  const handleSuccess = useCallback(
    (stripe, flag = true) => {
      onSuccess(stripe);
      setVisible(false);
      Swal.fire({
        icon: "success",
        title: "Ya puedes ver el curso",
        text: `Se compró el curso correctamente.${
          flag
            ? " Cuentas únicamente con el mes en curso para solicitar tu factura."
            : ""
        }`,
      });
    },
    [onSuccess]
  );
  const freePay = useCallback(async () => {
    setVisible(false);
    handleSuccess(
      {
        paymentIntent: {
          id: "free",
          object: "payment_intent",
          amount: 0,
          automatic_payment_methods: null,
          canceled_at: null,
          cancellation_reason: null,
          capture_method: "free",
          client_secret: "free",
          confirmation_method: "automatic",
          created: moment().unix(),
          currency: "mxn",
          description: cursos
            ?.map((m) => `(${m?.sku}) ${m?.name} $${m?.price}`)
            .join(", "),
          last_payment_error: null,
          livemode: false,
          next_action: null,
          payment_method: "free",
          payment_method_types: ["free"],
          processing: null,
          receipt_email: null,
          setup_future_usage: null,
          shipping: null,
          source: null,
          status: "succeeded",
        },
      },
      false
    );
  }, [cursos, handleSuccess]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (activate) {
      //debugger;
      if (amount >= 1) {
        setVisible(activate);
      } else {
        freePay();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activate, amount]);

  const NotVisible = () => {
    if (onCancel) onCancel();
    setVisible(false);
  };

  return (
    <>
      {visible ? (
        <div className="modal-stripe not-container-item">
          <div className="contain-modal-stripe">
            <div className="title-modal-stripe">
              <h1>COMPRAR CURSO {name_curse || ""}</h1>
              {!notClosable ? (
                <span onClick={(_) => NotVisible()} className="cerrar-modal">
                  x
                </span>
              ) : null}
            </div>
            <div className="body-modal-stripe">
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  onSuccess={handleSuccess}
                  amount={amount}
                  cursos={cursos}
                  customer_id={customer_id}
                />
              </Elements>
            </div>
            {!notClosable ? (
              <div className="footer-modal-stripe">
                <button className="button-foo" onClick={(_) => NotVisible()}>
                  Cancelar
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default StripeFormModal;
