import React, { useContext, useEffect } from "react";

//Hooks
import { useController, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";

//Data
import GlobalContext from "../../context/GlobalContext";

//assets
import puntos_form from "../../assets/puntos_form.png";
import direccion from "../../assets/images/direccion.png";
import correo from "../../assets/images/correo.png";
import telefono from "../../assets/images/telefono.png";

const ContactoForm = ({className, style}) => {
  const location = useLocation();
  const {
    NestPost,
    state: { user },
    isLogin,
  } = useContext(GlobalContext);
  const { control, handleSubmit, reset, setValue } = useForm();
  const onSubmit = (data) => {
    // debugger
    NestPost({
      schema: "contacto",
      body: {
        ...data,
        message: `Me interesa el curso ${data.message}`,
        from: location.pathname,
      },
    });
    reset({
      names: "",
      lastname: "",
      business: "",
      message: "",
      phone: "",
      email: "",
    });

    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 1300,
    });
    Toast.fire({
      icon: "success",
      title: "Se envió el mensaje correctamente.",
    });
  };

  useEffect(() => {
    if (isLogin) {
      //setValue()
    }
  }, [isLogin]);
  return (
    <div className={`${className||''} ContactoForm`} {...style?{style}:{}}>
      <div className="rigth">
        <div className="item">
          <div className="img-section">
            <img src={direccion} alt="" />
          </div>
          <div className="text-section">
            <h5>Dirección</h5>
            <p>
              Av. Pablo Neruda #2813 Col. Providencia 4ta sección Guadalajara,
              Jalisco.
            </p>
          </div>
        </div>
        <div className="item">
          <div className="img-section">
            <img src={telefono} alt="" />
          </div>
          <div className="text-section">
            <h5>Teléfono:</h5>
            <a href="tel:33 23 00 13 54">33 23 00 13 54</a>
          </div>
        </div>
        <div className="item">
          <div className="img-section">
            <img src={correo} alt="" />
          </div>
          <div className="text-section">
            <h5>Correo:</h5>
            <a href="mailto:contacto@mentordh.mx">contacto@mentordh.mx</a>
          </div>
        </div>
      </div>
      <div
        className="form"
        style={{ backgroundImage: `url('${puntos_form}')` }}
      >
        <h1>Contacto</h1>
        <div className="d-flex flex-wrap">
          <div className="flex-1">
            <label htmlFor="names">
              Nombre<span className="text-danger">*</span>
            </label>
            <Input label="Escribe tu nombre" name="names" control={control} />
          </div>
          <div className="flex-1">
            <label htmlFor="lastnames">Apellido</label>
            <Input label="Escribe tu apellido" name="lastnames" control={control} />
          </div>
        </div>
        <label htmlFor="business">
          Empresa<span className="text-danger">*</span>
        </label>
        <Input
          label="Escribe el nombre de tu empresa"
          name="business"
          control={control}
        />
        <Input label="Curso de tu interés" name="message" control={control} />
        <div className="d-flex flex-wrap">
          <div className="flex-1">
            <label htmlFor="phone">
              Teléfono<span className="text-danger">*</span>
            </label>
            <Input label="Escribe tu teléfono" name="phone" control={control} />
          </div>
          <div className="flex-1">
            <label htmlFor="email">
              E-mail<span className="text-danger">*</span>
            </label>
            <Input label="Escribe tu e-mail" name="email" control={control} />
          </div>
        </div>
        <button className="contacto-button" onClick={handleSubmit(onSubmit)}>
          Enviar
        </button>
      </div>
    </div>
  );
};

const Input = ({ name, label, control, ...props }) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    rules: { required: "es requerido" },
    defaultValue: "",
  });

  const inputProps = {
    ...props,
    ref,
    onChange: (v) => {
      onChange(v?.target?.value);
    },
    onBlur,
    value,
  };
  return (
    <>
      <div
        className={`contacto-input${isTouched ? " formc__touched" : ""}${
          isDirty ? " formc__dirty" : ""
        }`}
      >
        <input type="text" {...inputProps} placeholder={label} name={name} />
        <span className="label__error">{invalid ? error.message : ""}</span>
      </div>
    </>
  );
};

export default ContactoForm;
