import { Link, useParams } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import moment from "moment";
import { Helmet } from "react-helmet";
export default function DetallesPedido() {
  let { id } = useParams();
  const { setAppLoading, NestGet } = useContext(GlobalContext);
  const [pedido, setPedido] = useState(null);
  const fetchData = useCallback(
    async (page = 1) => {
      setAppLoading(true);
      NestGet({
        schema: `orders/detail/${id}`,
      })
        .then((res) => {
          const columnData = [];
          res.data.forEach((ord) => {
            columnData.push({
              n_pedido: ord.n_pedido,
              user: ord.user,
              courses:
                ord.num_course > 1
                  ? ord.num_course
                  : res.data[0].courses[0].name,
              amount: ord.amount,
              stripe: ord.stripe,
              notes: ord.notes,
              _id: ord._id,
              coursesInfo: ord.courses,
              buy_date: ord.buy_date,
              discount: ord.discount,
            });
          });
          setPedido(columnData[0]);
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading]
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="PedidosDetalles">
      <Helmet>
        <title>Detalles del pedido | Mentor</title>
      </Helmet>
      {pedido && pedido && (
        <div className="PedidosDetalles">
          <div className="pedidos-container">
            <h1 className="title-pedidos">
              Detalles de pedido <br /> #{pedido._id}
            </h1>
            <div className="detalle-pedido">
              <div className="info-extra">
                Estatus: {pedido?.stripe?.paymentIntent?.status || ""} <br />
                Precio total: {Number(pedido.amount).toFixed(2)} <br />
                Fecha de compra:{" "}
                {moment(
                  new Date(pedido.buy_date),
                  "YYYY-MM-DD[T]HH:mm:ss"
                ).format("DD/MM/YYYY HH:mm:ss")}{" "}
                <br />
              </div>
              <Link to={`/curso/${pedido.coursesInfo[0].sku}`}>
                <div className="d-flex my-5 flex-wrap align-items-center">
                  <div className="col-md-4">
                    <img
                      style={{ borderRadius: 16 }}
                      src={pedido.coursesInfo[0].image?.location}
                      alt={`imagen del ${pedido.coursesInfo[0].name}`}
                    />
                  </div>
                  <div className="col-md-5">
                    <h4 className="cart-name">{pedido.coursesInfo[0].name}</h4>

                    <p className="cart-description">
                      {pedido.coursesInfo[0].description}
                    </p>
                    <small className="cart-teacher">
                      {pedido.coursesInfo[0].teacher}
                    </small>
                  </div>
                  <div className="col-md-2">
                    <h4 className="cart-cost text-right">
                      {pedido.coursesInfo[0].cost > 0
                        ? `$ ${pedido.coursesInfo[0].cost?.toFixed(2)}`
                        : "Gratis"}
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
