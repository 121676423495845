import React from 'react'
import ReactMarkdown from 'react-markdown'

const Markdown = ({children}) => {
  return (
    <ReactMarkdown components={{ a: LinkRenderer }}>{children}</ReactMarkdown>
  )
}
function LinkRenderer(props) {
  console.log({ props });
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}
export default Markdown