import React from "react";
import PropTypes from "prop-types";

const ShopSVG = ({ height, width, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
      viewBox="0 0 18.99 21.96"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M9.9,0a8.89,8.89,0,0,1,.89.22A4,4,0,0,1,13.54,4c0,.26,0,.51,0,.8h3c.5,0,.73.19.78.68.16,1.45.31,2.91.46,4.37s.31,2.85.46,4.28.29,2.84.44,4.26c.1.9.19,1.8.29,2.71.05.47-.09.77-.46.87a1.24,1.24,0,0,1-.27,0H.75A.68.68,0,0,1,0,21.11c.17-1.59.33-3.19.5-4.78l.54-5.11c.17-1.56.33-3.13.49-4.69L1.66,5.4a.66.66,0,0,1,.71-.64H5.45V4.12a4,4,0,0,1,.44-1.9A4,4,0,0,1,9,0l.12,0Zm7.71,20.64v-.11c-.24-2.23-.47-4.46-.71-6.69l-.78-7.55c0-.17-.07-.24-.26-.23-.7,0-1.4,0-2.1,0h-.22v.27c0,.39,0,.78,0,1.18a.65.65,0,0,1-1.3,0c0-.24,0-.48,0-.71V6.07H6.76v.24c0,.39,0,.77,0,1.16a.64.64,0,0,1-.64.68.65.65,0,0,1-.67-.68V6.07H2.9L1.38,20.64ZM6.79,4.75h5.44c0-.28,0-.54,0-.8a2.6,2.6,0,0,0-1.66-2.42A2.59,2.59,0,0,0,7.68,2,3,3,0,0,0,6.79,4.75Z" />
        </g>
      </g>
    </svg>
  );
};

ShopSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

ShopSVG.defaultProps = {
  height: 22,
  width: 22,
};

export default ShopSVG;
