import React from "react";
import PropTypes from "prop-types";

const BannersSVG = ({ height, width, fill, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 408.9 408.95"
      fill={fill}
    >
      <g>
        <g>
          <path d="M0,378.6V30.48c.15-.36,.39-.71,.44-1.08C2.91,12.08,17.92-.25,37.78,0c37,.47,74,.13,111.01,.13,47.52,0,95.03,0,142.55,0,11.24,0,20.74,3.88,28.06,12.69,6.54,7.87,7.82,17.14,7.8,26.93-.07,41.79-.03,83.57-.03,125.36,0,1.67,0,3.33,0,5.29,2.21,0,3.78,0,5.36,0,19.57,0,39.13,0,58.7,0,12.95,0,17.67,4.67,17.67,17.45,0,34.33,0,68.67,0,103,0,10.66-5.25,15.87-15.97,15.87-20.36,.01-40.73,0-61.09,0h-4.35v13.6c2.27,0,4.25,0,6.23,0,13.18,0,26.35-.04,39.53,.02,6.13,.03,9.66,4.14,7.88,8.99-1.29,3.52-4.05,4.61-7.57,4.6-13.98-.04-27.95-.01-41.93-.01-1.39,0-2.79,0-4.44,0,0,13.15-.02,25.64,0,38.14,.02,9.79-2.49,18.63-9.29,25.98-5.65,6.11-12.92,9.06-20.8,10.87H30.35c-6.07-1.44-11.95-3.22-17.01-7.16-7.64-5.94-11.49-13.96-13.33-23.18ZM313.34,68.56H13.6v4.71c0,99.39,0,198.77,0,298.16,0,16,7.98,23.92,24.11,23.92,83.97,0,167.94,0,251.91,0,1.33,0,2.66,.03,3.99-.05,10.86-.66,18.8-8.05,19.57-18.91,.53-7.55,.27-15.15,.31-22.73,.04-6.48,0-12.97,0-19.42-3.92-.99-6.56-2.91-6.72-6.79-.18-4.37,2.95-6.19,6.44-7.34v-13.07c-9.92-2.29-13.38-6.59-13.39-16.59-.02-34.59-.01-69.18,0-103.78,0-1.46,.09-2.93,.28-4.37,.74-5.57,4.5-9.82,9.89-11.28,1.12-.3,2.25-.58,3.33-.86V68.56Zm-.31-14.11c0-8.35,.69-16.38-.17-24.24-1.11-10.1-10.32-16.46-22.17-16.47-84.78,0-169.56,0-254.34,0-1.59,0-3.21,0-4.78,.25-8.16,1.32-14.81,5.55-16.45,13.65-1.76,8.68-1.44,17.77-2.04,26.79H313.03Zm.83,129.79v108.65h81.13v-108.65h-81.13Z" />
          <path d="M402.15,333.93c-3.78,.07-6.85-2.95-6.84-6.74,0-3.59,2.89-6.61,6.46-6.79,3.66-.18,7,2.92,7.09,6.59,.09,3.73-2.94,6.87-6.71,6.93Z" />
          <path d="M27.21,238.48c0-17.3-.01-34.59,0-51.89,.01-10.84,5.3-16.15,16.2-16.16,30.08-.03,60.15-.02,90.23,0,11.11,0,16.34,5.27,16.34,16.44,.01,34.46,.01,68.92,0,103.38,0,11.43-5.12,16.49-16.62,16.49-29.94,0-59.89,0-89.83,0-11.16,0-16.31-5.17-16.32-16.38-.01-17.3,0-34.59,0-51.89Zm109.04-54.13H41.12v108.46h95.12v-108.46Z" />
          <path d="M286.18,238.85c0,17.3,.03,34.59,0,51.89-.02,10.97-5.15,16-16.2,16-30.07,0-60.15,0-90.22,0-11.05,0-16.11-5.04-16.11-16.07-.01-34.73-.01-69.45,0-104.18,0-10.95,5.14-16.06,16.18-16.07,30.07-.02,60.15-.02,90.22,0,10.98,0,16.11,5.15,16.13,16.14,.03,17.43,0,34.86,0,52.29Zm-108.78,54.06h94.83v-108.6h-94.83v108.6Z" />
          <path d="M68.07,129.8c0,2.12,.07,4.25-.01,6.37-.31,7.93-5.21,13.26-13.1,13.8-4.76,.32-9.57,.31-14.34,.02-8.02-.49-13.16-5.99-13.39-14.02-.12-4.25-.12-8.5,0-12.75,.23-8.09,5.38-13.51,13.39-13.93,4.64-.24,9.3-.24,13.94-.02,8.14,.38,13.2,5.65,13.49,13.76,.08,2.25,.01,4.51,.01,6.77Zm-13.89,6.45v-13.05h-13.08v13.05h13.08Z" />
          <path d="M122.57,109.19c11.04,0,22.09,0,33.13,0,3.14,0,6.16,.91,7.02,4.11,.55,2.04,.39,5.2-.86,6.56-1.55,1.69-4.51,2.65-6.95,2.84-5.16,.41-10.37,.13-15.56,.13-15.7,0-31.4,.01-47.11-.02-1.72,0-3.47-.07-5.15-.43-3.49-.74-5.22-3.17-5.13-6.6,.09-3.44,1.97-5.87,5.49-6.28,3.15-.37,6.37-.3,9.56-.31,8.52-.04,17.03-.01,25.55-.01h0Z" />
          <path d="M201.08,333.95c-7.44,0-14.88,.03-22.33-.01-5.15-.03-8.19-2.52-8.28-6.62-.09-4.11,3-6.92,8.01-6.94,15.02-.06,30.03-.06,45.05,0,5.02,.02,8.12,2.78,8.07,6.88-.04,4.12-3.08,6.65-8.2,6.69-7.44,.05-14.88,.01-22.33,.01Z" />
          <path d="M61.18,333.95c-6.38,0-12.75,.05-19.13-.02-4.91-.05-7.74-2.51-7.83-6.6-.09-4.21,2.86-6.93,7.93-6.96,12.75-.07,25.5-.07,38.25,0,4.98,.02,8.18,2.83,8.14,6.87-.04,4.06-3.18,6.66-8.24,6.7-6.38,.05-12.75,.01-19.13,.01Z" />
          <path d="M122.6,333.94c-4.38,0-8.76,.09-13.13-.03-4.19-.12-7.04-2.8-7.19-6.49-.15-3.77,2.78-6.93,7.11-7.01,8.88-.16,17.77-.17,26.65,.01,4.21,.08,6.92,2.99,6.94,6.72,.02,3.91-2.81,6.65-7.26,6.77-4.37,.11-8.75,.02-13.13,.02Z" />
          <path d="M262.23,333.93c-3.31,0-6.63,.11-9.93-.04-4.07-.18-6.97-3.12-6.94-6.75,.03-3.49,2.71-6.53,6.63-6.67,6.88-.24,13.78-.24,20.66-.01,4.07,.14,6.88,3.37,6.72,6.96-.16,3.69-3.02,6.34-7.19,6.49-3.31,.12-6.62,.02-9.93,.02Z" />
          <path d="M95.34,150.25c-2.74-.18-5.42-.13-8.01-.58-3.66-.64-5.43-3.15-5.38-6.8,.05-3.36,2.49-6.06,6.03-6.23,4.9-.24,9.83-.24,14.74-.01,3.61,.17,6,2.76,6.11,6.14,.12,3.67-1.6,6.21-5.25,6.87-2.72,.49-5.54,.43-8.24,.61Z" />
          <path d="M163.72,40.95c-8.64,0-17.29,.03-25.93-.01-5.11-.03-8.22-2.6-8.28-6.66-.06-4.04,3.12-6.89,8.12-6.91,17.29-.06,34.57-.07,51.86,0,5.09,.02,8.03,2.74,7.96,6.95-.07,4.2-2.97,6.59-8.21,6.62-8.51,.05-17.02,.01-25.53,.01Z" />
          <path d="M74.93,34.24c-.03,3.71-3.28,6.77-7.04,6.64-3.71-.13-6.52-3.07-6.44-6.72,.08-3.66,2.99-6.65,6.53-6.73,3.61-.08,6.97,3.22,6.95,6.81Z" />
          <path d="M95.38,40.9c-3.81-.09-6.84-3.24-6.7-6.97,.13-3.51,3.31-6.53,6.82-6.49,3.59,.05,6.7,3.11,6.69,6.59-.01,3.85-3.08,6.94-6.81,6.86Z" />
          <path d="M47.67,34.54c-.09,3.47-3.28,6.4-6.89,6.34-3.89-.06-6.76-3.11-6.55-6.97,.19-3.65,3.21-6.52,6.78-6.44,3.66,.08,6.76,3.37,6.66,7.07Z" />
        </g>
      </g>
    </svg>
  );
};
BannersSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

BannersSVG.defaultProps = {
  height: 26,
  width: 26,
  fill: "#222",
};

export default BannersSVG;
