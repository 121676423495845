import React, { useContext, useEffect, useMemo, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useHistory, NavLink } from "react-router-dom";
import useScreenChange from "../../hooks/useScreenChange";
import GlobalContext from "../../context/GlobalContext";
import { useForm } from "react-hook-form";
import {
  Avatar,
  LinkPillButton,
  Dropdown,
  DropdownItem,
  DropdownItemLink,
} from "../../core";
import { SearchInput } from "../Inputs";

import logo from "../../assets/images/mentor_white_B.png";
import movillogo from "../../assets/images/mentor_white_A.png";
import Curso from "../../pages/Cursos/Curso";
import "./styles.scss";
import useScrolling from "../../hooks/useScrolling";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const { control, handleSubmit } = useForm();
  const [categorias, setCategorias] = useState([]);
  const { width } = useScreenChange();
  const { scrollY } = useScrolling();
  const [show, setShow] = useState(false);

  const {
    isLoadedState,
    setState,
    setVolatileState,
    isLogin,
    isAdmin,
    Logout,
    CartCount,
    GetCartList,
    GetWishList,
    state: { user, token, rank },
  } = useContext(GlobalContext);
  const fullName = useMemo(
    () => `${user?.names || ""} ${user?.last_name || ""}`.trim(),
    [user]
  );
  const showButton = useMemo(() => {
    return location.pathname === "/";
  }, [location]);

  const classScrolling = useMemo(() => {
    return showButton ? (scrollY >= 358 ? " not_top" : "") : "not_top";
  }, [scrollY, showButton]);
  const handledLogoutout = () => {
    Logout().then((_) => {});
  };
  const handleSearch = ({ q }) => {
    history.push(`/buscar/${q}`);
  };
  const showCart = () => {
    history.push(`/ver_carrito`);
  };
  useEffect(() => {
    if (isLoadedState && isLogin) {
      GetCartList();
      GetWishList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedState, isLogin]);

  useEffect(() => {
    setCategorias(
      new Array(5)
        .fill({})
        .map((m, i) => ({ name: `Categoria ${i + 1}`, id: i + 1 }))
    );
  }, []);
  return (
    <>
      <header className={classScrolling}>
        <Link to="/" className="logo">
          <img
            src={width <= 425 ? movillogo : logo}
            alt=""
            className="left_nav"
          />
        </Link>
        <nav className="center_nav">
          <ul className={`${show ? "show" : ""}`}>
            <li onClick={(_) => setShow(false)}>
              <NavLink exact to="/">
                Inicio
              </NavLink>
            </li>
            <li onClick={(_) => setShow(false)}>
              <NavLink to="/cursos">Cursos</NavLink>
            </li>
            <li onClick={(_) => setShow(false)}>
              <NavLink to="/contacto">Contacto</NavLink>
            </li>
            <li onClick={(_) => setShow(false)}>
              <NavLink to="/quienes_somos">Sobre nosotros</NavLink>
            </li>
          </ul>
        </nav>
        {/* <SearchInput control={control} name="q" onSearch={handleSubmit(handleSearch)} /> */}
        <div className="right_nav">
          {isLogin ? (
            <>
              <div className="cart-shop" onClick={(_) => showCart()}>
                <i className="fa-solid fa-cart-shopping"></i>
                <span>{CartCount}</span>
              </div>

              {!show || width > 768 ? (
                <Dropdown
                  fill="#fff"
                  toggle={
                    <>
                      <>
                        <Avatar
                          src={user.image?.location}
                          name={fullName}
                          size={55}
                        />
                        {width <= 375 ? (
                          <></>
                        ) : (
                          <strong className="rank-points">
                            {" "}
                            {rank.points||0} pts
                          </strong>
                        )}
                      </>
                    </>
                  }
                >
                  {width <= 375 ? (
                    <DropdownItem className={"closable"}> {rank.points} pts</DropdownItem>
                  ) : null}
                  {isAdmin ? (
                    <DropdownItemLink className={"closable"} to={`/Backoffice/Usuarios`}>
                      Back Office
                    </DropdownItemLink>
                  ) : null}
                  {isAdmin ? (
                    <DropdownItemLink className={"closable"} to={`/Backoffice/Perfil`}>
                      Perfil
                    </DropdownItemLink>
                  ) : (
                    <DropdownItemLink className={"closable"} to={`/Perfil`}>Perfil</DropdownItemLink>
                  )}
                  {/* <DropdownItemLink to={`/Kardex`}>Kardex</DropdownItemLink> */}
                  <DropdownItemLink className={"closable"} to={`/wishlist`}>Wishlist</DropdownItemLink>
                  <DropdownItemLink className={"closable"} to={`/Pedidos`}>
                    Ver pedidos
                  </DropdownItemLink>
                  <DropdownItem className={"closable"} onClick={handledLogoutout}>
                    Cerrar sesión
                  </DropdownItem>
                </Dropdown>
              ) : null}
            </>
          ) : (
          <NavLink to="/Login">Login</NavLink>
          )}
          {width <= 768 ? (
            <button className="menu-button" onClick={(_) => setShow(!show)}>
              <i className={`fas fa-${show ? "times" : "bars"}`}></i>
            </button>
          ) : null}
        </div>
      </header>
    </>
  );
};

export default Header;
