import React, { useCallback, useContext, useEffect } from "react";

//Hooks
import { useForm } from "react-hook-form";
import { UserForm } from "../../components/Forms";
import useS3File from "../../hooks/useS3File";

//Data
import GlobalContext from "../../context/GlobalContext";

//Assets
import "./Perfil.scss";
import useS3NestFile from "../../hooks/useS3NestFile";

import Swal from "sweetalert2";

/**
 * @component
 * Component in charge of editing the profile
 */

export default function EditPerfil({ isBackOffice = false }) {
  const {
    state: { user },
    setState,
  } = useContext(GlobalContext);
  const { NestGet, NestPatch, setAppLoading } = useContext(GlobalContext);
  const { control, handleSubmit, setValue } = useForm();

  const { handleDelete: handleDeleteS3 } = useS3File();
  const { handleUpload, handleDelete } = useS3NestFile();

  const fetchData = useCallback(() => {
    NestGet({ schema: "user", id: user._id }).then((res) => {
      if (res.data.id) {
        console.log(res.data);
        const {
          customer_data,
          bill_data,
          phone,
          names,
          last_name,
          mothers_last_name,
          email,
          image,
          tipo_estudiante,
          time_in_platform,
          puesto,
        } = res.data;

        if (res.status === "success") {
          Object.keys({
            customer_data,
            bill_data,
            phone,
            names,
            last_name,
            mothers_last_name,
            email,
            image,
            tipo_estudiante,
            time_in_platform,
            puesto,
          }).forEach((key) => {
            setValue(key, res.data[key]);
          });
          const user = {
            ...res.data,
            _id: res.data.id,
          };
          console.log(user);
          setState({ user: user });
        }
      }
    });
  }, [user._id, NestGet, setValue]);

  const onSubmit = useCallback(
    async (data) => {
      setAppLoading(true);
      console.log(user);
      debugger;
      data.image = await handleUpload(data.image, `mentor/cursos/${user._id}`);
      debugger;
      if (
        user?.image?.location &&
        user.image.location !== data.image.location
      ) {
        if (user?.image?.location?.includes("mentorstore")) {
          await handleDeleteS3(user.image.key);
        } else {
          await handleDelete(user.image.key);
        }
      }
      NestPatch({ schema: "user", body: data, id: user._id })
        .then((res) => {
          if (res.status === "success") {           
              Swal.fire({
                title: "Cambios guardados correctamente",
                icon: "success",
              });
            fetchData();
            setAppLoading(false);
          }
        })
        .catch((err) => { });
    },
    [setAppLoading, user, handleUpload, NestPatch, fetchData]
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (

    <div className="not-container"
      style={isBackOffice ? {} : { paddingTop: "55px" }}
    >
      <UserForm control={control} isBackOfficeEditing={false} onSubmit={handleSubmit(onSubmit)} />
    </div>

  );
}
