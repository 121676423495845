import React, { useMemo, useCallback, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  InputC,
  SelectC,
  SwitchC,
  TextAreaC,
  Title,
  DateInputMentor,
} from "../../core";
import {
  countries,
  rulesCURP,
  rulesEmail,
  rulesRFC,
  states as states_,
  directions,
  departments,
  zones,
  usoCDFI,
  rulesRequired,
  regimens,
} from "../../Constants";
import { useWatch, useForm, useController, Controller } from "react-hook-form";
import AvatarPickerC from "../../core/Forms/Inputs/AvatarPickerC";
import useFormatter from "../../hooks/useFormatter";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import "./UserForm.scss"
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import DialogForgottenPasword from "../Dialogs/DialogForgottenPasword";
import Swal from "sweetalert2";
import GlobalContext from "../../context/GlobalContext";

/**
 * @component
 * Component responsible for displaying and edit user info
 */
const UserForm = ({ control, onSubmit, isBackOfficeEditing, profilePhoto, initialLoad }) => {
  const {
    NestPatch,
    NestPost,
    setAppLoading,
  } = useContext(GlobalContext);
  const { initialControl, handleSubmit, setValue, reset } = useForm(control);

  const user = useWatch({ control });
  const fullName = useMemo(
    () =>
      [user.names, user.last_name, user.mothers_last_name]
        .filter((f) => f)
        .map((m) => m.trim())
        .join(" "),
    [user]
  );
  const tipoEstudiante = useWatch({
    control,
    name: "tipo_estudiante",
  });

  const isExterno = tipoEstudiante === "Externo";
  const isLoad = tipoEstudiante === "Externo" || tipoEstudiante === "Interno";
  const country = useWatch({ control, name: "customer_data.country" });
  const states = useMemo(() => {
    const countryId = country
      ? countries.find((f) => f.value === country).id
      : undefined;
    return states_[countryId];
  }, [country]);
  const persona = useWatch({ control, name: "bill_data.persona" });
  const [visible, setVisible] = useState(false);

  const onSubmitChangePassword = useCallback(
    async (data) => {
      // setAppLoading(true);

      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 2800,
      });

      if (!data.new_password) {
        Toast.fire({
          title: "No se permiten contraseñas en blanco.",
          icon: "error",
        });
        return;
      }

      if (data.new_password != data.new_password_confirmation) {
        Toast.fire({
          title: "Las contraseñas no coinciden.",
          icon: "error",
        });
        return;
      }

      NestPatch({
        schema: `auth/change_password`,
        id: user._id,
        body: {
          password: data.old_password,
          new_password: data.new_password,
        },
      })
        .then((res) => {
          if (res.status == "success") {
            Swal.fire({
              title: "Contraseña cambiada con éxito.",
              icon: "success",
            });
          } else {
            Toast.fire({
              title: "La contraseña que ingresaste es incorrecta.",
              icon: "error",
            });
          }
          setAppLoading(false);
          console.log(res);
        })
        .catch((err) => { });
    },
    [setAppLoading, NestPost]
  );

  return (
    <>
      <div className="UserFormContainer">

        <div className="d-flex flex-wrap mnt-row">
          {isLoad || initialLoad
            ? (
              <>
                <div className="column menu">
                  <Box>
                    <List sx={{ marginLeft: "50px" }} dense="true">
                      <ListItem >
                        <ListItemButton>
                          <ListItemText
                            primary={<Typography variant="subtitle1" style={{ color: '#0000FF' }}>Perfil</Typography>} />
                        </ListItemButton>
                      </ListItem>
                      <ListItem>
                        <ListItemButton>
                          <ListItemText
                            primary={<Typography variant="subtitle1" style={{ color: '#A9A9A9' }}>Wishlist</Typography>} />
                        </ListItemButton>
                      </ListItem>
                      <ListItem>
                        <ListItemButton>
                          <ListItemText
                            primary={<Typography variant="subtitle1" style={{ color: '#A9A9A9' }}>Ver pedidos</Typography>} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Box>
                </div>
                <div className="scroll column details"
                  style={{
                    maxHeight: "calc(80vh - 15px)",
                    overflowX: "hidden"
                  }}
                >

                  {/* Perfil */}
                  <div className="d-flex flex-wrap mnt-row" >
                    <div className="column full">
                      <span className="h1-user-form">Perfil</span>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap mnt-row">
                    <div className="column" style={{ width: "10%" }}>
                      <AvatarPickerC
                        name="image"
                        control={control}
                        size={70}
                        userName={fullName}
                      />
                    </div>
                    <div className="column" style={{ width: "60%" }}>
                      <div>
                        <span className="h2-user-form">{fullName}</span>
                      </div>
                      <div>
                        <span>
                          {(user.customer_data !== undefined && user.customer_data.job_title !== undefined)
                            ? user.customer_data.job_title
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="column" style={{ width: "20%", marginRight: "10px" }}>
                      <div className="d-flex flex-wrap mnt-row">
                        <div className="column">
                          <PersonIcon sx={{ fontSize: 25 }}></PersonIcon>
                        </div>
                        <div className=" column h3-user-form" style={{ paddingTop: "5px" }}>Usuario {tipoEstudiante}</div>
                      </div>

                      <div className="d-flex flex-wrap mnt-row">
                        <div className="h3-user-form">
                          <span>
                            {(user.email !== undefined)
                              ? user.email
                              : "N/A"}
                          </span>
                        </div>
                      </div>


                    </div>
                  </div>

                  {/* Datos generales */}
                  <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                    <div className="column full mnt-row-spaced">
                      <span className="h2-user-form">Datos generales</span>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                    <div className="column" style={{ marginRight: "60px" }} >
                      <MentorTextField
                        disable={isBackOfficeEditing}
                        required
                        name="names"
                        label="Nombre Completo"
                        value={user.names}
                        control={control}
                        rules={{ ...rulesRequired }}
                      />
                    </div>
                    <div className="column" style={{ marginRight: "60px" }}>
                      <MentorTextField
                        disable={isBackOfficeEditing}
                        required
                        name="last_name"
                        label="Apellido paterno"
                        value={user.last_name}
                        control={control}
                        rules={{ ...rulesRequired }}
                      />
                    </div>
                    <div className="column">
                      <MentorTextField
                        disable={isBackOfficeEditing}
                        required
                        name="mothers_last_name"
                        label="Apellido materno"
                        value={user.mothers_last_name}
                        control={control}
                        rules={{}}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                    <div className="column" style={{ marginRight: "60px" }}>
                      <MentorTextField
                        disable={isBackOfficeEditing}
                        required
                        name="phone"
                        label="Celular"
                        value={user.phone}
                        control={control}
                        rules={{ ...rulesRequired }}
                      />
                    </div>
                    <div className="column" style={{ marginRight: "60px" }}>
                      <MentorTextField
                        disable={isBackOfficeEditing}
                        required
                        name="customer_data.rfc"
                        label="RFC"
                        value={isLoad ? user.customer_data.rfc : ''}
                        control={control}
                        rules={{ ...rulesRequired, ...rulesRFC }}
                        textTransform="uppercase"
                      />
                    </div>
                    <div className="column">
                      <MentorTextField
                        disable={isBackOfficeEditing}
                        required
                        name="customer_data.curp"
                        label="CURP"
                        value={isLoad ? user.customer_data.curp : ''}
                        control={control}
                        rules={{ ...rulesRequired, ...rulesCURP }}
                        textTransform="uppercase"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                    <div className="column" style={{ marginRight: "60px" }}>
                      <MentorTextField
                        disable={isBackOfficeEditing}
                        required
                        name="email"
                        label="Email"
                        value={user.email}
                        control={control}
                        rules={{ ...rulesRequired, ...rulesEmail }}
                      />
                    </div>
                    <div className="column" style={{ marginRight: "60px" }}>
                      <MentorTextField
                        disable={isBackOfficeEditing}
                        required
                        name="customer_data.job_title"
                        label="Puesto"
                        value={isLoad ? user.customer_data.job_title : ''}
                        control={control}
                        rules={{ ...rulesRequired }}
                      />
                    </div>
                    <div className="column" >
                      <DateInputMentor
                        required
                        control={control}
                        disable={isBackOfficeEditing}
                        label="Fecha de nacimiento"
                        name="customer_data.birthdate"
                        maxDate="now"
                        rules={{ ...rulesRequired }}
                      />
                    </div>
                  </div>



                  {/* Direccion */} {/* Externo/Interno */}
                  <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                    <div className="column full mnt-row-spaced">
                      <span className="h2-user-form">Dirección</span>
                    </div>
                  </div>
                  {isExterno || initialLoad
                    ?
                    <>
                      <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                        <div className="column" style={{ marginRight: "60px" }}>
                          <MentorSelect
                            disable={isBackOfficeEditing}
                            required
                            label={"Pais"}
                            name="customer_data.country"
                            control={control}
                            value={isLoad ? user.customer_data.country : ''}
                            options={countries}
                            rules={{ ...rulesRequired }}
                          />
                        </div>
                        <div className="column" style={{ marginRight: "60px" }}>
                          <MentorSelect
                            disable={isBackOfficeEditing}
                            required
                            label={"Estado"}
                            name="customer_data.state"
                            control={control}
                            value={isLoad ? user.customer_data.state : ''}
                            options={states}
                            rules={{ ...rulesRequired }}
                          />
                        </div>
                        <div className="column">
                          <MentorTextField
                            disable={isBackOfficeEditing}
                            required
                            name="customer_data.municipality"
                            label="Municipio"
                            value={isLoad ? user.customer_data.municipality : ''}
                            control={control}
                            rules={{ ...rulesRequired }}
                          />
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                        <div className="column" style={{ marginRight: "60px" }}>
                          <MentorSelect
                            disable={isBackOfficeEditing}
                            required
                            label="Dirección"
                            name="customer_data.direction"
                            control={control}
                            value={isLoad ? user.customer_data.direction : ''}
                            options={directions}
                            rules={{ ...rulesRequired }}
                          />
                        </div>
                        <div className="column" style={{ marginRight: "60px" }}>
                          <MentorSelect
                            disable={isBackOfficeEditing}
                            required
                            label="Zona"
                            name="customer_data.zone"
                            control={control}
                            value={isLoad ? user.customer_data.zone : ''}
                            options={zones}
                            rules={{ ...rulesRequired }}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                        <div className="column" >
                          <MentorTextField
                            disable={isBackOfficeEditing}
                            required
                            name="customer_data.domicilio"
                            label="Domicilio"
                            value={isLoad ? user.customer_data.domicilio : ''}
                            control={control}
                            rules={{ ...rulesRequired }}
                            width={"960px"}
                          />
                        </div>
                      </div>
                    </>
                  }


                  {/* Empresa */} {/* Externo/Interno */}
                  <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                    <div className="column full mnt-row-spaced">
                      <span className="h2-user-form">Empresa</span>
                    </div>
                  </div>
                  {isExterno || initialLoad
                    ?
                    <>
                      <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                        <div className="column" style={{ marginRight: "60px" }}>
                          <MentorTextField
                            disable={isBackOfficeEditing}
                            required
                            name="customer_data.job"
                            label="Ocupación"
                            value={isLoad ? user.customer_data.job : ''}
                            control={control}
                            rules={{ ...rulesRequired }}
                          />
                        </div>
                        <div className="column" style={{ marginRight: "60px" }}>
                          <MentorTextField
                            disable={isBackOfficeEditing}
                            required
                            name="customer_data.business"
                            label="Empresa"
                            value={isLoad ? user.customer_data.business : ''}
                            control={control}
                          />
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                        <div className="column" style={{ marginRight: "60px" }}>
                          <MentorSelect
                            disable={isBackOfficeEditing}
                            required
                            label="Departamento"
                            name="customer_data.department"
                            control={control}
                            value={isLoad ? user.customer_data.department : ''}
                            options={departments}
                            rules={{ ...rulesRequired }}
                          />
                        </div>
                        <div className="column" style={{ marginRight: "60px" }}>
                          <MentorTextField
                            disable={isBackOfficeEditing}
                            name="customer_data.udn"
                            label="UDN"
                            value={isLoad ? user.customer_data.udn : ''}
                            control={control}
                          />
                        </div>
                        <div className="column">
                          <MentorTextField
                            disable={isBackOfficeEditing}
                            name="customer_data.direct_Boss"
                            label="Jefe directo"
                            value={isLoad ? user.customer_data.direct_Boss : ''}
                            control={control}
                          />
                        </div>
                      </div>
                    </>
                  }

                  {/* Contraseña */}
                  {isBackOfficeEditing
                    ? <div></div>
                    :
                    <>
                      <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                        <div className="column full">
                          <span className="h2-user-form" style={{ fontSize: "25px" }}>Contraseña</span>
                        </div>
                        <div className="column full">
                          <span className="h3-user-form">Cambia tu contraseña cuando lo necesites. Por seguridad, te recomendamos que este compuesta de letras y números.</span>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">

                        {initialLoad ? (
                          <></>
                        )
                          : (
                            <div className="column" style={{ marginRight: "60px" }}>
                              <MentorTextField
                                name="password"
                                label="Contraseña anteriror"
                                InputProps={{
                                  sx: {
                                    borderRadius: 23
                                  }
                                }}
                                control={control}
                                rules={{}}
                                type="password"
                              />
                            </div>
                          )
                        }
                        <div className="column" style={{ marginRight: "60px" }}>
                          <MentorTextField
                            name="new_password"
                            label="Nueva contraseña"

                            control={control}
                            rules={{}}
                            type="password"
                          />
                        </div>
                        <div className="column">
                          <MentorTextField
                            name="new_password_confirmation"
                            label="Confirmar nueva contraseña"
                            InputProps={{
                              sx: {
                                borderRadius: 23
                              }
                            }}
                            control={control}
                            rules={{}}
                            type="password"
                          />
                        </div>
                      </div>
                      {initialLoad
                        ?
                        <></>
                        :
                        <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                          <div className="column" style={{ marginLeft: "-5px", marginRight: "50px" }}>
                            <Button className="mentor_button" onClick={() => onSubmitChangePassword} >
                              Guardar Nueva Contraseña
                            </Button>
                          </div>
                          <div className="column" style={{
                            marginRight: "50px", marginTop: "15px",
                            cursor: "pointer"
                          }}
                            onClick={() => setVisible(true)}
                          >
                            <a style={{ color: "blue" }}>¿Olvidaste tu contraseña?</a>
                          </div>
                          <DialogForgottenPasword visible={visible} setVisible={setVisible} />
                        </div>
                      }
                    </>
                  }

                  {/* Datos de Facturacion */}
                  <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                    <div className="column full">
                      <span className="h2-user-form" style={{ fontSize: "25px" }}>Datos de Facturación</span>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                    <div className="column" style={{ marginRight: "60px" }}>
                      <MentorSelect
                        disable={isBackOfficeEditing}
                        required
                        label="Tipo de persona"
                        name="bill_data.persona"
                        control={control}
                        options={[
                          { label: "Sin datos", value: "Sin datos" },
                          { label: "Persona física", value: "Física" },
                          { label: "Persona moral", value: "Moral" },
                        ]}
                        value={(isLoad && user.bill_data !== undefined) ? user.bill_data.persona : 'Sin datos'}
                        rules={{ ...rulesRequired }}
                      />
                    </div>
                  </div>

                  {/* Persona Fisica: Datos de Facturacion */}
                  {(persona === "Física" || persona === "Moral") ? (
                    <>
                      <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                        <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                          <div className="column" style={{ marginRight: "60px" }} >
                            {persona === 'Física'
                              ? (
                                <MentorTextField
                                  disable={isBackOfficeEditing}
                                  required
                                  name="bill_data.full_name"
                                  label="Nombre Completo"
                                  value={isLoad ? user.bill_data.full_name : ''}
                                  control={control}
                                  rules={{ ...rulesRequired }}
                                />
                              )
                              : (
                                <MentorTextField
                                  disable={isBackOfficeEditing}
                                  required
                                  name="bill_data.business_name"
                                  label="Razón Social"
                                  value={isLoad ? user.bill_data.business_name : ''}
                                  control={control}
                                  rules={{ ...rulesRequired }}
                                />
                              )
                            }

                          </div>
                          <div className="column" style={{ marginRight: "60px" }}>
                            <MentorTextField
                              disable={isBackOfficeEditing}
                              required
                              name="bill_data.rfc"
                              label="RFC"
                              value={user.bill_data.rfc}
                              control={control}
                              rules={{ ...rulesRequired }}
                              textTransform="uppercase"
                            />
                          </div>
                          <div className="column">
                            <MentorSelect
                              disable={isBackOfficeEditing}
                              required
                              label={"Régimen"}
                              name="bill_data.regimen"
                              control={control}
                              value={isLoad ? user.bill_data.regimen : ''}
                              options={regimens}
                              rules={{ ...rulesRequired }}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
                          <div className="column" style={{ marginRight: "60px" }} >
                            <MentorTextField
                              disable={isBackOfficeEditing}
                              required
                              name="bill_data.cp"
                              label="CP"
                              value={isLoad ? user.bill_data.cp : ''}
                              control={control}
                              rules={{ ...rulesRequired }}
                            />
                          </div>
                          <div className="column" style={{ marginRight: "60px" }}>
                            <MentorSelect
                              disable={isBackOfficeEditing}
                              required
                              label={"Uso de CFDI"}
                              name="bill_data.cfdi_use"
                              control={control}
                              value={isLoad ? user.bill_data.regimen : ''}
                              options={usoCDFI
                                .filter((ff) => ff.persona.includes(persona))
                                .map((m) => ({ ...m, value: m.use }))}
                              rules={{ ...rulesRequired }}
                            />
                          </div>
                          <div className="column">
                            <MentorTextField
                              disable={isBackOfficeEditing}
                              required
                              name="bill_data.email"
                              label="Correo para envío de factura"
                              value={isLoad ? user.bill_data.email : ''}

                              control={control}
                              rules={{ ...rulesEmail, ...rulesRequired }}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                          <div className="column" >
                            <MentorTextField
                              disable={isBackOfficeEditing}
                              required
                              name="bill_data.tax_residence"
                              label="Domicilio Fiscal"
                              value={isLoad ? user.bill_data.tax_residence : ''}
                              control={control}
                              rules={{ ...rulesRequired }}
                              width={"960px"}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (null)}
                </div>
                {/* Botton Guardar */}
                {isBackOfficeEditing
                  ? <div></div>
                  :
                  <>
                    <div style={{ width: "100%", textAlign: "right", marginRight: "40px", marginTop: "20px", marginBottom: "-20px" }}>
                      <Button
                        className="mentor_button btn-hide-profile"
                        onClick={onSubmit}
                      >
                        Guardar
                      </Button>
                    </div>
                  </>
                }
              </>
            ) : (
              <></>
            )}
        </div>
      </div >
    </>
  );
};

const StyledMentorTextField = styled(TextField)({
  'label': {
    color: '#333',
    font: "11px Montserrat",
    letterSpacing: "1px"

  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#999ea5',

    },
  },
  '& label.Mui-focused': {
    color: '#333',
    fontSize: "12px"
  },
  '& label.Mui-disabled': {
    WebkitTextFillColor: "#000000",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000000",
  },

});

const MentorTextField = ({ required, name, label, value, control, rules, width, type, textTransform, disable }) => {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <StyledMentorTextField
      required={required}
      disabled={disable}
      type={type}
      defaultValue={value}
      label={label}
      InputProps={{
        style: {
          borderRadius: 23,
          width: width,
          textTransform: textTransform
        }
      }}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      inputRef={field.ref}
      error={invalid ? true : false}
      helperText={invalid ? error.message : ''}
    />
  );

}

const MentorDateField = ({ required, name, label, value, control, rules, disable }) => {

  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules,
  });

  return (

    <StyledMentorTextField
      type="date"
      disabled={disable}
      required={required}
      defaultValue={value}
      InputProps={{
        sx: {
          borderRadius: 23,
          height: 35,
          //     <div class="MuiFormControl-root MuiTextField-root css-1h6mkeh-MuiFormControl-root-MuiTextField-root" style="
          //     width: 280px;
        }
      }}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      inputRef={field.ref}
      error={invalid ? true : false}
      helperText={invalid ? 'Campo requerido' : label}
    />
  );

}

const MentorSelect = ({ required, name, label, value, rules, control, options, disable }) => {

  return (
    <Controller
      readOnly={disable}
      name={name}
      id={name}
      control={control}
      rules={rules}
      defaultValue={null}
      render={({
        field: { ref, ...field },
        fieldState: { error, invalid }
      }) => {
        return (
          <Autocomplete
            {...field}
            disabled={disable}
            options={options}
            sx={{
              width: 280,
              '& .MuiInputBase-root': {
                width: 280,
                height: 35,
                borderRadius: 93,
                borderColor: '#999ea5',

              },
              'label': {
                color: '#999ea5',
                font: "10px Montserrat",
                letterSpacing: "1px"
              },
              'input': {
                marginTop: -1
              },

            }}
            renderInput={(params) => (
              <StyledMentorTextField
                {...params}
                disabled={disable}
                inputRef={ref}
                label={label}
                error={invalid}
                helperText={error?.message}
                required={rules.required}
              />
            )}
            onChange={(e, value) => field.onChange(value["value"])}
          />
        );
      }}
    />
  );
}

MentorTextField.defaultProps = {
  name: 'input',
  rules: {},
};


UserForm.propTypes = {
  control: PropTypes.object,
  onSubmit: PropTypes.func,
};

/**
 * @component
 * Component responsible for displaying and edit billing info
 */

export const UserBillForm = ({ control }) => {
  const { OnlyNumber } = useFormatter();
  const persona = useWatch({ control, name: "bill_data.persona" });
  const rfc = useWatch({ control, name: "customer_data.rfc" });
  const isMoral = persona === "Moral";
  const user = useWatch({ control });
  const fullName = useMemo(
    () =>
      [user.names, user.last_name, user.mothers_last_name]
        .filter((f) => f)
        .map((m) => m.trim())
        .join(" "),
    [user]
  );
  return (
    <>
      <Title>Datos de facturación</Title>
      <SwitchC
        control={control}
        label="Tipo de persona"
        name="bill_data.persona"
        options={[
          { label: "Sin datos", value: "" },
          { label: "Persona física", value: "Física" },
          { label: "Persona moral", value: "Moral" },
        ]}
      />
      {persona ? (
        <div className="d-flex flex-wrap">
          <div className="w-33">
            {isMoral ? (
              <InputC
                control={control}
                name="bill_data.business_name"
                label="Razón Social"
                rules={{ ...rulesRequired }}
              />
            ) : (
              <InputC
                control={control}
                name="bill_data.full_name"
                label="Nombre completo"
                defaultValue={fullName}
                rules={{ ...rulesRequired }}
              />
            )}
          </div>
          <div className="w-33">
            <InputC
              control={control}
              name="bill_data.rfc"
              label="RFC"
              setFormate={(v) => v.toUpperCase()}
              defaultValue={rfc}
              rules={{ ...rulesRequired, ...rulesRFC }}
            />
          </div>
          <div className="w-33">
            <SelectC
              control={control}
              label="Régimen"
              name="bill_data.regimen"
              options={regimens}
              rules={{ ...rulesRequired }}
            />
          </div>
          <div className="w-33">
            <InputC
              control={control}
              label="CP"
              maxLength={5}
              name="bill_data.cp"
              setFormate={(v) => OnlyNumber(v)}
            />
          </div>
          <div className="w-33">
            <SelectC
              control={control}
              label="Uso de CFDI"
              name="bill_data.cfdi_use"
              options={usoCDFI
                .filter((ff) => ff.persona.includes(persona))
                .map((m) => ({ ...m, value: m.use }))}
              rules={{ ...rulesRequired }}
            />
          </div>
          <div className="w-33">
            <InputC
              control={control}
              label="Correo para envío de factura"
              name="bill_data.email"
              type="email"
              rules={{ ...rulesEmail, ...rulesRequired }}
            />
          </div>
          <div className="w-100">
            <TextAreaC
              control={control}
              name="bill_data.tax_residence"
              label="Domicilio Fiscal"
              rules={{ ...rulesRequired }}
              rows={1}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserForm;
