import React, {useCallback, useEffect, useState} from 'react'

const useScrolling = () => {
    const [scroll, setScroll] = useState({scrollY: window.scrollY, scrollX:window.scrollX})
    const scrollCallback = useCallback(
      () => {
        setScroll({scrollY: window.scrollY, scrollX:window.scrollX})
      },
      [],
    )
    
    useEffect(() => {
        window.addEventListener('scroll', scrollCallback)
        return ()=>{
            window.removeEventListener('scroll', scrollCallback)
        }
    }, [])
    
  return scroll
}

export default useScrolling