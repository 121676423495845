import React from "react";
import PropTypes from "prop-types";

const CollapserArrowSVG = ({ height, width, fill, onClick, className }) => {
  return (
    <svg
      name={className}
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 8.73"
      className={className}
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path
            style={{ fill }}
            d="M0,.58A.59.59,0,0,0,0,.5.72.72,0,0,1,1.24.22c.41.39.8.79,1.2,1.19L7.87,6.85,8,7a1.11,1.11,0,0,1,.11-.16L14.7.27A.73.73,0,0,1,15.4,0a.74.74,0,0,1,.6.56V.86a1.26,1.26,0,0,1-.35.53Q12.11,4.92,8.59,8.45a.71.71,0,0,1-.74.25.91.91,0,0,1-.43-.26q-2-2-4.07-4.06l-3-3A1.26,1.26,0,0,1,0,.86Z"
          />
        </g>
      </g>
    </svg>
  );
};

CollapserArrowSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

CollapserArrowSVG.defaultProps = {
  height: 15,
  width: 15,
  fill: "#fff",
};

export default CollapserArrowSVG;
