import React from "react";
import PropTypes from "prop-types";

const ServiciosSVG = ({ height, width, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.96 21.97"
      fill="#fff"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M11.67,22H10.3l-.59-.08a10.69,10.69,0,0,1-7.33-4.11A10.61,10.61,0,0,1,.22,8.89a10.61,10.61,0,0,1,4.59-7,10.64,10.64,0,0,1,8-1.77,10.53,10.53,0,0,1,5.47,2.62A10.77,10.77,0,0,1,21.8,9.09c.07.4.11.81.16,1.21v1.37a1,1,0,0,0,0,.17,10.57,10.57,0,0,1-1.5,4.73,10.86,10.86,0,0,1-7.55,5.23C12.48,21.87,12.07,21.91,11.67,22Zm8.92-11A9.61,9.61,0,1,0,11,20.59,9.61,9.61,0,0,0,20.59,11Z" />
          <path d="M10.3,10.3V6.21A.68.68,0,0,1,11.65,6a1.23,1.23,0,0,1,0,.27v4h4.08a.68.68,0,0,1,.7.52.65.65,0,0,1-.3.74,1,1,0,0,1-.45.11h-4v4.1a.67.67,0,0,1-.49.67.64.64,0,0,1-.75-.27,1,1,0,0,1-.13-.46c0-1.26,0-2.52,0-3.77v-.27H6.23a.69.69,0,1,1,0-1.37H10.3Z" />
        </g>
      </g>
    </svg>
  );
};

ServiciosSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

ServiciosSVG.defaultProps = {
  height: 22,
  width: 22,
};

export default ServiciosSVG;
