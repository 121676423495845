import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import CollapserArrowSVG from '../../../assets/svgs/CollapserArrowSVG'
import './Dropdown.scss'


const Dropdowns = ({toggle,children, onToggle, style, fill, className}) => {
    const refDropdowns = useRef(null)
    const [show, setShow] = useState(false)
    const handleToggle = (e) =>{
        e.preventDefault()
        setShow(!show)
        onToggle(!show)
    }
    const handleClose = useCallback(
      (e) => {
        try {
          if( e.srcElement.className.includes('closable') || !e.path.some(s => s.isSameNode?s.isSameNode(refDropdowns.current):false)){
            onToggle(false)
            setShow(false)
          }
        } catch (error) { 
        }
      },
      [onToggle],
    )
    useEffect(() => {
        document.addEventListener("click", handleClose);
      return () => {
        document.removeEventListener("click", handleClose);
      };
    }, [handleClose])
    return (
        <div className={`Dropdowns ${className||''}`}  ref={refDropdowns}>
            <div className={`dropdowns-toggle ${show?'dropdowns-toggle__show':'dropdowns-toggle__close'}`} onClick={handleToggle}>
                {toggle}
                <CollapserArrowSVG fill={fill} />
            </div>
            <ul className={`dropdowns-list ${show?'dropdowns-list__show':'dropdowns-list__close'}`} style={style}>{children}</ul>
        </div>
    )
}

Dropdowns.propTypes = {
  fill: PropTypes.string,
  onToggle: PropTypes.func
}

Dropdowns.defaultProps = {
  fill: "#000",
  onToggle: ()=>{}
}

export default Dropdowns
