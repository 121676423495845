import React from "react";
import PropTypes from "prop-types";

const CategorySVG = ({ height, width, fill, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 257 256.51"
      fill={fill}
    >
      <g>
        <g>
          <path d="M55.51,0c10.1,0,20.2-.02,30.29,0,15.05,.04,25.28,10.2,25.3,25.18,.03,19.91,.04,39.81,0,59.72-.03,14.82-10.24,25.06-25.13,25.11-20.28,.06-40.56,.06-60.84,0C10.18,109.98,.07,99.86,.03,84.96c-.04-19.99-.05-39.98,0-59.97C.07,10.1,10.25,.05,25.22,.01c10.1-.02,20.2,0,30.29,0Zm-.09,18.49c-9.93,0-19.85,0-29.78,0-5.11,0-7.07,1.96-7.08,7.07,0,19.65,0,39.3,0,58.94,0,4.98,1.96,6.99,6.89,7,20.02,.02,40.04,.01,60.06,0,5.03,0,6.87-1.88,6.88-6.94,0-19.73,0-39.47,0-59.2,0-4.99-1.89-6.87-6.95-6.87-10.01-.01-20.02,0-30.03,0Z" />
          <path d="M201.3,256.5c-10.01,0-20.03,.02-30.04,0-15.18-.04-25.36-10.21-25.37-25.37-.02-19.99-.05-39.98,.01-59.97,.04-12.51,8.16-22.24,20.23-24.37,1.58-.28,3.21-.3,4.82-.3,20.37-.02,40.73-.06,61.1,0,12.68,.04,22.23,7.77,24.55,19.68,.24,1.24,.34,2.52,.34,3.79,.02,21.01,.15,42.02-.04,63.02-.11,11.74-8.23,21.02-19.72,23.16-1.82,.34-3.71,.35-5.57,.36-10.1,.03-20.2,.02-30.29,.02Zm.03-18.47c10.1,0,20.19,.01,30.29,0,4.77,0,6.79-2.04,6.8-6.83,.01-19.82,.01-39.64,0-59.46,0-4.65-2.03-6.74-6.63-6.74-20.19-.01-40.39,0-60.58,.04-4.7,0-6.62,1.97-6.62,6.67-.01,19.82,0,39.64,0,59.46,0,5.01,1.87,6.85,6.96,6.85,9.93,.01,19.85,0,29.78,0Z" />
          <path d="M55.84,256.5c-10.1,0-20.19,.02-30.29,0-15.03-.04-25.27-10.22-25.29-25.19-.03-19.9-.03-39.81,0-59.71,.03-14.82,10.24-25.06,25.13-25.11,20.28-.06,40.56-.06,60.84,0,14.98,.04,25.06,10.12,25.09,25.07,.05,19.99,.06,39.98,0,59.97-.04,14.89-10.22,24.94-25.18,24.98-10.1,.03-20.19,0-30.29,0Zm-36.89-55.3c0,9.99,0,19.99,0,29.98,0,4.86,1.91,6.81,6.75,6.82,20.11,.02,40.21,.02,60.32,0,4.72,0,6.76-2.1,6.76-6.87,.01-19.73,0-39.47,0-59.2,0-4.94-1.99-6.93-6.94-6.93-20.02-.01-40.04-.01-60.07,0-4.9,0-6.82,1.92-6.82,6.74-.01,9.82,0,19.65,0,29.47Z" />
          <path d="M257,54.99c-.15,30.72-25,55.21-55.83,55.02-29.98-.18-54.55-25.09-54.35-55.12C147.02,24.07,171.62-.05,202.73,.06c29.77,.1,54.42,25.05,54.27,54.93Zm-91.57-.04c0,20.09,16.25,36.45,36.27,36.53,20.27,.08,36.81-16.39,36.74-36.6-.06-19.6-16.64-36.3-36.08-36.34-20.41-.05-36.93,16.25-36.93,36.42Z" />
        </g>
      </g>
    </svg>
  );
};

CategorySVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

CategorySVG.defaultProps = {
  height: 26,
  width: 26,
  fill: "#222",
};

export default CategorySVG;
