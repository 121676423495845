import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import StripeFormModal from "../../components/Forms/Stripe";
import CarritoItem from "./CarritoItem";
import moment from "moment";
import { Helmet } from "react-helmet";

export default function CarritoCompras() {
  const {
    CartList,
    state: { user },
    NestPost,
    GetWishList,
    GetCartList,
  } = useContext(GlobalContext);
  const [TotalPrice, SetTotalPrice] = useState(0);
  const [SStripe, setSStripe] = useState(false);
  const [buyedCourses, setBuyedCourses] = useState([]);

  const HandleShop = () => {
    setSStripe(true);
    //TODOS
    //Limpiar carrito, redireccionar o algo cuando todo sale bien porque ahorita si pasa el pago en cero
  };
  const payed = (stripe) => {
    NestPost({
      schema: "orders",
      body: {
        stripe,
        courses: CartList,
        user_id: user?._id,
        amount: TotalPrice,
        buy_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    })
      .then((res) => {
        //Limpiar carrito, redireccionar o algo cuando todo sale bien porque ahorita si pasa el pago en cero
        GetWishList();
        GetCartList();
      })
      .finally((_) => {
        setSStripe(false);
      });
  };

  useEffect(() => {
    if (CartList.length <= 0) return;
    let sum = 0;
    const buyedCourses = [];
    for (const item of CartList) {
      sum += item.cost;
      if (!item.isBuyable) buyedCourses.push(item);
    }

    SetTotalPrice(sum);
    setBuyedCourses(buyedCourses);
  }, [CartList]);

  return (
    <div className="CarritoCompras">
      <Helmet>
        <title>Carrito | Mentor</title>
      </Helmet>
      <div className="carrito-container">
        <h1 className="title-carrito">Carrito de compras</h1>
        {CartList.length > 0 ? (
          <>
            <StripeFormModal
              onSuccess={payed}
              cursos={CartList}
              activate={SStripe}
              amount={TotalPrice}
              customer_id={user._id}
            />
            <div className="carrito-items">
              {CartList.map((item) => {
                return (
                  <div>
                    <CarritoItem key={item._id} item={item} />
                  </div>
                );
              })}
              <div className="text-right m-5">
                Total de la compra: ${TotalPrice.toFixed(2)}
              </div>
              {buyedCourses.length < 1 ? (
                <button onClick={HandleShop} className="button-cart">
                  Continuar con la compra
                </button>
              ) : (
                <p>
                  Hay productos en tu carrito que ya tienes, remuévelos para
                  continuar
                </p>
              )}
            </div>
          </>
        ) : (
          <div className="carrito-items">
            No hay ningún curso agregado al carrito.
          </div>
        )}
      </div>
    </div>
  );
}
