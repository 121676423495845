import React, { useContext, useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Grid, InputC } from "../../core";
import { useWatch } from "react-hook-form";

const columns = [
  {
    title: "",
    width: 100,
    key: "action",
  },
  {
    title: "Nombre completo",

    key: "names",
  },
  {
    title: "Correo",
    key: "email",
  },
  {
    title: "RFC",
    key: "customer_data.rfc",
  },
  {
    title: "Dirección",
    key: "customer_data.direction",
  },
];

const UserPriceGridManual = ({ control, copy, userType, OnlyNumberF }) => {
  const { NestGet, setAppLoading } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState();
  const [interval, setInterval] = useState(null);
  const query = useWatch({ control, name: `search_manual_${userType}` });

  const fetchData = useCallback(
    (page) => {
      setAppLoading(true);
      NestGet({
        schema: `user/search?limit=10&page=${page}&usrType=${userType}${
          query ? `&query=${query}` : ""
        }`,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
            setPagination(res.pagination);
          }
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading, query]
  );

  useEffect(() => {
    clearTimeout(interval);
    setInterval(
      setTimeout(() => {
        fetchData(1);
      }, 500)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <>
      <div className="w-100">
        <div className="w-100">
          <div className="row">
            <div className="col-md-9">
              <p>{copy}</p>
            </div>
            <div className="col-md-3">
              <InputC
                control={control}
                name={`search_manual_${userType}`}
                placeholder="Buscar"
                className="w-100"
              />
            </div>
          </div>
        </div>

        <Grid
          rowKey="id"
          columns={columns}
          dataSource={data}
          className="table-striped"
          pagination={pagination}
          onChangePage={fetchData}
        >
          <Grid.Column
            name="names"
            context={({ column, row }) => (
              <>
                {row.names} {row.last_name} {row.mothers_last_name}
              </>
            )}
          />
          <Grid.Column
            name="action"
            context={({ column, row, ...info }) => (
              <div className="d-flex">
                <InputC
                  control={control}
                  name={`specialPrices.${row._id}`}
                  setFormate={OnlyNumberF}
                />
              </div>
            )}
          />
        </Grid>
      </div>
    </>
  );
};

export default UserPriceGridManual;
