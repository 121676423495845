import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";
import Swal from "sweetalert2";

//Data
import GlobalContext from "../../context/GlobalContext";

//Hooks
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { rulesRequired } from "../../Constants";
import { Button, ChooseC, FileInputC, TextAreaC } from "../../core";
import useS3File from "../../hooks/useS3File";

//Components
import { DragDropInputOtions } from "../../components/Inputs";
import StripeFormModal from "../../components/Forms/Stripe";

/**
 * @component
 * Component responsible for showing the exam, sending results and deciding if it is possible to pass the course or pay for it again
 */

const Examen = () => {
  const history = useHistory();
  const { control, handleSubmit } = useForm();
  const { handleUpload } = useS3File();
  const {
    NestGet,
    NestPost,
    isLogin,
    state: { user },
  } = useContext(GlobalContext);
  const { sku } = useParams();
  const [loading, setLoading] = useState(false);
  const [listIndex, setListIndex] = useState([]);
  const [rulesAvailable, setRulesAvailable] = useState(true);
  const [cost, setCost] = useState(0);
  const [exam, setExam] = useState(undefined);
  const [countdown, setCountdown] = useState(0);
  const [course, setCourse] = useState(undefined);
  const [showStripe, setShowStripe] = useState(false);

  const padStart = (num) => Math.floor(num).toString().padStart(2, "0");
  const countdownF = useMemo(
    () => moment.duration(countdown, "s"),
    [countdown]
  );

  const quiz = useMemo(() => {
    return exam?.quiz || [];
  }, [exam]);

  const list = useMemo(
    () => (listIndex.length > 0 ? [listIndex[0]] : quiz.map((m, i) => i)),
    [listIndex, quiz]
  );

  const deltaQuiz = useMemo(() => {
    return quiz.length - listIndex.length;
  }, [quiz, listIndex]);

  const leftSwapHandle = useCallback(() => {
    setListIndex(([firsts, ...list]) => [...list, firsts]);
  }, []);

  const onSwapHandle = () => {
    if (listIndex.length) {
      return setListIndex(([firsts, ...list]) => list);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const quiz_data = {};
    for (let key in data) {
      const quest = quiz.find((f) => f._id === key);
      switch (quest.type) {
        case "upload": {
          quiz_data[key] = await handleUpload(
            data[key],
            `mentor/exam/${sku}/${user._id}`
          );
          break;
        }
        case "camp_text": {
          quiz_data[key] = data[key];
          break;
        }
        case "sort": {
          quiz_data[key] = data[key];
          break;
        }
        case "true or false":
        case "options": {
          quiz_data[key] = `${data[key]}`;
          break;
        }
        default:
          break;
      }
    }
    NestPost({
      schema: `cursos/eval_exam/${sku}`,
      body: {
        exam: quiz_data,
      },
    })
      .then((resp) => {
        if (resp.data.approved) {
          Swal.fire({
            icon: "success",
            title: "Pasaste la prueba",
            text: "¡Enhorabuena!",
          });
          history.replace(`/diplomado/${sku}`);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops... No pasaste la prueba",
            text: "¡Suerte para la proxima!",
          });

          history.replace(`/cursos/`);
        }
      })
      .catch((err) => console.log(err))
      .finaly(() => setLoading(false));
  };

  const fetchData = useCallback(() => {
    NestPost({
      schema: `cursos/exam/${sku}`,
    }).then((res) => {
      try {
        if (res.status === "success") {
          if (res?.data?.reload) {
            if (res?.data?.finished) {
              const Toast = Swal.mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3400,
              });
              Toast.fire({
                icon: "success",
                title: "Felicidades, terminaste el curso.",
              }).then(() => {
                history.replace(res.data.reload);
              });
            } else {
              return history.replace(res.data.reload);
            }
          }
          if (res?.data?.pay_again) {
            setCost(res.data.cost || 10);
            setShowStripe(true);
            return setCourse(res.data.course);
          }
          setExam(res.data);
          setListIndex(res?.data?.quiz.map((m, i) => i));
          setCountdown(res.data.time * 60);
        } else {
          history.replace(`/cursos/${sku}`);
        }
      } catch (ex) {
        history.replace(`/cursos/${sku}`);
      }
    });
  }, [NestPost, sku, history]);

  const payed = (stripe) => {
    if (!course) return;
    NestPost({
      schema: "orders",
      body: {
        stripe,
        courses: course?._id ? [course] : [],
        user_id: user?._id,
        amount: course?.cost,
        buy_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    })
      .then((res) => {
        setShowStripe(false);
        fetchData();
      })
      .finally((_) => {
        setCost(false);
      });
  };

  useEffect(() => {
    const inter = setTimeout(() => {
      if (countdown > 0) {
        setCountdown((time) => time - 1);
      } else if (exam) {
        setRulesAvailable(false);
        handleSubmit(onSubmit)();
      }
    }, 1000);
    return () => {
      clearTimeout(inter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);
  useEffect(() => {
    fetchData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PageQuiz">
      {isLogin ? (
        <StripeFormModal
          onSuccess={payed}
          cursos={[course]}
          activate={showStripe}
          amount={cost}
          customer_id={user._id}
          notClosable
        />
      ) : null}
      {exam ? (
        <>
          <div className="contain-title">
            <div className="Quiz-title">
              <h1 className="Quiz-title_text">Examen {exam?.name}</h1>
            </div>
          </div>
          <div className="contain-questions">
            <div className="header-questions">
              <div className="title-questions">
                <h4 className="text-right">
                  {deltaQuiz}/{quiz?.length || 0}
                </h4>
              </div>
              {/* <div className='timeline-questions'>
              Pagina 1 de 5
            </div> */}
            </div>
            <Questions
              quiz={quiz}
              control={control}
              list={list}
              rules={rulesAvailable ? rulesRequired : { required: false }}
            />

            <div className="d-flex justify-content-between align-items-center">
              {listIndex.length > 1 ? (
                <Button onClick={leftSwapHandle}>Saltar Pregunta</Button>
              ) : (
                <div></div>
              )}
              <h3 className="mb-0">
                {countdownF.asHours() >= 1 ? (
                  <>{padStart(countdownF.hours())}:</>
                ) : null}
                {padStart(countdownF.minutes())}:
                {padStart(countdownF.seconds())}
              </h3>
              {loading ? null : listIndex.length > 0 ? (
                <Button onClick={handleSubmit(onSwapHandle)}>Responder</Button>
              ) : (
                <Button onClick={handleSubmit(onSubmit)}>Enviar</Button>
              )}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const Questions = ({ quiz, control, list, rules, readOnly = false }) => {
  return (
    <div className="questions">
      {list.map((index) => {
        const quest = quiz[index];
        return (
          <div className="contain-question" key={quest._id}>
            {quest.type === "options" && (
              <>
                <span className="rounded-number">
                  {quiz.indexOf(quest) + 1}
                </span>
                <span className="question">{quest.question}</span>
                <div className="d-flex fooddp">
                  <div
                    className={`${
                      quest.extra_info ? "answers" : "answers-not choose"
                    }`}
                  >
                    <ChooseC
                      control={control}
                      name={quest._id}
                      rules={rules}
                      className="answer-choose"
                      readOnly={readOnly}
                    >
                      {quest.options.map((answer) => (
                        <ChooseC.Option value={answer.value} key={answer.value}>
                          <div className="contain-answer">
                            <div className="answer">
                              <span className={`square-select`}></span>
                              {answer.label}
                            </div>
                          </div>
                        </ChooseC.Option>
                      ))}
                    </ChooseC>
                  </div>
                  {quest.extra_info && (
                    <div className="text-information">
                      <p>{quest.extra_info}</p>
                    </div>
                  )}
                </div>
              </>
            )}

            {quest.type === "true or false" && (
              <>
                <span className="rounded-number">
                  {quiz.indexOf(quest) + 1}
                </span>
                <span className="question">{quest.question}</span>
                <div className={`d-flex fooddp`}>
                  <div
                    className={`${
                      quest.extra_info ? "answers" : "answers-not choose"
                    }`}
                  >
                    <ChooseC
                      control={control}
                      name={quest._id}
                      rules={rules}
                      className="answer-choose"
                      readOnly={readOnly}
                      pure
                    >
                      {[
                        { value: "true", label: "Verdadero" },
                        { value: "false", label: "Falso" },
                      ].map((answer) => (
                        <ChooseC.Option value={answer.value} key={answer.value}>
                          <div className="contain-answer">
                            <div className="answer">
                              <span className={`square-select`}></span>
                              {answer.label}
                            </div>
                          </div>
                        </ChooseC.Option>
                      ))}
                    </ChooseC>
                  </div>
                  {quest.extra_info && (
                    <div className="text-information">
                      <p>{quest.extra_info}</p>
                    </div>
                  )}
                </div>
              </>
            )}

            {quest.type === "sort" && (
              <>
                <span className="rounded-number">
                  {quiz.indexOf(quest) + 1}
                </span>
                <span className="question">{quest.question}</span>
                <div className="d-flex fooddp">
                  <div
                    className={`sorting ${
                      quest.extra_info ? "answers" : "answers-100"
                    }`}
                  >
                    <DragDropInputOtions
                      control={control}
                      name={quest._id}
                      options={quest.options}
                      readOnly={readOnly}
                    />
                  </div>
                  {quest.extra_info && (
                    <div className="text-information">
                      <p>{quest.extra_info}</p>
                    </div>
                  )}
                </div>
              </>
            )}

            {quest.type === "upload" && (
              <>
                <span className="rounded-number">
                  {quiz.indexOf(quest) + 1}
                </span>
                <span className="question">{quest.question}</span>
                <div className="d-flex fooddp">
                  <div
                    className={`${
                      quest.extra_info ? "answers" : "answers-100"
                    }`}
                  >
                    <div className="contain-answer">
                      <div className="answer_upload">
                        <FileInputC
                          control={control}
                          name={quest._id}
                          rules={rules}
                          readOnly={readOnly}
                        >
                          <i className="fa-solid fa-upload mr-1"></i>Subir
                          archivo
                        </FileInputC>
                      </div>
                    </div>
                  </div>
                  {quest.extra_info && (
                    <div className="text-information">
                      <p>{quest.extra_info}</p>
                    </div>
                  )}
                </div>
              </>
            )}

            {quest.type === "camp_text" && (
              <>
                <span className="rounded-number">
                  {quiz.indexOf(quest) + 1}
                </span>
                <span className="question">{quest.question}</span>
                <div className="d-flex fooddp">
                  <div className="answers">
                    <div className="contain-answer">
                      <TextAreaC
                        className="d-flex"
                        control={control}
                        name={quest._id}
                        rules={rules}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  {quest.extra_info && (
                    <div className="text-information">
                      <p>{quest.extra_info}</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

// {!quest &&
//   <>
//   <h1>Resumen de respuestas</h1>
//     {Respuestas.map((item) =>
//       <>
//         <p className='respuesta'>
//           {item.answer}
//         </p>
//       </>
//     )}
//   </>
// }

export default Examen;
