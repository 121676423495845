import React, { useContext, useEffect, useState } from "react";

//Hooks
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useController, useForm, useWatch } from "react-hook-form";
import { CheckC } from "../../core";
import { Link, useParams } from "react-router-dom";
import { rulesEmail } from "../../Constants";
import useQuery from "../../hooks/useQuery";

//Data
import GlobalContext from "../../context/GlobalContext";

//Assets
import logo from "../../assets/images/mentor_primary_B.png";

/**
 * @component
 * Component responsible for giving a login to update the information
 */

const Login = () => {
  const { queryParams } = useQuery();
  const { Login: Login_, dev, isLogin } = useContext(GlobalContext);
  const { control, handleSubmit, setValue } = useForm();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const onSubmit = handleSubmit((data) => {
    Login_(data)
      .then((res) => {
        if (queryParams?.redirect) {
          setTimeout(() => {
            history.replace(queryParams?.redirect);
          }, 0);
          return;
        }
        history.push("/Backoffice");
      })
      .catch((err) => {});
  });
  useEffect(() => {
    if (dev) {
      setValue("email", "villcio.cruz+admin@gmail.com");
      setValue("password", "1234");
    }

    if (isLogin) {
      history.push("/");
    }
  }, [dev, setValue, isLogin, history]);

  return (
    <div className="col-lg-12 align-self-center pad-0">
      <div className="form-section align-self-center">
        <div className="clearfix"></div>
        <div>
          <InputPill
            control={control}
            rules={{ required: true, ...rulesEmail }}
            placeholder="Correo electrónico"
            type="email"
            name="email"
            styles
          />
          <InputPasswordPill
            control={control}
            rules={{ required: true }}
            placeholder="Contraseña"
            name="password"
          />

          <div className="form-group clearfix mb-0">
            <Link to="/process-forgot" className="forgot-password">
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
          <div className="form-group clearfix mb-0">
            <button
              onClick={onSubmit}
              className="btn-md btn-theme float-left"
              id="buttonlg"
            >
              INICIAR SESIÓN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Registro = () => {
  const { queryParams } = useQuery();
  const { Registro, isLogin } = useContext(GlobalContext);
  const { control, handleSubmit } = useForm();
  const history = useHistory();
  const contra = useWatch({ control, name: "password" });
  const [show, setShow] = useState(false);

  const onSubmit = handleSubmit((data) => {
    Registro(data)
      .then((res) => {
        if (queryParams?.redirect) {
          setTimeout(() => {
            history.replace(queryParams?.redirect);
          }, 0);
          return;
        }
        history.push("/");
      })
      .catch((err) => {});
  });
  useEffect(() => {
    if (isLogin) {
      history.push("/");
    }
  }, [isLogin, history]);
  return (
    <div className="col-lg-12 align-self-center pad-0">
      <div className="form-section align-self-center">
        <div className="clearfix"></div>
        <div>
          <InputPill
            control={control}
            rules={{ required: true }}
            placeholder="Nombre(s)"
            type="text"
            name="names"
          />
          <InputPill
            control={control}
            rules={{ required: true }}
            placeholder="Apellido paterno"
            type="text"
            name="last_name"
          />
          <InputPill
            control={control}
            rules={{ required: false }}
            placeholder="Apellido materno"
            type="text"
            name="mothers_last_name"
          />
          <InputPill
            control={control}
            rules={{ required: true }}
            placeholder="Teléfono"
            type="tel"
            name="phone"
          />

          <InputPill
            control={control}
            rules={{ required: true, ...rulesEmail }}
            placeholder="Correo electrónico"
            type="email"
            name="email"
          />
          <InputPasswordPill
            control={control}
            rules={{ required: true }}
            placeholder="Contraseña"
            name="password"
          />
          <InputPasswordPill
            control={control}
            rules={{
              required: true,
              validate: (value) =>
                value === contra || "Las contraseñas no coinciden",
            }}
            placeholder="Confirmar contraseña"
            type={show ? "text" : "password"}
            name="password_confirm"
          />
          {/* <div className="float-left">
            <CheckC onInput={setShow} control={control}>
              {" "}
              &nbsp;&nbsp;Mostrar Contraseña
            </CheckC>
          </div> */}

          <div className="form-group clearfix mb-0">
            <button
              onClick={onSubmit}
              className="btn-md btn-theme float-left"
              id="buttonlg"
              style={{ fontSize: "16px" }}
            >
              Registrarse
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Confirm = () => {
  const history = useHistory();
  const { token } = useParams();
  const { NestPost } = useContext(GlobalContext);
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    NestPost({ schema: "auth/confirm", body: { token } }).then((res) => {
      if (res?.data?.status !== "fail") {
        setStatus(true);
      } else {
        setMessage(res.data.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="Login">
      <div className="contact-section">
        <div className="container">
          <div className="row login-box">
            <div className="col-lg-12 align-self-center pad-0">
              <div className="form-section align-self-center">
                <div className="logo-2">
                  <img className="logo-log" src={logo} alt="Grupo Guía" />
                </div>
                <div className="clearfix"></div>
                <h3>
                  {message ||
                    (status ? "Su cuenta se encuentra activa ahora" : "")}
                </h3>
                <div>
                  {status ? (
                    <div className="form-group clearfix mb-0">
                      <button
                        onClick={(_) => history.push("/login")}
                        className="btn-md btn-theme float-left"
                        id="buttonlg"
                      >
                        Iniciar sesión
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Forgot = () => {
  const history = useHistory();
  const { isLogin, NestPost } = useContext(GlobalContext);
  const { control, handleSubmit } = useForm();

  const onSubmit = handleSubmit(({ email }) => {
    NestPost({
      schema: "auth/forgotten-password",
      body: {
        email,
      },
    }).then((res) => {
      if (res?.data?.status !== "fail") {
        Swal.fire({
          title: "En breve recibirás más instrucciones en tu correo.",
          icon: "success",
        });
      }
    });
  });
  if (isLogin) {
    history.push("/");
  }
  return (
    <div className="Login">
      <div className="contact-section">
        <div className="container">
          <div className="row login-box">
            <div className="col-lg-12 align-self-center pad-0">
              <div className="form-section align-self-center">
                <div className="logo-2">
                  <img className="logo-log" src={logo} alt="Grupo Guía" />
                </div>
                <div className="clearfix"></div>
                <h3>Proceso de recuperación</h3>
                <div>
                  <InputPill
                    control={control}
                    rules={{ required: true, ...rulesEmail }}
                    placeholder="Escribe tu Correo"
                    type="email"
                    name="email"
                    icon="fa fa-envelope"
                  />

                  <div className="form-group clearfix mb-0">
                    <button
                      onClick={onSubmit}
                      className="btn-md btn-theme float-left"
                      id="buttonlg"
                    >
                      Enviarme instrucciones
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ForgotForm = () => {
  const history = useHistory();
  const { token } = useParams();
  const { isLogin, NestPost, NestGet } = useContext(GlobalContext);
  const { control, handleSubmit, setValue } = useForm();
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const contra = useWatch({ control, name: "password" });

  const onSubmit = handleSubmit(({ password }) => {
    NestPost({
      schema: `auth/validate-forgotten-token/${token}`,
      body: {
        password,
      },
    }).then((res) => {
      if (res?.data?.status !== "fail") {
        Swal.fire({
          title: "Su contraseña ha sido cambiada con éxito",
          icon: "success",
        });
        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else {
        Swal.fire({ title: res.data.message, icon: "error" });
      }
    });
  });
  useEffect(() => {
    NestGet({
      schema: `auth/validate-forgotten-token/${token}`,
    }).then((res) => {
      if (res?.data?.status !== "fail") {
        setUser(res.data);
        setValue("email", res.data.email);
      } else {
        setMessage(res.data.message);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="Login">
      <div className="contact-section">
        <div className="container">
          <div className="row login-box">
            <div className="col-lg-12 align-self-center pad-0">
              <div className="form-section align-self-center">
                <div className="logo-2">
                  <img
                    className="logo-log"
                    src={logo}
                    alt="Mentor Desarrollo Humano"
                  />
                </div>
                <div className="clearfix"></div>

                <h3>{message || "Proceso de recuperación"}</h3>
                {user ? (
                  <div>
                    <h4>Ya casi {user.names}</h4>
                    {/* <InputPill
                      control={control}
                      rules={{ required: true, ...rulesEmail }}
                      placeholder="Escribe tu Correo"
                      type="email"
                      name="email"
                      icon="fa fa-envelope"
                      disabled
                    /> */}

                    <InputPill
                      control={control}
                      rules={{ required: true }}
                      placeholder="Contraseña"
                      type={show ? "text" : "password"}
                      name="password"
                      icon="fa fa-lock"
                    />
                    <InputPill
                      control={control}
                      rules={{
                        required: true,
                        validate: (value) =>
                          value === contra || "Las contraseñas no coinciden",
                      }}
                      placeholder="Confirmar contraseña"
                      type={show ? "text" : "password"}
                      name="password_confirm"
                      icon="fa fa-lock"
                    />
                    <div className="float-left">
                      <CheckC onInput={setShow} control={control}>
                        {" "}
                        &nbsp;&nbsp;Mostrar contraseña
                      </CheckC>
                    </div>
                    <div className="form-group clearfix mb-0">
                      <button
                        onClick={onSubmit}
                        className="btn-md btn-theme float-left"
                        id="buttonlg"
                      >
                        Enviarme instrucciones
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const InputPill = ({
  control,
  icon,
  placeholder,
  rules = {},
  name,
  onInput = () => {},
  ...props
}) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, error },
  } = useController({
    control,
    defaultValue: "",
    rules,
    name,
  });

  const inputProps = {
    ...props,
    ref,
    onChange: onChange,
    onBlur,
    onInput: () => onInput(value),
    value,
    placeholder,
    className: `input-text ${icon ?? "no-icon-padding"}`,
  };
  return (
    <div
      className={`form-group form-box${invalid ? " form-group__error" : ""}`}
    >
      {icon && (
        <i className={`${icon} icons_input active`} aria-hidden="true"></i>
      )}
      <input {...inputProps} />
      <span className="form-group-error">{invalid ? error.message : ""}</span>
    </div>
  );
};
export const InputPasswordPill = ({
  control,
  icon,
  placeholder,
  rules = {},
  name,
  onInput = () => {},
  ...props
}) => {
  const [show, setShow] = useState(false);
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, error },
  } = useController({
    control,
    defaultValue: "",
    rules,
    name,
  });

  const inputProps = {
    ...props,
    ref,
    onChange: onChange,
    onBlur,
    onInput: () => onInput(value),
    value,
    placeholder,
    className: `input-text ${icon ?? "no-icon-padding"}`,
    type: show ? "text" : "password",
  };
  return (
    <div
      className={`pill-password form-group form-box${
        invalid ? " form-group__error" : ""
      }`}
    >
      {icon && (
        <i className={`${icon} icons_input active`} aria-hidden="true" />
      )}

      <input {...inputProps} />
      <i
        onClick={(_) => setShow(!show)}
        className={`fa fa-${
          show ? "eye" : "eye-slash"
        } icons_input_password active`}
        aria-hidden="true"
      ></i>
      <span className="form-group-error">{invalid ? error.message : ""}</span>
    </div>
  );
};

export default Login;
