import React from "react";
import { Helmet } from "react-helmet";


/**
 * @component
 * Component responsible for displaying the help center page
*/

const CentroDeAyuda = () => {
  return (
    <div className="AvisoPrivacidad">
      <Helmet>
        <title>Centro de ayuda | Mentor</title>
      </Helmet>
      <div className="container-fluid" style={{ padding: 0, height: "50vh" }}>
        <div className="hero-image">
          <div className="hero-text">
            <h1 style={{ fontSize: 50, color: "#fff" }}>Centro de ayuda</h1>
          </div>
        </div>
      </div>
      <section className="container wow fadeIn pt-5 pb-5">
        <div className="container privacity_zone">
          <div className="row row_pv">
            <p className="privacity_p">
              <a href="https://wa.me/7352436933?text=Buen día">
                Vía whatsapp: 73 5243 6933
              </a>
              <p>
                Vía correo electrónico:
                <a href="mailto:contacto@mentordh.mx"> contacto@mentordh.mx</a>
              </p>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CentroDeAyuda;
