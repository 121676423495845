import React, {useContext} from 'react'
import GlobalContext from '../context/GlobalContext'
import Header from '../components/Home/Header.jsx'
import Footer from '../components/Home/Footer.jsx'
import Loading from '../components/Loading/Loading.jsx'
import './styles.scss'
import { DialogLogin } from '../components/Dialogs'


const Home = ({children, className}) => {
    const {isLoading} = useContext(GlobalContext)
    return (
        <div className="HomeLayout">
            
            <Header />
            
            <main className={`Container ${className||''}`}>
                {children}
            </main>
            <Footer />
            {isLoading?
            <Loading />
            :null}
            <DialogLogin />
        </div>
    )
}



export default Home
