import React from "react";
import { Helmet } from "react-helmet";

//Components
import ContactoSection from "../../components/Home/ContactoSection";

/**
 * @component
 * Component in charge of calling the contact view
 */
const Contacto = () => {
  return (
    <>
      <Helmet>
        <title>Contacto | Mentor</title>
      </Helmet>
      <ContactoSection />
    </>
  );
};

export default Contacto;
