import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouteMatch } from "react-router-dom";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

//Data
import GlobalContext from "../../context/GlobalContext";
import FormContext from "../../context/FormContext";

//Hooks
import { useController, useForm, useWatch } from "react-hook-form";
import { rulesRequired } from "../../Constants";
import {
  Alert,
  Button,
  CheckC,
  CheckGroupC,
  Collapse,
  Dialog,
  DragDrop,
  FileInputC,
  Grid,
  GridDD,
  InputC,
  LabelWrapper,
  LinkButton,
  Markdown,
  PillButton,
  QuillAreaC,
  SelectC,
  Steps,
  TextAreaC,
  TimeInputC,
  ToggleSwitchC,
} from "../../core";
import useFormatter from "../../hooks/useFormatter";
import useVimeo from "../../hooks/useVimeo";
import useS3File from "../../hooks/useS3File";

//Components
import { FilmotecaInput } from "../../components/Inputs";
import Swal from "sweetalert2";
import useS3NestFile from "../../hooks/useS3NestFile";
import UserPriceGrid from "./UserPriceGrid";
import UserPriceGridManual from "./UserPriceGridManual";
import UserPriceByTitle from "./UserPriceByTitle";
import UserVisibilityGrid from "./UserVisibilityGrid";
import CertificateChooser from "./CertificateChooser";

/**
 * @component
 * Component responsible for displaying the form to add a course
 */

const reactiveOptions = [
  { value: "options", label: "Opción Multiple" },
  { value: "sort", label: "Ordenar opciones" },
  // { value: 'match', label: 'Relacionar' },
  { value: "upload", label: "Subir archivo" },
  { value: "true or false", label: "Verdad o falso" },
  { value: "camp_text", label: "Preguntas abiertas" },
];

const CursosForm = ({ isNew }) => {
  const { handleDelete: handleDeleteS3 } = useS3File();
  const { handleUpload, handleDelete } = useS3NestFile();
  const {
    state: { accessToken },
    setState,
    setAppLoading,
    NestGet,
    NestPost,
    NestPut,
  } = useContext(GlobalContext);
  const { OnlyNumber, OnlyNumberF, OnlyAlphanumeric } = useFormatter();
  const { setAccessToken, observableGetVideo, availableToken } = useVimeo();
  const history = useHistory();
  let { id } = useParams();
  const match = useRouteMatch();
  const [filmoteca, setFilmoteca] = useState([]);
  const [semilla, setSemilla] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [step, setStep] = useState(1);
  const [skuCalculate, setSkuCalculate] = useState(true);
  const { control, handleSubmit, reset, setValue, unregister } = useForm();
  const has_exam = useWatch({ control, name: "has_exam" });

  //1 Informacion general
  //2 Costos
  //3 Generales
  //4 Modulos
  //5 Examen

  const fetchData = useCallback(() => {
    NestGet({
      schema: "categorias",
    }).then((res) => {
      if (res.status === "success") {
        setCategorias(res.data.map((m) => ({ label: m.name, value: m.id })));
      }
    });
  }, [NestGet]);

  const fetchDataFil = useCallback(() => {
    setFilmoteca([]);
    observableGetVideo({}).subscribe((chunkData) => {
      setFilmoteca((state) =>
        [
          ...state,
          ...chunkData.data.map((m) => ({
            value: m.uri.replace("/videos/", ""),
            label: m.name,
            filmData: m,
          })),
        ].sort((a, b) => a.label.localeCompare(b.label))
      );
    });
  }, [observableGetVideo]);

  const makeData = async (data) => {
    data.cost = data.cost || "0";
    data.cost = Number(OnlyNumber(data.cost));
    data.cost_Interno = data.cost_Interno || "0";
    data.cost_Interno = Number(OnlyNumber(data.cost_Interno));

    data.cost_Externo = data.cost_Externo || "0";
    data.cost_Externo = Number(OnlyNumber(data.cost_Externo));

    data.cost_Mixto = data.cost_Mixto || "0";
    data.cost_Mixto = Number(OnlyNumber(data.cost_Mixto));
    const specialPrices = {};
    //loope over specialPrice object
    data.specialPrices = data.specialPrices || {};
    for (const [key, value] of Object.entries(data.specialPrices)) {
      //if its a valid number, then add it to the special prices
      if (value && !isNaN(value)) {
        specialPrices[key] =
          typeof value == "number" ? value : Number(OnlyNumber(value));
      }
    }
    data.specialPrices = specialPrices;

    const cost_job_title = {};
    //loope over cost_job_title object
    data.cost_job_title = data.cost_job_title || {};
    for (const [key, value] of Object.entries(data.cost_job_title)) {
      //if its a valid number, then add it to the special prices
      if (value && !isNaN(value)) {
        cost_job_title[key] =
          typeof value == "number" ? value : Number(OnlyNumber(value));
      }
    }
    data.cost_job_title = cost_job_title;

    console.log("data", data);

    data.image = await handleUpload(data.image, `mentor/cursos/${id}`);
    data.image_detalle = await handleUpload(
      data.image_detalle,
      `mentor/cursos/${id}`
    );
    if (data.reconocimiento) {
      data.reconocimiento = await handleUpload(
        data.reconocimiento,
        `mentor/cursos/${id}`
      );
    } else {
      data.has_reconocimiento = false;
    }

    if (data.diploma) {
      data.diploma = await handleUpload(data.diploma, `mentor/cursos/${id}`);
    } else {
      data.has_diploma = false;
    }

    if (data.certificado) {
      data.certificado = await handleUpload(
        data.certificado,
        `mentor/cursos/${id}`
      );
    } else {
      data.has_certificate = false;
    }
    if (data.modules) {
      data.modules =
        (await Promise.all(
          data.modules?.map(async (module) => {
            module.capsules =
              (await Promise.all(
                module.capsules?.map(async (capsule) => {
                  capsule.support_material =
                    (await Promise.all(
                      capsule.support_material.map(async (file) => {
                        return await handleUpload(
                          file,
                          `mentor/cursos/${id}/support_material`,
                          true
                        );
                      })
                    )) || [];
                  return capsule;
                })
              )) || [];
            return module;
          })
        )) || [];
    }
    return data;
  };

  const resolveSave = (res) => {
    setAppLoading(true);
    if (semilla?.image && semilla.image.key !== res.data.image.key) {
      if (semilla?.image?.location?.includes("mentorstore")) {
        handleDeleteS3(semilla.image.key);
      } else {
        handleDelete(semilla.image.key);
      }
    }
    if (
      semilla?.image_detalle &&
      semilla.image_detalle.key !== res.data.image_detalle.key
    ) {
      if (semilla?.image?.location?.includes("mentorstore")) {
        handleDeleteS3(semilla.image_detalle.key);
      } else {
        handleDelete(semilla.image_detalle.key);
      }
    }
    semilla.modules?.forEach(async (module, i_module) => {
      module.capsules?.forEach(async (capsul, i_capsul) => {
        capsul.support_material.forEach(async (file, i_file) => {
          if (
            file &&
            file?.key !==
              res.data?.modules?.[i_module]?.capsules?.[i_capsul]
                ?.support_material?.[i_file]?.key
          ) {
            if (semilla?.image?.location?.includes("mentorstore")) {
              handleDeleteS3(file.key);
            } else {
              handleDelete(file.key);
            }
          }
        });
      });
    });
    setSemilla(res.data);
    Object.keys(res.data).forEach((key) => {
      if (
        key === "cost" ||
        key === "cost_Interno" ||
        key === "cost_Externo" ||
        key === "cost_Mixto"
      ) {
        setValue(key, OnlyNumberF(`${res.data[key]}`) || "");
      } else {
        setValue(key, res.data[key]);
      }
    });
    setAppLoading(false);
  };

  const onSubmit = async (data) => {
    setSkuCalculate(false);
    setAppLoading(true);
    data = await makeData(data);
    console.log(data);
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 1300,
    });
    if (step === 5 && data.modules) {
      // for (const module of data.modules) {
      //   if (module.quiz && module.quiz.length < 1) {
      //     Toast.fire({
      //       icon: "error",
      //       title: "Todos los módulos necesitan tener un quiz.",
      //     });
      //     setAppLoading(false);
      //     return;
      //   }
      // }
    }
    let promise;
    console.log("before if", id);
    if (id) {
      console.log("step", step);
      if (step == 5) {
        console.log("updated modules");
        promise = NestPut({
          schema: "cursos/update-modules",
          body: data,
          id,
        });
      } else {
        console.log("NOT updated modules");
        promise = NestPut({
          schema: "cursos",
          body: data,
          id,
        });
      }
    } else {
      promise = NestPost({
        schema: "cursos",
        body: data,
      });
    }
    promise
      .then((res) => {
        if (res.status === "success") {
          resolveSave(res);
          if (!(step === 6))
            history.replace(match.path.replace(/new|:id/, res.data.id));
          setStep((step) => (step < 6 ? step + 1 : step));
        }
      })
      .finally((_) => {
        setAppLoading(false);
        if (step === 6) {
          Toast.fire({
            icon: "success",
            title: "Se guardó correctamente el curso.",
          });
        }
      });
  };
  const onSave = async (data) => {
    data = await makeData(data);
    setAppLoading(true);
    let promise;
    if (step == 5) {
      console.log("updated modules");
      promise = NestPut({
        schema: "cursos/update-modules",
        body: data,
        id,
      });
    } else {
      console.log("NOT updated modules");
      promise = NestPut({
        schema: "cursos",
        body: data,
        id,
      });
    }
    return promise
      .then((res) => {
        if (res.status === "success") {
          resolveSave(res);
        }
      })
      .finally((_) => {
        setAppLoading(false);
      });
  };

  const checkId = useCallback(async () => {
    let accessToken_ = accessToken;
    if (!accessToken) {
      const token = await NestGet({
        schema: "admin/getAccessTokenVimeo",
      }).then(async (res) => {
        return res.data;
      });
      accessToken_ = token;
      setState({ accessToken: token });
    }

    setAccessToken(accessToken_);

    if (!isNew) {
      setAppLoading(true);
      const res = await NestGet({
        schema: "cursos",
        id,
      });

      if (!res.data) {
        history.replace(match.path.replace(/:id/, "new"));
      } else {
        if (res.data.sku) {
          setSkuCalculate(false);
        }
        setSemilla(res.data);
        Object.keys(res.data).forEach((key) => {
          if (
            key === "cost" ||
            key === "cost_Interno" ||
            key === "cost_Externo" ||
            key === "cost_Mixto"
          ) {
            setValue(key, OnlyNumberF(`${res.data[key]}`) || "");
          } else {
            setValue(key, res.data[key]);
          }
        });
      }
      setAppLoading(false);
    }
    fetchData();
  }, [
    NestGet,
    OnlyNumberF,
    accessToken,
    fetchData,
    history,
    id,
    isNew,
    match.path,
    setAccessToken,
    setAppLoading,
    setState,
    setValue,
  ]);

  const notChangeSKU = useCallback((v) => {
    setSkuCalculate(false);
  }, []);

  const generateSku = useCallback(
    (v) => {
      if (skuCalculate) {
        const traslate = {
          á: "a",
          é: "e",
          í: "i",
          ó: "o",
          ú: "u",
          Á: "A",
          É: "E",
          Í: "I",
          Ó: "O",
          Ú: "U",
          " ": "-",
        };
        let newSku = v
          .replace(/[áéíóúÁÉÍÓÚ ]/g, (c) => {
            return traslate[c];
          })
          .toLowerCase();
        setValue("sku", newSku);
      }
    },
    [skuCalculate, setValue]
  );

  useEffect(() => {
    if (availableToken) {
      fetchDataFil();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableToken]);
  useEffect(() => {
    checkId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FormContext.Provider value={{ filmoteca, fetchDataFil }}>
      <div className="work_area d-flex flex-column justify-content-center ">
        <div className="w-100">
          <Steps
            activeStep={step}
            steps={[
              { label: "Información principal" },
              { label: "Costos" },
              { label: "Generales" },
              { label: "Diplomas, reconocimiento y certificado" },
              { label: "Módulos" },
              { label: "Examen" },
            ]}
            setStep={setStep}
          />

          {step === 1 ? (
            <>
              <div className="d-flex flex-wrap">
                <div className="flex-grow-2">
                  <InputC
                    control={control}
                    className="w-100"
                    label="Título"
                    name="name"
                    rules={rulesRequired}
                    onInput={generateSku}
                  />
                </div>
                <div className="flex-grow-1">
                  <InputC
                    control={control}
                    className="w-100"
                    label="SKU"
                    name="sku"
                    rules={rulesRequired}
                    onInput={notChangeSKU}
                    setFormate={OnlyAlphanumeric}
                  />
                </div>
                <ToggleSwitchC
                  control={control}
                  name="active"
                  label="Estatus"
                />
              </div>
              <TextAreaC
                control={control}
                label="Descripción"
                name="description"
              />
              <div className="d-flex flex-wrap">
                <div className="flex-1">
                  <SelectC
                    control={control}
                    name="access"
                    label="Tipo de acceso"
                    options={["Interno", "Externo", "Mixto"].map((m) => ({
                      label: m,
                      value: m,
                    }))}
                  />
                </div>
                <div className="flex-1">
                  <SelectC
                    control={control}
                    name="level"
                    label="Nivel"
                    options={[
                      "Básico",
                      "Intermedio",
                      "Avanzado",
                      "Complementario",
                    ].map((m) => ({ label: m, value: m }))}
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap">
                {/* <div className="flex-1">
                  <InputC
                    control={control}
                    name="cost"
                    label="Costo"
                    setFormate={OnlyNumberF}
                  /> 
                </div> */}
                <div className="flex-1">
                  <InputC
                    control={control}
                    name="points"
                    label="Puntos que otorga"
                    optional="pts"
                    setFormate={OnlyNumberF}
                  />
                </div>
                <div className="flex-1">
                  <CheckC
                    control={control}
                    name="unskippable"
                    label="unskippable"
                  >
                    Avance lineal
                  </CheckC>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="flex-1">
                  <div className="w-70">
                    <SelectC
                      control={control}
                      name="categories"
                      label="Categorias"
                      options={categorias}
                      isMulti
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="w-100 p-2">
                  <UserVisibilityGrid
                    setValue={setValue}
                    copy={
                      "Selecciona a los usuarios internos Guia que tendrán visible el curso"
                    }
                    userType="Interno"
                    control={control}
                  />

                  <UserVisibilityGrid
                    setValue={setValue}
                    copy={
                      "Selecciona a los usuarios externos que tendrán visible el curso"
                    }
                    userType="Externo"
                    control={control}
                  />
                </div>
              </div>
            </>
          ) : null}
          {step === 2 ? (
            <>
              <div className="d-flex flex-wrap">
                <div className="w-100 p-2">
                  <InputC
                    control={control}
                    name="cost"
                    label="Costo general"
                    setFormate={OnlyNumberF}
                  />
                </div>

                <UserPriceGrid
                  setValue={setValue}
                  copy={
                    "Selecciona a los usuarios internos Guia que tendrán el descuento especial"
                  }
                  userType="Interno"
                  labelInputCopy={"Costo para usuarios internos Guia "}
                  control={control}
                  OnlyNumberF={OnlyNumberF}
                />

                <UserPriceGrid
                  setValue={setValue}
                  copy={
                    "Selecciona a los usuarios externos que tendrán el descuento especial"
                  }
                  userType="Externo"
                  labelInputCopy={"Costo para usuarios externos "}
                  control={control}
                  OnlyNumberF={OnlyNumberF}
                />

                <UserPriceGrid
                  setValue={setValue}
                  copy={
                    "Selecciona a los usuarios que tendrán el descuento especial"
                  }
                  userType="Mixto"
                  labelInputCopy={"Costo para usuarios con descuento especial "}
                  control={control}
                  OnlyNumberF={OnlyNumberF}
                />

                <UserPriceGridManual
                  setValue={setValue}
                  copy={"Asignar el costo manualmente"}
                  userType="Mixto"
                  control={control}
                  OnlyNumberF={OnlyNumberF}
                />

                <UserPriceByTitle
                  setValue={setValue}
                  copy={"Asignar el costo manualmente"}
                  userType="Mixto"
                  control={control}
                  OnlyNumberF={OnlyNumberF}
                />
              </div>
            </>
          ) : null}
          {step === 3 ? (
            <>
              <div className="d-flex flex-wrap">
                <div className="flex-1"></div>
                <div className="flex-1">
                  <FileInputC
                    control={control}
                    name="image"
                    label="Imagen tarjeta"
                    accept="image/*"
                    className="w-100"
                  />
                </div>
                <div className="flex-1">
                  <FileInputC
                    control={control}
                    name="image_detalle"
                    label="Imagen portada"
                    accept="image/*"
                    className="w-100"
                  />
                </div>
              </div>
              <FilmotecaInput
                control={control}
                filmoteca={filmoteca}
                name="preview"
                label="Vista previa"
                rules={rulesRequired}
                onLoadNewFil={fetchDataFil}
              />
              <div className="d-flex flex-wrap">
                <div className="flex-1">
                  <TextAreaC
                    control={control}
                    name="will_you_learn"
                    label="¿Qué aprenderás?"
                  />
                </div>
                <div className="flex-1">
                  <TextAreaC
                    control={control}
                    name="prior_knowledge"
                    label="Conocimientos requeridos"
                  />
                </div>
              </div>
            </>
          ) : null}
          {step === 4 ? (
            <CertificateChooser setValue={setValue} control={control} />
          ) : null}
          {step === 5 ? (
            <Modules
              control={control}
              name="modules"
              onSave={handleSubmit(onSave)}
            />
          ) : null}
          {step === 6 ? (
            <>
              <div className="d-flex flex-wrap">
                <div className="flex-1">
                  <ToggleSwitchC
                    control={control}
                    name="has_exam"
                    label="Tiene examen"
                  />
                </div>
              </div>
              {has_exam ? <Exam control={control} name="exam" /> : null}
            </>
          ) : null}
          <div className="w-100" style={{ height: 100 }}></div>
          <div className="d-flex justify-content-end">
            <Button onClick={handleSubmit(onSubmit)}>Guardar</Button>
            {step === 6 ? (
              <Button
                onClick={() => {
                  handleSubmit(onSubmit)().finally(() => {
                    history.push("/Backoffice/Cursos");
                  });
                }}
                color="#ff0011"
              >
                Finalizar
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </FormContext.Provider>
  );
};

const Exam = ({ control, name }) => {
  return (
    <LabelWrapper label="Examen">
      <div className="d-flex">
        <div className="flex-1">
          <InputC
            className="w-100"
            control={control}
            name={`${name}.time`}
            label="Duración"
            optional="en minutos"
            rules={rulesRequired}
          />
        </div>
        <div className="flex-1">
          <InputC
            className="w-100"
            control={control}
            name={`${name}.approving_with`}
            label="Aprueba"
            optional="%"
            rules={rulesRequired}
          />
        </div>
      </div>
      <Quiz control={control} name={`${name}.quiz`} rules={rulesRequired} />
    </LabelWrapper>
  );
};

const Modules = ({ control, name, onSave }) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isTouched, isDirty, error, invalid },
  } = useController({
    control,
    defaultValue: [],
    name,
  });
  const onDelete = (index) => {
    const updatedList = [...value];
    updatedList.splice(index, 1);
    onChange(updatedList);
  };
  const onAddItem = () => {
    onChange([...value, { name: "", capsules: [], quiz: [] }]);
    console.log(`${name}.${value.length}`);
    setTimeout(() => {
      document.getElementById(`${name}.${value.length}`).scrollIntoView();
    }, 100);
  };
  return (
    <LabelWrapper label="Módulos">
      <div className="d-flex justify-content-end">
        <Button color="#66bb6a" onClick={onAddItem}>
          Agregar Módulo <i className="fa fa-plus"></i>
        </Button>
      </div>

      <Alert>
        <p className="mb-2">
          Puede organizar los módulos haciendo click en cuanto el cursor cambie
          a <i className="fa fa-hand"></i> y soltando donde usted lo quiera
        </p>
      </Alert>
      <DragDrop
        name={name}
        items={value}
        keyExtractor={(item, i) => `${item?._id || ""}-${i}`}
        onChange={onChange}
        onDelete={onDelete}
        renderItem={(module, index) => (
          <Module
            control={control}
            name={`${name}.${index}`}
            module={module}
            onSave={onSave}
          />
        )}
      />
    </LabelWrapper>
  );
};

const Module = ({ control, name, onSave }) => {
  const { setAppLoading } = useContext(GlobalContext);
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isTouched, isDirty, error, invalid },
  } = useController({
    control,
    defaultValue: [],
    name,
  });
  const dotname = useWatch({ control, name: `${name}.name` });
  const quiz = useWatch({ control, name: `${name}.quiz` });
  const [show, setShow] = useState(false);

  const columns = [
    {
      title: "Pregunta",
      key: "question",
    },
    {
      title: "Reactivo",
      key: "type",
    },
  ];

  return (
    <>
      <div className="d-flex px-4">
        <div className="flex-grow-1">
          <div className="row">
            <div className="col">
              <InputC
                control={control}
                name={`${name}.name`}
                label="Nombre del módulo"
                className="w-100"
                rules={rulesRequired}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Capsules
                onSave={onSave}
                control={control}
                name={`${name}.capsules`}
                rules={rulesRequired}
              />
            </div>
            <div className="col-md-12">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <label>Evaluación:</label>
                <Button onClick={(_) => setShow(true)}>
                  Editar evaluación del módulo
                </Button>
              </div>
              <Grid
                rowKey="_id"
                columns={columns}
                dataSource={quiz.map((m, i) => ({ _id: i, ...m }))}
                className="table-striped"
                // pagination={pagination}
                // onChangePage={fetchData}
              >
                <Grid.Column
                  name="type"
                  context={({ column, row }) => (
                    <>
                      {reactiveOptions.find((f) => f.value === column)?.label}
                    </>
                  )}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        visible={show}
        classNameModalDialog="bg-90-vw modal-dialog-scrollable"
        onClose={(_) => setShow(false)}
      >
        <Dialog.Header>
          <strong className="h4 mb-0 text-center w-100">
            Evaluación del módulo "{dotname}"
          </strong>
        </Dialog.Header>
        <Quiz control={control} name={`${name}.quiz`} />
        <Dialog.Footer>
          <Button onClick={(_) => setShow(false)}>Salir</Button>
          <Button
            color="#66bb6a"
            onClick={(_) => {
              setAppLoading(true);
              onSave();
              setShow(false);
            }}
          >
            Guardar
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

const Capsules = ({ control, name, onSave, rules }) => {
  const { setAppLoading } = useContext(GlobalContext);
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isTouched, isDirty, error, invalid },
  } = useController({
    control,
    defaultValue: [],
    name,
    rules,
  });

  const initValue = useMemo(
    (_) => ({
      name: "",
      copy: "",
      has_video: true,
      video: null,
      duration: "",
      is_partial: false,
      start: "",
      end: "",
    }),
    []
  );

  const columns = [
    {
      title: "Nombre",
      key: "name",
    },
    {
      title: "Tipo",
      key: "has_video",
    },
    {
      title: "Acciones",
      width: 100,
      key: "action",
    },
  ];

  const { control: controlCap, reset, handleSubmit, setValue } = useForm();

  const [show, setShow] = useState("");
  const [defaultValue, setDefaultValue] = useState(
    JSON.parse(JSON.stringify(initValue))
  );

  const showDialog = (index = -1, data) => {
    setShow(`${index}`);
    console.log("initValue", initValue);
    setDefaultValue(data || JSON.parse(JSON.stringify(initValue)));
  };
  const closeModal = useCallback(() => {
    setShow("");
    setDefaultValue({});
    reset(JSON.parse(JSON.stringify(initValue)));
  }, [initValue, reset]);

  const onAddItem = useCallback(
    ({ capsule }) => {
      const index = Number(show);
      if (index === -1) {
        onChange([...value, capsule]);
      } else {
        value.splice(index, 1, capsule);
        onChange(value);
      }
      setAppLoading(true);
      onSave();
      closeModal();
    },
    [closeModal, onChange, onSave, setAppLoading, show, value]
  );

  const deleteItem = useCallback(
    (index) => {
      value.splice(index, 1);
      onChange(value);
    },
    [onChange, value]
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <label>Capsulas:</label>
        <Button color="#66bb6a" onClick={(_) => showDialog()}>
          Agregar capsula <i className="fa fa-plus"></i>
        </Button>
      </div>
      {/* <Collapse 
        show={!!show}
        classNameModalDialog="bg-90-vw modal-dialog-scrollable"
        onClose={closeModal}>
      
        {!!show?<Capsule
          control={controlCap}
          name={"capsule"}
          defaultValue={defaultValue}
          rules={rulesRequired}
        />:null}
        
        <div className="d-flex justify-content-evenly">
          <Button color="#c62828" onClick={closeModal}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit(onAddItem, (e) => console.log(e))}>
            Guardar
          </Button>
        </div>
      </Collapse> */}
      <GridDD
        name={name}
        rowKey="_id"
        columns={columns}
        dataSource={value.map((m, i) => ({ _id: i, ...m }))}
        className="table-striped"
        onChange={onChange}
        // pagination={pagination}
        // onChangePage={fetchData}
      >
        <GridDD.Column
          name="has_video"
          context={({ column, row }) => <>{column ? "Video" : "Lectura"}</>}
        />
        <GridDD.Column
          name="action"
          context={({ column, row, index }) => (
            <>
              <PillButton
                color={"#00b8d4"}
                onClick={(_) => showDialog(index, row)}
              >
                <i className="fa fa-edit"></i>
              </PillButton>
              <PillButton color={"#c62828"} onClick={(_) => deleteItem(index)}>
                <i className="fa fa-trash"></i>
              </PillButton>
            </>
          )}
        />
      </GridDD>
      <Dialog
        visible={!!show}
        classNameModalDialog="bg-90-vw modal-dialog-scrollable"
        onClose={closeModal}
      >
        {!!show ? (
          <Capsule
            control={controlCap}
            name={"capsule"}
            defaultValue={defaultValue}
            rules={rulesRequired}
          />
        ) : null}
        <Dialog.Footer>
          <Button onClick={handleSubmit(onAddItem, (e) => console.log(e))}>
            Guardar
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

const Capsule = ({ control, name, defaultValue, rules }) => {
  const { filmoteca, fetchDataFil } = useContext(FormContext);
  const { OnlyNumber, OnlyTime } = useFormatter();
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isTouched, isDirty, error, invalid },
  } = useController({
    control,
    defaultValue: defaultValue || {},
    name,
    rules,
  });
  const {
    field: { onChange: onChangeD, value: duration },
  } = useController({
    control,
    defaultValue: defaultValue.duration,
    name: `${name}.duration`,
  });
  const {
    field: { onChange: onChangeS, value: start },
  } = useController({
    control,
    defaultValue: defaultValue.start,
    name: `${name}.start`,
  });
  const {
    field: { onChange: onChangeE, value: end },
  } = useController({
    control,
    defaultValue: defaultValue.end,
    name: `${name}.end`,
  });
  const [has_video, setHas_video] = useState(defaultValue.has_video);
  const [is_partial, setIs_partial] = useState(defaultValue.is_partial);

  const onChangeFil = useCallback(
    (filData) => {
      const duration = moment
        .utc(Number(filData.duration) * 1000)
        .format("HH:mm:ss");
      onChangeD(duration);
      onChangeS("00:00");
      onChangeE(duration);
    },
    [onChangeD, onChangeS, onChangeE]
  );
  const changeHasVideo = useCallback(
    (v) => {
      setHas_video(v);
      if (v !== undefined && !v) {
        onChangeD("");
        onChangeS("");
        onChangeE("");
        onChange((value) => ({
          ...value,
          video: "",
          is_partial: false,
        }));
      }
    },
    [onChange, onChangeD, onChangeE, onChangeS]
  );
  const changeIsPartial = useCallback(
    (v) => {
      setIs_partial(v);
      if (!v) {
        onChangeS("");
        onChangeE("");
      }
    },
    [onChangeE, onChangeS]
  );
  const validateTime = (t) => (v) => {
    const dur = moment(duration, "HH:mm:ss");
    const val = moment(v, "HH:mm:ss");
    const com = moment(t ? start : end, "HH:mm:ss");
    if (val.diff(dur, "s") > 0) {
      return "El valor no puede ser mayor a la duración del video";
    }
    if (t === 0) {
      return (
        com.diff(val, "s") > 0 ||
        "El valor de inicio no puede ser mayor al de final"
      );
    } else {
      return (
        val.diff(com, "s") > 0 ||
        "El valor de final no puede ser menor al de inicio"
      );
    }
  };
  return (
    <>
      <InputC
        control={control}
        name={`${name}.name`}
        label="Nombre de la capsula"
        className="w-100"
        rules={rulesRequired}
      />
      <QuillAreaC
        control={control}
        name={`${name}.copy`}
        label="Copy"
        className="w-100"
        rules={{
          validate: (v) => v !== "<p><br></p>" || "El campo es requerido",
        }}
      />
      <div className="flex-1">
        <ToggleSwitchC
          control={control}
          name={`${name}.has_video`}
          label="Tiene video"
          onChange={changeHasVideo}
        />
      </div>
      {has_video ? (
        <>
          <FilmotecaInput
            control={control}
            name={`${name}.video`}
            filmoteca={filmoteca}
            onLoadNewFil={fetchDataFil}
            onChangeFil={onChangeFil}
            rules={rulesRequired}
            single
          />
          <div className="d-flex flex-wrap">
            <div className="">
              <ToggleSwitchC
                control={control}
                name={`${name}.is_partial`}
                label="Es fragmento del video"
                onChange={changeIsPartial}
              />
            </div>
            {is_partial ? (
              <>
                <div className="">
                  <TimeInputC
                    control={control}
                    name={`${name}.start`}
                    label="Inicia"
                    disableClock
                    format="mm:ss"
                    rules={{ ...rulesRequired, validate: validateTime(0) }}
                  />
                </div>
                <div className="">
                  <TimeInputC
                    control={control}
                    name={`${name}.end`}
                    label="Finaliza"
                    disableClock
                    format="mm:ss"
                    rules={{ ...rulesRequired, validate: validateTime(1) }}
                  />
                </div>
              </>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <TimeInputC
            control={control}
            name={`${name}.duration`}
            label="Duración"
            disableClock
            format="HH:mm:ss"
            rules={rulesRequired}
          />
        </>
      )}
      <div className="d-flex flex-wrap">
        <div className="flex-1">
          <FileInputC
            control={control}
            name={`${name}.support_material`}
            label="Material de apoyo"
            multiple
          />
        </div>
        <div className="flex-1">
          <SourceInformation
            control={control}
            name={`${name}.source_information`}
          />
        </div>
      </div>
    </>
  );
};

const SourceInformation = ({ control, name, rules }) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isTouched, isDirty, error, invalid },
  } = useController({
    control,
    defaultValue: [],
    name,
    rules,
  });

  const columns = [
    {
      title: "Recurso",
      key: "source",
    },
    {
      title: "Acciones",
      width: 100,
      key: "action",
    },
  ];

  const { control: controlI, handleSubmit, reset, setValue } = useForm();

  const [index, setIndex] = useState(-1);

  const onSubmit = ({ source }) => {
    const updatedList = [...value];
    if (index > -1) {
      updatedList.splice(index, 1, source);
    } else {
      updatedList.push(source);
    }
    onChange(updatedList);
    setIndex(-1);
    reset({ source: "" });
  };
  const onDelete = (indx) => {
    const updatedList = [...value];
    updatedList.splice(indx, 1);
    onChange(updatedList);
  };
  const onEdit = (indx, { source }) => {
    setValue("source", source);
    setIndex(indx);
  };
  return (
    <LabelWrapper label="Fuente de información">
      <div className="d-flex flex-wrap justify-content-between">
        <div className="flex-grow-1">
          <TextAreaC control={controlI} name="source" rules={rulesRequired} />
        </div>
        <Button color="#66bb6a" onClick={handleSubmit(onSubmit)}>
          {index > -1 ? "Editar" : "Agregar"} <i className="fa fa-plus"></i>
        </Button>
      </div>
      <Alert>
        <p>
          El formato para enlaces es{" "}
          <strong>{"[nombre del enlace](url)"}</strong>
        </p>
        <p>
          ver mas referencia{" "}
          <a
            href="https://es.wikipedia.org/wiki/Markdown"
            target="_blank"
            rel="noopener noreferrer"
          >
            aqui
          </a>
        </p>
      </Alert>
      <GridDD
        rowKey="i"
        columns={columns}
        name={name}
        dataSource={value.map((m, i) => ({ source: m, i }))}
        onChange={(v) => {
          onChange(v.map((m) => m.source));
        }}
      >
        <GridDD.Column
          name="source"
          context={({ column, row }) => (
            <>
              <Markdown>{column}</Markdown>
            </>
          )}
        />
        <GridDD.Column
          name="action"
          context={({ column, row, index }) => (
            <>
              <PillButton color={"#00b8d4"} onClick={(_) => onEdit(index, row)}>
                <i className="fa fa-edit"></i>
              </PillButton>
              <PillButton color={"#c62828"} onClick={(_) => onDelete(index)}>
                <i className="fa fa-trash"></i>
              </PillButton>
            </>
          )}
        />
      </GridDD>
    </LabelWrapper>
  );
};

const Quiz = ({ control, name, label, rules = {} }) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isTouched, isDirty, error, invalid },
  } = useController({
    control,
    defaultValue: [],
    name,
    rules,
  });
  const onChangeReagent = (v, index) => {
    let updatedList = [...value];
    updatedList.splice(index, 1);
    // Add dropped item
    updatedList.splice(index, 0, v);
    onChange(updatedList);
  };
  const onDelete = (index) => {
    const updatedList = [...value];
    updatedList.splice(index, 1);
    onChange(updatedList);
  };
  const onAddItem = () => {
    onChange([
      ...value,
      { question: "", type: "", options: [], answer: undefined },
    ]);
    console.log(`${name}.${value.length}`);
    setTimeout(() => {
      document.getElementById(`${name}.${value.length}`).scrollIntoView();
    }, 100);
  };
  return (
    <LabelWrapper label={label}>
      <div className="d-flex justify-content-end">
        <Button color="#66bb6a" onClick={onAddItem}>
          Agregar reactivo <i className="fa fa-plus"></i>
        </Button>
      </div>
      <span className="label__error">{invalid ? error.message : ""}</span>
      <DragDrop
        name={name}
        items={value}
        keyExtractor={(item, i) => `${item?._id || ""}-${i}`}
        onChange={onChange}
        onDelete={onDelete}
        renderItem={(reagent, index) => (
          <Reagent
            control={control}
            name={`${name}.${index}`}
            module={reagent}
            onChange={(v) => onChangeReagent(v, index)}
          />
        )}
      />
    </LabelWrapper>
  );
};
const Reagent = ({ control, name, onChange }) => {
  const [firts, setFirts] = useState(false);
  const { control: control_, handleSubmit, reset } = useForm();
  const {
    control: controlFix,
    setValue,
    handleSubmit: handleSubmitFix,
    reset: resetFix,
  } = useForm();
  const {
    control: controlDin,
    handleSubmit: handleSubmitDin,
    reset: resetDin,
  } = useForm();
  const value = useWatch({ control, name });
  const type = useWatch({ control, name: `${name}.type` });
  const options = useWatch({ control, name: `${name}.options` });

  const onSubmit = ({ option }) => {
    reset({ option: "" });

    changeValue({ options: [...options, { value: option, label: option }] });
  };
  const onSubmitFix = ({ optionFix }) => {
    resetFix({ optionFix: "" });
    const option0 = options?.[0] || [];
    const option1 = options?.[1] || [];
    option0.push({ value: optionFix, label: optionFix });
    changeValue({ options: [option0, option1] });
  };
  const onSubmitDin = ({ optionDin }) => {
    resetDin({ optionDin: "" });
    const option0 = options?.[0] || [];
    const option1 = options?.[1] || [];
    option1.push({ value: optionDin, label: optionDin });
    changeValue({ options: [option0, option1] });
  };
  const changeValue = useCallback(
    (obj) => {
      onChange({ ...value, ...obj });
    },
    [onChange, value]
  );
  const deleteOption = (index) => {
    onChange({ ...value, options: options.filter((f, i) => i !== index) });
  };
  const setValues = useCallback(() => {
    const newValue = { ...value };
    switch (type) {
      case "options":
        newValue.answer = "";
        newValue.options = [];
        break;
      case "true or false":
        newValue.answer = "";
        newValue.options = [
          { value: "true", label: "Verdad" },
          { value: "false", label: "Falso" },
        ];
        break;
      case "upload":
        newValue.answer = null;
        newValue.options = [];
        break;
      case "match":
        newValue.answer = [];
        newValue.options = [[], []];
        break;
      case "sort":
        newValue.answer = [];
        newValue.options = [];
        break;
      default:
        console.log("ola");
        break;
    }
    onChange(newValue);
  }, [onChange, type, value]);

  useEffect(() => {
    if (firts) {
      setValues();
    } else {
      setFirts(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  return (
    <div className="d-flex flex-wrap" id={name}>
      <div className="flex-grow-1">
        <div className="row">
          <div className="col-md-6">
            <InputC
              control={control}
              className="w-100"
              name={`${name}.question`}
              label="Pregunta"
              rules={rulesRequired}
            />
          </div>
          <div className="col-md-6">
            <SelectC
              control={control}
              className="w-100"
              name={`${name}.type`}
              label="Tipo de pregunta"
              options={reactiveOptions}
              rules={rulesRequired}
            />
          </div>
          <div className="col-md-12">
            <TextAreaC
              control={control}
              label="Texto de apoyo"
              name={`${name}.extra_info`}
            />
          </div>

          {type === "options" ? (
            <>
              <div className="col-md-6">
                <div className="d-flex align-items-center w-100">
                  <div className="flex-grow-1">
                    <InputC
                      control={control_}
                      className="w-100"
                      name="option"
                      label="Opcion"
                      rules={rulesRequired}
                    />
                  </div>
                  <PillButton
                    color="#66bb6a"
                    className="mt-4"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <i className="fa fa-plus"></i>
                  </PillButton>
                </div>
                <div className="d-flex align-items-center w-100 flex-wrap">
                  <OptionsBlock
                    options={options}
                    onDeleteOption={(i) => deleteOption(i)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <SelectC
                  control={control}
                  className="w-100"
                  name={`${name}.answer`}
                  label="Respuesta"
                  options={options}
                  rules={rulesRequired}
                />
              </div>
            </>
          ) : null}
          {type === "sort" ? (
            <>
              <div className="col-md-6">
                <div className="d-flex align-items-center w-100">
                  <div className="flex-grow-1">
                    <InputC
                      control={control_}
                      className="w-100"
                      name="option"
                      label="Opcion"
                      rules={rulesRequired}
                    />
                  </div>
                  <PillButton
                    color="#66bb6a"
                    className="mt-4"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <i className="fa fa-plus"></i>
                  </PillButton>
                </div>
                <div className="d-flex align-items-center w-100 flex-wrap">
                  <OptionsBlock
                    options={options}
                    onDeleteOption={(i) => deleteOption(i)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <LabelWrapper label="Respuesta">
                  <DragDropInputOtions
                    control={control}
                    name={`${name}.answer`}
                    options={options}
                    rules={rulesRequired}
                  />
                </LabelWrapper>
              </div>
            </>
          ) : null}
          {type === "match" ? (
            <>
              <div className="col-md-6">
                <div className="d-flex align-items-center w-100">
                  <div className="flex-grow-1">
                    <InputC
                      control={controlFix}
                      className="w-100"
                      name="optionFix"
                      label="Opcion"
                      rules={rulesRequired}
                    />
                  </div>
                  <PillButton
                    color="#66bb6a"
                    className="mt-4"
                    onClick={handleSubmitFix(onSubmitFix)}
                  >
                    <i className="fa fa-plus"></i>
                  </PillButton>
                </div>
                <div className="d-flex align-items-center w-100 flex-wrap">
                  <OptionsBlock
                    options={options[0]}
                    onDeleteOption={(i) => deleteOption(i)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center w-100">
                  <div className="flex-grow-1">
                    <InputC
                      control={controlDin}
                      className="w-100"
                      name="optionDin"
                      label="Opcion"
                      rules={rulesRequired}
                    />
                  </div>
                  <PillButton
                    color="#66bb6a"
                    className="mt-4"
                    onClick={handleSubmitDin(onSubmitDin)}
                  >
                    <i className="fa fa-plus"></i>
                  </PillButton>
                </div>
                <div className="d-flex align-items-center w-100 flex-wrap">
                  <OptionsBlock
                    options={options[1]}
                    onDeleteOption={(i) => deleteOption(i)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <LabelWrapper label="Respuesta">
                  <DragDropInputOtions
                    control={control}
                    name={`${name}.answer`}
                    options={options[1]}
                    rules={rulesRequired}
                  />
                </LabelWrapper>
              </div>
            </>
          ) : null}
          {type === "true or false" ? (
            <>
              <div className="col-md-6">
                <SelectC
                  control={control}
                  className="w-100"
                  name={`${name}.answer`}
                  label="Respuesta"
                  options={[
                    { value: "true", label: "Verdad" },
                    { value: "false", label: "Falso" },
                  ]}
                  rules={rulesRequired}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const OptionsBlock = ({ options, onDeleteOption }) => {
  return (
    <div className="d-flex flex-wrap">
      {options?.map((m, i) => (
        <OptionBlock
          key={`${m}-${i}`}
          label={m.label}
          onClick={(_) => onDeleteOption(i)}
        />
      ))}
    </div>
  );
};

const OptionBlock = ({ label, onlyBlock = false, onClick = (_) => {} }) => {
  return (
    <div
      className="d-inline-block p-1"
      style={{
        backgroundColor: "hsl(0, 0%, 90%)",
        borderRadius: "2px",
        display: "flex",
        margin: "2px",
        minWidth: "0",
        boxSizing: "border-box",
      }}
    >
      <strong className="flex-grow-1">{label}</strong>
      {!onlyBlock ? (
        <i
          className="fa fa-times text-danger ml-2 pointer"
          onClick={onClick}
        ></i>
      ) : null}
    </div>
  );
};

const DragDropInputOtions = ({ name, control, options }) => {
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isTouched, isDirty, error, invalid },
  } = useController({
    control,
    defaultValue: [],
    name,
  });
  const valueClean = Array.isArray(value) ? value : [];
  useEffect(() => {
    if (options && value && valueClean && options?.length !== value?.length) {
      onChange([
        ...valueClean.filter((ff) => options.map((m) => m.value).includes(ff)),
        ...options.map((m) => m.value).filter((ff) => !value.includes(ff)),
      ]);
    }
  }, [options, value, onChange]);
  return (
    <DragDrop
      name={name}
      items={valueClean}
      keyExtractor={(item, i) => `${item}-${i}`}
      onChange={onChange}
      renderItem={(option, index) => (
        <strong key={`${option}_${index}`}>{option}</strong>
      )}
    />
  );
};

export default CursosForm;

/*
  question: { type: String },
  type: { type: String, enum: ['options', 'true or false', 'match', 'upload'] },
  options: { type: Array },
  answer: { type: mongoose.Schema.Types.Mixed },
*/
