import React, { useMemo, useState } from "react";
import PropTypes from 'prop-types'
import { useController } from "react-hook-form";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QuillAreaC = ({
  control,
  style,
  label,
  name,
  optional,
  defaultValue,
  rules,
  onInput,
  placeholder,
  ...props
}) => {
  const {
    field: {ref, onChange, onBlur, value},
    fieldState: {invalid, isTouched, isDirty, error},
  } = useController({
    control,
    defaultValue: defaultValue,
    rules,
    name,
  });

  const formats = useMemo(() => [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ], [])
  const modules = useMemo(() =>  ( {
    toolbar: [
      
      [{ 'header': [false, 1, 2, 3, 4, 5, 6,] },{ 'font': [] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      
      [{size: []}],
      ['link', 'image'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }), [])
  
  const inputProps = {
    ...props,
    value,
    onChange:v=>{
      onChange(v);
      onInput(v);
    },
    theme:"snow",
    modules,
    formats,
    bounds:'.app',
    placeholder,
    ref,
    onBlur,
  }

  return (
  <div className={`FormC QuillAreaC${isTouched?' formc__touched':''}${isDirty?' formc__dirty':''}`} style={style}>
    {label ? <label htmlFor={`_${name}_`}>{rules.required?<strong>* </strong>:null}{label}: {optional?<span className='optional'>({typeof optional === 'string'?optional:'opcional'})</span>:null}</label> : null}
    <ReactQuill
      {...inputProps}
    />
    <span className='label__error'>{invalid ? error.message : ''}</span>
  </div>
  );
}


QuillAreaC.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  defaultValue: PropTypes.any,
};

QuillAreaC.defaultProps = {
  name: 'quill',
  rules: {},
  defaultValue: '',
  onInput: v => {},
};

export default QuillAreaC