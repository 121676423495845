import React, { PureComponent, useContext, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  ReferenceLine,
} from "recharts";
import GlobalContext from "../../context/GlobalContext";
import { Avatar } from "../../core";
import { Liston } from "../Backoffice/Perfil";
import useScreenChange from "../../hooks/useScreenChange";
import { Helmet } from "react-helmet";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export class Example extends PureComponent {
  static demoUrl = "https://codesandbox.io/s/tiny-bar-chart-35meb";
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={150} height={40} data={data}>
          <Bar dataKey="uv" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default function Kardex() {
  const {state:{user}} = useContext(GlobalContext)
  const isExterno = user?.tipo_estudiante === "Externo";
  return (
    <div className="Row Kardex">
      <div className="kardex-container">
        <div className="py-5">

          <Perfil />
          {isExterno?null:<Datos />}
        </div>
      </div>
    </div>
  );
}

const Perfil = () => {
  const { width } = useScreenChange();
  const {
    state: { rank, user },
  } = useContext(GlobalContext);
  console.log(user);
  const fullName = useMemo(
    () =>
      [user.names, user.last_name, user.mothers_last_name]
        .filter((f) => f)
        .map((m) => m.trim())
        .join(" "),
    [user]
  );
  const sizeAvatar = useMemo(() => {
    if (width > 1240) {
      return 200;
    }
    return 125;
  }, [width]);

  const tipoEstudiante = user.tipo_estudiante;
  const isExterno = tipoEstudiante === "Externo";

  return (
    <div className="contenedor-datos">
      <Helmet>
        <title>Kardex | Mentor</title>
      </Helmet>
      <div className="background-contain">
        <div className="kavatar-foto">
          <Avatar
            name={fullName}
            src={user?.image?.location}
            size={sizeAvatar}
          />
          <p className="texto-foto">
            {user?.customer_data?.zone && (
              <div>
                {isExterno ? (
                  <i className="fa-solid fa-location-pin">
                    <p
                      style={{ lineHeight: 1.2 }}
                    >{`${user?.customer_data?.country}, ${user?.customer_data.state}, ${user?.customer_data?.municipality}`}</p>
                  </i>
                ) : (
                  <p
                    style={{ lineHeight: 1.2 }}
                  >{`${user?.customer_data?.zone}, ${user?.customer_data.direction}, ${user?.customer_data?.department}`}</p>
                )}
              </div>
            )}
          </p>
        </div>
        <div className="datos-perfil">
          <h1 className="nombre-perfil">{fullName}</h1>
          {/* <h5 className="text-secondary">@LUISPEREZ88</h5> */}
        </div>
        <div className="kardex-info">
          <div>
            <h3 className="text-secondary">{rank.points}</h3>
            <small>Puntos</small>
            <br />
            <h3 className="text-secondary">{user?.time_in_platform || 0}</h3>
            <small>Tiempo.</small>
          </div>
          <div className="liston">
            <Liston points={rank?.points} />
          </div>
          <div>
            <h3 className="text-secondary">
              {rank.place}/{rank.of}
            </h3>
            <small>Ranking</small>
            <br />
            <h3 className="text-secondary">8.7</h3>
            <small>PROMEDIO</small>
          </div>
        </div>
      </div>
    </div>
  );
};

const Datos = () => {
  const {
    state: { rank, user },
  } = useContext(GlobalContext);
  const fullName = useMemo(
    () =>
      [user.names, user.last_name, user.mothers_last_name]
        .filter((f) => f)
        .map((m) => m.trim())
        .join(" "),
    [user]
  );
  return (
    <>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets">
          <p className="mb-auto">{fullName}</p>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets">
          <div className="col-12">
            <p className="mb-auto">Puesto</p>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets">
          <div className="col-12">
            <p className="mb-auto">Desarrollo o departamento</p>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets">
          <div className="col-12">
            <p className="mb-auto">Jefe directo</p>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">Cursos Y/O Capacitaciones</p>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">Cursos Y/O capacitaciones mentor</p>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets">
          <div className="back-puntos col-12 px-5 pt-3">
            
            
            
            
            
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets">
          <div className="back-puntos col-12 px-5 pt-3">
            
            
            
            
            
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">EVENTOS INSTIRUCIONALES</p>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">EVENTOS DE INTEGRACION</p>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets">
          <div className="back-puntos col-12 px-5 pt-3">
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets">
          <div className="back-puntos col-12 px-5 pt-3">
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">EVENTOS DE PRESERVA</p>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets">
          <div className="back-puntos col-12 px-5 pt-3">
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
            <div className="flex-wrap d-flex mt-5 justify-content-evenly my-3 justify-content-center">
              {/* <div className="col-md-8 px-0 ">Lorem IPSUM DOLOR</div>
              <div className="col-2 bs-shadow">SI</div>
              <div className="col-2 bs-shadow">NO</div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <Charts /> */}
    </>
  );
};

const Charts = () =>{
  return (
    <>
    <div className="flex-wrap d-flex mt-5 justify-content-evenly mt-5 justify-content-evenly">
      <div className="col-md-5 my-2 d-flex background-targets b-shadow">
        <div className="col-12">
          <p className="mb-auto">NUMERO DE OPERACIONES DE VENTA</p>
        </div>
      </div>
      <div className="col-md-5 my-2 d-flex background-targets b-shadow">
        <div className="col-12">
          <p className="mb-auto">NUMERO DE OPERACIONES DE VENTA</p>
        </div>
      </div>
    </div>
    <div className="flex-wrap d-flex mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets">
          <div
            className="col-12 px-5 pt-3"
            style={{ width: "100%", height: 300 }}
          >
            <ResponsiveContainer>
              <BarChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <ReferenceLine y={0} stroke="#000" />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets">
          <div
            className="col-12 px-5 pt-3 overflow-auto"
            style={{ width: "100%", height: 300 }}
          >
            {/* GRAFICA */}
            <ResponsiveContainer>
              <LineChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">TIPO DE OPERACION</p>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">MONTO DESPLAZADO VENTA</p>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets">
          <div
            className="col-12 px-5 pt-3"
            style={{ width: "100%", height: 300 }}
          >
            <ResponsiveContainer>
              <BarChart
                layout="vertical"
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid />
                <XAxis />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets">
          <div
            className="col-12 px-5 pt-3"
            style={{ width: "100%", height: 300 }}
          >
            <ResponsiveContainer>
              <BarChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <ReferenceLine y={0} stroke="#000" />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">MONTO DESPLAZADO RENTA</p>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">MONTO DESPLAZADO DE BATEO EN LEADS</p>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets">
          <div
            className="col-12 px-5 pt-3"
            style={{ width: "100%", height: 300 }}
          >
            <ResponsiveContainer>
              <LineChart width={500} height={400} data={data}>
                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets">
          <div
            className="col-12 px-5 pt-3"
            style={{ width: "100%", height: 300 }}
          >
            <ResponsiveContainer>
              <BarChart
                layout="vertical"
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid />
                <XAxis />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">MONTO DE ULTIMA OPERACION</p>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">FECHA DE ULTIMA OPERACION</p>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets">
          <div
            className="col-12 px-5 pt-3"
            style={{ width: "100%", height: 300 }}
          >
            <ResponsiveContainer>
              <BarChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <ReferenceLine y={0} stroke="#000" />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets">
          <div
            className="col-12 px-5 pt-3"
            style={{ width: "100%", height: 300 }}
          >
            <ResponsiveContainer>
              <LineChart width={500} height={400} data={data}>
                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">RECORD DE OPERACION POR MES</p>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets b-shadow">
          <div className="col-12">
            <p className="mb-auto">OBSERVACIONES Y COMENTARIOS</p>
          </div>
        </div>
      </div>
      <div className="flex-wrap d-flex mt-5 justify-content-evenly">
        <div className="col-md-5 my-2 d-flex background-targets">
          <div
            className="col-12 px-5 pt-3"
            style={{ width: "100%", height: 300 }}
          >
            <ResponsiveContainer>
              <BarChart
                layout="vertical"
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid />
                <XAxis />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="col-md-5 my-2 d-flex background-targets">
          <div className="col-12 px-5 pt-3">COMENTARIOS</div>
        </div>
      </div>
    </>
  )
}