import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  Alert,
  Button,
  Dialog,
  Grid,
  InputC,
  LinkPillButton,
  PillButton,
  Tooltip,
} from "../../core";

//Hooks
import { useForm } from "react-hook-form";
import useS3File from "../../hooks/useS3File";
import useFormatter from "../../hooks/useFormatter";

//Data
import GlobalContext from "../../context/GlobalContext";
import { Helmet } from "react-helmet";
import { UserBillForm } from "../../components/Forms/UserForm";
import Swal from "sweetalert2";

/**
 * @component
 * Component responsible for displaying order and billing information
 */

export default function ListaPedidos() {
  const { setAppLoading, NestGet } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState();
  const [show, setShow] = useState("");
  const columnas = [
    {
      title: "N.Pedido",
      key: "n_pedido",
    },
    {
      title: "Cursos",
      key: "courses",
    },
    {
      title: "Precio comprado",
      key: "amount",
    },
    {
      title: "Estatus",
      key: "stripe.paymentIntent.status",
    },
    {
      title: "",
      key: "action",
    },
  ];

  const fetchData = useCallback(
    async (page = 1) => {
      setAppLoading(true);
      NestGet({
        schema: `orders/user/detail`,
      })
        .then((res) => {
          const columnData = [];
          let index = 0;
          for (const ord of res.data) {
            console.log(`pedido ${ord.n_pedido}`, ord);
            columnData.push({
              n_pedido: ord.n_pedido,
              user: ord.user,
              courses:
                ord.num_course > 1
                  ? ord.num_course
                  : res.data[index].courses[0].name,
              amount: ord.amount,
              stripe: ord.stripe,
              notes: ord.notes,
              _id: ord._id,
              coursesInfo: ord.courses,
              buy_date: ord.buy_date,
              discount: ord.discount,
              available_invoice: ord.available_invoice,
            });
            index++;
          }
          setData(columnData);
          //setData(res.data);
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading]
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="Pedidos">
      <Helmet>
        <title>Pedidos | Mentor</title>
      </Helmet>
      <div className="PedidoPage">
        <div className="pedidos-container">
          <Grid
            rowKey="_id"
            columns={columnas}
            dataSource={data}
            className="table-striped"
            pagination={pagination}
            onChangePage={fetchData}
          >
            <Grid.Column
              name="courses"
              context={({ column, row }) => (
                <div className="d-flex">
                  {row.courses > 1 ? (
                    <strong>
                      <a>{row.courses}</a>
                    </strong>
                  ) : (
                    <a>{row.courses}</a>
                  )}
                </div>
              )}
            />
            <Grid.Column
              name="n_pedido"
              context={({ column, row }) => (
                <div className="d-flex">{`${column
                  .toString()
                  .padStart(4, "0")}`}</div>
              )}
            />
            <Grid.Column
              name="amount"
              context={({ column, row }) => (
                <div className="d-flex">
                  {column ? `$${Number(column).toFixed(2)}` : "Gratis"}
                </div>
              )}
            />
            <Grid.Column
              name="stripe.paymentIntent.status"
              context={({ column, row }) => (
                <div className="d-flex">{column}</div>
              )}
            />
            <Grid.Column
              name="created_at"
              context={({ column, row }) => (
                <div className="d-flex">
                  {moment(new Date(column), "YYYY-MM-DD[T]HH:mm:ss").format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                </div>
              )}
            />
            <Grid.Column
              name="updated_at"
              context={({ column, row }) => (
                <div className="d-flex">
                  {moment(new Date(column), "YYYY-MM-DD[T]HH:mm:ss").format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                </div>
              )}
            />
            <Grid.Column
              name="action"
              context={({ column, row }) => (
                <div className="d-flex">
                  <LinkPillButton to={`/Detalles/Pedido/${row._id}`}>
                    <i className="fas fa-eye"></i>
                  </LinkPillButton>

                  {row.available_invoice ? (
                    <Tooltip tip="Solicitud de factura" align="left">
                      <PillButton
                        color="#00897B"
                        onClick={(_) => setShow(row._id)}
                      >
                        <i className="fas fa-file-invoice"></i>
                      </PillButton>
                    </Tooltip>
                  ) : null}
                </div>
              )}
            />
          </Grid>
        </div>
      </div>
      <Dialog
        visible={!!show}
        onClose={(_) => {
          setShow("");
        }}
        classNameModalDialog="bill__modal"
      >
        <Dialog.Header>
          <h4>
            Solicitud de factura #
            {data
              .find((f) => f._id === show)
              ?.n_pedido.toString()
              .padStart(4, "0")}
          </h4>
        </Dialog.Header>
        <strong>
          Para solicitar tu factura esta es la información para la misma,
          confirmar antes de continuar
        </strong>
        <BillRequest
          order={data.find((f) => f._id === show)}
          onClose={(_) => {
            fetchData();
            setShow("");
          }}
        />
      </Dialog>
    </div>
  );
}

const BillRequest = ({ order, onClose }) => {
  const {
    state: { user },
    setState,
  } = useContext(GlobalContext);
  const { NumberF } = useFormatter();
  const { NestGet, NestPatch, NestPost, setAppLoading } =
    useContext(GlobalContext);
  const { control, handleSubmit, setValue, unregister } = useForm();

  const fetchData = useCallback(() => {
    NestGet({ schema: "user", id: user._id }).then((res) => {
      if (res.data.id) {
        const {
          customer_data,
          bill_data,
          phone,
          names,
          last_name,
          mothers_last_name,
          email,
          image,
          tipo_estudiante,
          time_in_platform,
        } = res.data;

        if (res.status === "success") {
          Object.keys({
            customer_data,
            bill_data,
            phone,
            names,
            last_name,
            mothers_last_name,
            email,
            image,
            tipo_estudiante,
            time_in_platform,
          }).forEach((key) => {
            setValue(key, res.data[key]);
          });
          const user = {
            ...res.data,
            _id: res.data.id,
          };
          console.log(user);
          setState({ user: user });
        }
      }
    });
  }, [NestGet, user._id, setState, setValue]);

  const onSubmit = useCallback(
    async (data) => {
      setAppLoading(true);
      NestPatch({ schema: "user", body: data, id: user._id })
        .then((res) => {
          fetchData();
          setAppLoading(false);
        })
        .catch((err) => {});
      NestPost({
        schema: `orders/requestInvoice/${order._id}`,
        body: data.bill_data,
        id: user._id,
      })
        .then((res) => {
          onClose();
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1300,
          });
          Toast.fire({
            icon: "success",
            title: "Su solicitud fue enviada.",
          });
        })
        .catch((err) => {});
    },
    [
      setAppLoading,
      user._id,
      NestPatch,
      NestPost,
      order._id,
      fetchData,
      onClose,
    ]
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <UserBillForm control={control} />
      <InputC
        disabled
        control={control}
        defaultValue={NumberF(order.amount, 1)}
        label="Monto pagado"
      />
      <Alert type="warm">
        <p>
          <strong>
            Verifique que sus datos estén correctos, una vez enviado los datos,
            no aplican modificaciones o cancelaciones.{" "}
          </strong>
        </p>
        <p>
          <a
            href="/aviso_de_privacidad"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Consulta de Aviso de Privacidad</strong>
          </a>
        </p>
      </Alert>
      <div className="d-flex justify-content-end">
        <Button onClick={handleSubmit(onSubmit)}>Solicitar</Button>
      </div>
    </>
  );
};
