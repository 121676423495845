import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import "../Forms.scss";

const Option = ({ children, onClick, value, option, name, readOnly }) => {
  const isActive = option === value ? " choose-option__active" : "";
  const cursorCancel = readOnly ? " cursor_cancel" : "";
  return (
    <label className={`choose-option${isActive}${cursorCancel}`}>
      <input type="radio" value={value} name={name} disabled={readOnly} />
      {children}
    </label>
  );
};

const ChooseC = ({
  name,
  control,
  rules,
  children,
  defaultValue,
  onInput,
  style,
  className,
  readOnly,
  pure,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    control,
    defaultValue,
    rules,
    name,
  });

  const handleClick = (v) => {
    if (readOnly) {
      return;
    }
    let val = v.target.value;
    val = pure ? val : val === "true" || (val === "false" ? false : val);
    onChange(val);
    onInput(val);
  };

  const options = useMemo(
    () =>
      (Array.isArray(children) ? children : [children]).filter(
        (ff) => ff.type === Option
      ),
    [children]
  );
  const isTouched_ = isTouched ? " formc__touched" : "";
  const isDirty_ = isDirty ? " formc__dirty" : "";
  const className_ = className ? ` ${className}` : "";

  return (
    <div
      className={`FormC ChooseC${isTouched_}${isDirty_}${className_}`}
      style={style}
      onClick={onBlur}
      onChange={handleClick}
    >
      {options.map(({ props }, i) => (
        <Option
          key={i}
          {...props}
          option={value}
          name={name}
          readOnly={readOnly}
        />
      ))}
      <span className="label__error">{invalid ? error.message : ""}</span>
    </div>
  );
};
//function Option({name,option:{label,value},checked})

ChooseC.Option = Option;

ChooseC.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  defaultValue: PropTypes.any,
  pure: PropTypes.bool,
  readOnly: PropTypes.bool,
};

ChooseC.defaultProps = {
  name: "choose",
  rules: {},
  onInput: (v) => {},
};

export default ChooseC;
