import React, { useContext } from "react";
import { Link } from "react-router-dom";
import useScreenChange from "../../hooks/useScreenChange";
import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/images/mentor_white_B.png";
import movillogo from "../../assets/images/mentor_white_A.png";
import facebook_footer from "../../assets/images/facebook_footer.png";
import instragram_footer from "../../assets/images/instragram_footer.png";
import web_footer from "../../assets/images/web_footer.png";
import spotify from "../../assets/images/spotify.png";
import { WrapAnimation } from "../../core";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  const { width } = useScreenChange();
  const {
    isLoadedState,
    setVolatileState,
    setState,
    state: { language, token },
  } = useContext(GlobalContext);

  return (
    <footer>
      <nav className="">
        <WrapAnimation
          className="item"
          classAnimation="animate__animated animate__fadeIn"
        >
          <div>
            <img src={logo} alt="" />
          </div>
          <ul className="network">
            <li>
              <a href="https://www.facebook.com/mentordesarrollohumano/" target="_blank" rel="noopener noreferrer">
                <img
                  src={facebook_footer}
                  alt="logo de facebook"
                />
              </a>
            </li>
            <li>
              <a href="https://instagram.com/mentordh?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                <img
                  src={instragram_footer}
                  alt="logo de instagram"
                />
              </a>
            </li>
            <li>
              <a href="https://mentordh.mx/" target="_blank" rel="noopener noreferrer">
                <img
                  src={web_footer}
                  alt="logo de web site"
                />
              </a>
            </li>
            <li>
              <a href="https://open.spotify.com/show/3EHzUqvgfEu4Ud9NRQ4nEw?si=sItIu-OZQemk__nGRkFUAA&utm_source=whatsapp" target="_blank" rel="noopener noreferrer">
                <img src={spotify} alt="logo de web site" />
              </a>
            </li>
          </ul>
        </WrapAnimation>
        <WrapAnimation
          className="item"
          classAnimation="animate__animated animate__fadeIn"
        >
          <ul>
            <li>
              <Link to="/aviso_de_privacidad">Aviso de privacidad</Link>
            </li>
          </ul>
        </WrapAnimation>
      </nav>

      <div className="text-footer">
        <p className="text-center mb-0">
          Copyright @ Mentor 2022 | Todos los derechos reservados
        </p>
      </div>
    </footer>
  );
};

export default Footer;
