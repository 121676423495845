import React, { useContext } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import cursoImg from "../../assets/mocks/activo-5.png";

export default function CarritoItem({ item }) {
  const { CartDeleteCurso } = useContext(GlobalContext);
  return (
    
    <div className="d-flex my-5 flex-wrap align-items-center" >
      <Link to={`/curso/${item.sku}`} className="col-md-11 d-flex flex-wrap align-items-center">
      <div className="col-md-5">
        <img
          style={{ borderRadius: 16 }}
          src={item?.image?.location||cursoImg}
          alt={`imagen del ${item.name}`}
        />
      </div>
      <div className="col-md-5">
        
          <h4 className="cart-name">{item.name}</h4>
        
        <p className="cart-description">{item.description}</p>
        <small className="cart-teacher">{item.teacher}</small>
      </div>
      <div className="div-cart-trash">
        <h4 className="cart-cost text-right">
          {item.cost > 0 ? `$ ${item.cost.toFixed(2)}` : "Gratis"}
        </h4>
      </div>
      </Link>
      <div className="col-1 div-cart-trash">
        <h4
          className="cart-trash"
          onClick={(_) => CartDeleteCurso(item.list_id)}
        >
          <i className="fa-solid fa-trash"></i>
        </h4>
      </div>
    </div>
  );
}
