import React from "react";
import PropTypes from "prop-types";
import { colors } from "../../Color";
import Avatar from './Avatar'
import "./Avatar.scss";


const AvatarWhitLabel = ({ src, name, color, size, onClick }) => {
    return (
        <div className="AvatarWhitLabel">
            <Avatar src={src} name={name} color={color} size={size} onClick={onClick} />
            <span className="avatar-label">{name}</span>
        </div>
    )
}

AvatarWhitLabel.propTypes = {
    src: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
    onClick: PropTypes.func
};
AvatarWhitLabel.defaultProps = {
    name: "Avatar",
    color: colors[Math.floor(Math.random() * colors.length)],
    size: 30,
};

export default AvatarWhitLabel
