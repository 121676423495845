import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {useController} from 'react-hook-form';
import '../Forms.scss'

const SwitchC = ({
  label,
  name,
  control,
  rules,
  style,
  defaultValue,
  vertical,
  options,
  onInput,
  color,
  className,
  optional,
  readOnly,
  disabled,
  ...props
}) => {
  
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    control,
    defaultValue,
    rules,
    name,
  });
  
  const isValue = useCallback(
    option => {
      return option===value
    },
    [value],
  )

  const inputProps = {
    ...props,
    name,
    onChange:v=>{
      let val = v.target.value
      val = val === 'true'||(val === 'false'?false:val)
      onChange(val);
      onInput(val);
    },
    value,
    disabled: disabled||readOnly
  };
  
  useEffect(() => {
    
    return () => {
    };
  }, [])
  return (
    <div className={`FormC SwitchC${isTouched ? " formc__touched" : ""}${isDirty ? " formc__dirty" : ""}`} style={{...style,...color?{'--color-switch':color}:{}}} onClick={onBlur}>
      {label ? <label htmlFor={`_${name}_`}>{rules.required?<strong>* </strong>:null}{label}: {optional?<span className='optional'>({typeof optional === 'string'?optional:'opcional'})</span>:null}</label> : null}
      <div id={`_${name}_`} className={`formc-input switch-container${vertical?' switch_conteiner__vertical':''} ${className||''}`} onChange={inputProps.onChange}>
          {options.map(option=><Option {...inputProps} option={option} key={option?.value||option} checked={isValue(option.value)} />)}
      </div>
      
      <span className="label__error">{invalid ? error.message : ""}</span>
    </div>
  );
};

function Option({name,option:{label,value},checked,...props}){
  return(<>
   <label className={`switch-item${checked?' switch-item__selected':''}`}><input {...props} type="radio" value={value} name={name} /> {label}</label>
  </>)
}

SwitchC.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  defaultValue: PropTypes.any,
  options: PropTypes.array,
  vertical: PropTypes.bool,
};
SwitchC.defaultProps = {
  defaultValue: '',
  name: "input",
  rules: {},
  onInput: (v) => {},
  options: [],
  vertical: false,
};

export default SwitchC;
