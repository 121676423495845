import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import moment from "moment";

//Hooks
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

//Data
import GlobalContext from "../../context/GlobalContext";
import { usoCDFI } from "../../Constants";
import useFormatter from "../../hooks/useFormatter";

/**
 * @component
 * Component responsible for displaying order and billing information
 */

const PedidoDetalle = () => {
  let { id } = useParams();
  const { setAppLoading, NestGet } = useContext(GlobalContext);
  const { NumberF } = useFormatter();
  const [data, setData] = useState(null);
  const monto = useMemo(() => {
    const iva = (data?.amount || 0) * (1 - 1 / 1.16);
    const subtotal = (data?.amount || 0) - iva;
    return { iva, subtotal };
  }, [data?.amount]);
  const fetchData = useCallback(
    async (page = 1) => {
      setAppLoading(true);
      NestGet({
        schema: `orders/detail/${id}`,
      })
        .then((res) => {
          const columnData = [];
          res.data.forEach((ord) => {
            columnData.push({
              n_pedido: ord.n_pedido,
              user: ord.user,
              courses:
                ord.num_course > 1
                  ? ord.num_course
                  : res.data[0].courses[0].name,
              amount: ord.amount,
              stripe: ord.stripe,
              notes: ord.notes,
              _id: ord._id,
              coursesInfo: ord.courses,
              buy_date: ord.buy_date,
              discount: ord.discount,
              request_invoice: ord.request_invoice,
            });
          });
          setData(columnData[0]);
        })
        .finally((_) => {
          setAppLoading(false);
        });
    },
    [NestGet, setAppLoading]
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="work_area">
      {data && (
        <div>
          <div className="row">
            <div className="w-60 mx-auto">
              <h1>Detalle del pedido</h1>
            </div>
          </div>
          <div className="row">
            <div className="w-60 mx-auto">
              <h2>#{id}</h2>
            </div>
          </div>
          <div className="row">
            <div className="modal-body row">
              <div className="col-md-6">
                <div className="w-75 mx-auto ">
                  <p className="h2 p-2">
                    <strong>Información del pedido</strong>
                  </p>
                  <p className="h3 p-2">
                    <strong>Usuario: </strong> {data.user}
                  </p>
                  <p className="h3 p-2">
                    <strong>Fecha y hora: </strong>
                    {moment(
                      new Date(data.buy_date),
                      "YYYY-MM-DD[T]HH:mm:ss"
                    ).format("DD/MM/YYYY HH:mm:ss")}
                  </p>
                  <p className="h3 p-2">
                    <strong>Precio: </strong>{" "}
                    {"$" + Number(data.amount).toFixed(2)}
                  </p>
                  <p className="h3 p-2">
                    <strong>Estatus: </strong>
                    {data?.stripe?.status ||
                      data?.stripe?.paymentIntent?.status ||
                      ""}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <p className="h2 p-2">
                  <strong>Cursos adquiridos:</strong>
                </p>

                {data &&
                  data.coursesInfo.map((course) => {
                    return (
                      <div
                        className="container table table-bordered "
                        style={{ backgroundColor: "#f7f9fc" }}
                        key={course._id}
                      >
                        <p className="h4 p-2 ">
                          {course.name} #{course._id}
                        </p>
                        <p className="h5 p-2">{"" + course.description}</p>
                        <p className="h6 p-2">{course.teacher}</p>
                        <div className="d-flex justify-content-between bg-light">
                          <div></div>
                          <p className="h3 ">{"$" + course.cost}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="row">
            {data.request_invoice ? (
              <div
                className="col table table-bordered "
                style={{ backgroundColor: "#f7f9fc" }}
              >
                <p className="h2 p-2">
                  <strong>Información para factura:</strong>
                </p>
                <p className="h3 p-2">
                  <strong>Persona: </strong> {data.request_invoice.persona}
                </p>
                {data.request_invoice.persona === "Moral" ? (
                  <p className="h3 p-2">
                    <strong>Razón Social: </strong>{" "}
                    {data.request_invoice.business_name}
                  </p>
                ) : (
                  <p className="h3 p-2">
                    <strong>Nombre completo: </strong>{" "}
                    {data.request_invoice.full_name}
                  </p>
                )}
                <p className="h3 p-2">
                  <strong>RFC: </strong> {data.request_invoice.rfc}
                  <strong className="ml-2">Correo: </strong>{" "}
                  {data.request_invoice.email}
                </p>
                <p className="h3 p-2">
                  <strong>Régimen : </strong> {data.request_invoice.regimen}
                </p>
                <p className="h3 p-2">
                  <strong>Uso CFDI: </strong>{" "}
                  {
                    usoCDFI.find((f) => f.use === data.request_invoice.cfdi_use)
                      ?.label
                  }
                </p>
                <p className="h3 p-2">
                  <strong>Domicilio Fiscal: </strong>{" "}
                  {data.request_invoice.tax_residence}
                </p>
                <p className="h3 p-2">
                  <strong>CP: </strong> {data.request_invoice.cp}
                </p>
                <p className="h3 p-2">
                  <strong>Monto pagado: </strong>{" "}
                </p>
                <ul className="h3">
                  <li>Subtotal: {NumberF(monto.subtotal, 1)}</li>
                  <li>IVA: {NumberF(monto.iva, 1)}</li>
                  <li>Total: {NumberF(data.amount, 1)}</li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default PedidoDetalle;
