import React, { useEffect, useMemo, useState } from "react";
import {Link} from 'react-router-dom'
import Slider from "react-slick";
import PropTypes from "prop-types";
import Curso from "../Cards/Curso";
import useScreenChange from "../../hooks/useScreenChange";
import { Title } from "../../core";

import './Sliders.scss'

const CategoriaSlider = ({title, withProgress, to, curses}) => {
  const { width } = useScreenChange();
  const cardDimencion = useMemo(() => {
    return width>768?352:300
  }, [width])
  const Wraper = ({children})=> to ? <Link to={to}><i className="fas fa-mouse-pointer mr-2"></i>{children}</Link>:<>{children}</>
  return (
    <div className="CategoriaSlider">
      <div className="categoria-title"><Title color="#345361"><Wraper>{title}</Wraper></Title></div>
      <Sliders slidesToShow={Math.floor((width-76) / cardDimencion)}>
        {curses.map(m=><Curso hasProgress={withProgress} progress={Math.floor(Math.random()*50)-20} key={m.id} {...m} />)}
      </Sliders>
    </div>
    
  );
};

const Sliders = ({ children, slidesToShow=1 }) => {
  
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow||1,
    slidesToScroll: Math.floor(slidesToShow/2)||1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: true,
  };

  
  return (
  <>
  {Array.isArray(children)&&children.length>=slidesToShow?
  <Slider className="" {...settings}>{children}</Slider>:
  <div className="d-flex">{children}</div>
  }</>);
  
};

CategoriaSlider.propTypes = {};

export default CategoriaSlider;
