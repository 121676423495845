import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

const PlacesInputC = ({ label, name, control, rules, onInput, onPlaceChange, optional, ...props }) => {
  const refLocationSearch = useRef(null);
  const autocomplete = useRef(null);

  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    control,
    defaultValue: "",
    rules,
    name,
  });
  const inputProps = {
    ...props,
    ref: refLocationSearch,
    onBlur
  };
  
  useEffect(() => {
    let listenerHandle;

    const leadingMaps = async ()=>{
        //google = await loader.load();
        autocomplete.current = new window.google.maps.places.Autocomplete(
        refLocationSearch.current,
        { types: ["geocode"], location: window.location }
        );
        autocomplete.current.setComponentRestrictions({
        country: ["mx"], //Restriccion de ubicaciones solo a mexico
        });
        // Cuando el usuario selecciona una dirección en el menú desplegable,
        // rellena los campos de dirección en el formulario.
        listenerHandle = window.google.maps.event.addListener(
        autocomplete.current,
        "place_changed",
        function () {
            // Cuando se modifica la ubicacion seleccionada se llama la funcion fillInAddress
            const componentForm = {
                sublocality_level_1: 'long_name', //Colonia
                locality: 'long_name', //Ciudad
                administrative_area_level_1: 'long_name' //Estado
            }
            const newValue = {}
            // Obtener los detalles de lugar el objeto de autocompletado.
            const place = autocomplete.current.getPlace();
            if(!place.geometry){
              newValue[`location_${name}`] = place.name
              onChange(newValue)
              onInput(newValue)
              return
            }
            
            //console.log(place.geometry);
            newValue[`lon_${name}`] = place.geometry.location.lng();
            newValue[`lat_${name}`] = place.geometry.location.lat();
        
            // Recibe cada componente de la dirección de los lugares más detalles
            // y llena el campo correspondiente en el formulario.
            for (var i = 0; i < place.address_components.length; i++) {
              let addressType = place.address_components[i].types[0];
              if (componentForm[addressType]) {
                newValue[`${addressType}_${name}`] = place.address_components[i][componentForm[addressType]];
              }
            }
            newValue[`location_${name}`] = refLocationSearch.current.value
            onChange(newValue)
            onInput(newValue)
            onPlaceChange(place)
        }
        );
    }
    leadingMaps()
    return () => {
      
      window.google.maps.event.removeListener(listenerHandle);      
      autocomplete.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInput,onPlaceChange, name]);
  useEffect(()=>{
      if(value[`location_${name}`]!==refLocationSearch.current.value){
        refLocationSearch.current.value = value[`location_${name}`]||''
      }
  },[value,name])
  return (
    <div className={`${isTouched ? " formc__touched" : ""}${isDirty ? " formc__dirty" : ""}`} >
      {label ? (<label htmlFor={`location_${name}`}>{rules.required?<strong>* </strong>:null}{label}: {optional?<span className='optional'>({typeof optional === 'string'?optional:'opcional'})</span>:null}</label>) : null}
      
      <input id={`location_${name}`}
        className="formc-input"
        {...inputProps}
      />
      <span className="label__error">{invalid ? error.message : ""}</span>
    </div>
  );
};

PlacesInputC.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  onPlaceChange: PropTypes.func,
  defaultValue: PropTypes.any,
};

PlacesInputC.defaultProps = {
  name: "input",
  rules: {},
  onInput: (v) => {},
  onPlaceChange: (v) => {},
};

export default PlacesInputC;
