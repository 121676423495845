/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import reactDom from "react-dom";

const ImageFullScreen = (props) => {
  const [show, setShow] = useState(false);
  const portalRef = useRef(document.querySelector("#portal"));
  return (
    <>
      <img {...props} style={{ cursor: "pointer" }} onClick={(_) => setShow(true)} />
      {show
        ? reactDom.createPortal(
            <div className="Fullscreen">
              <div className="d-flex justify-content-end align-items-center">
                <i className="fa fa-times text-white fa-lg pointer" onClick={_=> setShow(false)}></i>
              </div>
              <img src={props.src} alt={props.alt} style={{width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center'}} />
            </div>,
            portalRef.current
          )
        : null}
    </>
  );
};

export default ImageFullScreen;
