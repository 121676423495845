/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import PropTypes from "prop-types";
import CollapserArrowSVG from "../../../assets/svgs/CollapserArrowSVG";
import "./Item.scss";

const CollapserItem = ({ to, icon, text, children, isShow }) => {
  const [show, setShow] = useState(isShow);
  const Icon = icon;
  return (
    <li className={`menu-item menu-item__collapser menu-item__${show?'show':'close'}`}>
      <a className="menu-item-anchor" onClick={() => setShow(!show)}>
        <Icon />
        <div className="menu-item-tooltip">{text}</div>
        <span className="menu-item-tooltiptext tooltip__right">{text}</span>
        <CollapserArrowSVG />
      </a>
      {children?<ul>{show ?children: null}</ul>:null}
    </li>
  );
};

CollapserItem.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string.isRequired,
  isShow: PropTypes.bool,
};
CollapserItem.defaultProps = {
  icon: () => <></>,
  isShow: false,
};

export default CollapserItem;
