import React, { useState } from 'react';
import { GoogleMap} from '@react-google-maps/api';
import PropTypes from 'prop-types'
import { useRef } from 'react';


const MapC = ({children,zoom,center,containerStyle,onZoomChanged,...props}) => {
    const ref = useRef(null)
    const [load, setLoad] = useState(false)
    return (
        
            <GoogleMap
            {...props}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onZoomChanged={z=>{
                try {
                    onZoomChanged(ref.current.getZoom())
                } catch (error) {
                    onZoomChanged(zoom)
                }
            }}
            onLoad={m=>{
                setLoad(true)
                ref.current = m
            }}
            >
                {load?children:null}
            </GoogleMap>
      
    )
}

MapC.propTypes = {
    containerStyle: PropTypes.object,
    center: PropTypes.object,
    zoom: PropTypes.number,
    onZoomChanged: PropTypes.func
}

MapC.defaultProps = {
    containerStyle:{
        width: '100%',
        height: '100%'
    },
    center: { lat: 20.6596988, lng: -103.3496092 },
    zoom: 10,
    onZoomChanged: z=>z
}

export default MapC
