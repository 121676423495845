import React, { useContext } from 'react'
import GlobalContext from '../../context/GlobalContext'
import { Dialog } from '../../core'
import Login,{Forgot, Registro} from '../../pages/Inicio/Login'

const DialogLogin = () => {
    const {volatileState:{loginDialog}, setVolatileState} = useContext(GlobalContext)
  return (
    <Dialog visible={!!loginDialog} onClose={_=>setVolatileState({loginDialog:''})}>
        <Registro />
        
    </Dialog>
  )
}

export default DialogLogin