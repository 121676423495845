import React from "react";
import { Helmet } from "react-helmet";

/**
 * @component
 * Component responsible for showing the privacity policity
 */

const AvisoPrivacidad = () => {
  return (
    <div className="AvisoPrivacidad not-container">
      <Helmet>
        <title>Aviso de privacidad | Mentor</title>
      </Helmet>
      <div className="container-fluid" style={{ padding: 0, height: "70vh" }}>
        <div className="hero-image">
          <div className="hero-text">
            <h1 style={{ fontSize: 50, color: "#fff" }}>
              AVISO DE PRIVACIDAD INTEGRAL
            </h1>
          </div>
        </div>
      </div>
      <section className="container wow fadeIn pt-5 pb-5">
        <div className="container privacity_zone">
          <div className="row row_pv text-justify">
            <h1>Introducción</h1>
            <p className="privacity_p">
              El presente Aviso de Privacidad se expide en base a Normas Oficiales Mexicanas: Lineamientos del Aviso de Privacidad publicado en el Diario Oficial de la Federación (D.O.F.) el día 17 de Enero de 2013, la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, la Constitución Política de los Estados Unidos Mexicanos y el Convenio 108 del Convenio de Europa y su Protocolo.
            </p>
            <h2>Identificación del Responsable</h2>
            <p className="privacity_p">
              ANTHROPOS DESARROLLO HUMANO, SOCIEDAD CIVIL (En lo sucesivo denominado como “EL RESPONSABLE”) con domicilio en Avenida Pablo Neruda número 2813, Colonia Providencia 4ta Sección, Ciudad de Guadalajara, Estado de Jalisco, Código Postal 44639 y dominio web
              &nbsp;
              <a
                className="text-underline"
                href="http://www.mentordh.mx"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.mentordh.mx
              </a>
              &nbsp;
              siempre preocupado por la seguridad y protección de datos personales, da a conocer a sus clientes, proveedores, usuarios de plataformas digitales y cualquier otra persona física (TITULAR) que por cualquier motivo entregue o haya entregado información personal, lo siguiente:
            </p>
            <h2>Finalidad del tratamiento</h2>
            <h4>¿Cuáles y para qué fines utilizaremos sus datos personales?</h4>
            <p className="privacity_p">
              Los datos personales proporcionados por usted, serán utilizados para las siguientes finalidades necesarias para el servicio que prestamos:
              <br />
              <br />
              Finalidades Primarias: Son aquellas necesarias para llevar y mantener una relación con usted, para el servicio que solicita.
              <br />
              <br />
              Finalidades Secundarias: Son aquellas que no cumplen la condición de finalidad primaria y que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención.
              <br />
              <br />
              Finalidades mercadotécnicas, publicitarias o de prospección comercial: Son aquellas utilizadas para darnos a conocer en el mercado y captar clientes.
              <br />
            </p>

            <table>
              <tr>
                <td colSpan={2}>
                  CLIENTES (datos personales de aquellos a los que prestamos
                  servicios)
                </td>
              </tr>
              <tr>
                <td colSpan={2}>FINALIDAD PRIMARIA</td>
              </tr>
              <tbody>
                <td>Dato</td>
                <td>Finalidad</td>
              </tbody>
              <tbody>
                <td>
                  Identificación oficial (que puede incluir imagen, nombre
                  completo, domicilio, CURP, número de identificación, número de
                  pasaporte, nacionalidad)
                </td>
                <td>Para verificar y confirmar su identidad.</td>
              </tbody>
              <tbody>
                <td>Nombre completo</td>
                <td>
                  Para cumplir con nuestras obligaciones fiscales al facturarle.
                  <br />
                  Para llevar a cabo la inscripción como alumno en nuestro
                  sistema de capacitación.
                  <br />
                  Para entregarle material de apoyo en capacitación
                  personalizado.
                  <br />
                  Enviarle cotización y/o información de nuestros servicios
                  personalizada
                  <br />
                  Para entregarle certificado o constancia al tomar cualquiera
                  de los cursos o servicios que le ofrecimos.
                  <br />
                </td>
              </tbody>
              <tbody>
                <td>Su imagen personal y voz identificable</td>
                <td>
                  Cuando sea grabada una sesión de educación y/o capacitación, de manera presencial o virtual, para guardar evidencia del trabajo realizado y poderlo compartir el material a los usuarios con derecho a visualizar la grabación.
                  <br />
                  <br />
                  Cuando se utilice una plataforma de firmas digitales en la que se requiera para dar trámite a dicho procedimiento.
                </td>
              </tbody>
              <tbody>
                <td>Su firma autógrafa</td>
                <td>
                  Cuando sea necesaria para establecer una relación jurídica o comercial con usted
                </td>
              </tbody>
              <tbody>
                <td>
                  Su correo personal y teléfono (puede ser número de celular y/o
                  oficina y/o casa)
                </td>
                <td>
                  Para mantener contacto con usted
                  <br />
                  <br />
                  Para enviarle material de capacitación, cotizaciones,
                  información solicitada.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  FINALIDAD SECUNDARIA: Usted tiene derecho a no otorgar consentimiento para el tratamiento de sus datos personales para fines secundarios.
                </td>
              </tr>
              <tbody>
                <td>Su correo electrónico</td>
                <td>
                  Para enviarle información general sobre nuestros cursos y servicios, distintos a los solicitados por usted
                </td>
              </tbody>
              <tbody>
                <td>Su número de celular</td>
                <td>
                  Para enviarle información general sobre nuestros cursos y servicios distintos,  ya sea por llamada telefónica o por mensaje en su  celular, incluyendo plataformas de mensajería instantánea.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  FINALIDAD MERCADOTECNICA, PUBLICITARIA O DE PROSPECCIÓN COMERCIAL
                </td>
              </tr>
              <tbody>
                <td>Su imagen identificable</td>
                <td>
                  Para dar testimonio de nuestro trabajo subiendo a nuestras redes sociales y/o sitios web fotografías y/o videos donde usted se identifique.
                </td>
              </tbody>
              <tbody>
                <td>Su voz</td>
                <td>
                  Para dar testimonio de nuestro trabajo subiendo a nuestras redes sociales y/o sitios web fotografías y/o videos donde se escuche su  voz identificable.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  CAPACITADORES (datos personales de aquellos que participan proporcionando capacitación)
                </td>
              </tr>
              <tbody>
                <td>
                  Identificación oficial (que puede incluir imagen, nombre
                  completo, domicilio, CURP, número de identificación, número de
                  pasaporte, nacionalidad)
                </td>
                <td>Para identificarlo.</td>
              </tbody>
              <tbody>
                <td>
                  Nombre completo
                </td>
                <td>
                  Para registrarlo en nuestros servicios de capacitación.
                  <br />
                  Para expedir la constancia de participación
                </td>
              </tbody>
              <tbody>
                <td>
                  Su imagen personal y voz identificable
                </td>
                <td>
                  Para presentar su material de capacitación o comunicados en los que participe a través de fotografías o video.
                  <br />
                  Para permitir sea identificado a través de una fotografía por las personas que escucharán u observarán su colaboración en material grabado.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su correo personal y teléfono (puede ser número de celular y/u oficina y/o casa)
                </td>
                <td>
                  Para mantener contacto con usted
                  <br />
                  Para enviarle su constancia de participación cuando aplique.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  FINALIDAD SECUNDARIA: Usted tiene derecho a no otorgar consentimiento para el tratamiento de sus datos personales para fines secundarios.
                </td>
              </tr>
              <tbody>
                <td>
                  Su correo personal
                </td>
                <td>
                  Para enviarle información general sobre nuestros cursos y servicios
                </td>
              </tbody>
              <tbody>
                <td>
                  Su número de celular
                </td>
                <td>
                  Para enviarle información general sobre nuestros cursos y servicios distintos,  ya sea por llamada telefónica o por mensaje en su  celular, incluyendo plataformas de mensajería instantánea.
                  <br />
                  Para identificarle en encuestas que nos permitan evaluar nuestros servicios.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su edad
                </td>
                <td>
                  Para fines estadísticos que nos permitan conocer mejor a nuestros consumidores.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su ubicación
                </td>
                <td>
                  Para fines estadísticos que nos permitan conocer mejor a nuestros consumidores.
                </td>
              </tbody>
              <tbody>
                <td>
                  Nombre completo
                </td>
                <td>
                  Para identificarle en encuestas que nos permitan evaluar nuestros servicios.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  FINALIDAD MERCADOTECNICA, PUBLICITARIA O DE PROSPECCIÓN COMERCIAL
                </td>
              </tr>
              <tbody>
                <td>
                  Su imagen identificable
                </td>
                <td>
                  Para dar testimonio de nuestro trabajo subiendo a nuestras redes sociales y/o sitios web fotografías y/o videos donde usted se identifique.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su voz
                </td>
                <td>
                  Para dar testimonio de nuestro trabajo subiendo a nuestras redes sociales y/o sitios web fotografías y/o videos donde se escuche su  voz identificable.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  PROVEEDORES (datos personales de aquellos que nos proporcionan productos o servicios)
                </td>
              </tr>
              <tbody>
                <td>
                  Nombre completo
                </td>
                <td>
                  Identificarlo
                  <br />
                  Para entregarle certificado o constancia al tomar cualquiera de los cursos o servicios que le ofrecimos
                </td>
              </tbody>
              <tbody>
                <td>
                  Imagen y voz identificable
                </td>
                <td>
                  Cuando de común acuerdo se firme a través de una plataforma de firmas electrónicas que exijan dichos datos para identificarle.
                </td>
              </tbody>
              <tbody>
                <td>
                  Firma autógrafa
                </td>
                <td>
                  Para  ser plasmada en documentos necesarios para iniciar una relación jurídica o comercial.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  FINALIDAD SECUNDARIA: Usted tiene derecho a no otorgar consentimiento para el tratamiento de sus datos personales para fines secundarios.
                </td>
              </tr>
              <tbody>
                <td>
                  Su correo electrónico
                </td>
                <td>
                  Para enviarle información general sobre nuestros cursos y servicios
                  <br />
                  Para enviarle encuestas que nos permitan evaluar nuestros servicios.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su nombre completo
                </td>
                <td>
                  Para identificarle en encuestas que nos permitan evaluar  nuestros servicios.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su número de celular
                </td>
                <td>
                  Para enviarle información general sobre nuestros cursos y servicios distintos,  ya sea por llamada telefónica o por mensaje en su  celular, incluyendo plataformas de mensajería instantánea.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  FINALIDAD MERCADOTECNICA, PUBLICITARIA O DE PROSPECCIÓN COMERCIAL
                </td>
              </tr>
              <tbody>
                <td>
                  Su imagen identificable
                </td>
                <td>
                  Para dar testimonio de nuestro trabajo subiendo a nuestras redes sociales y/o sitios web fotografías y/o videos donde usted se identifique.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su voz
                </td>
                <td>
                  Para dar testimonio de nuestro trabajo subiendo a nuestras redes sociales y/o sitios web fotografías y/o videos donde se escuche su  voz identificable.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  Por regla general, no serán recabados datos personales distintos a los necesarios para entablar una relación jurídica o comercial EMPLEADOS O ASPIRANTES A EMPLEADOS (datos personales de aquellos que tengan o pretendan tener una relación laboral con el responsable)
                </td>
              </tr>
              <tbody>
                <td>
                  Identificación oficial (que puede incluir imagen, nombre completo, domicilio, CURP, número de identificación, número de pasaporte, nacionalidad)
                </td>
                <td>
                  Para identificarlo
                </td>
              </tbody>
              <tbody>
                <td>
                  Nombre completo
                </td>
                <td>
                  Para plasmarlo de manera correcta en el contrato individual de trabajo o cualquier otra relación jurídica que adquiera el responsable
                </td>
              </tbody>
              <tbody>
                <td>
                  Contactos de referencia
                </td>
                <td>
                  Para solicitar referencias y confirmar lo que usted nos ha manifestado en entrevista.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su  correo electrónico
                </td>
                <td>
                  Para mantener contacto con usted durante la etapa de entrevista.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su comprobante de domicilio, acta de nacimiento,  cedula profesional, titulo de estudios, registro federal de contribuyentes, curriculum vitae
                </td>
                <td>
                  Para integrar su expediente.
                  <br />
                  Para valorar sus conocimientos y aptitudes en la vacante para la que está aplicando.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su cuenta de banco
                </td>
                <td>
                  Para hacerle transferencia o deposito de su salario y prestaciones de ley en los términos indicados en el contrato que corresponda.
                </td>
              </tbody>
              <tbody>
                <td>
                  Información sobre su crédito Infonavit
                </td>
                <td>
                  Para conocer si existe alguna obligación de retención de su nómina.
                </td>
              </tbody>
              <tbody>
                <td>
                  Firma autógrafa
                </td>
                <td>
                  Para hacer constar su consentimiento o su intervención.
                </td>
              </tbody>
              <tbody>
                <td>
                  Número de teléfono para emergencias
                </td>
                <td>
                  Para llamar a ese número ante una emergencia que requiere informar a una tercera persona.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  FINALIDAD SECUNDARIA: Usted tiene derecho a no otorgar consentimiento para el tratamiento de sus datos personales para fines secundarios.
                </td>
              </tr>
              <tbody>
                <td>
                  Su imagen
                </td>
                <td>
                  Para felicitarlo públicamente a través de chats el día de su cumpleaños
                </td>
              </tbody>
              <tbody>
                <td>
                  Su número de celular
                </td>
                <td>
                  Para integrarle a chats que le permitan conocer información relacionada con la ubicación de trabajo.
                </td>
              </tbody>
              <tbody>
                <td>
                  Nombre completo
                </td>
                <td>
                  Para identificarle en encuestas que nos permitan evaluar nuestros servicios.
                </td>
              </tbody>
              <tr>
                <td colSpan={2}>
                  FINALIDAD MERCADOTECNICA, PUBLICITARIA O DE PROSPECCIÓN COMERCIAL
                </td>
              </tr>
              <tbody>
                <td>
                  Su imagen identificable
                </td>
                <td>
                  Para dar testimonio de nuestro trabajo subiendo a nuestras redes sociales y/o sitios web fotografías y/o videos donde usted se identifique.
                </td>
              </tbody>
              <tbody>
                <td>
                  Su voz
                </td>
                <td>
                  Para dar testimonio de nuestro trabajo subiendo a nuestras redes sociales y/o sitios web fotografías y/o videos donde se escuche su  voz identificable.
                </td>
              </tbody>
            </table>

            <div
              className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-5"
              style={{ paddingRight: "0%" }}
            >
              <p className="privacity_p">
                Cuando sus datos sean recabados de manera directa en el domicilio señalado por el responsable, usted puede indicar no desea el uso de los datos con finalidades secundarias y/o mercadotécnica, publicitaria o de prospección comercial, pudiendo presentar en ese momento un escrito, manifestando lo anterior. Solicite el formato correspondiente en el domicilio del responsable.
                <br />
                <br />
                Si sus datos se recaban directamente por el responsable a través de plataformas digitales, usted puede negar el uso para finalidades secundarias y/o mercadotécnicas y/o publicitarias y/o de prospección comercial enviando un correo a
                &nbsp;
                <a href="mailto:contactojuridico@grupoguia.mx ">
                  contactojuridico@grupoguia.mx
                </a>
                &nbsp;
                indicando su nombre completo, correo electrónico y  curp, con firma autógrafa, anexando identificación oficial que nos permita identificarle.
                <br />
                <br />
                La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que  solicita o contrata con nosotros.
                <br />
                <br />
                <span className="bg-gray">
                  Cuando el aviso de privacidad no se haga del conocimiento del titular de manera personal o directa, como por ejemplo en su envío por correo postal, el titular tiene un plazo de 5 cinco días hábiles para que, de ser el caso, manifieste su negativa para el tratamiento o aprovechamiento de sus datos personales en las finalidades secundarias y/o mercadotécnicas, publicitaria o de prospección comercial.
                </span>
              </p>
              <h2>Datos sensibles</h2>
              <p className="privacity_p">
                Por regla general, no recabamos datos sensibles, entendiéndose como tales aquellos que afectan a su esfera más íntima, o cuyo uso indebido de nuestra parte pueda dar origen a discriminación o conlleve un riesgo para usted. En particular se considerarán sensibles aquellos datos que puedan revelar aspectos sobre su origen racial o étnico, su estado de salud presente o futura, su información genética, sus creencias religiosas, filosóficas o morales, su afiliación sindical, sus opiniones políticas o su preferencia sexual.
                <br />
                <br />
                En caso de ser necesario solicitar información considerada sensible, será necesaria su autorización de manera expresa y por escrito. Lo anterior no será aplicable cuando su tratamiento sea necesario por estar previsto en una ley, dichos datos figuren en fuentes de acceso público, los datos se encuentren sometidos a un procedimiento previo de disociación, tenga el propósito de cumplir obligaciones derivadas de una relación jurídica entre el titular y el responsable, exista una situación de emergencia que pueda potencialmente dañar su persona o sus bienes y en general los casos establecidos por el artículo 10 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
              </p>
              <h2>
                Acciones concretas para menores de edad y personas en condición
                de discapacidad
              </h2>
              <p>
                Reconocemos la vulnerabilidad de los datos personales de menores de edad y personas en condición de discapacidad. Nuestro esfuerzo será siempre proporcionar asistencia a las personas en esta condición para efecto de que reciban el servicio de su preferencia, bajo una expectativa de equidad razonable.
                <br />
                <br />
                En el caso de menores de edad y personas declaradas en estado de interdicción tomamos medidas específicas como son:
                <br />
                La obtención del consentimiento de los padres y tutores por medio de una carta consentimiento que deberá ser firmada de manera autógrafa. Previo a la firma de dicha carta deberá acreditar su personalidad. En general deberá presentar cualquier documento legal que compruebe su facultad como representante del menor o persona en estado de interdicción.
                <br />
              </p>
              <h2>Cámaras de seguridad</h2>
              <p>
                Contamos con cámaras de vigilancia en nuestro domicilio. Usted será grabado únicamente con fines de seguridad para usted y de las personas que nos visiten.
              </p>
              <h2>Transferencia de datos</h2>
              <h4>¿A quién transferimos sus datos?</h4>
              <p>
                Le informamos que en primera instancia, EL RESPONSABLE no hace transferencia de sus  datos personales. Sin embargo, existen algunos supuestos en los que debemos transferir la totalidad o parcialidad de datos para cumplir de manera eficaz con el fin para el que usted nos ha contratado o solicitado, o bien, para gestionar el cumplimiento de sus obligaciones, en cuyo caso, la persona que recibe la información deberá apegarse a los lineamientos de este Aviso de Privacidad. El responsable puede hacer transferencia de sus datos a los siguientes:
              </p>
              <table>
                <tr>
                  <th>Destinatario de los datos personales</th>
                  <th>País</th>
                  <th>Finalidad</th>
                </tr>
                <tbody>
                  <td>Guia Excelencia Inmobiliaria S.A. de C.V.</td>
                  <td>México</td>
                  <td>
                    Para solicitar apoyo en la elaboración de contratos,
                    protección en materia de propiedad intelectual y en general
                    soporte jurídico que nos permita cumplir con nuestras
                    obligaciones.
                  </td>
                </tbody>
                <tbody>
                  <td>Plataforma digital Youtube al canal ______</td>
                  <td>Estados Unidos</td>
                  <td>
                    Para compartir videos que usted hubiere autorizado con algún
                    tema de interés para alguno de nuestros clientes actuales o
                    potenciales.
                  </td>
                </tbody>
                <tbody>
                  <td>Redes sociales Facebook</td>
                  <td>Estados Unidos</td>
                  <td>
                    Para compartir fotografías o videos que muestren nuestro
                    trabajo.
                  </td>
                </tbody>
                <tbody>
                  <td>Instituto Mexicano del Seguro Social</td>
                  <td>México</td>
                  <td>
                    Para el cumplimiento de las obligaciones sanitarias que nos
                    impone la Legislación de la materia aplicable.
                  </td>
                </tbody>
                <tbody>
                  <td>Su aseguradora</td>
                  <td>México</td>
                  <td>
                    En su caso, para realizar los trámites correspondientes para
                    la intervención del seguro.
                  </td>
                </tbody>
                <tbody>
                  <td>Su banco</td>
                  <td>México</td>
                  <td>
                    En su caso, para realizar el cobro de nuestros servicios con
                    tarjeta de crédito o débito.
                  </td>
                </tbody>
              </table>
              <h2 className="mt-5">Derechos ARCO</h2>
              <h4>¿Cómo puede ejercer sus derechos ARCO?</h4>
              Usted tiene derecho a conocer qué datos personales tenemos, para qué los utilizamos y las condiciones de uso que les damos.
              <br />
              <br />
              Usted puede en cualquier momento revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para los fines primarios, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
              <br />
              <br />
              Usted podrá ejercer su derecho de revocación, así como sus derechos ARCO, proporcionando un escrito al encargado de datos personales mediante correo electrónico a la dirección: contactojuridico@grupoguia.mx
              &nbsp;
              <a
                className="text-underline"
                href="mailto:contactojuridico@grupoguia.mx"
              >contactojuridico@grupoguia.mx</a>
              &nbsp;
              o bien, entregando dicho escrito directamente en nuestras instalaciones en el domicilio mencionado al presente “Aviso” con atención: DEPARTAMENTO JURIDICO.
              <br />
              <br />
              Dicho documento deberá contener, como mínimo: Fecha, nombre completo, domicilio (Calle, Colonia, Código Postal, Municipio o Delegación, Ciudad y Estado), Teléfono, Correo Electrónico, la acción específica respecto a sus datos que solicita al responsable y el motivo, identificación oficial y en caso de ser representante legal, el documento que acredite su personalidad.
              <br />
              <br />
              Una vez recibida su solicitud, el responsable dará respuesta en un término no mayor a 20 días. Dicha respuesta será enviada al correo o domicilio que usted haya proporcionada en su solicitud.
              <br />
              <br />
              El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos, de nuestras prácticas de privacidad, de cambios en nuestro modelo de negocio, o por otras causas.
              <br />
              <br />
              Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de nuestra
              &nbsp;
              <a
                className="text-underline"
                href="http://www.mentordh.mx"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.mentordh.com
              </a>
              <br />
              <br />
              En caso de realizar cambios o actualizaciones al presente Aviso, el responsable los publicará de manera generalizada en comunicados colocados en el domicilio mencionado en este documento. En caso de que usted así lo solicite por escrito, le enviaremos nuestras actualizaciones al correo electrónico que usted nos proporcione.
              <br />
              <br />
              Para cualquier asunto o duda relacionada con este Aviso de Privacidad, puede contactarnos al correo electrónico
              &nbsp;
              <a
                className="text-underline"
                href="mailto:contactojuridico@grupoguia.mx"
              >
                contactojuridico@grupoguia.mx
              </a>
              &nbsp;
              o acudir a nuestras instalaciones.
              <br />
              <br />
              <b>
                Fecha de última actualización del presente Aviso de Privacidad
                06 de Junio de 2022.
              </b>
              &nbsp;
              En todo caso, recomendamos visite la página de internet
              frecuentemente.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AvisoPrivacidad;
