import Swal from "sweetalert2";
import React, { useCallback, useContext, useEffect, useState } from "react";

//Hooks
import { useForm, useWatch } from "react-hook-form";
import useS3NestFile from "../../hooks/useS3NestFile";
import { rulesRequired } from "../../Constants";

//Components
import { FileInputC, ToggleSwitchC } from "../../core";

export default function CertificateChooser({ control, setValue }) {
  const has_reconocimiento = useWatch({
    control,
    name: `has_reconocimiento`,
  });
  const has_diploma = useWatch({
    control,
    name: `has_diploma`,
  });
  const has_certificate = useWatch({
    control,
    name: `has_certificate`,
  });
  console.log("has_certificate", has_certificate);
  console.log("has_diploma", has_diploma);
  console.log("has_reconocimiento", has_reconocimiento);

  return (
    <div>
      <div className="row">
        <div className="col">
          <ToggleSwitchC
            control={control}
            name="has_reconocimiento"
            label="Tiene reconocimiento"
            onInput={(e) => {
              setValue("has_diploma", false);
              setValue("has_certificate", false);
            }}
          />
        </div>

        <div className="col">
          <FileInputC
            control={control}
            label="Reconocimiento"
            name="reconocimiento"
            accept="image/*"
            disabled={!has_reconocimiento}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ToggleSwitchC
            control={control}
            name="has_diploma"
            label="Tiene diploma"
            onInput={(e) => {
              setValue("has_reconocimiento", false);
              setValue("has_certificate", false);
            }}
          />
        </div>
        <div className="col">
          <FileInputC
            control={control}
            label="Diploma"
            name="diploma"
            accept="image/*"
            disabled={!has_diploma}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ToggleSwitchC
            control={control}
            name="has_certificate"
            label="Tiene certificado"
            onInput={(e) => {
              setValue("has_diploma", false);
              setValue("has_reconocimiento", false);
            }}
          />
        </div>
        <div className="col">
          <FileInputC
            control={control}
            label="Certificado"
            name="certificado"
            accept="image/*"
            disabled={!has_certificate}
          />
        </div>
      </div>
    </div>
  );
}
