import React from 'react'
import PropTypes from 'prop-types'
import '../Forms.scss'

const ToggleSwitch = ({defaultValue,rules,name,disabled,onChange,onInput,...props}) => {

      const selectProps = {
        onChange: (v) => {
          onChange(v.target.checked);
          onInput(v.target.checked);
        },
        disabled,
        ...props
      };
    return (
        <div className="ToggleSwitchC">
            <label className="switch" htmlFor={`_${name}_`}>
                <input type="checkbox" {...selectProps} id={`_${name}_`} />
                <span className="slider round"></span>
            </label>
        </div>
    )
}

ToggleSwitch.propTypes = {

name: PropTypes.string,

rules: PropTypes.object,
onInput: PropTypes.func,
onChange: PropTypes.func,

defaultValue: PropTypes.bool,

};
ToggleSwitch.defaultProps = {
name: "select",
rules: {},
onInput: (v) => {},
onChange: (v) => {},
defaultValue: false,
value: true
};

export default ToggleSwitch
