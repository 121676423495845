import React from "react";
import PropTypes from "prop-types";

const CloseSVG = ({ height, width, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.97 17.96"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path
            style={{ fill: "#bbbbba" }}
            d="M18,17.29c-.1,0-.08.14-.11.2a.89.89,0,0,1-1.42.19L9.18,10.41c-.19-.19-.19-.19-.38,0L1.57,17.64A.88.88,0,1,1,.29,16.43l4.1-4.11L7.62,9.1c.1-.1.1-.16,0-.26L.32,1.56A.89.89,0,0,1,.19.33,1.25,1.25,0,0,1,.71,0h.35a2,2,0,0,1,.75.55l7,7.05c.11.12.17.11.28,0L16.31.41A1.5,1.5,0,0,1,16.92,0h.35a1,1,0,0,1,.67.68l0,0v.39a3,3,0,0,1-.68.83L10.37,8.83c-.12.12-.11.18,0,.29l7.08,7.08a2,2,0,0,1,.52.7Z"
          />
        </g>
      </g>
    </svg>
  );
};

CloseSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func
};

CloseSVG.defaultProps = {
  height: 18,
  width: 18,
};

export default CloseSVG;
