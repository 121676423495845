import React, { useCallback, useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import GlobalContext from '../../context/GlobalContext';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import './reportesCursos.scss';

/**
 * @component
 * Component responsible for displaying the courses reports grid
 */

const ReportesCursos = () => {
    const { NestGet } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);

    const fetchData = useCallback(
        () => {
            setLoading(true);
            NestGet({
                schema: 'reportes/cursos',
            })
                .then((res) => {
                    if (res.status === "success") {
                        let rows = [];
                        res.data.forEach(element => {
                            if (element.curso[0] && element.user[0]) {
                                rows.push({
                                    id: element._id,
                                    curso: element.curso[0].name,
                                    cursoActivo: element.curso[0].active,
                                    nivelDeCurso: element.curso[0].level,
                                    puntosCurso: element.curso[0].points,
                                    requiereExamen: element.curso[0].has_exam,
                                    califAprobatoria: element.curso[0].exam ? element.curso[0].exam.approving_with : 0,
                                    duracion: element.curso[0].n_duration,
                                    nombreDeUsuario: `${element.user[0].names} ${element.user[0].last_name} ${element.user[0].mothers_last_name}`,
                                    tipoDeUsuario: element.user[0].tipo_estudiante,
                                    correo: element.user[0].email,
                                    departamento: element.user[0].customer_data ? element.user[0].customer_data.department : '',
                                    area: element.user[0].customer_data ? element.user[0].customer_data.zone : '',
                                    jefeDirecto: element.user[0].customer_data ? element.user[0].customer_data.direct_Boss : '',
                                    puesto: element.user[0].puesto,
                                    usuarioActivo: element.user[0].active,
                                    cursoTerminado: element.completedExam,
                                    progreso: `${element.progress}%`
                                })
                            }
                        });
                        setTableData(rows);
                        setLoading(false);

                    }
                })
                .finally((_) => {
                });
        },
        [NestGet]
    );

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            field: 'curso',
            headerName: 'Curso',
            type: 'string',
            flex: 1.6
        },
        {
            field: 'cursoActivo',
            headerName: 'C.Activo',
            type: 'boolean',
            flex: 1
        },
        {
            field: 'nivelDeCurso',
            headerName: 'C.Nivel',
            type: 'string',
            flex: 0.7
        },
        {
            field: 'puntosCurso',
            headerName: 'C.Puntos',
            type: 'number',
            flex: 0.7
        },
        {
            field: 'requiereExamen',
            headerName: 'C.Exámen',
            type: 'boolean',
            flex: 0.7
        },
        {
            field: 'califAprobatoria',
            headerName: 'Calf min',
            type: 'number',
            flex: 0.7
        },
        {
            field: 'duracion',
            headerName: 'Duración',
            type: 'string',
            flex: 0.5
        },
        {
            field: 'nombreDeUsuario',
            headerName: 'Usuario',
            type: 'string',
            flex: 1
        },
        {
            field: 'tipoDeUsuario',
            headerName: 'U.Tipo',
            type: 'string',
            flex: 1
        },
        {
            field: 'correo',
            headerName: 'Correo',
            type: 'string',
            flex: 1
        },
        {
            field: 'departamento',
            headerName: 'U.Dep',
            type: 'string',
            flex: 1
        },
        {
            field: 'area',
            headerName: 'U.Área',
            type: 'string',
            flex: 1
        },
        {
            field: 'jefeDirecto',
            headerName: 'Jefe directo',
            type: 'string',
            flex: 1
        },
        {
            field: 'puesto',
            headerName: 'U.Puesto',
            type: 'string',
            flex: 0.7
        },
        {
            field: 'usuarioActivo',
            headerName: 'U.Activo',
            type: 'boolean',
            flex: 0.5
        },
        {
            field: 'cursoTerminado',
            headerName: 'Cuso terminado',
            type: 'string',
            flex: 1
        },
        {
            field: 'progreso',
            headerName: 'Pogreso',
            type: 'string',
            flex: 0.5
        }
    ];

    return (
        <Box sx={{ height: '90vh', width: '100%' }}>
            <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={tableData}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                            correo: false,
                            duracion: false,
                            departamento: false,
                            cursoTerminado: false
                        },
                    },
                }}
                loading={loading}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );

}

export default ReportesCursos;
