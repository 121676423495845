import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import GlobalContext from "../../context/GlobalContext";
import cursoImg from "../../assets/mocks/activo-5.png";

export default function ListaDeseos() {
  const { id: user_id } = useParams();
  const { WishList, WishDeleteCurso, CartAddCurso, GetWishList } =
    useContext(GlobalContext);

  const WishListToCart = (item) => {
    CartAddCurso(item);
    WishDeleteCurso(item.list_id, 0);
  };
  useEffect(() => {
    GetWishList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="CarritoCompras">
      <Helmet>
        <title>Lista de deseos | Mentor</title>
      </Helmet>
      {/* {<div>{JSON.stringify(WishList)}</div>} */}
      <div className="carrito-container">
        <h1 className="title-carrito">Lista de deseos</h1>
        {WishList.length > 0 && (
          <div className="carrito-items">
            {WishList.map((item) => (
              <div
                className="d-flex my-5 flex-wrap align-items-center"
                key={item._id}
              >
                <Link
                  to={`/curso/${item.sku}`}
                  className="col-md-10 d-flex flex-wrap align-items-center"
                >
                  <div className="col-md-5">
                    <img
                      style={{ borderRadius: 16 }}
                      src={item?.image?.location || cursoImg}
                      alt={`imagen del ${item.name}`}
                    />
                  </div>
                  <div className="col-md-5">
                    <h4 className="cart-name">{item.name}</h4>

                    <p className="cart-description">{item.description}</p>
                    <small className="cart-teacher">{item.teacher}</small>
                  </div>
                  <div className="col-md-2">
                    <h4 className="cart-cost text-right">
                      {item.cost > 0 ? `$ ${item.cost}` : "Gratis"}
                    </h4>
                  </div>
                </Link>
                <div className="col-1">
                  <h4
                    className="cart-trash"
                    onClick={(_) => WishDeleteCurso(item.list_id)}
                  >
                    <i className="fa fa-solid fa-trash"></i>
                  </h4>
                </div>
                <div className="col-1">
                  <h4
                    className="cart-trash"
                    onClick={(_) => WishListToCart(item)}
                  >
                    <i className="fa fa-solid fa-cart-plus"></i>
                  </h4>
                </div>
              </div>
            ))}
          </div>
        )}
        {WishList.length === 0 && (
          <div className="carrito-items">
            No hay ningún curso agregado a la lista de deseos.
          </div>
        )}
      </div>
    </div>
  );
}
