/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

const Collapse = ({show, children, className}) => {
    const [init, setInit] = useState(0)
    const containerRef = useRef(null)
    
    const handleShow = useCallback(
        (show) => {
            if(show){
                containerRef.current.classList.remove('show')
                containerRef.current.classList.add('collapsing')
                containerRef.current.classList.remove('collapse')
                containerRef.current.style.height = `${init}px`
                // debugger
                setTimeout(()=>{
                    containerRef.current.style.height = ''
                },100)
                setTimeout(()=>{
                    containerRef.current.classList.remove('collapsing')
                    containerRef.current.classList.add('collapse')
                },400)
            } else {
                containerRef.current.style.position = ''
                containerRef.current.style.visibility = ''
                containerRef.current.classList.add('collapsing')
                containerRef.current.classList.remove('collapse')
                
                setTimeout(()=>{
                    containerRef.current.style.height = `${init}px`
                },100)
                setTimeout(()=>{
                    containerRef.current.classList.remove('collapsing')
                    containerRef.current.classList.add('collapse')
                    containerRef.current.classList.add('show')
                    containerRef.current.style.height = ''
                },400)
            }
        },
        [init],
    )
    useEffect(()=>{
        if(init){
            handleShow(!show)
        }else{
            setInit(containerRef.current.offsetHeight)
            containerRef.current.classList.add('collapse')
        }
    },[show,handleShow])
    
    return (
        <div ref={containerRef} className={`${className}`} style={{visibility: 'hidden', position: 'fixed'}}>
            {children}
        </div>
    )
}

Collapse.prototype={
    show: PropTypes.bool,
    className: PropTypes.string
}

export default Collapse
