import React from "react";
import PropTypes from "prop-types";

const BellSVG = ({ height, width, fill, active,onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.97 30.89">
      <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
              <path d="M0,26l.09-.31A3.46,3.46,0,0,1,1.16,24,23,23,0,0,0,3,22.09,12.58,12.58,0,0,0,5.4,16.7,30.27,30.27,0,0,0,6,10.85,8,8,0,0,1,21.36,8,7.94,7.94,0,0,1,22,10.8c.08,1.46.12,2.92.28,4.37a14.17,14.17,0,0,0,1.84,5.69,11.76,11.76,0,0,0,2,2.47c.3.29.62.56.91.86a3,3,0,0,1,.94,2.06.93.93,0,0,1-1,1.1H18.39c-.68,2.24-2.14,3.58-4.52,3.54s-3.66-1.38-4.31-3.54H9.22c-2.67,0-5.35,0-8,0A1.14,1.14,0,0,1,0,26.67Zm25.75-.51-.45-.41a14.58,14.58,0,0,1-4.42-7.48A26.15,26.15,0,0,1,20.19,12,11.76,11.76,0,0,0,20,9.91,6.14,6.14,0,0,0,13.1,5,6,6,0,0,0,8.43,8.37a6.17,6.17,0,0,0-.67,3,29.68,29.68,0,0,1-.39,4.89,16.13,16.13,0,0,1-1.95,5.51,14.88,14.88,0,0,1-3,3.55c-.08.07-.17.12-.25.18l.07.06ZM11.51,27.36a2.62,2.62,0,0,0,2.68,1.71,2.54,2.54,0,0,0,2.22-1.71Z" />
              {active?<circle fill="#ffb400" id="Elipse_23" data-name="Elipse 23" cx="23.47" cy="9.5" r="9.5" />:null}
          </g>
      </g>
  </svg>
  );
};

BellSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

BellSVG.defaultProps = {
  height: 31,
  width: 33,
  fill: "#000",
  active: false,
};

export default BellSVG;
