import {nanoid} from 'nanoid'
import { useEffect } from 'react';
import Api from '../utilities/api'
const api = new Api()

const useS3NestFile = () => {

    const handleDelete = async (fileName) => {
        return deleteFile(fileName)
          .catch(err => console.error(err))
    }
    const handleUpload = async (file,dirName='',conservar_name=false) => {
        if(!(file instanceof File)){
            return Promise.resolve(file?.location?file:{location:file})
        }
        const id = await nanoid()
        const [ext, name_] = file.name.split('.').reverse()
        const name = `${name_}<@>`
        const blob = file.slice(0, file.size, file.type); 
        
        const newFile = new File([blob], `${conservar_name?name:''}${id}.${ext}`, {type: file.type});
        return uploadFile(newFile, dirName)
          .catch(err => console.error(err))
    }

    const deleteFile = async (key) => {
        return api.http.post(`s3/delete`,{key},'').then(res=>res.data)
    }
    const uploadFile = async (file, key) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('key', key)
        return api.http.postMultipart(`s3`, formData, '').then(res=>res.data)
    }
    useEffect(() => {
        window.Buffer = window.Buffer || require("buffer").Buffer;
    }, [])

    return {
        handleDelete,
        handleUpload
    }
}

export default useS3NestFile;