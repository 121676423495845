import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./Schemas.scss";

const Tooltip = ({ align, children, tip }) => {
  const tooltip_align =
    {
      top: " tooltip-top",
      right: " tooltip-right",
      left: " tooltip-left",
      bottom: " tooltip-bottom",
    }[align.toLowerCase()] || "";
  const tooltipTextRef = useRef(null)
  const [tooltipTextHeight, setTooltipTextHeight] = useState( (tooltipTextRef.current?.offsetWidth||0)/2)
  useEffect(() => {
    setTooltipTextHeight((tooltipTextRef.current?.offsetWidth||0)/2)
  }, [tooltipTextRef])
  return (
    <div className="Tooltip">
      {children}
      {tip ? <span ref={tooltipTextRef} style={{'--position': `-${tooltipTextHeight}px`}} className={`tooltiptext${tooltip_align}`}>{tip}</span> : null}
    </div>
  );
};

Tooltip.propTypes = {
  align: PropTypes.oneOf(["top", "right", "left", "bottom"]),
  tip: PropTypes.string,
};

Tooltip.defaultProps = {
  align: "right",
  tip: "",
};

export default Tooltip;
