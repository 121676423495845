import React from "react";
//import PropTypes from 'prop-types'
import "./Card.scss";

function Header() {
  return null;
}

function Body() {
  return null;
}

function Footer() {
  return null;
}

const Card = ({ children, className, style }) => {
  const [header, body, footer] = Array.isArray(children)
    ? [
        children.find((child) => child.type === Header),
        children.find((child) => child.type === Body),
        children.find((child) => child.type === Footer),
      ]
    : [
        children?.type === Header&&children,
        children?.type === Body&&children,
        children?.type === Footer&&children,
    ];
  return (
    <div className={`Card ${className||''}`} style={style}>
      {header ? (
        <div className={`card-header ${header.props.className||''}`}>{header.props.children}</div>
      ) : null}
      {body ? (
        <div className={`card-body ${body.props.className||''}`}>{body.props.children}</div>
      ) : children ? (
        <div className="card-body">{children}</div>
      ) : null}
      {footer ? (
        <div className={`card-footer ${footer.props.className||''}`}>{footer.props.children}</div>
      ) : null}
    </div>
  );
};

Card.Header = Header;

Card.Body = Body;

Card.Footer = Footer;

Card.propTypes = {};

Card.defaultProps = {};

export default Card;
