import React, { useContext, useEffect, useState } from "react";

import Login, { Registro } from "./Login";
//Assets
import logo from "../../assets/mentor_logo_1.png";
export default function AuthChooser({ defaultScreen }) {
  const [screen, setScreen] = useState(defaultScreen ?? "Login");

  return (
    <div className="Login">
      <div className="contact-section">
        <div className="container">
          <div className="row ">
            <div className="logo-3">
              <img
                width="500px"
                height="500px"
                className="logo-log"
                src={logo}
                alt="Grupo Guía"
              />
            </div>
          </div>
          <div className="row login-box">
            <div className={`col ${!(screen == "Registro") ? "selected" : ""}`}>
              <div
                className={`auth-chooser-option `}
                onClick={() => setScreen("Registro")}
              >
                Registrate
              </div>
            </div>
            <div
              className={`col ${!(screen == "Login") ? "selected-reg" : ""}`}
            >
              <div
                className={`auth-chooser-option `}
                onClick={() => setScreen("Login")}
              >
                Iniciar sesión
              </div>
            </div>

            {screen == "Registro" ? <Registro /> : <div />}
            {screen == "Login" ? <Login /> : <div />}
          </div>
        </div>
      </div>
    </div>
  );
}
