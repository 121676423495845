import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './Button.scss'

const LinkPillButton = ({outLine, children, to, disabled, color, className}) => {
    const style = color?{"--color": color}:{}
    const outLine_ = outLine?' button__outline':''
    const className_ = className?` ${className}`:''
    return (
        <Link style={style} disabled={disabled} to={to} className={`PillButton${outLine_}${className_}`}>
            {children}
        </Link>
    )
}

LinkPillButton.propTypes = {
    outLine: PropTypes.bool,
    onClick: PropTypes.func
}

LinkPillButton.defaultProps = {
    outLine: false,
    onClick: ()=>{}
}

export default LinkPillButton
