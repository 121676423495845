import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Draggable, Droppable, } from 'react-beautiful-dnd'
const grid = 6;
const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    display: 'flex',
    // change background colour if dragging
    ...isDragging&& {background: "lightgreen" },

    // styles we need to apply on draggables
    ...draggableStyle
  };
}
const DragDrop = ({items, renderItem, keyExtractor, onChange, name,onDelete}) => {
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...items];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        onChange(updatedList);
      }
    return (
      <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId={name} >
            {(provided) => (
              <div
                className="list-container"
                
                {...provided.droppableProps}
                ref={provided.innerRef}
                
              >
                {items.map((item, index) => (
                  <Draggable key={keyExtractor(item,index)} draggableId={keyExtractor(item,index)} index={index}>
                    {(provided,snapshot) => (
                      <div
                        className="item-container"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div style={{width: 50, display: 'grid', justifyContent: 'center'}} 
                        {...provided.dragHandleProps}>
                          <i className="fas fa-grip-vertical h4 mb-0"></i>
                        </div>
                        <div style={{flexGrow: 1}}>
                          {renderItem(item, index)}
                        </div>
                        {onDelete?<div style={{width: 50, display: 'grid', justifyContent: 'center'}} onClick={_=>onDelete(index)}>
                          <i className="fas fa-times h4 mb-0 text-danger"></i>
                        </div>:null}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
    )
  }

DragDrop.propTypes = {
    items:PropTypes.array,
    renderItem:PropTypes.func,
    keyExtractor:PropTypes.func,
    onChange:PropTypes.func
}
DragDrop.defaultValue = {
    items:[],
    renderItem:(item,index)=>{JSON.stringify(item)},
    keyExtractor:(item,index)=>`${item.key}-${index}`,
    onChange: v=>{},
}

export default DragDrop