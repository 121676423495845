import React, { useEffect, useMemo, useState } from "react";
import {Link} from 'react-router-dom'
import Slider from "react-slick";
import PropTypes from "prop-types";
import Curso from "../Cards/Curso";
import useScreenChange from "../../hooks/useScreenChange";
import { Title } from "../../core";

import './Lists.scss'

const CategoriaList = ({title, withProgress, to, curses}) => {
  const { width } = useScreenChange();
  const Wraper = ({children})=> to ? <Link to={to}><i className="fas fa-mouse-pointer mr-2"></i>{children}</Link>:<>{children}</>
  return (
    <div className="CategoriaList">
      <div className="categoria-title"><Title color="#345361"><Wraper>{title}</Wraper></Title></div>
      <div className="categoria-list">
        {curses.map(m=><Curso hasProgress={withProgress} progress={Math.floor(Math.random()*50)-20} key={m.id} {...m} />)}
      </div>
    </div>
    
  );
};

const Sliders = ({ children, slidesToShow=1 }) => {
  
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow||1,
    slidesToScroll: Math.floor(slidesToShow/2)||1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: true,
  };
  
  return (
  <>
  {Array.isArray(children)&&children.length>=slidesToShow?
  <Slider className="" {...settings}>{children}</Slider>:
  <div className="d-flex">{children}</div>
  }</>);
  
};

CategoriaList.propTypes = {};

export default CategoriaList;
