import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

//Hooks
import Vimeo from "@u-wave/react-vimeo";
import { Button, Collapse, Dialog, LinkButton } from "../../core";
import useFormatter from "../../hooks/useFormatter";

//Data
import GlobalContext from "../../context/GlobalContext";

//Components
import StripeFormModal from "../../components/Forms/Stripe";

//Assets
import punto from "../../assets/images/foquitos.png";
import foco_bg from "../../assets/images/foco_bg.png";
import { transformHours } from "../../utilities/transformHours";

// MUI components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LockIcon from '@mui/icons-material/Lock';
import Rating from '@mui/material/Rating';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import CategoryIcon from '@mui/icons-material/Category';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

/**
 * @component
 * Component responsible for displaying the details and complete information of the course
 */

const Curso = () => {
  const { NumberF } = useFormatter();
  const {
    NestGet,
    NestPost,
    isLogin,
    CartList,
    CartAddCurso,
    CartDeleteCurso,
    WishList,
    WishAddCurso,
    WishDeleteCurso,
    state: { user, token, rank },
  } = useContext(GlobalContext);
  const history = useHistory();

  const { sku } = useParams();
  const [paused, setPaused] = useState(false);
  const [curse, setCurse] = useState(null);
  const [show, setShow] = useState({});
  const [SStripe, setSStripe] = useState(false);
  const [comments, setComments] = useState([]);
  const [calificacion, setCalificacion] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const fullName = (user) =>
    `${user?.names || ""} ${user?.last_name || ""}`.trim();

  const WishListItemId = useMemo(() => {
    if (curse && Object.keys(curse).length > 0 && WishList.length > 0) {
      return curse.isInWishList
        ? WishList?.find((f) => f.id === curse.id)?.list_id
        : "";
    }
    return "";
  }, [WishList, curse]);

  const CartListItemId = useMemo(() => {
    if (curse && Object.keys(curse).length > 0 && CartList.length > 0) {
      return curse.isInCartList
        ? CartList?.find((f) => f.id === curse.id)?.list_id
        : "";
    }
    return "";
  }, [CartList, curse]);

  const firstCapsule = useMemo(() => {
    const firstModule = curse?.modules?.[0] || {};
    const cap = firstModule?.capsules?.[0] || {};
    return `/clase/${sku}/${firstModule?._id}/${cap?._id}`;
  }, [sku, curse]);

  const openReco = useCallback(() => {
    const a = document.createElement('a')
    if (curse?.has_certificate) {
      a.download = `Certificado_${curse?.sku}.pdf`
    }
    if (curse?.has_diploma) {
      a.download = `Diploma_${curse?.sku}.pdf`
    }
    if (curse?.has_reconocimiento) {
      a.download = `Reconocimiento_${curse?.sku}.pdf`
    }
    a.href = curse.certificate
    a.target = 'new'
    a.click()
  }, [curse])
  const isPayedCurse = useCallback(
    (url) => {
      if (!curse?.isBuyable) {
        history.push(url);
      } else {
        HandleShop();
      }
    },
    [history, curse]
  );

  const NotPayedCurse = () => {
    setVisible(true);
  };

  const makeCapsule = useCallback(
    (modul, cap) => {
      return `/clase/${sku}/${modul?._id}/${cap?._id}`;
    },
    [sku]
  );

  const fetchData = useCallback(
    async (cursoSku) => {
      if (loading) {
        return;
      }
      setLoading(true);
      NestGet({
        schema: "cursos",
        query: {
          query: JSON.stringify({ sku: cursoSku }),
          user_id: JSON.stringify({ user_id: user._id }),
        },
      })
        .then((res) => {
          if (res.status === "success") {
            const curs = res?.data?.[0];
            if (curs) {
              console.log(curs);
              setCurse(curs);
              NestGet({
                schema: "commentCurso",
                query: {
                  query: JSON.stringify({ curso: curs._id }),
                },
              }).then((res) => {
                if (res.status === "success") {
                  setComments(res.data);
                }
              });
              NestGet({
                schema: "evaluarCurso",
                query: {
                  query: JSON.stringify({ curso: curs._id }),
                },
              }).then((res) => {
                if (res.status === "success") {
                  setCalificacion(res.data);
                }
              });
            } else {
              history.replace("/");
            }
          }
        })
        .finally((_) => {
          setLoading(false);
        });
    },
    [NestGet, history, user._id, loading]
  );

  useEffect(() => {
    fetchData(sku);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku, CartList, WishList]);

  const HandleShop = () => {
    setSStripe(true);
  };
  const payed = (stripe) => {
    NestPost({
      schema: "orders",
      body: {
        stripe,
        courses: curse?._id ? [curse] : [],
        user_id: user?._id,
        amount: curse?.cost,
        buy_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    })
      .then((res) => { })
      .finally((_) => {
        setSStripe(false);
        fetchData(curse.sku);
      });
  };

  const [tabValue, settabValue] = React.useState('presentación');

  const handleTabChange = (event, newValue) => {
    settabValue(newValue);
  };

  const totalRating = (data) => {

    if (data.length > 0) {
      const reducedRating = data.reduce((v, c) => {
        return v + c;
      }, 0) / (data.length || 1);
      return Math.floor(reducedRating * 100) / 100;

    } else {
      return 0;
    }
  };

  return (
    <>
      {curse ? (
        <div className="CursoFormContainer">
          <div className="not-container-item column curso-contenido ">

            <div className="d-flex flex-wrap mnt-row">
              <span className="h1-curso-form">{curse.name}</span>
            </div>
            <div className="d-flex flex-wrap mnt-row">
              <span className="h3-curso-form">{curse.description}</span>
            </div>

            <div className="d-flex flex-wrap mnt-row mnt-row-spaced-2">
              <div className="column" style={{ width: "100%", marginTop: "40px", marginLeft: "90px", textAlign: "right" }}>
                <span className="h2-curso-form">Maestro: {curse.teacher ? curse.teacher : "N/A"}</span>
              </div>
            </div>

            <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
              <div className="evaluation-section">
                <div className="evaluation-section-cell" style={{ display: "table" }}>
                  <div style={{ display: "table-cell" }}>
                    <span className="h3-curso-form">Evaluación del curso</span>
                  </div>
                  <div style={{ display: "table-cell", padding: "0px 20px 0px 20px" }}>
                    <Rating name="read-only" value={totalRating(calificacion.map((m) => m.cal_curse))} readOnly size="small" />
                  </div>
                </div>
                <div className="evaluation-section-cell" >
                  <div style={{ display: "table-cell" }}>
                    <span className="h3-curso-form">Evaluación del maestro</span>
                  </div>
                  <div style={{ display: "table-cell", padding: "0px 20px 0px 20px" }}>
                    <Rating name="read-only" value={totalRating(calificacion.map((m) => m.cal_teacher))} readOnly size="small" />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
              <div className="preview">
                {curse?.preview ? (
                  <Vimeo
                    className="capsula-video"
                    video={curse.preview}
                    autoplay={true}
                    responsive
                    paused={false}
                    showTitle={false}
                    muted={false}
                    loop
                  />
                ) : (
                  <img
                    src={curse?.image?.location}
                    alt={`Preview de ${curse.name}`}
                  />
                )}
              </div>
            </div>
            <>
              <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                <span className="h1-curso-form">¿Qué aprenderás?</span>
              </div>

              <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                <span className="h3-curso-form">{curse.will_you_learn}</span>
              </div>
              <Divider></Divider>
              <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                <span className="h1-curso-form">Conocimientos requeridos</span>
              </div>

              <div className="d-flex flex-wrap mnt-row mnt-row-spaced-1">
                <span className="h3-curso-form">{curse.prior_knowledge === undefined ? "N/A" : curse.prior_knowledge}</span>
              </div>
            </>
          </div>
          <div className="column curso-details">
            <div className="curso-info-card" >
              <Box
                sx={{
                  width: 320,
                  height: "490",
                  boxShadow: 3,
                  overflow: "auto",
                  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                  p: 1,
                  m: 1,
                  borderRadius: 5,
                  textAlign: 'center',
                  fontSize: '0.875rem',
                  fontWeight: '700',
                }}
              >
                <div className="mnt-row-spaced-1">
                  <span className="h1-curso-form">
                    {curse.isBuyable
                      ? (curse.cost ? NumberF(curse.cost, true) : "Gratis")
                      : ("Ya tienes este curso")
                    }
                  </span>
                </div>
                {curse.isBuyable
                  ? (
                    <>
                      <Button className="mentor-button"
                        onClick={isLogin ? (_) => HandleShop() : NotPayedCurse}
                      >
                        {curse.cost ? "Comprar ahora" : "Ver Curso"}
                      </Button>


                      <Button className="mentor-button-2"
                        onClick={(_) =>
                          isLogin
                            ? curse.isInCartList
                              ? CartDeleteCurso(CartListItemId)
                              : CartAddCurso(curse)
                            : NotPayedCurse()
                        }
                      >
                        {curse.isInCartList ? "Eliminar de carrito" : "Agregar a carrito "}
                      </Button>
                      <Button className="mentor-button-2"
                        onClick={() =>
                          isLogin
                            ? curse.isInWishList
                              ? WishDeleteCurso(WishListItemId)
                              : WishAddCurso(curse)
                            : NotPayedCurse()
                        }
                      >
                        {curse.isInWishList ? "Eliminar de Wishlist" : "Agregar a Wishlist"}
                      </Button>
                    </>
                  )
                  : (
                    <>
                      <Button className="mentor-button"
                        onClick={
                          isLogin
                            ? (_) =>
                              isPayedCurse(curse?.lastCap ? curse.lastCap : firstCapsule)
                            : NotPayedCurse
                        }
                      >
                        {curse.cost ? "Comprar ahora" : "Ver Curso"}
                      </Button>
                    </>
                  )
                }
                <div style={{ textAlign: "justify", marginLeft: "50px" }}>
                  <IconButton label="Módulos" style={{ color: "#34495e", marginTop: "10px" }} disabled={true} >
                    <CategoryIcon label="Módulos" />
                    <span className="h3-curso-form" style={{ marginLeft: "10px" }}>
                      {curse.n_modules} Módulo{curse.n_modules > 1 ? "s" : ""}{" "}
                      ({transformHours(curse.n_duration)})
                    </span>
                  </IconButton>
                  <IconButton label="Recursos adicionales" style={{ color: "#34495e", marginTop: "5px" }} disabled={true}>
                    <AddCircleIcon label="Recursos adicionales" />
                    <span className="h3-curso-form" style={{ marginLeft: "10px" }}>Recursos adicionales</span>
                  </IconButton>
                  <IconButton label="Nivel" style={{ color: "#34495e", marginTop: "5px" }} disabled={true}>
                    <BarChartIcon label="Nivel" />
                    <span className="h3-curso-form" style={{ marginLeft: "10px" }}>Nivel: {curse.level}</span>
                  </IconButton>

                  <IconButton label="Certifícate al terminar" style={{ color: "#34495e", marginTop: "5px" }} disabled={true}>
                    <MilitaryTechIcon label="Certifícate al terminar" />
                    <span className="h3-curso-form" style={{ marginLeft: "10px" }}>Certifícate al terminar</span>
                  </IconButton>
                </div>
                {curse?.certificate ? (
                  <div className="d-flex justify-content-center mt-4">
                    <Button className="mentor-button" onClick={_=>openReco()}>
                      {curse?.has_certificate?'Descarga tu Certificado':''}
                      {curse?.has_diploma?' Descarga tu Diploma':''}
                      {curse?.has_reconocimiento?'Descarga tu Reconocimiento':''}{" "}
                      <i className="fa fa-download"></i>
                    </Button>
                  </div>
                ) : null}
              </Box>
            </div>
          </div>
          {isLogin ? (
            <StripeFormModal
              name_curse={curse.name}
              cursos={[curse]}
              activate={SStripe}
              amount={curse.cost}
              onSuccess={payed}
              customer_id={user._id}
              onCancel={() => setSStripe(false)}
            />
          ) : null}

          <Dialog visible={visible} onClose={(_) => setVisible(false)}>
            <div className="sesionDialog">
              <div className="d-flex justify-content-evenly align-items-center mb-3">
                <h3 className="mb-0 text-center">
                  Para continuar ingresa con tu cuenta ó regístrate
                </h3>
              </div>
              <div className="d-flex justify-content-evenly align-items-center">
                <LinkButton to={`/login?redirect=/curso/${sku}`}>Login</LinkButton>{" "}
                <strong className="h4 mb-0">ó</strong>{" "}
                <LinkButton to={`/registro?redirect=/curso/${sku}`}>
                  Registro
                </LinkButton>
              </div>
            </div>
          </Dialog>
        </div>
      )
        : (
          null
        )}
    </>
  );
};

const CardEvaluation = ({ title, data }) => {
  const redon = useCallback((num) => Math.floor(num * 100) / 100, []);
  const F2R = (num) => (num * 100) / 5;

  const ratingTotal = useMemo(() => {
    return redon(
      data.reduce((v, c) => {
        return v + c;
      }, 0) / (data.length || 1)
    );
  }, [data, redon]);
  const ratingGroup = useMemo(() => {
    const ratings = data.reduce(
      (v, c) => {
        v[c]++;
        return v;
      },
      { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 }
    );
    return Object.keys(ratings).reduce((v, c) => {
      v[c] = redon((ratings[c] * 100) / (data.length || 1));
      return v;
    }, {});
  }, [data, redon]);

  return (
    <div className="card-evaluacion">
      <strong className="title">{title}</strong>
      <div className="rating-container">
        <strong>{ratingTotal}</strong>
        <div className="rating">
          <Rating
            size={28}
            ratingValue={F2R(ratingTotal)}
            readonly
            fillColor="#34495e"
            emptyColor="#9b9b9b"
          />
        </div>
      </div>
      <ul>
        {new Array(5).fill(0).map((_, i) => (
          <li key={`bar-of-${5 - i}`}>
            <div
              className="bar"
              style={{ "--procentual": `${ratingGroup[5 - i]}%` }}
            ></div>
            <Rating
              size={14}
              ratingValue={F2R(5 - i)}
              readonly
              fillColor="#34495e"
              emptyColor="#9b9b9b"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Curso;
