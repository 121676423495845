import { useState, useEffect, useCallback } from "react";

function useScreenChange() {
  const [screen, setScreen] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const reportWindowSize = useCallback(() => {
    const size = {};
    size.height = document.body.offsetHeight;
    size.width = document.body.offsetWidth;
    setScreen(size);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", reportWindowSize);
    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, [reportWindowSize]);
  return { height:screen.height, width:screen.width };
}

export default useScreenChange;
