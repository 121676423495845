import React from "react";
import { Helmet } from "react-helmet";


/**
 * @component
 * Component responsible for displaying the goal screen
*/

const NuestroObjetivo = () => {
  return (
    <div className="AvisoPrivacidad">
      <Helmet>
        <title>Nuestro objetivo | Mentor</title>
      </Helmet>
      <div className="container-fluid" style={{ padding: 0, height: "50vh" }}>
        <div className="hero-image">
          <div className="hero-text">
            <h1 style={{ fontSize: 50, color: "#fff" }}>Nuestro objetivo</h1>
          </div>
        </div>
      </div>
      <section className="container wow fadeIn pt-5 pb-5">
        <div className="container privacity_zone">
          <div className="row row_pv">
            <p className="privacity_p">
              Incrementar el número de profesionistas certificados y preparados
              a través de una capacitación especializada y actualizada. Buscando
              los métodos de aprendizaje más revolucionarios e innovadores. Ser
              parte del proceso de aprendizaje y crecimiento tanto personal como
              profesional.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NuestroObjetivo;
