import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import './Grid.scss'
import { DragDropContext, Draggable, Droppable, } from 'react-beautiful-dnd'
const grid = 6;
const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    
    // change background colour if dragging
    // ...isDragging&& {background: "lightgreen" },

    // styles we need to apply on draggables
    ...draggableStyle
  };
}


const GridDD = ({name, rowKey,columns,dataSource,className,pagination,onChangePage,children, onCloseCb, onChange}) => {
    
    const _columns_ = useMemo(()=>{
        if(columns){
            return columns
        } else {
            return dataSource.reduce((v,c)=>{
                Object.keys(c).forEach(key => {
                    try {
                        if(!v.find(f=>f.key===key)){
                            v.push({
                                key: key,
                                title: key.replace(/_/g,' ').toCapitalize(),
                                dataIndex: key
                            })
                        }
                    } catch (error) {
                    }
                });
                
                return v
            },[])
        } 
    },[columns,dataSource])

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...dataSource];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        onChange(updatedList);
      }
    
    return (
        <>
        <table className={`Grid table ${className}`} style={{position: 'relative'}}>
            <Header columns={_columns_} children={children} />
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId={name} >
                  {(provided) => (
                    <tbody 
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    >
                        {dataSource.map((item,index)=>
                        <Draggable key={`${item[rowKey]}`} draggableId={`${item[rowKey]}`} index={index}>
                            {(provided,snapshot) => (  
                                <Row columns={_columns_} row={item} children={children} index={index} provided={provided} snapshot={snapshot} />
                            )}
                        </Draggable>
                        )}
                        {provided.placeholder}
                    </tbody>
                  )}
              </Droppable>
            </DragDropContext>
        </table>
        {pagination && dataSource.length && pagination.last_page>1?
            <div className="col-12">
                <div id="pagination">
                    <nav> 
                        <ul className="pagination justify-content-center">
                            {pagination.page>1?<li className="page-item"><span onClick={_=>{onChangePage(1)}} className="page-link" aria-label="Previous"> &lt;&lt; </span> </li>:null}
                            {pagination.page-1>0?<li className="page-item"><span onClick={_=>{onChangePage(pagination.page-1)}} className="page-link">{pagination.page-1}</span> </li>:null}
                            <li className="page-item active"><span onClick={_=>{onChangePage(pagination.page)}} className="page-link">{pagination.page}</span> </li>
                            {pagination.page!==pagination.last_page?<li className="page-item"><span onClick={_=>{onChangePage(pagination.page+1)}} className="page-link">{pagination.page+1}</span> </li>:null}
                            {pagination.page<pagination.last_page?<li className="page-item"><span onClick={_=>{onChangePage(pagination.last_page)}} className="page-link" aria-label="Next"> &gt;&gt; </span> </li> :null}
                        </ul> 
                    </nav>
                </div>
            </div>
        :null}
        
        </>
    )
}

const Header = ({columns}) => {
    return(
        <thead>
            <tr>
                <th></th>
                {columns.map((column)=>
                <th key={column.key} className={`${column.className||''}`} style={{width: column.width||'inherit'}}>
                    {column.title}
                </th>
                )}  
            </tr>
        </thead>
    )
} 
const Row = ({row,columns,children, index, provided, snapshot}) => {
    const columnsHashMap = useMemo(
        () => {
            const hashMap = columns.reduce((v,c) => {
                const path = (c.dataIndex||c.key).split('.')
                const column = path.reduce((vk,ck)=>vk?.[ck],row)
                return {
                    ...v,
                    [c.key]: typeof column ==='string'? column : JSON.stringify(column) 
                }
            },{})
            return hashMap
        },
        [row,columns],
    )
    return(
        <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
            )}
        >
            <td {...provided.dragHandleProps}>
                <i className="fas fa-grip-vertical h4 mb-0"></i>
            </td>
            {columns.map((column)=>
            <td key={column.key} className={`${column.className||''}`} style={{width: column.width||'inherit', ...column.style||{}}}>
                <Column row={row} column={columnsHashMap[column.key]||''} name={column.key}  children={children} index={index} />
            </td>
            )}
        </tr>
    )
}


function Column({column, row, name, children, index}){
    const childCol = Array.isArray(children)?
    children.find(f=>f?.type === Column&&f?.props?.name === name):(
        children?.type === Column&&children?.props?.name === name&&children
    )
    if(childCol){
        try {
            return childCol?.props?.context({column: JSON.parse(column),row, index})
        } catch (error) {
            return childCol?.props?.context({column, row, index})
        }
    }
    
        
        try {
            return <>{JSON.parse(column)}</>
        } catch (error) {
            return <>{column}</>
        }
    
}

GridDD.Column = Column

GridDD.propTypes = {
    rowKey: PropTypes.string,
    columns: PropTypes.array,
    dataSource: PropTypes.array,
    pagination: PropTypes.object,
    className: PropTypes.string,
    onChangePage: PropTypes.func
}

GridDD.defaultProps = {
    rowKey: 'id',
    dataSource: [],
    className: '',
    onChangePage: ()=>{},
    onCloseCb: cb=>cb()
}

export default GridDD



