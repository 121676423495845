import React, { useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'
function Header() {
    return null;
  }
  
  function Body() {
    return null;
  }
  
  function Footer() {
    return null;
  }
const Dialog = ({visible, onClose, children, className, classNameModalDialog}) => {
    const portalRef = useRef(document.querySelector('#portal'))
    return visible?ReactDOM.createPortal(
        (<Portal classNameModalDialog={classNameModalDialog} className={className} visible={visible} onClose={onClose}>{children}</Portal>),
        portalRef.current,
      ):null
}

const Portal = ({visible, onClose, children, className, classNameModalDialog}) =>{
    const refDialog = useRef(null)
    const [header, body, footer] = Array.isArray(children)
    ? [
        children.find((child) => child.type === Header),
        children.find((child) => child.type === Body),
        children.find((child) => child.type === Footer),
      ]
    : [
        children?.type === Header&&children,
        children?.type === Body&&children,
        children?.type === Footer&&children,
    ];
    const handleClose = useCallback(
        (e) => {
            if(visible&&!e.path.some(s => s.isSameNode?s.isSameNode(refDialog.current):false)){
                onClose&&onClose()
            }
        },
        [refDialog,onClose,visible],
      )
      useEffect(() => {
          document.querySelector('#portal').addEventListener("click", handleClose);
        return () => {
          document.querySelector('#portal').removeEventListener("click", handleClose);
        };
      }, [handleClose])
    return (
        
            <div className={`Dialog ${visible?'modal-open':''}`}>
                <div className={`modal fade${visible?' show':''}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={visible?{display: "block", paddingRight: 0}:{}} aria-modal={visible} aria-hidden={!visible}>
                    <div className={`modal-dialog modal-dialog-centered ${classNameModalDialog}`} role="document" >
                        <div className={`modal-content ${className||''}`} ref={refDialog}>
                            <div className={`modal-header ${header?.props?.className||''}`}>
                                {header ? (header.props.children) : null}
                                {onClose?<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose} >
                                    <span aria-hidden="true">&times;</span>
                                </button>:null}
                            </div>
                            <div className={`modal-body ${body?.props?.className||''}`}>
                                {body ? (body.props.children) : (children||'')}
                            </div>
                            <div className={`modal-footer ${footer?.props?.className||''}`}>
                                {footer ? (footer.props.children) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        
    )
}

Dialog.Header = Header;

Dialog.Body = Body;

Dialog.Footer = Footer;

Dialog.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
}

Dialog.defaultProps = {
    visible: false,
    classNameModalDialog: '',
}

export default Dialog
