import React, {useCallback,useEffect} from "react";
import './Schemas.scss'

const Steps = ({ steps, activeStep, setStep=v=>v,onStepChange=v=>v }) => {
    const isVisited = useCallback(i =>{return i+1<activeStep?' step-item__visited':''},[activeStep])
    const isActive = useCallback(i =>{return i+1===activeStep?' step-item__active':''},[activeStep])
    useEffect(() => {
      const stepString = activeStep?steps[activeStep-1]:{label:''}
      onStepChange(stepString.label)
    }, [activeStep, steps, onStepChange])
  return (
    <div className="Steps">
      {steps.map((m, i) => (
        <div className={`step-item${isVisited(i)}${isActive(i)}`} key={`${i}-${m.label}`} onClick={()=>isVisited(i)?setStep(i+1):null}>
          <div className="step-graph">
            <div className="step-dot" />
          </div>
          <div className="step-info">
            <strong>{m.label}</strong>
            <span>{m.description}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export const StepsButtons = ({ steps, activeStep, setStep }) => {
  const isVisited = useCallback(i =>{return i+1<activeStep?' step-item__visited':''},[activeStep])
  const isActive = useCallback(i =>{return i+1===activeStep?' step-item__active':''},[activeStep])
  return (
    <div className="StepsButtons">
      {steps.map((m, i) => (
        <div className={`step-item${isVisited(i)}${isActive(i)}`} key={`${i}-${m.label}`} onClick={()=>isVisited(i)?setStep(i+1):null}>
          <div className="step-info">
            <span>{m.label}</span>
            {m.description?<span>{m.description}</span>:null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Steps;
