import React from "react";
import PropTypes from "prop-types";

const BarsSVG = ({ height, width, fill, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 383.22 287.63"
      fill={fill}
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M0,19.49c.37-.89.79-1.77,1.09-2.68A23.92,23.92,0,0,1,23.9,0C31.51,0,39.11,0,46.72,0H357.64c13,0,22.52,7.28,25.05,19.12a23.9,23.9,0,0,1-22.05,28.73c-1.62.09-3.24,0-4.86,0-108.51,0-217-.14-325.51.17C15.21,48.14,4.68,43.36,0,28.47Z" />
          <path d="M0,139.25c.46-1.12,1-2.23,1.37-3.37a23.74,23.74,0,0,1,22.16-16.06c8.23-.12,16.46,0,24.69,0H357.64c13,0,22.52,7.29,25.05,19.13a23.91,23.91,0,0,1-22.05,28.73c-1.62.08-3.24,0-4.87,0-108.5,0-217-.15-325.5.16-15.06,0-25.59-4.73-30.27-19.62Z" />
          <path d="M0,259c.46-1.12,1-2.23,1.37-3.37a23.71,23.71,0,0,1,22.16-16.06c8.23-.12,16.46,0,24.69,0H357.64c13,0,22.52,7.29,25.05,19.13a23.9,23.9,0,0,1-22.05,28.72c-1.62.09-3.24,0-4.87,0-108.5,0-217-.14-325.5.17C15.21,287.67,4.68,282.9,0,268Z" />
        </g>
      </g>
    </svg>
  );
};

BarsSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

BarsSVG.defaultProps = {
  height: 26,
  width: 26,
  fill: "#222",
};

export default BarsSVG;
