import React, { useContext, useEffect, useState } from "react";


//Components
import BuscadorSection from "../../components/Home/BuscadorSection";
import ContactoSection from "../../components/Home/ContactoSection";
import Servicios from "../../components/Home/Servicios";
import PrincipalBanner from "../../components/Home/PrincipalBanner";
import GlobalContext from "../../context/GlobalContext";
import SwiperBanner from "../../components/Home/SwiperBanner";



/**
 * @component
 * Component in charge of calling views for startup
*/

const Inicio = () => {
  const {isLogin, NestGet} = useContext(GlobalContext)
  const [banners, setBanners] = useState([])
  useEffect(() => {
    NestGet({schema: 'banners', query: {query: JSON.stringify({active: true})}}).then(res=>{
      if(res.status==='success'){
        setBanners(res.data)
      }
    })
    return () => {
    };
  }, [NestGet])
  return (
    <div className="Home">
      <PrincipalBanner />
      <SwiperBanner banners={banners}  />
      <Servicios />
      <div className="bg-gradian">
        <ContactoSection />
      </div>
    </div>
  );
};

export default Inicio;
