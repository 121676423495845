import React from "react";
import PropTypes from "prop-types";

const RelogSVG = ({ height, width, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.96 21.96"
      fill="#fff"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M11.67,22H10.3l-.21,0c-.46-.06-.92-.1-1.38-.2A10.74,10.74,0,0,1,2.24,17.6a10.66,10.66,0,0,1-2-8.66A10.57,10.57,0,0,1,3.91,2.6,10.72,10.72,0,0,1,12.78.15a10.54,10.54,0,0,1,5.46,2.6,10.79,10.79,0,0,1,3.55,6.34c.08.4.12.8.17,1.2v1.38a.9.9,0,0,0,0,.16,10.53,10.53,0,0,1-1.5,4.73,10.83,10.83,0,0,1-7.56,5.23C12.48,21.87,12.07,21.91,11.67,22ZM11,20.46A9.48,9.48,0,1,0,1.51,11,9.49,9.49,0,0,0,11,20.46Z" />
          <path d="M11.43,10.84h5.16a.75.75,0,0,1,.79.92.77.77,0,0,1-.78.58H10.83c-.62,0-.9-.28-.9-.9V5.24a.77.77,0,0,1,.6-.84.75.75,0,0,1,.89.6c0,.11,0,.22,0,.32v5.52Z" />
        </g>
      </g>
    </svg>
  );
};

RelogSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

RelogSVG.defaultProps = {
  height: 22,
  width: 22,
};

export default RelogSVG;
