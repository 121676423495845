import React from "react";
import PropTypes from "prop-types";

const FilmsSVG = ({ height, width, fill, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 202.87 328.5"
      fill={fill}
    >
      <g>
        <path d="M2.63,328.5C.55,326.98,0,324.93,0,322.39,.05,216.98,.04,111.56,.04,6.15,.04,1.16,1.24,0,6.34,0c63.4,0,126.8,0,190.2,0,5.1,0,6.29,1.16,6.29,6.14,0,105.42,0,210.83,.04,316.25,0,2.54-.55,4.59-2.63,6.11H2.63ZM161.62,104.33H41.36v119.84h120.26V104.33Zm-.13-8.65V8.44H41.38V95.68h120.1Zm.03,137.23H41.36v87.06h120.16v-87.06ZM32.73,127.9v-23.68H8.55v23.68h24.18Zm137.35-.08h24.31v-23.5h-24.31v23.5Zm-137.33,8.63H8.53v23.53h24.21v-23.53Zm137.38,23.34h24.23v-23.42h-24.23v23.42ZM8.47,191.92h24.23v-23.41H8.47v23.41Zm161.65,0h24.23v-23.4h-24.23v23.4ZM8.42,224.27h24.33v-23.56H8.42v23.56Zm161.72-23.67v23.68h24.18v-23.68h-24.18ZM32.65,63.56v-23.51H8.51v23.51h24.14Zm161.7-.01v-23.54h-24.23v23.54h24.23ZM8.58,72.12v23.51h24.12v-23.51H8.58Zm161.55-.06v23.55h24.21v-23.55h-24.21ZM32.64,256.38v-23.5H8.52v23.5h24.12Zm137.48-23.63v23.57h24.23v-23.57h-24.23Zm-137.38,55.81v-23.53H8.54v23.53h24.2Zm161.62,0v-23.61h-24.13v23.61h24.13ZM8.49,8.37V31.3h24.18V8.37H8.49ZM194.29,31.44V8.5h-24.13V31.44h24.13ZM32.71,320.11v-23.02H8.56v23.02h24.15Zm137.37-.09h24.3v-22.82h-24.3v22.82Z" />
      </g>
    </svg>
  );
};

FilmsSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

FilmsSVG.defaultProps = {
  height: 26,
  width: 26,
  fill: "#222",
};

export default FilmsSVG;
