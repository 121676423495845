import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { colors } from "../../Color";
import { useController } from "react-hook-form";
import useFormatter from "../../../hooks/useFormatter";
function AvatarPickerC({
  userName,
  name,
  color,
  src,
  imgSrc,
  size,
  outLine,
  children,
  label,
  control,
  rules,
  style,
  defaultValue,
  onInput,
  optional,
  multiple,
  readOnly,
  ...props
}) {


  const fileRef = useRef();
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    control,
    defaultValue: multiple ? defaultValue || [] : defaultValue,
    rules,
    name,
  });
  const files = useMemo(
    () => (multiple ? (value ? value : []) : value ? [value] : []),
    [value, multiple]
  );

  const handleClick = useCallback(() => {
    fileRef.current.click();
  }, [fileRef]);
  const onChange_ = useCallback(
    (v) => {
      const files = multiple
        ? [...value, ...v.target.files]
        : v.target.files[0];
      if (!files) {
        return;
      }
      onChange(files);
      onInput(files);
      const dt = new DataTransfer();
      fileRef.current.files = dt.files;
    },
    [multiple, value, onChange, onInput]
  );

  const inputProps = {
    ...props,
    ref: fileRef,
    onChange: onChange_,
    onBlur,
    type: "file",
    multiple,
  };


  return (
  <div
    className={`FormC FileInputC${isTouched ? " formc__touched" : ""}${
      isDirty ? " formc__dirty" : ""
    }${!label ? " formc__unlabel" : ""}`}
    style={style}
  >
    {label ? (
      <label className="text-center" htmlFor={`_${name}_`}>
        {rules.required ? <strong>* </strong> : null}
        {label}:{" "}
        {optional ? (
          <span className="optional">
            ({typeof optional === "string" ? optional : "opcional"})
          </span>
        ) : null}
      </label>
    ) : null}
    {!readOnly ? (
      <>
        <input {...inputProps} />
        <File onClick={handleClick} file={files[0]} name={userName} color={color} size={size} />
      </>
    ) : null}
    <span className="label__error">{invalid ? error.message : ""}</span>
  </div>
  );
}


const File = ({ onClick, file, color, size, name }) => {
  
  const { BlobtoUrl } = useFormatter();
  const file_ = useMemo((_) => file?.location || file || '', [file]);
  const src = useMemo(_=> typeof file_ === "string" ? file_ : BlobtoUrl(file_), [file_, BlobtoUrl])
  return (
    <div
      onClick={onClick}
      className="Avatar pointer"
      style={{
        backgroundColor: color,
        height: size,
        width: size,
        borderRadius: size / 2,
      }}
    >
      {name ? (
        <strong style={{ fontSize: size / 2 }}>{name.slice(0, 2)}</strong>
      ) : null}
      <div className="avatar-img" style={{
        backgroundImage: `url('${src}')`
      }}>
        <div className="edit">
          <i className="fa fa-edit" style={{ fontSize: size / 3 }} />
        </div>
      </div>
    </div>
  );
};

AvatarPickerC.propTypes = {
  size: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onInput: PropTypes.func,
  defaultValue: PropTypes.any,
  outLine: PropTypes.bool,
}

AvatarPickerC.defaultProps = {
  color: colors[Math.floor(Math.random() * colors.length)],
  name: "input",
  rules: {},
  onInput: (v) => {},
  outLine: false,
};


export default AvatarPickerC
