import React from "react";
import PropTypes from 'prop-types'

const BandaInicioSVG = ({
  height,
  width,
  fill,
  onClick,
}) => {
  return (
    <svg
      id="Capa_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 227.07 334.78"
      onClick={onClick}
      height={height}
      width={width}
      fill={fill}
    >
      <g id="Insignias">
        <g>
          <path
            
            d="M113.52,0c9.94,.87,18.95,4.13,26.4,10.59,8.05,6.98,17.1,10.4,27.71,11.64,22.9,2.67,34.83,14.57,37.35,38.08,1.01,9.46,4.06,17.76,10.21,25.18,3.45,4.16,6.18,9.04,8.56,13.92,4.78,9.82,4.22,19.86-.54,29.53-2.13,4.32-4.64,8.62-7.76,12.25-6.46,7.53-9.41,16.13-10.48,25.84-2.5,22.61-14.09,34.84-36.61,37.52-10.64,1.26-20.02,4.37-28.13,11.59-7.07,6.29-15.73,9.93-25.14,10.58-9,.62-17.07-3.02-24.57-7.78-1.26-.8-2.55-1.62-3.62-2.65-7.86-7.52-17.35-10.87-28.07-11.7-4.04-.31-8.1-1.48-11.97-2.81-16.04-5.53-22.98-18.1-24.71-33.84-1.14-10.4-4.2-19.53-11.24-27.45C4.45,133.23,.51,124.47,.04,114.76c-.49-10.08,3.28-19.3,9.76-26.81,7.6-8.81,10.94-18.65,12.52-30.07,2.99-21.73,14.19-32.96,37.65-35.68,10.21-1.18,18.95-4.52,26.69-11.26C94.3,4.3,103.45,.82,113.52,0Zm-.2,161.31c26.23,.17,47.97-21.14,48.16-47.2,.19-26.66-21.07-48.34-47.64-48.58-26.29-.24-48.18,21.26-48.34,47.5-.16,26.65,21.09,48.1,47.82,48.28Z"
          />
          <path
            
            d="M41.49,225.73c7.6,1.28,14.91,2.24,22.05,3.87,2.5,.57,4.81,2.57,6.9,4.29,11.18,9.2,23.83,14.91,38.25,16.63,10.52,1.25,20.46-1.23,29.79-5.6,6.26-2.94,12.08-6.96,17.75-10.97,3.53-2.5,6.91-4.6,11.34-5.16,5.91-.74,11.76-2.04,17.92-3.14v3.76c0,30.44-.04,60.88,.04,91.32,.01,5.25-1.32,9.64-6.04,12.41-4.91,2.88-9.47,1.64-14.08-1.15-16.12-9.77-32.37-19.32-48.45-29.13-2.64-1.61-4.47-1.42-6.99,.11-16.11,9.77-32.36,19.32-48.48,29.08-4.63,2.8-9.22,3.93-14.09,1.04-4.7-2.79-5.96-7.23-5.95-12.47,.09-30.44,.04-60.88,.04-91.32v-3.57Z"
          />
          <path
            
            d="M113.32,137.34c-13.31-.07-23.93-10.84-23.82-24.13,.11-13,10.93-23.71,23.97-23.72,13.19-.02,24.04,10.86,24.01,24.06-.03,13.26-10.8,23.86-24.16,23.79Z"
          />
        </g>
      </g>
    </svg>
  );
}


BandaInicioSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func
};

BandaInicioSVG.defaultProps = {
  height: 18,
  width: 18,
  fill: '#34495e',
  onClick: ()=>{},
};


export default BandaInicioSVG
