import React from 'react'
import punto from '../../assets/cuadros.png'

import { ContactoForm } from '../Forms'

const ContactoSection = () => {
  return (
    <div className="bg-gradian not-container">
    <div className='ContactoSection ' style={{backgroundImage: `url('${punto}')`}}>
        <ContactoForm />
    </div>
    </div>
  )
}

export default ContactoSection