import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types'

const WrapAnimation = ({children, classAnimation, ...prop}) => {
  const wrapRef = useRef(null)
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // Loop over the entries
      entries.forEach((entry) => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          entry.target.classList.add(...classAnimation.split(' '));
        }
      });
    });
    observer.observe(wrapRef.current)
    // destroy observer
    return ()=>observer.disconnect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div ref={wrapRef} {...prop}>
      {children}
  </div>;
};



WrapAnimation.propTypes = {
    classAnimation: PropTypes.string
}

export default WrapAnimation
