import React from 'react'
import PropTypes from 'prop-types'
import './Forms.scss'

const LabelWrapper = ({label, style, children, optional, className}) => {
    return (
        <div className={`FormC LabelWrapper ${className||''}`} style={style}>
            {label ? <label>{label}: {optional?<span className='optional'>({typeof optional === 'string'?optional:'opcional'})</span>:null}</label> : null}
            <div className="labelwrapper-container">
                {children}
            </div>
        </div>
    )
}

LabelWrapper.propTypes = {
    label: PropTypes.string
}

export default LabelWrapper
