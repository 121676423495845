import React, { useContext } from "react";

//Hooks
import { WrapAnimation } from "../../core";
import { useController, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

//assets
import banner_img from "../../assets/mocks/bg-2.png";
import { Helmet } from "react-helmet";

const PrincipalBanner = () => {
  const { control } = useForm();

  return (
    <div
      style={{ backgroundImage: `url('${banner_img}')` }}
      className="PrincipalBanner"
    >
      <Helmet>
        <title>Mentor</title>
      </Helmet>
      <div className="sections-home">
        <WrapAnimation
          className="banner-content"
          classAnimation="animate__animated animate__fadeInRight"
        >
          <h1>MENTOR</h1>
          <h2>DESARROLLO HUMANO</h2>
          <p>Expertos en capacitación y crecimiento personal</p>
          <InputSearch control={control} />
        </WrapAnimation>
      </div>
    </div>
  );
};

const InputSearch = () => {
  const { handleSubmit, control } = useForm();
  const history = useHistory();

  const Buscar = (data) => {
    history.replace(`/buscar/${data.busqueda}`);
  };

  return (
    <WrapAnimation
      className="InputSearch"
      classAnimation="animate__animated animate__fadeInTopLeft"
    >
      <Input
        label="Buscar curso"
        onEnter={handleSubmit(Buscar)}
        name="busqueda"
        control={control}
      />
      <button onClick={handleSubmit(Buscar)}>Buscar</button>
    </WrapAnimation>
  );
};

const Input = ({ name, label, control, onEnter, ...props }) => {
  const handleKeyDown = (event) => {
    console.log("Typed");
    if (event.key === "Enter") {
      onEnter();
    }
  };
  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    rules: { required: "es requerido" },
    defaultValue: "",
  });

  const inputProps = {
    ...props,
    ref,
    onChange: (v) => {
      onChange(v?.target?.value);
    },
    onBlur,
    value,
  };
  return (
    <>
      <input
        onKeyDown={(ev) => {
          handleKeyDown(ev);
        }}
        type="text"
        {...inputProps}
        placeholder={label}
        name={name}
      />
    </>
  );
};

export default PrincipalBanner;
