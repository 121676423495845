import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import './Grid.scss'



const Grid = ({rowKey,columns,dataSource,className,pagination,onChangePage,children, onCloseCb}) => {
    
    const _columns_ = useMemo(()=>{
        if(columns){
            return columns
        } else {
            return dataSource.reduce((v,c)=>{
                Object.keys(c).forEach(key => {
                    try {
                        if(!v.find(f=>f.key===key)){
                            v.push({
                                key: key,
                                title: key.replace(/_/g,' ').toCapitalize(),
                                dataIndex: key
                            })
                        }
                    } catch (error) {
                    }
                });
                
                return v
            },[])
        } 
    },[columns,dataSource])
    

    return (
        <>
        <table className={`Grid table ${className}`}>
            <Header columns={_columns_} children={children} />
            <tbody>
                {dataSource.map((item,i)=><Row key={item[rowKey]} columns={_columns_} row={item} children={children} index={i} />)}
            </tbody>
        </table>
        {pagination && dataSource.length && pagination.last_page>1?
            <div className="col-12">
                <div id="pagination">
                    <nav> 
                        <ul className="pagination justify-content-center">
                            {pagination.page>1?<li className="page-item"><span onClick={_=>{onChangePage(1)}} className="page-link" aria-label="Previous"> &lt;&lt; </span> </li>:null}
                            {pagination.page-1>0?<li className="page-item"><span onClick={_=>{onChangePage(pagination.page-1)}} className="page-link">{pagination.page-1}</span> </li>:null}
                            <li className="page-item active"><span onClick={_=>{onChangePage(pagination.page)}} className="page-link">{pagination.page}</span> </li>
                            {pagination.page!==pagination.last_page?<li className="page-item"><span onClick={_=>{onChangePage(pagination.page+1)}} className="page-link">{pagination.page+1}</span> </li>:null}
                            {pagination.page<pagination.last_page?<li className="page-item"><span onClick={_=>{onChangePage(pagination.last_page)}} className="page-link" aria-label="Next"> &gt;&gt; </span> </li> :null}
                        </ul> 
                    </nav>
                </div>
            </div>
        :null}
        
        </>
    )
}

const Header = ({columns}) => {
    return(
        <thead>
            <tr>
                {columns.map((column)=>
                <th key={column.key} className={`${column.className||''}`} style={{width: column.width||'inherit'}}>
                    {column.title}
                </th>
                )}  
            </tr>
        </thead>
    )
} 
const Row = ({row,columns,children, index}) => {
    const columnsHashMap = useMemo(
        () => {
            const hashMap = columns.reduce((v,c) => {
                const path = (c.dataIndex||c.key).split('.')
                const column = path.reduce((vk,ck)=>vk?.[ck],row)
                return {
                    ...v,
                    [c.key]: typeof column ==='string'? column : JSON.stringify(column) 
                }
            },{})
            return hashMap
        },
        [row,columns],
    )
    return(
        <tr>
            {columns.map((column)=>
            <td key={column.key} className={`${column.className||''}`} style={{width: column.width||'inherit', ...column.style||{}}}>
                <Column row={row} column={columnsHashMap[column.key]||''} name={column.key}  children={children} index={index} />
            </td>
            )}
        </tr>
    )
}


function Column({column, row, name, children, index}){
    const childCol = Array.isArray(children)?
    children.find(f=>f?.type === Column&&f?.props?.name === name):(
        children?.type === Column&&children?.props?.name === name&&children
    )
    if(childCol){
        try {
            return childCol?.props?.context({column: JSON.parse(column),row, index})
        } catch (error) {
            return childCol?.props?.context({column, row, index})
        }
    }
    
        
        try {
            return <>{JSON.parse(column)}</>
        } catch (error) {
            return <>{column}</>
        }
    
}

Grid.Column = Column

Grid.propTypes = {
    rowKey: PropTypes.string,
    columns: PropTypes.array,
    dataSource: PropTypes.array,
    pagination: PropTypes.object,
    className: PropTypes.string,
    onChangePage: PropTypes.func
}

Grid.defaultProps = {
    rowKey: 'id',
    dataSource: [],
    className: '',
    onChangePage: ()=>{},
    onCloseCb: cb=>cb()
}

export default Grid
