import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'

const HtmlFotmat = ({html}) => {
  return (
    <>{
        parse(html)
    }</>
  )
}

HtmlFotmat.propTypes = {
    html: PropTypes.string.isRequired
}

export default HtmlFotmat