import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

//Hooks
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NavLink } from "react-router-dom";
import { Title, WrapAnimation } from "../../core";
import useFormatter from "../../hooks/useFormatter";

//Data
import GlobalContext from "../../context/GlobalContext";

//Assets
import punto from "../../assets/punto.png";
import punto_gray from "../../assets/punto_gray.png";
import categoria from "../../assets/images/cursos.png";

//Components
import CategoriaSlider from "../../components/Sliders/CategoriaSlider";
import SlidersBanner from "../../components/Home/SlidersBanner";
import CategoriaList from "../../components/Lists/CategoriaList";
import { Helmet } from "react-helmet";


import './CursosOld.scss';
/**
 * @component
 * Component responsible for displaying the listing courses
 */

const Cursos = (props) => {
  const history = useHistory();
  const {
    NestGet,
    NestPost,
    state: { user },
  } = useContext(GlobalContext);
  const { sku } = useParams();
  const { BlobToBase64 } = useFormatter();
  const [categories, setCategories] = useState([]);
  const [categorie, setCategorie] = useState({});
  const [curses, setCurses] = useState({});
  const categorie_curses = useMemo(() => {
    return Object.keys(curses)
      .map(
        (cat_id) =>
          categories.find((f) => f.id === cat_id) || {
            name: cat_id,
            id: cat_id,
            sku: "",
          }
      )
      .map((m) => ({ ...m, curses: curses[m.id] }));
  }, [curses, categories]);

  const fetchData = useCallback(
    async (sku_) => {
      setCategorie({});
      setCategorie({});
      if (sku_) {
        NestGet({
          schema: "categorias",
          query: {
            limit: 1,
            page: 1,
            query: JSON.stringify({ active: true, sku: sku_ }),
          },
        }).then((res) => {
          if (res.status === "success") {
            const cat = res.data?.[0];
            if (cat) {
              setCategorie(cat);
              NestPost({
                schema: `cursos/categoria/${sku}`,
                body: {
                  user_id: user ? user._id : undefined,
                },
              }).then((res) => {
                if (res.status === "success") {
                  setCurses(res.data);
                }
              });
            } else {
              history.replace("/cursos");
            }
          }
        });
      } else {
        NestPost({
          schema: "cursos/categoria/all",
          body: {
            user_id: user ? user._id : "",
          },
        }).then((res) => {
          if (res.status === "success") {
            setCurses(res.data);
          }
        });
      }
      NestGet({
        schema: "categorias",
        query: {
          query: JSON.stringify({ active: true }),
        },
      }).then((res) => {
        if (res.status === "success") {
          setCategories(res.data.sort((a, b) => a.name.localeCompare(b.name)));
        }
      });
    },
    [NestGet, NestPost, history, sku]
  );
  const CategoriaTemplate = useMemo(() => {
    return sku ? CategoriaList : CategoriaSlider;
  }, [sku]);
  useEffect(() => {
    fetchData(sku);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku]);
  return (
    <div className="Cursos bg-gradian">
      <Helmet>
        <title>Cursos | Mentor</title>
      </Helmet>
      <div
        className="cursos-container"
        style={{ backgroundImage: `url('${punto}')` }}
      >
        <WrapAnimation
          className="categories"
          classAnimation="animate__animated animate__fadeInTopLeft"
        >
          <nav style={{ backgroundImage: `url('${punto_gray}')` }}>
            <h3>Cursos online</h3>
            <h4>Categorías</h4>
            <ul>
              {categories.map((cat) => (
                <li key={cat.id}>
                  <NavLink to={`/cursos/${cat.sku}`}>{cat.name}</NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </WrapAnimation>
        <WrapAnimation
          className="banners"
          classAnimation="animate__animated animate__fadeInTopRight"
        >
          <SlidersBanner
            banners={categorie?.images || [{ location: categoria }]}
          />
        </WrapAnimation>
        <div className="list-curses">
          {categorie_curses.map((cat, i) =>
            cat.curses.length > 0 ? (
              <WrapAnimation
                classAnimation={`animate__animated animate__fadeInBottom${
                  i % 2 ? "Right" : "Left"
                }`}
                key={cat.id}
              >
                <CategoriaTemplate
                  title={cat.name}
                  to={cat.sku ? `/cursos/${cat.sku}` : false}
                  curses={cat.curses}
                />
              </WrapAnimation>
            ) : categorie_curses.length > 1 ? null : (
              <div className="categoria-title">
                <Title color="#345361">
                  <p style={{ textAlign: "center" }}>
                    No hay cursos para esta categoría.
                  </p>
                </Title>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

Cursos.propTypes = {};

export default Cursos;
