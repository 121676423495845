import moment from "moment";

export function transformHours(stringHours) {
  const milliseconds = moment.duration(stringHours).asMilliseconds();
  let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  let hours = Math.floor(milliseconds / (1000 * 60 * 60));
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes;
}
