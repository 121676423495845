import React, { useMemo, useState, useRef, useEffect } from 'react'
import { useTable, useSortBy, usePagination } from "react-table";
import PropTypes from 'prop-types'
import './Grid.scss'
import TableFilter from "react-table-filter";
import "./MentorGridFilter.scss"
import TablePagination from "@mui/material/TablePagination";
import {
    Paper,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TableContainer,
    styled,
} from "@material-ui/core";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';


const MentorGrid = props => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table.rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const table = useTable(
        {
            columns: props.columns,
            data: props.data
        },
    );

    const _columns_ = useMemo(() => {
        if (props.columns) {
            return props.columns
        } else {
            return props.data.reduce((v, c) => {
                Object.keys(c).forEach(key => {
                    try {
                        if (!v.find(f => f.key === key)) {
                            v.push({
                                key: key,
                                title: key.replace(/_/g, ' ').toCapitalize(),
                                dataIndex: key
                            })
                        }
                    } catch (error) {
                    }
                });

                return v
            }, [])
        }
    }, [props.columns, props.data])



    return (
        // <>
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer style={{ maxHeight: "70vh" }}>
                <Table size="small" stickyHeader {...table.getTableProps()} className={`Grid ${props.className} mentor_table`}>
                    <TableHead>
                        {table.headerGroups.map(headerGroup => (
                            <>
                                {props.columns.map((column) =>
                                    <TableCell
                                        id={column.id}
                                        key={column.key}
                                        filterkey={column.filterkey}
                                        className={`${column.className || ''}`}
                              
                                    >
                                        {column.title}
                                    </TableCell>
                                )}
                            </>
                        ))}
                    </TableHead >
                    {props.data.length > 0 && (
                        <TableBody>
                            {(table.rows).map(
                                (row, i) =>
                                    table.prepareRow(row) || (
                                        <Row
                                            key={row.getRowProps().key}
                                            id={row.getRowProps().key}
                                            columns={_columns_}
                                            row={row.original}
                                            children={props.children} index={i}
                                        />
                                    )
                            )}
                        </TableBody>
                    )}
                    {props.loading === false && props.data.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={6}>no records found</TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableContainer>
            {props.pagination && props.data.length && props.pagination.last_page > 1 ?
                <div className="col-12" style={{ paddingTop: "10px" }}>
                    <div id="pagination">
                        <nav>
                            <ul className="pagination justify-content-center">
                                {props.pagination.page > 1 ? <li className="page-item"><span onClick={_ => { props.onChangePage(1) }} className="page-link" aria-label="Previous"> &lt;&lt; </span> </li> : null}
                                {props.pagination.page - 1 > 0 ? <li className="page-item"><span onClick={_ => { props.onChangePage(props.pagination.page - 1) }} className="page-link">{props.pagination.page - 1}</span> </li> : null}
                                <li className="page-item active"><span onClick={_ => { props.onChangePage(props.pagination.page) }} className="page-link">{props.pagination.page}</span> </li>
                                {props.pagination.page !== props.pagination.last_page ? <li className="page-item"><span onClick={_ => { props.onChangePage(props.pagination.page + 1) }} className="page-link">{props.pagination.page + 1}</span> </li> : null}
                                {props.pagination.page < props.pagination.last_page ? <li className="page-item"><span onClick={_ => { props.onChangePage(props.pagination.last_page) }} className="page-link" aria-label="Next"> &gt;&gt; </span> </li> : null}
                            </ul>
                        </nav>
                    </div>
                </div>
                : null}
        </Paper>
    );
}

function Column({ column, row, name, children, index }) {
    const childCol = Array.isArray(children) ?
        children.find(f => f?.type === Column && f?.props?.name === name) : (
            children?.type === Column && children?.props?.name === name && children
        )
    if (childCol) {
        try {
            return childCol?.props?.context({ column: JSON.parse(column), row, index })
        } catch (error) {
            return childCol?.props?.context({ column, row, index })
        }
    }


    try {
        return <>{JSON.parse(column)}</>
    } catch (error) {
        return <>{column}</>
    }

}

const Row = ({ row, columns, children, index }) => {
    const columnsHashMap = useMemo(
        () => {
            const hashMap = columns.reduce((v, c) => {
                const path = (c.dataIndex || c.key).split('.')
                const column = path.reduce((vk, ck) => vk?.[ck], row)
                return {
                    ...v,
                    [c.key]: typeof column === 'string' ? column : JSON.stringify(column)
                }
            }, {})
            return hashMap
        },
        [row, columns],
    )
    return (
        <TableRow >
            {columns.map((column) =>
                <TableCell key={column.key} className={`${column.className || ''}`} style={{ width: column.width || 'inherit', ...column.style || {} }}>
                    <Column row={row} column={columnsHashMap[column.key] || ''} name={column.key} children={children} index={index} />
                </TableCell>
            )}
        </TableRow>
    )
}

MentorGrid.Column = Column

MentorGrid.propTypes = {
    rowKey: PropTypes.string,
    columns: PropTypes.array,
    dataSource: PropTypes.array,
    pagination: PropTypes.object,
    className: PropTypes.string,
    onChangePage: PropTypes.func,
    children: PropTypes.array
}

MentorGrid.defaultProps = {
    rowKey: 'id',
    dataSource: [],
    className: '',
    onChangePage: () => { },
    onCloseCb: cb => cb(),
    children: []
}

export default MentorGrid
