import { useMemo } from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
  
    return {
        search,
        query: useMemo(() =>new URLSearchParams(search), [search]),
        queryParams: useMemo(() =>{
            const preQuery = new URLSearchParams(search)
            const query = {}
            preQuery.forEach((value,key)=>query[key]=value)
            return query
        }, [search])
    };
  }

  export default useQuery