import React, { useState } from "react";
import moment from "moment";

//Hooks
import { Dialog, Grid, PillButton } from "../../core";
import { Link } from "react-router-dom";

//Components
import CursosDetalle from "./CursosDetalle";

/**
 * @component
 * Component responsible for displaying a table with the detailed list of orders
 */

export default function PedidoGrid({ data, pagination, columns, fetchData }) {
  const [courseModalVisibility, setCourseModalVisibility] = useState(false);
  const [courseModalInfo, setCourseModalInfo] = useState([]);

  function showCoursesModal(column) {
    setCourseModalInfo(column.coursesInfo);
    setCourseModalVisibility(true);
  }

  return (
    <>
      <Dialog
        visible={courseModalVisibility}
        onClose={() => {
          setCourseModalVisibility(false);
        }}
        children={<CursosDetalle courseInfo={courseModalInfo}></CursosDetalle>}
      />

      <Grid
        rowKey="_id"
        columns={columns}
        dataSource={data}
        className="table-striped"
        pagination={pagination}
        onChangePage={fetchData}
      >
        <Grid.Column
          name="courses"
          context={({ column, row }) => (
            <div
              onClick={() => {
                row.courses > 1 ? showCoursesModal(row) : (() => {})();
              }}
              className="d-flex"
            >
              {row.courses > 1 ? (
                <strong>
                  <a>{row.courses}</a>
                </strong>
              ) : (
                <a>{row.courses}</a>
              )}
            </div>
          )}
        />
        <Grid.Column
          name="n_pedido"
          context={({ column, row }) => (
            <div className="d-flex">{`${column
              .toString()
              .padStart(4, "0")}`}</div>
          )}
        />
        <Grid.Column
          name="request_invoice"
          context={({ column, row }) => column?'Si':'No'}
        />
        <Grid.Column
          name="amount"
          context={({ column, row }) => (
            <div className="d-flex">
              {column ? `$${Number(column).toFixed(2)}` : "Gratis"}
            </div>
          )}
        />
        <Grid.Column
          name="created_at"
          context={({ column, row }) => (
            <div className="d-flex">
              {moment(new Date(column), "YYYY-MM-DD[T]HH:mm:ss").format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            </div>
          )}
        />
        <Grid.Column
          name="updated_at"
          context={({ column, row }) => (
            <div className="d-flex">
              {moment(new Date(column), "YYYY-MM-DD[T]HH:mm:ss").format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            </div>
          )}
        />

        <Grid.Column
          name="action"
          context={({ column, row }) => (
            <div className="d-flex">
              <Link to={`/Backoffice/Pedido/${row._id}`}>
                <PillButton>
                  <i className="fas fa-eye"></i>
                </PillButton>
              </Link>
            </div>
          )}
        />
      </Grid>
    </>
  );
}
