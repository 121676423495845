import { useEffect, useCallback, useMemo, useReducer, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";

import esLocale from "moment/locale/es-mx";
//import moment from 'moment';

import API from "../utilities/api";
import { googleMapsApiKey } from "../config";
import useSocket from "./useSocket";
const api = new API();
const initialState = {
  token: "",
  isLoading: false,
  isLoadedState: false,
  notifications: [],
  user: {
    names: "Maria Ivana",
    last_name: "Del regil",
  },
  rank: {
    points: 0,
  },
};

function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case "SET_STATE":
      localStorage.setItem("state", JSON.stringify(payload));
      return payload;
    case "UPDATE_STATE":
      const new_state = { ...state, ...payload };
      localStorage.setItem("state", JSON.stringify(new_state));
      return new_state;
    default:
      return state;
  }
}
function volatilReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case "SET_STATE":
      return payload;
    case "UPDATE_STATE":
      const new_state = { ...state, ...payload };
      return new_state;
    default:
      return state;
  }
}

const useApiContext = () => {
  const dev = process.env.NODE_ENV === "development";
  // const [state, setState_] = useState(initialState);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [socket /*setSocket*/] = useSocket();
  const [volatileState, dispatchVolatile] = useReducer(volatilReducer, {});

  const setState = useCallback((obj) => {
    dispatch({ type: "UPDATE_STATE", payload: { ...obj } });
  }, []);
  const setState_ = useCallback((obj) => {
    dispatch({ type: "SET_STATE", payload: { ...obj } });
  }, []);
  const setVolatileState = useCallback((obj) => {
    dispatchVolatile({ type: "UPDATE_STATE", payload: { ...obj } });
  }, []);
  const setAppLoading = useCallback(
    (isLoading) => {
      setVolatileState({ isLoading });
    },
    [setVolatileState]
  );
  const setHasNotifications = useCallback(
    (hasNotifications) => {
      setState({ hasNotifications });
    },
    [setVolatileState]
  );

  const DummyFetch = useCallback(
    (data) =>
      fetch("http://google.com")
        .then((res) => ({ data }))
        .catch((err) => ({ data })),
    []
  );
  const DummyFetchSetTime = useCallback((data, time = 100) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve({ data }), time);
    });
  }, []);
  const Logout = useCallback(async () => {
    const data = Object.assign({}, initialState);
    data.menuOpen = window.innerWidth > 768 && !!data.menuOpen;

    data.language =
      data.language || (navigator.language.includes("es") ? "es-MX" : "en-US");
    setState_(data);
    localStorage.setItem("state", JSON.stringify(data));
    setTimeout(() => {
      window.location.href = "/";
      localStorage.setItem("state", JSON.stringify(data));
      setState_(data);
    }, 1000);

    return {
      status: "success",
      message: "",
    };
  }, [setState_]);

  const WrapApi = useCallback(
    async (cb) => {
      const result = await cb;
      if (!result) {
        return Promise.resolve({ status: "success", data: [] });
      }
      const { data, res, logout, token } = result;
      if (token) {
        setState({ token });
      }
      if (logout) {
        Logout();
      }
      const resdata = await data;
      if (res.status >= 400) {
        return Promise.reject({ ...resdata, status: "fail" });
      } else {
        let res = {};
        if (!resdata.hasOwnProperty("data")) {
          res.data = resdata;
        } else {
          res = resdata;
        }
        return Promise.resolve({ ...res, status: "success" });
      }
    },
    [setState, Logout]
  );

  const WrapFetch = useCallback(
    (cb) =>
      async (...params) => {
        const data = await cb(...params);
        if (data.status !== "success") {
          var icon_message = data.message.includes('Ponte en contacto con los administradores') ? 'info' : 'error';
          var title_message =  data.message.includes('Ponte en contacto con los administradores') ?'Tu cuenta se ha deshabilitado' : "Error!"
          Swal.fire({
            title: title_message,
            text: data.message,
            icon: icon_message,
          });
        }
        return data;
      },
    []
  );
  const GetLatLngHandled = (place) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${place}&key=${googleMapsApiKey}`;
    return fetch(url).then((res) => res.json());
  };
  const GetPlaceHandled = (e) => {
    const markerLatLng = e.latLng;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${markerLatLng.lat()},${markerLatLng.lng()}&key=${googleMapsApiKey}`;
    return fetch(url).then((res) => res.json());
  };

  const Login = useCallback(
    async ({ email, password }) => {
      //TODO LOGIN

      try {
        const user = await WrapApi(
          api.http.post("auth/login", { email, password, api: "Mentor" })
        );

        if (user.status !== "success") {
          return {
            ...user,
            status: "error",
            message:
              user.code ===
              "klayware.providers.kw2p_service_provider.register.ambito_inexistente"
                ? "Código de empresa inexistente"
                : "El usuario o contraseña no coinciden",
          };
        } else {
          setState({
            token: user.data.token,
            user: user.data.user,
          });
          return {
            status: "success",
          };
        }
      } catch (error) {
        return {
          ...error,
          status: "error",
          message: error.message,
        };
      }
    },
    [WrapApi, setState]
  );

  const Registro = useCallback(
    async ({ email, password, phone, names, last_name, mothers_last_name }) => {
      //TODO LOGIN
      try {
        const user = await WrapApi(
          api.http.post("auth/register", {
            email,
            password,
            phone,
            names,
            last_name,
            mothers_last_name,
          })
        );
        if (user.status !== "success") {
          return {
            ...user,
            status: "error",
            message:
              user.code ===
              "klayware.providers.kw2p_service_provider.register.ambito_inexistente"
                ? "Código de empresa inexistente"
                : "El usuario o contraseña no coinciden",
          };
        } else {
          return {
            status: "success",
          };
        }
      } catch (error) {
        return {
          ...error,
          status: "error",
          message: error.message,
        };
      }
    },
    [WrapApi]
  );

  const NestGet = useCallback(
    async ({ schema, id, query }) => {
      //TODO Nest
      try {
        const nest = await WrapApi(
          api.http.get(
            `${schema}${id ? `/${id}` : ""}${
              query ? `?${new URLSearchParams(query).toString()}` : ""
            }`,
            state.token
          )
        );

        return nest;
      } catch (error) {
        return {
          ...error,
          status: "error",
          message: error.message,
        };
      }
    },
    [WrapApi, state.token]
  );

  const NestPost = useCallback(
    async ({ schema, body }) => {
      //TODO Nest
      try {
        const nest = await WrapApi(
          api.http.post(`${schema}`, body, state.token)
        );
        return nest;
      } catch (error) {
        return {
          ...error,
          status: "error",
          message: error.message,
        };
      }
    },
    [WrapApi, state.token]
  );

  const NestSearch = useCallback(
    async ({ schema, query }) => {
      //TODO Nest
      try {
        const nest = await WrapApi(
          api.http.post(`${schema}/search`, { query }, state.token)
        );
        return nest;
      } catch (error) {
        return {
          ...error,
          status: "error",
          message: error.message,
        };
      }
    },
    [WrapApi, state.token]
  );

  const NestPatch = useCallback(
    async ({ schema, id, body }) => {
      //TODO Nest
      try {
        const nest = await WrapApi(
          api.http.patch(`${schema}${id ? `/${id}` : ""}`, body, state.token)
        );
        return nest;
      } catch (error) {
        return {
          ...error,
          status: "error",
          message: error.message,
        };
      }
    },
    [WrapApi, state.token]
  );

  const NestPut = useCallback(
    async ({ schema, id, body }) => {
      //TODO Nest
      try {
        const nest = await WrapApi(
          api.http.put(`${schema}${id ? `/${id}` : ""}`, body, state.token)
        );
        return nest;
      } catch (error) {
        return {
          ...error,
          status: "error",
          message: error.message,
        };
      }
    },
    [WrapApi, state.token]
  );

  const NestDelete = useCallback(
    async ({ schema, id }) => {
      //TODO Nest
      try {
        const nest = await WrapApi(
          api.http.delete(`${schema}${id ? `/${id}` : ""}`, state.token)
        );
        return nest;
      } catch (error) {
        return {
          ...error,
          status: "error",
          message: error.message,
        };
      }
    },
    [WrapApi, state.token]
  );

  const RecoverPassword = useCallback(
    async ({ ambito, correo }) => {
      api.ambito = ambito;
      try {
        const user = await WrapApi(api.forgotPassword({ correo }));
        if (user.status !== "success") {
          return {
            ...user,
            status: "error",
            message: user.message,
          };
        } else {
          return user;
        }
      } catch (error) {
        return {
          ...error,
          status: "error",
          message: error.message,
        };
      }
    },
    [WrapApi]
  );
  const WiggotImg = useCallback(({ id }) => {
    return fetch(`${api.base}/wiggot/img/${id}`);
  }, []);
  const WiggotApi = useCallback(
    ({ params, method = "GET", url, body }) => {
      // const hecors = 'https://cryptic-headland-94862.herokuapp.com/'
      params = params ? `?${params}` : "";
      let proceso;
      if (method === "GET") {
        proceso = api.http.get(`wiggot/${url}${params}`);
      }
      if (method === "POST") {
        proceso = api.http.post(`wiggot/${url}${params}`, body);
      }
      return WrapApi(proceso)
        .then((res) => {
          if (res.data.statusCode >= 400) {
            return Promise.reject(res.data);
          }
          return res.data;
        })
        .catch((err) => {
          setAppLoading(false);
          return { properties: [] };
        });
    },
    [WrapApi, setAppLoading]
  );

  const StripeApi = useCallback(
    ({ url = "", body }) => {
      return WrapApi(api.http.post(`stripe/${url}`, body, state.token))
        .then((res) => {
          if (res.data.statusCode >= 400) {
            return Promise.reject(res.data);
          }
          return res.data;
        })
        .catch((err) => {
          setAppLoading(false);
          return err;
        });
    },
    [WrapApi, setAppLoading, state.token]
  );

  const GetMyNotifications = useCallback(() => {
    return NestSearch({
      schema: "notifications",
      query: {
        to: state.user._id,
      },
    }).then((res) => {
      if (res.status === "success") {
        setState({ notifications: res.data.reverse() });
        return;
      }
    });
  }, [NestSearch, setState, state?.user?._id]);

  useEffect(() => {
    moment().locale("es-mx", [esLocale]);
    const res = localStorage.getItem("state");
    const data = Object.assign({}, initialState, JSON.parse(res || "{}"));
    data.menuOpen = window.innerWidth > 768 && !!data.menuOpen;

    data.language =
      data.language || (navigator.language.includes("es") ? "es-MX" : "en-US");
    data.isLoadedState = true;
    setState_(data);
  }, [setState_]);

  useEffect(() => {
    if (state?.user?._id) {
      socket.emit("joinUser", state.user._id);
      GetMyNotifications();
    }
    return () => {
      if (state?.user?._id) {
        socket.emit("leaveUser", state.user._id);
        GetMyNotifications();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setState, state?.user?._id]);

  const [CartList, setCartList] = useState([]);

  const GetCartList = () => {
    return NestGet({ schema: "user/cartlist" }).then((res) => {
      if (res.status === "success") {
        setCartList(res.data.map((m) => ({ ...m.curso, list_id: m._id })));
      }
    });
  };
  const CartAddCurso = ({
    _id,
    cost,
    description,
    image_detalle,
    teacher,
    name,
  }) => {
    NestPost({ schema: "user/cartlist", body: { curso: _id } })
      .then((res) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 1300,
        });
        if (res.status === "error") {
          Toast.fire({
            icon: "error",
            title:
              res.message === "item already in list"
                ? "Este curso ya está dentro del carrito."
                : res.message,
          });
        } else {
          GetCartList();
          Toast.fire({
            icon: "success",
            title: "Se agregó el curso al carrito.",
          });
        }
      })
      .catch((err) => {});
  };
  const CartDeleteCurso = (_id, tost = 1) => {
    NestDelete({ schema: `user/cartlist/${_id}` }).then((res) => {
      if (res.status === "success") {
        GetCartList();
        if (tost === 1) {
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1300,
          });
          Toast.fire({
            icon: "success",
            title: "Se eliminó el curso del carrito.",
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1300,
          });
          Toast.fire({
            icon: "success",
            title: "Se pasó el curso al carrito de compras.",
          });
        }
      }
    });
  };

  const [WishList, setWishList] = useState([]);

  const GetWishList = () => {
    return NestGet({ schema: "user/wishlist" }).then((res) => {
      if (res.status === "success") {
        setWishList(res.data.map((m) => ({ ...m.curso, list_id: m._id })));
      }
    });
  };
  const WishAddCurso = ({
    _id,
    cost,
    description,
    image_detalle,
    teacher,
    name,
  }) => {
    NestPost({ schema: "user/wishlist", body: { curso: _id } })
      .then((res) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 1300,
        });
        if (res.status === "error") {
          Toast.fire({
            icon: "error",
            title:
              res.message === "item already in list"
                ? "Este curso ya está dentro de la lista de deseos."
                : res.message,
          });
        } else {
          GetWishList();
          Toast.fire({
            icon: "success",
            title: "Se agregó el curso a la lista de deseos.",
          });
        }
      })
      .catch((err) => {});
  };
  const WishDeleteCurso = (_id, tost = 1) => {
    NestDelete({ schema: `user/wishlist/${_id}` }).then((res) => {
      if (res.status === "success") {
        GetWishList();
        if (tost === 1) {
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1300,
          });
          Toast.fire({
            icon: "success",
            title: "Se eliminó el curso de la lista de deseos.",
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1300,
          });
          Toast.fire({
            icon: "success",
            title: "Se pasó el curso al carrito de compras.",
          });
        }
      }
    });
  };

  return {
    socket,
    dev,
    state,
    hasNotifications: state.hasNotifications,
    volatileState,
    setState,
    WishList,
    GetWishList,
    WishAddCurso,
    WishDeleteCurso,
    setVolatileState,
    setAppLoading,
    GetCartList,
    CartAddCurso,
    CartList,
    CartDeleteCurso,
    setHasNotifications,
    CartCount: CartList.length,
    isLogin: useMemo(() => !!state.token, [state.token]),
    user_id: state?.user?._id || "",
    role: state?.user?.type,
    isAdmin: state?.user?.type !== "student",
    rol: state?.user?.type,
    isLoading: volatileState.isLoading,
    isLoadedState: state.isLoadedState,
    DummyFetch,
    DummyFetchSetTime,
    Registro: WrapFetch(Registro),
    Login: WrapFetch(Login),
    Logout: WrapFetch(Logout),
    RecoverPassword: WrapFetch(RecoverPassword),
    NestGet: WrapFetch(NestGet),
    NestSearch: WrapFetch(NestSearch),
    NestPost: WrapFetch(NestPost),
    NestPatch: WrapFetch(NestPatch),
    NestPut: WrapFetch(NestPut),
    NestDelete: WrapFetch(NestDelete),
    GetMyNotifications,
    WiggotApi,
    WiggotImg,
    StripeApi,
    GetPlaceHandled,
    GetLatLngHandled,
  };
};

export default useApiContext;
