import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

//Data
import GlobalContext from '../../context/GlobalContext';

//Hooks
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'react-hook-form';
import { ChooseC, FileInputC, TextAreaC } from '../../core';
import { rulesRequired } from '../../Constants';
import useS3File from '../../hooks/useS3File';

//Components
import { DragDropInputOtions } from '../../components/Inputs';


/**
 * @component
 * Component responsible for displaying the complete questionnaire and evaluating it
*/

const Quiz = () => {
  const history = useHistory()
  const {control, handleSubmit} = useForm();
  const {handleUpload} = useS3File()
  const { NestGet, NestPost, isLogin, state:{user} } = useContext(GlobalContext)
  const { sku, module } = useParams()
  const [capsule_, setCapsule] = useState({})
  const [isCalific, setCalific] = useState(false)
  const [evaluadas, setEvaluadas] = useState({})
  

  const this_mod = useMemo(() => {
    return capsule_.modules?.find(f => f._id === module)
  }, [module, capsule_])

  const quiz = useMemo(() => {
    return this_mod?.quiz || []
  }, [this_mod])

  const next_cap = useMemo(() => {
    if (Object.keys(capsule_).length === 0) {
      return ''
    }
    const modul_index = capsule_.modules?.map(m => m._id).indexOf(module)
    if (modul_index + 1 >= capsule_.modules?.length && capsule_.has_exam) {
      return `/exam/${sku}`
    } else if (modul_index + 1 >= capsule_.modules?.length && !capsule_.has_exam) {
      // If the module does not have exam, return to previous cap
      const module_ = capsule_.modules?.[modul_index]
      const nexCap = module_?.capsules?.[0]?._id
      return `/clase/${sku}/${module}/${nexCap}`
    }
    
    const module_ = capsule_.modules?.[modul_index + 1]
    const nexCap = module_?.capsules?.[0]?._id
    return `/clase/${sku}/${module_._id}/${nexCap}`
  }, [capsule_, module, sku])

  const onSubmit = async data => {
    const quiz_data = {}
    const evaluar_data = {}
    for(let key in data){
      const quest = quiz.find(f=>f._id === key)
      switch(quest.type){
        case "upload":
        {
          quiz_data[key] = await handleUpload(data[key],`mentor/quiz/${sku}/${module}/${user._id}`)
          evaluar_data[key] = true
          break
        }
        case "camp_text":
        {
          quiz_data[key] = data[key]
          evaluar_data[key] = true
          break
        }
        case "sort":{
          quiz_data[key] = data[key]
          evaluar_data[key] = data[key].every((m,i)=>m===quest.answer[i])
          break
        }
        case "true or false":
        case "options":{
          quiz_data[key] = `${data[key]}`
          evaluar_data[key] = `${data[key]}` === quest.answer
          break
        }
        default:
          break
      }

    }
    setEvaluadas(evaluar_data)
    setCalific(true)
    NestPost({
      schema: `cursos/eval_quiz/${sku}/${module}`,
      body:{
        exam: quiz_data
      }
    })
  }

  //eval_quiz

  const fetchData = useCallback(
    () => {
      NestPost({
        schema: `cursos/capsula/${sku}`,
        body: {
          modul_id: module,
          cap_id: 'quiz',
        }
      }).then(res => {
        if (res.status === 'success') {
          setCapsule(res.data)
        } else {
          history.replace(`/cursos/${sku}`)
        }
      })
    },
    [NestPost, sku, module, history],
  )


  useEffect(() => {
    fetchData()
  }, [sku, module])

  return (
    <div className="PageQuiz">
      <div className='contain-title'>
        <div className='Quiz-title'>
          <h1 className='Quiz-title_text'>Quiz {this_mod?.name||''}</h1>
        </div>
      </div>

      <div className='contain-questions'>
        <div className='header-questions'>
        </div>
        <Questions quiz={quiz} control={control} evalue={evaluadas} readOnly={isCalific} />
        <div className='button-section'>
          {isCalific?
          <button onClick={_ => history.push(next_cap)} className='button-quiz'>Siguiente</button>:
          <button onClick={handleSubmit(onSubmit)} className='button-quiz'>Calificar</button>
          }
        </div>
      </div>
    </div>
  )
}


const Questions = ({ quiz, control, evalue, readOnly }) => {
  return (
    <>
      {quiz.map((quest) =>
          <div className={`questions${evalue[quest._id] !== undefined?(evalue[quest._id]?' success':' error'):''}`} key={quest._id}>
            <div className='contain-question'>
              {quest.type === "options" &&
                <>
                  <span className='rounded-number'>{quiz.indexOf(quest) + 1}</span>
                  <span className='question'>{quest.question}</span>
                  <div className='d-flex fooddp'>
                    <div className={`${quest.extra_info ? 'answers' : 'answers-not choose'}`}>
                      <ChooseC control={control} name={quest._id} rules={rulesRequired} className="answer-choose" readOnly={readOnly}>
                        {quest.options.map((answer) =>(
                          <ChooseC.Option value={answer.value} key={answer.value}>
                            <div className='contain-answer' >
                              <div className='answer'>
                                <span className={`square-select`}>
                                </span>{answer.label}</div>
                            </div>
                          </ChooseC.Option>
                        ))}
                      </ChooseC>
                    </div>
                    {quest.extra_info &&
                      <div className='text-information'>
                        <p>{quest.extra_info}</p>
                      </div>}
                  </div>
                </>
              }

              {quest.type === "true or false" &&
                <>
                  <span className='rounded-number'>{quiz.indexOf(quest) + 1}</span>
                  <span className='question'>{quest.question}</span>
                  <div className={`d-flex fooddp`}>
                    <div className={`${quest.extra_info ? 'answers' : 'answers-not choose'}`}>
                      <ChooseC control={control} name={quest._id} rules={rulesRequired} className="answer-choose" readOnly={readOnly} pure>
                        {[{value: 'true', label: 'Verdadero'},{value: 'false', label: 'Falso'}].map((answer) =>(
                          <ChooseC.Option value={answer.value} key={answer.value}>
                            <div className='contain-answer' >
                              <div className='answer'>
                                <span className={`square-select`}>
                                </span>{answer.label}</div>
                            </div>
                          </ChooseC.Option>
                        ))}
                      </ChooseC>
                    </div>
                    {quest.extra_info &&
                      <div className='text-information'>
                        <p>{quest.extra_info}</p>
                      </div>}
                  </div>
                </>
              }

              {quest.type === "sort" &&
                <>
                  <span className='rounded-number'>{quiz.indexOf(quest) + 1}</span>
                  <span className='question'>{quest.question}</span>
                  <div className='d-flex fooddp'>
                    <div className={`sorting ${quest.extra_info ? 'answers' : 'answers-100'}`}>
                      <DragDropInputOtions control={control} name={quest._id} options={quest.options} readOnly={readOnly} />
                    </div>
                    {quest.extra_info &&
                      <div className='text-information'>
                        <p>{quest.extra_info}</p>
                      </div>}
                  </div>
                </>
              }

              {quest.type === "upload" &&
                <>
                  <span className='rounded-number'>{quiz.indexOf(quest) + 1}</span>
                  <span className='question'>{quest.question}</span>
                  <div className='d-flex fooddp'>
                    <div className={`${quest.extra_info ? 'answers' : 'answers-100'}`}>
                      <div className='contain-answer'>
                         <div className="answer_upload">
                          <FileInputC control={control} name={quest._id} rules={rulesRequired} readOnly={readOnly}>
                            <i className="fa-solid fa-upload mr-1"></i>Subir archivo
                          </FileInputC>
                        </div>
                      </div>
                    </div>
                    {quest.extra_info &&
                      <div className='text-information'>
                        <p>{quest.extra_info}</p>
                      </div>}
                  </div>
                </>
              }

              {quest.type === "camp_text" &&
                <>
                  <span className='rounded-number'>{quiz.indexOf(quest) + 1}</span>
                  <span className='question'>{quest.question}</span>
                  <div className='d-flex fooddp'>
                    <div className='answers'>
                      <div className='contain-answer'>
                        <TextAreaC className="d-flex" control={control} name={quest._id} rules={rulesRequired} readOnly={readOnly}/>
                      </div>
                    </div>
                    {quest.extra_info &&
                      <div className='text-information'>
                        <p>{quest.extra_info}</p>
                      </div>}
                  </div>
                </>
              }
            </div>
          </div>
      )}
    </>
  )
}



export default Quiz