import { useEffect } from "react";
import { useController } from "react-hook-form";
import { DragDrop } from "../../core";

const DragDropInputOtions = ({name,control,options}) =>{
    const {
      field: { ref, onChange, onBlur, value },
      fieldState: { isTouched, isDirty, error, invalid },
    } = useController({
      control,
      defaultValue: [],
      name,
    });
    useEffect(()=>{
      
      if(options&&value&&options?.length!==value?.length){
        onChange([...value.filter(ff=>options.map(m=>m.value).includes(ff)),...options.map(m=>m.value).filter(ff=>!value.includes(ff))])
      }
    },[options, value, onChange])

    return <DragDrop
    name={name}
    items={value}
    keyExtractor={(item,i)=>`${item}-${i}`}
    onChange={onChange}
    renderItem={(option,index)=>
    <div className='contain-answer'>
      <label className='answer_sort'>{option}</label>
    </div>
    }
  />
  }

  export default DragDropInputOtions;
  