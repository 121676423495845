import React from "react";
import PropTypes from "prop-types";

const UserSVG = ({ height, width, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 341.07 408.65"
    >
      <defs></defs>
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M60.84,408.65c-4.72-1.93-9.87-2.07-14.69-3.75-26.3-9.16-41.84-27.34-45.07-55-2.79-23.95.12-47.67,5.19-71.14,4-18.29,10.53-35.48,21.65-50.68,15.23-20.83,36.28-30.19,61.63-30.79,4.69-.11,8.73,2,12.58,4.43,5.63,3.49,11.18,7.11,16.77,10.67,17.7,11.24,36.86,17.4,58,15.88,15.57-1.13,30-6.33,43.26-14.45,4.52-2.76,9.22-5.36,13.31-8.68,10.63-8.63,22.48-8.9,35-6.15,24,5.27,40.9,19.62,52.46,40.89,9.82,18.06,14.62,37.62,17.6,57.75,2.23,15,2.7,30.13,2.48,45.3-.48,32-22.58,58.23-54,64.21-2.74.52-5.72-.1-8.24,1.54ZM170.46,384.7h96.18a70.47,70.47,0,0,0,18.54-2.06c19.9-5.43,31.65-20.54,31.81-41.4a258,258,0,0,0-2.33-39.76c-2.57-17.35-6.29-34.37-14.91-49.93-7.18-12.94-16.63-23.2-31.35-27.66-8.75-2.65-16.95-3.5-24.55,3.23-3.23,2.87-7.21,4.94-11,7.18-31,18.47-63.58,23.75-98.37,12.27-14.93-4.92-28-13.14-40.82-21.85a15.61,15.61,0,0,0-11.94-2.81,56.81,56.81,0,0,0-18,5.85c-13.59,7.44-21.27,19.8-26.76,33.5-11,27.45-13.85,56.27-12,85.54.91,14.18,8.26,24.93,20.83,31.86,8.86,4.88,18.57,6,28.47,6Q122.37,384.75,170.46,384.7Z" />
          <path d="M69.62,98.32C69.66,43.48,113.44-.1,168.4,0a98.52,98.52,0,0,1-.26,197C112.79,197.22,68.69,151,69.62,98.32Zm98.28,74.36c39.88,1.17,74.77-33.43,74.59-74a74.58,74.58,0,0,0-74-74.73C127.6,23.43,93.77,57.1,93.6,98.36,93.43,140.18,128.66,173.6,167.9,172.68Z" />
        </g>
      </g>
    </svg>
  );
};

UserSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};

UserSVG.defaultProps = {
  height: 22,
  width: 22,
};

export default UserSVG;
