import React from 'react'
import './Schemas.scss'

const Title = ({children,subtitle,color}) => {
    return (
        <div className='Title' style={color?{'--color': color}:{}}>
            {children}{subtitle?(<span className='subtitle'> {subtitle}</span>):null}
        </div>
    )
}

export default Title
