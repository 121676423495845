import { useCallback, useMemo, useState } from "react";
import tus from "tus-js-client";
import { Observable } from "rxjs";

function dummySuccess() {
  console.log("Download %s from %s", this.file.name, this.url);
}
function dummyError(error) {
  console.log("Failed because: " + error);
}
function dummyProgress(bytesUploaded, bytesTotal) {
  let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
  console.log(bytesUploaded, bytesTotal, percentage + "%");
}

const useVimeo = (accessToken_ = "") => {
  const [accessToken, setAccessToken] = useState(accessToken_);
  const headerPost = useMemo(() => {
    return accessToken
      ? {
          Accept: "application/vnd.vimeo.*+json;version=3.4",
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      : null;
  }, [accessToken]);
  const notFoundToken = () => {
    console.log("Not found access token try again");
  };
  const deleteVideo = useCallback(
    async (video_id) => {
      if (!headerPost) {
        notFoundToken();
        return;
      }
      return fetch(`https://api.vimeo.com/videos/${video_id}`, {
        method: "DELETE",
        headers: headerPost,
      });
    },
    [headerPost]
  );

  const getVideDuration = useCallback((file) => {
    return new Promise((resolve, reject) => {
      var video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.onError = function (error) {
        reject(error);
      };

      video.src = URL.createObjectURL(file);
    });
  }, []);

  const editVideo =  useCallback(
    async ({
      id:video_id,
      name,
      description,
      onSuccess = dummySuccess,
      onError = dummyError,
    }) => {
      if (!headerPost) {
        notFoundToken();
        return;
      }
      try {
        const response = await fetch(`https://api.vimeo.com/videos/${video_id}`, {
          method: "PATCH",
          body: JSON.stringify({
            name,
            description,
          }),
          headers: headerPost,
        }).then((res) => res.json());

        if (response.error) {
          throw new Error(response.error);
        }
        onSuccess(response)
      } catch (error) {
        onError(error);
      }
    },
    [headerPost]
  );
  const uploadVideo = useCallback(
    async ({
      file,
      name,
      description,
      onSuccess = dummySuccess,
      onProgress = dummyProgress,
      onError = dummyError,
    }) => {
      if (!headerPost) {
        notFoundToken();
        return;
      }
      const fileSize = file.size.toString();
      try {
        const response = await fetch(`https://api.vimeo.com/me/videos`, {
          method: "POST",
          body: JSON.stringify({
            upload: {
              approach: "tus",
              size: fileSize,
            },
            name,
            description,
          }),
          headers: headerPost,
        }).then((res) => res.json());

        if (response.error) {
          throw new Error(response.error);
        }
        response.duration = await getVideDuration(file);

        // Create a new tus upload
        const upload = new tus.Upload(file, {
          endPoint: "https://api.vimeo.com/me/videos",
          uploadUrl: response.upload.upload_link,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          headers: {},
          onError,
          onProgress,
          onSuccess: (_) => onSuccess(response),
        });

        // Start the upload
        upload.start();
        return;
      } catch (error) {
        onError(error);
      }
    },
    [getVideDuration, headerPost]
  );

  const getVideo = useCallback(
    (query) => {
      if (!headerPost) {
        notFoundToken();
        return;
      }
      const url = [
        "https://api.vimeo.com/me/videos",
        query && new URLSearchParams(query).toString(),
      ]
        .filter((ff) => ff)
        .join("?");
      return fetch(url, {
        method: "GET",
        headers: headerPost,
      }).then((res) => res.json());
    },
    [headerPost]
  );
  const getImage = useCallback(
    (vide_id) => {
      if (!headerPost) {
        notFoundToken();
        return;
      }
      const url = `https://api.vimeo.com/videos/${vide_id}/pictures`;
      return fetch(url, {
        method: "GET",
        headers: headerPost,
      }).then((response) => response.json());
    },
    [headerPost]
  );

  const observableGetVideo = (query) => {
    return new Observable((sub) => {
      const get = (page) =>
        getVideo({ ...query, page }).then((resp) => {
          sub.next(resp);
          if (page < Math.ceil(resp.total / resp.per_page)) {
            get(page + 1);
          } else {
            sub.complete();
          }
        });
      get(1);
    });
  };

  return {
    setAccessToken,
    deleteVideo,
    uploadVideo,
    editVideo,
    getVideo,
    getImage,
    observableGetVideo,
    availableToken: !!accessToken,
  };
};

export default useVimeo;
