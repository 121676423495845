import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import './Item.scss'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const SimpleItem = ({ to, icon, text, href, onClick, ...props }) => {
  const { width } = useWindowDimensions();
  const handleClick = useCallback(
    () => {
      if(width<=768){
        onClick()
      }
    },
    [onClick, width],
  )
    const Icon = icon
  return (
    <li className="menu-item" onClick={handleClick}>
      {!href?
      <Link className="menu-item-anchor" {...props} to={to}>
        <Icon />
        <div className="menu-item-tooltip">
         {text}
        </div>
        <span className="menu-item-tooltiptext tooltip__right">{text}</span>
      </Link>:
      <a className="menu-item-anchor" {...props} href={href}>
        <Icon />
        <div className="menu-item-tooltip">
          {text}
        </div>
        <span className="menu-item-tooltiptext tooltip__right">{text}</span>
      </a>
      }
    </li>
  );
};

SimpleItem.propTypes = {
    to: PropTypes.string,
    text: PropTypes.string.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
};
SimpleItem.defaultProps = {
    icon: ()=><></>,
    onClick: ()=>{},
  };

export default SimpleItem;
