import React, { useContext } from "react";

//Hooks
import { WrapAnimation } from "../../core";
import { Link } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";

//assets

import icon1 from '../../assets/servicios/why-1.png'
import icon2 from '../../assets/servicios/why-2.png'
import icon3 from '../../assets/servicios/why-3.png'
import icon4 from '../../assets/servicios/why-4.png'
import punto from "../../assets/punto.png";


/**
 * @component
 * Component in charge of why mentor with home
*/

const Servicios = () => {

  const { isLogin } = useContext(GlobalContext);

  return (
    <div className="Servicios" style={{ backgroundImage: `url('${punto}')` }}>
      <h2>¿Por qué Mentor?</h2>
      <p>
        Porque queremos incrementar el número de profesionales preparados a
        través de una capacitación especializada y actualizada utilizando los
        métodos de aprendizaje más revolucionarios e innovadores.
      </p>
      <div className="servicios-container">
        <Item
          source={icon1}
          title="Ponentes"
          descripcion="Contamos con un claustro de ponentes expertos en la materia y con un compromiso de actualización constante, además de estar en permanente búsqueda de expertos en temas de nuestro catálogo."
        />
        <Item
          className="animate__delay-1s"
          source={icon2}
          title="Modalidad"
          descripcion="El formato de nuestros cursos permite tener la flexibilidad que necesitas para continuar con tus actividades sin descuidar tu formación."
        />
        <Item
          className="animate__delay-2s"
          source={icon3}
          title="Certificación"
          descripcion="Somos un centro evaluador avalado por CONOCER. Además de que la mayoría de nuestros cursos cuentan con diversas certificaciones o acreditaciones."
        />
        <Item
          className="animate__delay-3s"
          source={icon4}
          title="Experiencia"
          descripcion="Contamos con un amplio catálogo de temas además de una gran trayectoria y experiencia en la impartición de los mismos."
        />
      </div>
      {isLogin ? null : (
        <Link className="btn" to="/login">
          Iniciar sesión
        </Link>
      )}
    </div>
  );
};

const Item = ({ source, title, descripcion, className }) => {
  return (
    <div
      className={`item ${className || ""}`}
    >
      <img src={source} alt={`imagen de ${title}`} />
      <strong>{title}</strong>
      <p>{descripcion}</p>
    </div>
  );
};

export default Servicios;
