import React, {useContext} from 'react'
import GlobalContext from '../context/GlobalContext'
import Header from '../components/Dash/Header.jsx'
import Footer from '../components/Dash/Footer.jsx'
import Menu from '../components/Menu/Menu.jsx'
import Loading from '../components/Loading/Loading.jsx'
import './styles.scss'

const Dash = ({children}) => {
    const {isLoading} = useContext(GlobalContext)
    return (
        <div className="DashLayout">
            <Header />
            {/* <div className="Container"> */}
            <Menu />
            <main className="Container">
                {children}
                <Footer />
            </main>
            {isLoading?
            <Loading />
            :null}
            {/* </div> */}
        </div>
    )
}

export default Dash
