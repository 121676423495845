import React, { useCallback, useContext } from "react";

import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import { Button, InputC, Title, Dialog } from "../../core";
import { rulesEmail } from "../../Constants";
import GlobalContext from "../../context/GlobalContext";

export default function DialogForgottenPasword({ visible, setVisible }) {
  const { control, handleSubmit } = useForm();

  const { NestPost, setAppLoading } = useContext(GlobalContext);
  const onSubmit = useCallback(
    async (data) => {
      setAppLoading(true);

      NestPost({
        schema: "auth/forgotten-password",
        body: {
          email: data.recovery_email,
        },
      })
        .then((res) => {
          Swal.fire({
            title: "En breve recibirás más instrucciones en tu correo.",
            icon: "success",
          });
          setVisible(false);
          setAppLoading(false);
        })
        .catch((err) => {
          setAppLoading(false);
        });
    },
    [setAppLoading, NestPost]
  );
  return (
    <Dialog visible={visible} onClose={() => setVisible(false)}>
      <Dialog.Body>
        <div className="d-flex flex-wrap justify-content-center">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <div className="w-100 p-2">
                  <Title>
                    <strong style={{ fontSize: "20px" }}>
                      Obtener código para iniciar sesión
                    </strong>
                  </Title>
                </div>
                <div className="w-100 p-2">
                  <p>Enviar código por correo electrónico</p>
                  <InputC
                    control={control}
                    name="recovery_email"
                    className="w-100"
                    placeholder="c********@gmail.com"
                    rules={{ ...rulesEmail, required: true }}
                  />
                </div>
                <Button
                  className="btn-hide-profile"
                  onClick={handleSubmit(onSubmit)}
                >
                  Enviar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog.Body>
    </Dialog>
  );
}
