import React from 'react'
import { Helmet } from 'react-helmet';


/**
 * @component
 * Component responsible for displaying the terms and conditions screen
*/

const TerminosCondiciones = () => {
    return (
        <div className="AvisoPrivacidad">
          <Helmet>
            <title>Términos y condiciones | Mentor</title>
          </Helmet>
          <div className="container-fluid" style={{padding: 0, height: '50vh'}}>
            <div className="hero-image">
              <div className="hero-text">
                <h1 style={{fontSize:50, color: '#fff'}}>
                    Términos y condiciones
                </h1>
              </div>
            </div>
          </div>
          <section className="container wow fadeIn pt-5 pb-5">
            <div className="container privacity_zone">
              <div className="row row_pv">
                <p className="privacity_p">
                  Con fundamento en los artículos 15 y 16 de la Ley Federal de
                  Protección de Datos Personales en Posesión de Particulares hacemos
                  de su conocimiento que Guía Excelencia Inmobiliaria, S. A. de C.
                  V., con domicilio Avenida Pablo Neruda 2813, Colonia Providencia,
                  C. P. 44639 Guadalajara, Jalisco, es Responsable de recabar sus
                  datos personales, del uso que se le dé a los mismos y de su
                  protección.
                </p>
                <div
                  className="col-sm-12 col-md-12 col-lg-6 col-xl-6"
                  style={{paddingLeft: '0%', paddingRight: '4%'}}
                >
                  <p className="privacity_p">
                    La información personal que se recabe será utilizada para las
                    siguientes finalidades:
                  </p>
                  <ol start="1">
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Integrar su expediente.
                    </li>
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Darlo de alta en el sistema.
                    </li>
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Realizar cotizaciones sobre sus productos o servicios.
                    </li>
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Dar cumplimiento a las obligaciones contraídas.
                    </li>
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Promover nuestros servicios y productos.
                    </li>
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Realizar estudios mercadológicos.
                    </li>
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Gestión de escrituración y formalización notarial.
                    </li>
                  </ol>
                </div>
                <div
                  className="col-sm-12 col-md-12 col-lg-6 col-xl-6"
                  style={{paddingRight: '0%'}}
                >
                  <p className="privacity_p">
                    De manera adicional, utilizaremos su información personal para
                    las siguientes finalidades secundarias, pero que nos permiten y
                    facilitan brindarle una mejor atención:
                  </p>
                  <ol start="1">
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Notificarle sobre nuevos servicios o productos que tengan
                      relación con los ya contratados o sobre cambios en éstos
                      mismo.
                    </li>
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Elaborar estudios y programas que son necesarios para
                      determinar la preferencia de nuestros servicios.
                    </li>
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Realizar evaluaciones periódicas de nuestros servicios a
                      efecto de mejorar la calidad de los mismos.
                    </li>
                    <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                      Fines mercadotécnicos, publicitarios o de prospección.
                    </li>
                  </ol>
                </div>
                <p className="privacity_p">
                  En caso de que no desee que sus datos personales sean tratados
                  para estos fines adicionales, usted puede presentar desde este
                  momento en el Departamento de Datos Personales, a través del medio
                  que se mencionan al final de este Aviso de Privacidad,
                  manifestando lo anterior.
                </p>
                <p className="privacity_p">
                  La negativa para el uso de sus datos personales para estas
                  finalidades no podrá ser un motivo para terminar la relación que
                  tenemos con usted.
                </p>
                <p className="privacity_p">
                  <strong>
                    DATOS PERSONALES QUE SERÁN SOMETIDOS A TRATAMIENTO
                  </strong>
                </p>
                <p className="privacity_p">
                  Para las finalidades señaladas en el presente aviso de privacidad,
                  podemos recabar sus datos personales de distintas formas: cuando
                  usted nos los proporciona directamente; cuando visita nuestro
                  sitio de Internet o utiliza nuestros servicios en línea, y cuando
                  obtenemos información a través de otras fuentes que están
                  permitidas por la ley.
                </p>
                <p className="privacity_p">
                  Para llevar a cabo las finalidades antes mencionadas, requerimos
                  obtener datos personales de identificación, de contacto y
                  laborales. Le informamos que no manejamos datos sensibles ni
                  patrimoniales o financieros.
                </p>
                <p className="privacity_p">
                  <strong>
                    LIMITACIÓN DEL USO Y/O DIVULGACIÓN DE TUS DATOS Y/O REVOCACIÓN
                    DEL CONSENTIMIENTO PARA SU TRATAMIENTO
                  </strong>
                </p>
                <p className="privacity_p">
                  En todo momento, usted podrá revocar el consentimiento que nos ha
                  otorgado para el tratamiento de sus datos personales, a fin de que
                  dejemos de hacer uso de los mismos; así mismo podrá limitar el
                  alcance, en todo o en parte del uso y/o divulgación de los datos
                  personales. Para ello, es necesario que presente su petición en el
                  Departamento de Datos Personales, a través del medio que se
                  mencionan al final de este Aviso de Privacidad.
                </p>
                <p className="privacity_p">
                  Es importante que tenga en cuenta que no en todos los casos
                  podremos atender su solicitud o concluir el uso de forma
                  inmediata, ya que es posible que por alguna obligación legal
                  requiramos seguir tratando sus datos personales. Asimismo, usted
                  deberá considerar que para ciertos fines, la revocación de su
                  consentimiento implicará la conclusión de su relación con
                  nosotros.
                </p>
                <p className="privacity_p">
                  Asimismo, sus datos se cancelarán un año después de dar por
                  concluido su tratamiento sin perjuicio de ninguna ley especial
                  vigente que contemple un plazo mayor, en cuyo caso, se conservarán
                  hasta en tanto la ley así lo obligue.{" "}
                </p>
                <p className="privacity_p">
                  Para conocer el procedimiento y requisitos para la revocación del
                  consentimiento, usted podrá comunicarse al teléfono (33) 23 00 13 54
                  o vía correo electrónico a privacidad@grupoguia.mx
                </p>
                <p className="privacity_p">
                  <strong>TRANSFERENCIAS Y REMISIONES DE DATOS PERSONALES</strong>
                </p>
                <p className="privacity_p">
                  Le informamos que sus datos personales son compartidos dentro del
                  país con las siguientes personas y empresas distintas a nosotros,
                  para los siguientes fines:
                </p>
                <table
                  style={{"width":"100%","borderCollapse":"collapse","border":"none"}}
                  width="603"
                >
                  <tbody>
                    <tr>
                      <td
                        style={{"width":"149.6pt","border":"solid windowtext 1.0pt","padding":"0in 5.4pt 0in 5.4pt"}}
                        width="199"
                      >
                        <p className="privacity_pt">
                          <strong>Destinatario de los Datos</strong>
                        </p>
                      </td>
                      <td
                        style={{"width":"302.35pt","border":"solid windowtext 1.0pt","borderLeft":"none","padding":"0in 5.4pt 0in 5.4pt"}}
                        width="403"
                      >
                        <p className="privacity_pt">
                          <strong>Finalidad</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{"width":"149.6pt","border":"solid windowtext 1.0pt","borderTop":"none","padding":"0in 5.4pt 0in 5.4pt"}}
                        width="199"
                      >
                        <p className="privacity_pt">
                          Sociedades desarrolladoras de los inmuebles y/o quienes
                          los promuevan
                        </p>
                      </td>
                      <td
                        style={{"width":"302.35pt","borderTop":"none","borderLeft":"none","borderBottom":"solid windowtext 1.0pt","borderRight":"solid windowtext 1.0pt","padding":"0in 5.4pt 0in 5.4pt"}}
                        width="403"
                      >
                        <p className="privacity_pt">
                          Toda vez que Guía Excelencia Inmobiliaria, S.A. de C.V. se
                          dedica únicamente a la gestoríaa e intermediación para la
                          compraventa de inmuebles, la operación final debe
                          celebrarse con los desarrolladores o quienes pretendan
                          realizar la venta.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{"width":"149.6pt","border":"solid windowtext 1.0pt","borderTop":"none","padding":"0in 5.4pt 0in 5.4pt"}}
                        width="199"
                      >
                        <p className="privacity_pt">Notarias</p>
                      </td>
                      <td
                        style={{"width":"302.35pt","borderTop":"none","borderLeft":"none","borderBottom":"solid windowtext 1.0pt","borderRight":"solid windowtext 1.0pt","padding":"0in 5.4pt 0in 5.4pt"}}
                        width="403"
                      >
                        <p className="privacity_pt">
                          A fin de realizar las debidas protocolizaciones de las
                          compraventas que se realicen
                        </p>
                      </td>
                    </tr>
                    <tr style={{height: '3.85pt'}}>
                      <td
                        style={{"width":"149.6pt","border":"solid windowtext 1.0pt","borderTop":"none","padding":"0in 5.4pt 0in 5.4pt","height":"3.85pt"}}
                        width="199"
                      >
                        <p className="privacity_pt">Instituciones bancarias.</p>
                      </td>
                      <td
                        style={{"width":"302.35pt","borderTop":"none","borderLeft":"none","borderBottom":"solid windowtext 1.0pt","borderRight":"solid windowtext 1.0pt","padding":"0in 5.4pt 0in 5.4pt","height":"3.85pt"}}
                        width="403"
                      >
                        <p className="privacity_pt">
                          Cuando se busca obtener un crédito hipotecario, Guía
                          Excelencia Inmobiliaria, S.A. de C.V. comparte los datos
                          pertinentes a los bancos de la elección del titular para
                          que éstos realicen los estudios que le resulten
                          pertinentes y suficientes.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="privacity_p" style={{marginTop: "3%"}}>
                  Asimismo Guía Excelencia Inmobiliaria, S. A. de C. V., puede
                  llegar a transmitir sus datos personales en los casos exigidos en
                  el artículo 37 de la Ley Federal de Protección de Datos Personales
                  en Posesión de los Particulares.
                </p>
                <p className="privacity_p">
                  <strong>MODIFICACIONES AL AVISO DE PRIVACIDAD.</strong>
                </p>
                <p className="privacity_p">
                  Nos reservamos el derecho de efectuar en cualquier momento
                  modificaciones o actualizaciones al presente aviso de privacidad,
                  en atención a novedades legislativas o políticas internas.
                </p>
                <p className="privacity_p">
                  Nos comprometemos a mantenerlo informado sobre los cambios que
                  pueda sufrir el presente aviso de privacidad, en caso de que esto
                  suceda, será publicado en nuestro sitio Web: http://grupoguia.mx/.
                </p>
                <p className="privacity_p">
                  El procedimiento a través del cual se llevarán a cabo las
                  notificaciones sobre cambios o actualizaciones al presente aviso
                  de privacidad es el siguiente:
                </p>
                <ol start="1">
                  <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                    Entrar a la página web: http://grupoguia.mx/
                  </li>
                  <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                    Al final de la página, en la parte inferior, hacer click sobre
                    la liga Aviso de Privacidad.
                  </li>
                  <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                    La liga te direccionará a otra página donde se desplegarán los
                    avisos de privacidad.
                  </li>
                  <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                    Seleccionar el aviso de privacidad indicado.
                  </li>
                  <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                    Revisar la fecha de última actualización.
                  </li>
                </ol>
                <p className="privacity_p">
                  <strong>CONTROL Y SEGURIDAD DE INFORMACIÓN PERSONAL.</strong>
                </p>
                <p className="privacity_p">
                  Guía Excelencia Inmobiliaria, S. A. de C. V., se compromete a
                  tomar las medidas necesarias para proteger la información
                  recopilada, utilizando tecnologías de seguridad y procedimientos
                  de control en el acceso, uso o divulgación de su información
                  personal sin autorización; por ejemplo, almacenando, la
                  información personal proporcionada en servidores ubicados en
                  Centros de Datos que cuentan con controles de acceso limitado.
                  Para solicitudes en línea, utilizamos también tecnologías de
                  seguridad que protegen la información personal que nos sea
                  transmitida a través de los diversos medios electrónicos; sin
                  embargo, ningún sistema de seguridad o de transmisión de datos del
                  cual la empresa no tenga el control absoluto y/o tenga dependencia
                  con internet puede garantizar que sea totalmente seguro.
                </p>
                <p className="privacity_p">
                  De igual modo, Guía Excelencia Inmobiliaria, S. A. de C. V., sus
                  empleados, representantes, subcontratistas, consultores y/o los
                  terceros que intervengan en cualquier fase del tratamiento de los
                  Datos Personales y/o Datos Personales Patrimoniales del Titular
                  deberán guardar confidencialidad respecto de éstos, obligación que
                  subsistirá aún después de finalizar la relación entre la
                  organización con el Titular.
                </p>
                <p className="privacity_p">
                  <strong>MEDIOS ELECTRÓNICOS Y COOKIES.</strong>
                </p>
                <p className="privacity_p">
                  Si usted utiliza medios electrónicos en relación a sus datos
                  personales se generarán cookies a efecto de proporcionarle un
                  mejor servicio.
                </p>
                <p className="privacity_p">
                  Los cookies son pequeñas piezas de información que son enviadas
                  por el sitio Web a su navegador.
                </p>
                <p className="privacity_p">
                  Los cookies se almacenan en el disco duro de su equipo y se
                  utilizan para determinar sus preferencias cuando se conecta a los
                  servicios de nuestros sitios, así como para rastrear determinados
                  comportamientos o actividades llevadas a cabo por usted dentro de
                  nuestros sitios.
                </p>
                <p className="privacity_p">
                  En secciones de nuestro sitio requerimos que el cliente tenga
                  habilitados los cookies ya que algunas de las funcionalidades
                  requieren de éstas para trabajar. Los cookies nos permiten, entre
                  otras cosas:
                </p>
                <ol start="1">
                  <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                    Reconocerlo al momento de entrar a nuestros sitios y ofrecerle
                    de una experiencia personalizada.
                  </li>
                  <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                    Conocer la configuración personal del sitio especificada por
                    usted, por ejemplo, los cookies nos permiten detectar el ancho
                    de banda que usted ha seleccionado al momento de ingresar al
                    home page de nuestro sitio, de tal forma que sabemos qué tipo de
                    información es aconsejable descargar.
                  </li>
                  <li className="privacity_sp" style={{listStyle: 'decimal'}}>
                    Calcular el tamaño de nuestra audiencia y medir algunos
                    parámetros de tráfico, pues cada navegador que obtiene acceso a
                    nuestros sitios adquiere un cookie que se usa para determinar la
                    frecuencia de uso y las secciones de los sitios visitadas,
                    reflejando así sus hábitos y preferencias, información que nos
                    es útil para mejorar el contenido, los titulares y las
                    promociones para los usuarios.
                  </li>
                </ol>
                <p className="privacity_p">
                  El botón de “ayuda” que se encuentra en la barra de herramientas
                  de la mayoría de los navegadores, le dirá cómo evitar aceptar
                  nuevos cookies, cómo hacer que el navegador le notifique cuando
                  recibe un nuevo cookie o cómo deshabilitar todos los cookies; sin
                  embargo, las cookies le permitirán tomar ventaja de las
                  características más benéficas que le ofrecemos, por lo que
                  recomendamos tenerlas activas.
                </p>
                <p className="privacity_p">
                  <strong>DEL EJERCICIO DE LOS DERECHOS ARCO.</strong>
                </p>
                <p className="privacity_p">
                  Es importante informarle que usted tiene el derecho de ejercer sus
                  derechos de Acceso, Rectificación, Cancelación y Oposición de sus
                  datos personales, para ello, es necesario que envíe la solicitud
                  por escrito en los términos que marca la Ley en su artículo 29 a
                  Guía Excelencia Inmobiliaria, S. A. de C. V., con domicilio
                  Avenida Pablo Neruda 2813, Colonia Providencia, C. P. 44639
                  Guadalajara, Jalisco.
                </p>
                <p className="privacity_p">
                  Para conocer el procedimiento y requisitos para el ejercicio de
                  los derechos ARCO, usted podrá ponerse en contacto con nuestro
                  Departamento de Datos Personales, que dará los formatos necesarios
                  para dar trámite de las solicitudes para el ejercicio de estos
                  derechos, y atenderá cualquier duda que pudiera tener respecto al
                  tratamiento de su información, al teléfono (33) 3627 5000 o vía
                  correo electrónico a privacidad@grupoguia.mx.
                </p>
                <p className="privacity_p">
                  Fecha de última actualización: 08 de julio de 2016
                </p>
              </div>
            </div>
          </section>
        </div>
      );
}

export default TerminosCondiciones