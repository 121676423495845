import React from "react";
import { Helmet } from "react-helmet";

import capacitacion from "../../assets/servicios/capacitacion.png";
import objetivo from "../../assets/servicios/objetivo.png";

/**
 * @component
 * Component responsible for showing the screen of who we are
 */
const QuienesSomos = () => {
  return (
    <div className="SobreNosotros not-container">
      <Helmet>
        <title>Sobre nosotros | Mentor</title>
      </Helmet>
      <div className="container-fluid" style={{ padding: 0, height: "50vh", minHeight: 500 }}>
        <div className="hero-image">
          <div className="hero-text">
            <h1 style={{ fontSize: 50, color: "#fff" }}>Sobre nosotros</h1>
          </div>
        </div>
      </div>
      <section className="container wow fadeIn pt-5 pb-5">
        <div className="container privacity_zone">
          <div className="contain-img">
            <img src={capacitacion} alt="" />
          </div>
          <div className="contain-text">
            <h1>¿Quiénes somos?</h1>
            <p>
              Somos una empresa de capacitación y desarrollo que busca ser una
              referencia para la formación profesional y personal, a través de
              herramientas adecuadas para su mejor desempeño de campo. Tenemos
              los recursos más actualizados para profesionistas con los que
              incrementarán sus oportunidades de crecimiento. Contamos con
              cursos de actualización para la comercialización de los bienes
              raíces y programas especializados en liderazgo y desarrollo humano
              ajustados a las necesidades de cada persona.
            </p>
          </div>
        </div>
        <div className="container privacity_zone">
          <div className="contain-img">
            <img src={objetivo} alt="" />
          </div>
          <div className="contain-text">
            <h1>Nuestro Objetivo</h1>
            <p>
              Incrementar el número de profesionistas certificados y preparados
              a través de una capacitación especializada y actualizada. Buscando
              los métodos de aprendizaje más revolucionarios e innovadores. Ser
              parte del proceso de aprendizaje y crecimiento tanto personal como
              profesional.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default QuienesSomos;
