import React, { useContext, useCallback, useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import AyudaSVG from "../../assets/svgs/AyudaSVG";
import HomeSVG from "../../assets/svgs/HomeSVG";
import CloseSVG from "../../assets/svgs/CloseSVG";
import BarsSVG from "../../assets/svgs/BarsSVG";
import LupaSVG from "../../assets/svgs/LupaSVG";
import ShopSVG from "../../assets/svgs/ShopSVG";
import RelogSVG from "../../assets/svgs/RelogSVG";
import ServiciosSVG from "../../assets/svgs/ServiciosSVG";
import SoporteSVG from "../../assets/svgs/SoporteSVG";
import DesarolloSVG from "../../assets/svgs/DesarrolloSVG";
import { SimpleItem, CollapserItem } from "../../core";
import ComercioSVG from "../../assets/svgs/ComercioSVG";
import UserSVG from "../../assets/svgs/UserSVG";
import BannersSVG from "../../assets/svgs/BannersSVG";
import FilmsSVG from "../../assets/svgs/FilmsSVG";
import CategorySVG from "../../assets/svgs/CategorySVG";
import CursosSVG from "../../assets/svgs/CursosSVG";
import BellSVG from "../../assets/svgs/BellSVG";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as ReporteSVG } from '../../assets/svgs/reporte.svg';
import "./menu.scss";

const Menu = () => {
  let match = useRouteMatch();
  const {
    state: { menuOpen },
    setState,
    isAdmin,
    hasNotifications,
    setHasNotifications,
  } = useContext(GlobalContext);
  const { NestGet } = useContext(GlobalContext);
  const fetchData = useCallback(async () => {
    NestGet({
      schema: "contacto/notifications",
    })
      .then((res) => {
        setHasNotifications(res.data);
      })
      .finally((_) => { });
  }, [NestGet]);
  const { pathname } = useLocation();
  useEffect(() => {
    fetchData();
    console.log(pathname);
  }, [pathname]);

  return (
    <div
      className={`Menu ${menuOpen !== undefined && menuOpen ? "menu__open" : "menu__close"
        } ${menuOpen === undefined ? "hidden" : ""}`}
    >
      <div className="menu-action">
        {menuOpen ? (
          <CloseSVG onClick={() => setState({ menuOpen: false })} />
        ) : (
          <BarsSVG
            fill="#bbbbba"
            height={18}
            width={18}
            onClick={() => setState({ menuOpen: true })}
          />
        )}
      </div>
      <nav className="menu-container">
        <ul>
          <SimpleItem
            onClick={(_) => setState({ menuOpen: false })}
            text="Inicio"
            icon={HomeSVG}
            to="/"
          />
          {isAdmin ? (
            <>
              <SimpleItem
                onClick={(_) => setState({ menuOpen: false })}
                text="Usuarios"
                icon={UserSVG}
                to={`${match.url}/Usuarios`}
              />
              <SimpleItem
                onClick={(_) => setState({ menuOpen: false })}
                text="Reportes"
                icon={ReporteSVG}
                to={`${match.url}/Reportes`}
              />
              <SimpleItem
                onClick={(_) => setState({ menuOpen: false })}
                text="Filmoteca"
                icon={FilmsSVG}
                to={`${match.url}/Filmoteca`}
              />
              <SimpleItem
                onClick={(_) => setState({ menuOpen: false })}
                text="Banners"
                icon={BannersSVG}
                to={`${match.url}/Banners`}
              />
              <SimpleItem
                onClick={(_) => setState({ menuOpen: false })}
                text="Categorias"
                icon={CategorySVG}
                to={`${match.url}/Categorias`}
              />
              <SimpleItem
                onClick={(_) => setState({ menuOpen: false })}
                text="Cursos"
                icon={CursosSVG}
                to={`${match.url}/Cursos`}
              />
              <SimpleItem
                onClick={(_) => setState({ menuOpen: false })}
                text="Buzon contacto"
                icon={(_) => (
                  <SoporteSVG active={hasNotifications ? true : false} />
                )}
                to={`${match.url}/Buzon_contacto`}
              />
              <SimpleItem
                onClick={(_) => setState({ menuOpen: false })}
                text="Pedidos"
                icon={ComercioSVG}
                to={`${match.url}/Pedido`}
              />
              <SimpleItem
                onClick={(_) => setState({ menuOpen: false })}
                text="Asignar cursos"
                icon={ServiciosSVG}
                to={`${match.url}/Asignar`}
              />
            </>
          ) : (
            <></>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
