import React from "react";
import PropTypes from "prop-types";

const ComercioSVG = ({ height, width, onClick }) => {
  return (
    <svg
      onClick={onClick}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 390.56 341.73"
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <path d="M0,119V109c.2-.13.49-.21.59-.39Q29.41,57.79,58.14,6.88C60.91,2,64.78,0,70.26,0c19.2.1,38.4,0,57.6,0Q224,.05,320.14,0c5.84,0,9.74,2.2,12.64,7.33,18.4,32.61,37,65.1,55.38,97.74a19.06,19.06,0,0,1,2.37,10.05c-1.44,20.69-11.38,36.23-29.55,46.27-2.77,1.54-3.91,3-3.91,6.3.13,53.79,0,107.59.22,161.38,0,9.21-5.73,12.67-12.83,12.66q-149.17-.19-298.35-.09c-9.08,0-12.57-3.52-12.57-12.68q0-80.68.06-161.38c0-3-.76-4.7-3.58-6.12-9.78-4.93-17.72-12.12-22.55-22C4.28,133,2.44,125.82,0,119Zm337.65,203V168.44c-24.08-1.82-28.3-3.92-49.63-24.57-11.39,15.56-26.72,24.16-46,24.21s-34.84-8.6-46.38-24.23c-24.4,33.91-72.12,30.53-93.16-.09C90.19,160.42,73.63,168.49,53,168.23V321.92ZM362.39,99c-.79-1.51-1.38-2.73-2.05-3.91-13.6-24-27.25-47.88-40.76-71.88-1.49-2.64-3.08-3.67-6.18-3.66q-118.08.14-236.15,0c-2.86,0-4.51.72-6,3.35C57.67,47.19,43.9,71.34,30.19,95.52c-.6,1.07-1.12,2.18-1.8,3.52ZM299.22,119c.56,14.94,17.31,29.31,34.4,29.78,19.68.55,35.16-14.81,36.74-29.78Zm-278.66-.06c1,13.26,14.82,29.53,35.1,29.82,17.66.25,35.11-14.16,36-29.82Zm92.63,0c2.75,17.69,20.38,31.36,38.15,29.74,17.11-1.56,33.53-16.31,33-29.74Zm93,0c1.87,16.56,18.92,30.37,36.55,29.78,17.33-.57,34-14.93,34.53-29.78Z" />
        </g>
      </g>
    </svg>
  );
};

ComercioSVG.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
};
ComercioSVG.defaultProps = {
  height: 22,
  width: 22,
};

export default ComercioSVG;
